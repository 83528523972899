import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import RoutesLinks from "../../../constants/routes";
import elementStore from "../../../store/element/elementStore";
import { ElementsTypes } from "../../../types/elementsTypes";
import Table from "../../ui/table/Table";
import { tableContainerStyle } from "../../ui/table/TableComponents";
import TableFieldsDetailsLink from "../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { ColumnType } from "../../ui/table/types";
import withPermission from "../../../hocs/withPermissions";
import { TableMainLink } from "../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedElementsTableProps {
    permission: string;
}

const columns: ColumnType<ElementsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.elementName",
        field: "name",
        sortable: true,
        isResizable: true,
        width: "440px",
        getField: (dataItem) => (
            <TableMainLink to={`${RoutesLinks.elements.href}/${dataItem.id}`}>
                {dataItem.name}
            </TableMainLink>
        ),
    },
    {
        header: "table.header.listName",
        field: "list_name",
        sortable: true,
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.createdAt", field: "created", sortable: true },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.status", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={RoutesLinks.elements.href} />
        ),
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...elementStore.pagination,
        setPaginationPage: elementStore.setPaginationPage,
        setPaginationSize: elementStore.setPaginationSize,
    };

    useEffect(() => {
        elementStore.fetchData();
    }, [elementStore.pagination, elementStore.ordering, elementStore.filters]);

    return (
        <Table
            scrollable
            tableContainerStyles={tableContainerStyle}
            columns={columns}
            data={elementStore.data}
            pagination={tablePagination}
            checkedItems={elementStore.checkedItems}
            loading={elementStore.loading}
            sortMode={elementStore.ordering}
            onSort={elementStore.toggleSort}
            onCheckboxChange={elementStore.toggleItem}
            onMainCheckboxChange={elementStore.toggleMainItem}
        />
    );
});

const ProtectedElementsTable = ({ permission }: ProtectedElementsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedElementsTable);
