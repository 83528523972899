/* eslint-disable no-restricted-syntax */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent } from "react";
import * as Yup from "yup";

import useTranslation from "../../../../../../hooks/useTranslation";
import listElementsStore from "../../../../../../store/list/listElementsStore";
import snackbarStore from "../../../../../../store/snackbar/snackbarStore";
import ErrorLabel from "../../../../../ui/errors/ValidationErrorBox/ErrorLabel";
import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import NotUploadedAreaContent from "./NotUploadedAreaContent";
import UploadedAreaContent from "./UploadedAreaContent";
import { Box, UploadAreaComponent } from "./UploadListsElementsModalComponents";

export interface UploadFIleType {
    file: File | null;
}

type UploadListsElementsModalProps = ModalProps & { listId?: string }

export const SUPPORTED_FORMAT = ".xlsx";

const UploadListsElementsModal = ({
    listId, isOpen, title, buttonText, setOn, handleClose,
}: UploadListsElementsModalProps) => {
    const { t } = useTranslation();

    const formik = useFormik<UploadFIleType>({
        initialValues: { file: null },
        validationSchema: Yup.object({
            file: Yup.mixed()
                .test(
                    "fileFormat",
                    t.lists.validation.file,
                    (value) => value instanceof File && value.name.endsWith(SUPPORTED_FORMAT),
                ),
        }),
        onSubmit: async (value) => {
            if (listId && value.file) {
                const formData = new FormData();
                formData.append("data", value.file, value.file.name);
                formData.append("list_id", listId);

                handleClose?.();
                formik.resetForm();
                listElementsStore.resetPostErrors();

                await listElementsStore.uploadData(formData);

                if (listElementsStore.isUploadFileSuccess) {
                    snackbarStore.add({ variant: "success", text: t.lists.snackbar.uploadFileSuccess });
                    listElementsStore.fetchData(listId);
                } else if (listElementsStore.isUploadFileFailed) {
                    const errorMessage = listElementsStore.postErrors || t.lists.snackbar.uploadFileError;
                    snackbarStore.add({ variant: "error", text: errorMessage });
                }
            }
        },
    });

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            formik.setFieldValue("file", event.target.files[0]);
        }
    };

    const handleFileDrop = (event: React.DragEvent) => {
        event.preventDefault();

        if (event.dataTransfer.files.length) {
            formik.setFieldValue("file", event.dataTransfer.files[0]);
        }
    };

    const resetForm = () => formik.resetForm();

    const closeUploadListsElementsModal = () => {
        handleClose?.();
        resetForm();
        listElementsStore.resetPostErrors();
    };

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            buttonDisabled={!formik.values.file || !!formik.errors.file}
            hasChildModal
            isForm
            setOn={setOn}
            handleClose={closeUploadListsElementsModal}
            isLoading={listElementsStore.isFileUploading}
            handleSubmit={formik.handleSubmit}
        >
            <Box>
                <UploadAreaComponent htmlFor="file-upload" onDrop={handleFileDrop} $isFile={!!formik.values.file}>
                    {formik.values.file
                        ? <UploadedAreaContent formik={formik} onClick={resetForm} />
                        : <NotUploadedAreaContent file={formik.values.file} onChange={handleFileChange} />}
                </UploadAreaComponent>
                {formik.errors.file && ErrorLabel(formik.errors.file)}
            </Box>
        </Modal>
    );
};

export default observer(UploadListsElementsModal);
