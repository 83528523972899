import { NewRulePackServiceType, RulesPacksServicesTypes } from "../../types/rulesPacksServicesTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: RulesPacksServicesTypes,
}

const postRulesPacksServices = (data: NewRulePackServiceType) => request<ResponseType>({
    method: "POST",
    uri: "rules-packs/services/",
    data,
});

export default postRulesPacksServices;
