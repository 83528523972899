import dayjs from "dayjs";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { MAX_DATE } from "../../../../constants/dates";
import { permissionsMap } from "../../../../constants/permissions";
import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../constants/validationRules";
import useTranslation from "../../../../hooks/useTranslation";
import elementStore from "../../../../store/element/elementStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { EditElementType } from "../../../../types/elementsTypes";
import PreDatePicker from "../../../ui/datepicker/PreDatePicker";
import { SwitchButtonContainer } from "../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../ui/modal/modalStyle";
import ProtectedSwitch from "../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import { Title } from "../../entities/partners/new/NewPartnersComponents";

const fieldsList: Array<keyof EditElementType> = ["name", "is_active", "description", "time_on_the_list"];

const currentDate = dayjs();

const EditElementModal = ({
    isOpen, title, buttonText, setOn,
}: ModalProps) => {
    const { t } = useTranslation();
    const { id: elementId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = elementStore;

    const formik = useFormik<EditElementType>({
        initialValues: {},
        validationSchema: Yup.object({
            time_on_the_list: Yup.string().nullable().test({
                message: t.validation.pastDate,
                test: (field) => dayjs(field).isAfter(currentDate),
            }),
        }),
        onSubmit: async (fields) => {
            if (elementId) {
                const fieldsData = { ...fields, time_on_the_list: fields.time_on_the_list || MAX_DATE };

                await elementStore.patchData(fieldsData, elementId, fieldsList);

                if (elementStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    setOn(false);
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            }
        },
    });

    const handleModalClose = () => {
        elementStore.unsetEditState();
        formik.resetForm();
    };

    const toggleElementStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        if (selectedItem) {
            const timeOnTheList = selectedItem.time_on_the_list === MAX_DATE ? "" : selectedItem.time_on_the_list;

            formik.setValues({ ...selectedItem, time_on_the_list: timeOnTheList });
        }
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isForm
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={elementStore.loading}
            buttonDisabled={elementStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleModalClose}
        >
            <EditSectionWrapper>
                <FloatTextArea
                    formik={formik}
                    disabled={elementStore.loading}
                    name="description"
                    placeholder={t.table.header.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                />
                <PreDatePicker
                    formik={formik}
                    disabled={elementStore.loading}
                    isNowButtonShown={false}
                    name="time_on_the_list"
                    placeholder={t.placeholder.addForever}
                    addonBeforeText={t.placeholder.until}
                />
                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        checked={formik.values.is_active}
                        disabled={elementStore.loading}
                        permission={permissionsMap.elements.changeStatus}
                        onChange={toggleElementStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditElementModal);
