import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const BasicButton = styled.button`
    display: inline-flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 8px;
    border: none;
    padding: 20px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;

    &:disabled {
        color: ${colors.textDisabled};
        background: ${colors.buttonDisabledBackground};
        cursor: not-allowed;

        &:hover {
            background: ${colors.buttonDisabledBackground};
            color: ${colors.textDisabled};
        }
    }
`;

export const SecondaryButton = styled(BasicButton)<{ $width?: string }>`
    color: ${colors.textPrimary};
    font-family: Roboto;
    background: ${colors.buttonDefaultBackground};
    width: ${(props) => (props.$width ? props.$width : "420px")};

    &:hover {
        background: ${colors.buttonSecondaryHover};
    }
`;

export const PrimaryButton = styled(BasicButton)<{ $width?: string, $marginTop?: string }>`
    width: ${(props) => (props.$width ? props.$width : "420px")};
    margin-top: ${(props) => (props.$marginTop ? props.$marginTop : "0")};
    background: ${colors.buttonPrimary};
    color: ${colors.white};

    &:hover {
        background: ${colors.buttonPrimaryHover};
    }
`;

export const TextButton = styled.button`
    color: ${colors.textPrimary};
    border: none;
    background: none;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 105%;
    cursor: pointer;

    &:hover {
        color: ${colors.buttonPrimaryHover};
    }
`;
