import dataStore from "../../common/dataStore";
import { NewPartnerType, PartnerFiltersTypes, PartnerTypes } from "../../../types/partnerTypes";
import { getPartners } from "../../../api/partners/getPartners";
import { getSelectedPartner } from "../../../api/partners/getSelectedPartner";
import deactivatePartners from "../../../api/partners/deactivatePartners";
import { postPartner } from "../../../api/partners/postPartner";
import patchEditPartner from "../../../api/partners/patchEditPartner";
import activatePartners from "../../../api/partners/activatePartners";

class PartnerStore extends dataStore<PartnerTypes, PartnerFiltersTypes, NewPartnerType> {
    constructor() {
        super("PartnerStore");
    }

    protected fetchDataMethod = getPartners;

    protected fetchSelectedItemMethod = getSelectedPartner;

    protected deactivateSelectedItemsMethod = deactivatePartners;

    protected activateSelectedItemsMethod = activatePartners;

    protected postDataMethod = postPartner;

    protected patchDataMethod = patchEditPartner;
}

const partnerStore = new PartnerStore();

export default partnerStore;
