import useTranslation from "../../../../../hooks/useTranslation";
import { ModalTitle, Row } from "../../../../common/modal/warning/blockModal/BlockModalComponents";
import Button from "../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";

interface EditPermissionsModalProps extends ModalProps {
    toggleModal: () => void
    handleNavigate: () => void
}

const EditPermissionsModal = ({ ...props }: EditPermissionsModalProps) => {
    const { t } = useTranslation();
    const { toggleModal, handleNavigate } = props;

    return (
        <Modal {...props}>
            <ModalTitle>{t.modal.modalBody.permissionIsNotSaved}</ModalTitle>

            <Row>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={toggleModal}
                >
                    {t.buttons.back}
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    onClick={handleNavigate}
                >
                    {t.buttons.cancel}
                </Button>
            </Row>
        </Modal>
    );
};

export default EditPermissionsModal;
