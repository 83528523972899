/* eslint-disable no-nested-ternary */
import { ComponentType, FC } from "react";

import authStore from "../store/auth/authStore";

const withPermission = <T extends object>(
    WrappedComponent: ComponentType<T>,
    requiredPermissions: string | string[],
    isFullPermissionsMatchingRequired?: boolean,
    FallbackComponent?: ComponentType,
): FC<T> => {
    const ComponentWithPermission: FC<T> = (props) => {
        const { userPermissions } = authStore;

        const hasPermission = Array.isArray(requiredPermissions)
            ? isFullPermissionsMatchingRequired
                ? requiredPermissions.every((permission) => userPermissions.includes(permission))
                : requiredPermissions.some((permission) => userPermissions.includes(permission))
            : userPermissions.includes(requiredPermissions);

        if (hasPermission) {
            return <WrappedComponent {...props} />;
        }

        return FallbackComponent ? <FallbackComponent /> : null;
    };

    return ComponentWithPermission;
};

export default withPermission;
