import * as Yup from "yup";
import dayjs from "dayjs";
import { DATE_BACK_FORMAT } from "../constants/dates";

export const validateDateRange = (translate: (s: string) => string, compareKey: string) => Yup.string().test({
    message: translate("validation.endDate"),
    test: (value, context) => (
        !context.parent[compareKey] || !value
        || dayjs(
            value,
            DATE_BACK_FORMAT,
        ).isSameOrAfter(dayjs(context.parent[compareKey], DATE_BACK_FORMAT))
    ),
});
