import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AuthorizationLayout from "../authorizationLayout/AuthorizationLayout";
import { AuthRoutes } from "../../constants/routes";
import PagesFirstLogin from "../pages/firstLogin/WelcomePage/PagesFirstLogin";
import PagesLogin from "../pages/auth/login/auth/PagesLogin";
import authStore from "../../store/auth/authStore";
import PagesAuthTFA from "../pages/auth/login/authTFA/PagesAuthTFA";
import PagesCheckInbox from "../pages/auth/password/recovery/CheckInbox/PagesCheckInbox";
import PagesPasswordRecovery from "../pages/auth/password/recovery/PagesPasswordRecovery";
import ChangePassword from "../pages/auth/password/recovery/PagesChangePassword";

const NotAuthenticatedRoutes = () => (
    <Routes>
        <Route path="/" element={<AuthorizationLayout />}>
            <Route path={AuthRoutes.login.href} element={<PagesLogin />} />
            <Route path={AuthRoutes.firstLoginWelcomePage.href} element={<PagesFirstLogin />} />
            {authStore.useTFA && <Route path={AuthRoutes.loginTFA.href} element={<PagesAuthTFA />} />}
            <Route path={AuthRoutes.passwordReset.href} element={<PagesPasswordRecovery />} />
            <Route path={AuthRoutes.passwordResetCheckInbox.href} element={<PagesCheckInbox />} />
            <Route
                path={AuthRoutes.passwordChangedPassword.href}
                element={<ChangePassword />}
            />
            <Route path="*" element={<Navigate to={AuthRoutes.login.href} replace />} />
        </Route>
    </Routes>
);

export default observer(NotAuthenticatedRoutes);
