import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import processingStore from "../../../../../store/entities/processing/processingStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import PasswordBox from "../../../../ui/textInput/PasswordBox";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditProcessingModal from "./EditProcessingModal";
import ProtectedProcessingPartnersTable from "./tabsTables/ProtectedProcessingPartnersTable";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

const ProcessingDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();

    const { selectedItem } = processingStore;

    const [isEditModalVisible, setEditModalVisibility] = useState<boolean>(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTabTitle = id ? `${t.entities.processing.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.entities.title, t.entities.processing.title, currentTabTitle);

    const preInputsList = [
        { label: t.table.header.processingName, value: selectedItem?.name, key: "processing-name" },
        { label: t.table.header.processingId, value: selectedItem?.id?.toString(), key: "processing-id" },
        { label: t.table.header.email, value: selectedItem?.email, key: "email" },
        { label: t.table.header.timezone, value: selectedItem?.timezone?.toString(), key: "timezone" },
        { label: t.table.header.ipAddresses, value: selectedItem?.ip_addresses?.join(", "), key: "ip" },
        {
            label: t.table.header.token, value: selectedItem?.secret_token || "", isPassword: true, key: "secret-token",
        },
    ];

    const handleNavigate = () => navigateBackward({ tab: Tabs.PROCESSINGS });

    const actionHeaderContent = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) processingStore.fetchSelectedItem(id);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContent} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.entities.processing.details.title} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.processings.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsList.map((input) => (
                            input.isPassword ? <PasswordBox {...input} isReadOnly /> : <PreInput {...input} />
                        ))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow
                        label={t.table.header.description}
                        value={selectedItem?.description}
                    />
                </DetailsSectionWrapper>

                <ProtectedProcessingPartnersTable partnerId={Number(id)} permission={permissionsMap.partners.view} />

                <EditProcessingModal
                    isOpen={isEditModalVisible}
                    buttonText={t.buttons.save}
                    title={t.entities.processing.edit.title}
                    setOn={setEditModalVisibility}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(ProcessingDetails);
