import { HyperLinkInputContainer, Hyperlink, InputLabel } from "../../../ui/textInput/InputStyles";

interface TgGroupDetailHyperlinkProps {
    placeholder: string;
    route: string;
    title?: string;
    isGrid?: boolean;
}

const TgGroupDetailsHyperlink = ({
    placeholder, title, route, isGrid,
}: TgGroupDetailHyperlinkProps) => (
    <HyperLinkInputContainer $isGrid={isGrid}>
        <InputLabel>{placeholder}</InputLabel>
        { title ? <Hyperlink to={route} target="_blank">{title}</Hyperlink> : "-" }
    </HyperLinkInputContainer>
);

export default TgGroupDetailsHyperlink;
