import FiltersDataStore from "../common/filtersStore";
import { getUsers } from "../../api/users/getUsers";
import { FilterUserType } from "../../types/userType";

class FiltersCreatedByStore extends FiltersDataStore<FilterUserType> {
    protected fetchListMethod = getUsers;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: item.username,
        }));
    }
}

const filtersCreatedByStore = new FiltersCreatedByStore();

export default filtersCreatedByStore;
