const MAX_DATE = "9999-12-31T23:59:59.999999Z";
const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss:SSS";
const DATE_BACK_FORMAT = "YYYY-MM-DDTHH:mm:ss:SSSZ";
const DATE_INPUT_FORMAT = "DD-MM-YYYY HH:mm:ss";

export {
    MAX_DATE,
    DATE_FORMAT,
    DATE_INPUT_FORMAT,
    DATE_BACK_FORMAT,
};
