import { ChangeEvent, InputHTMLAttributes } from "react";
import { SwitchCheckbox, SwitchContainer, SwitchSlider } from "./SwitchComponent";

interface ControlledSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    isChecked: boolean;
}

const ControlledSwitch = ({ isChecked, onChange, ...props }: ControlledSwitchProps) => {
    const onSwitchChange = (event: ChangeEvent<HTMLInputElement>) => onChange?.(event);

    return (
        <SwitchContainer>
            <SwitchCheckbox
                type="checkbox"
                checked={isChecked}
                onChange={onSwitchChange}
                {...props}
            />
            <SwitchSlider />
        </SwitchContainer>
    );
};

export default ControlledSwitch;
