import { TR } from "../../TableComponents";
import { DataType, RowProps } from "../../types";
import TableCell from "../TableCell/TableCell";

const StaticRow = <D extends DataType>({
    item, checkedItems, columns, onCheckboxChange, columnWidths, isItemSelectionAllowed,
}: RowProps<D>) => {
    const { id } = item;

    return (
        <TR
            $selected={checkedItems?.includes(+id)}
        >
            {columns?.map((column) => (
                <TableCell
                    key={column.field + column.header}
                    item={item}
                    column={column}
                    width={columnWidths[column.field]}
                    onCheckboxChange={onCheckboxChange}
                    checkedItems={checkedItems}
                    isItemSelectionAllowed={isItemSelectionAllowed}
                />
            ))}
        </TR>
    );
};

export default StaticRow;
