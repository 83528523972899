import { flow } from "mobx";
import dataStore from "../../common/dataStore";
import getRulesPacks from "../../../api/rulesPacks/getRulesPacks";
import { NewRuleTypes, RulesPacksTypes } from "../../../types/rulesPacksTypes";
import { parseDateFromServer } from "../../../helpers";

class PartnerRulesPacksStore extends dataStore<RulesPacksTypes, any, NewRuleTypes> {
    protected fetchDataByIdMethod = getRulesPacks;

    fetchData = flow(function* fetchData(this: PartnerRulesPacksStore, id: number | string) {
        const response = yield this.fetchDataByIdWithoutSet({ partner: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<RulesPacksTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const partnerRulesPacksStore = new PartnerRulesPacksStore();

export default partnerRulesPacksStore;
