import request from "../request";
import { EditRoleType, RoleType } from "../../types/rolesTypes";

interface ResponseType {
    message: string;
    response: RoleType;
}

const patchRole = (data: EditRoleType, roleId: string | null) => request<ResponseType>({
    method: "PATCH",
    uri: `roles/${roleId}/`,
    data,
});

export default patchRole;
