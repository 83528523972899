import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { Tabs } from "../../../../../constants/tabs";
import { capitalizeFirstLetter, extractValues } from "../../../../../helpers";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import { AlertProcessingTypes } from "../../../../../types/alertsTypes";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import GlobalLoader from "../../../../ui/loader/GlobalLoader";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditAlertModal from "./EditAlertModal";

const displayAttributesMap = {
    service_id: "Service ID",
    partner_id: "Partner ID",
    customer_id: "Customer ID",
    email: "E-mail",
    phone: "Phone",
    ip: "IP",
    card_hash: "Card Hash",
    created: "Created at",
};

const AlertDetails = () => {
    const { t } = useTranslation();
    const { id: alertId } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = alertsStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = alertId ? `${t.alerts.text.partOfLink} №${alertId}` : "";
    const headerTitle = generateHeaderName(t.alerts.title, currentTitle);

    const handleNavigate = () => navigateBackward({ tab: Tabs.ALERTS });

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const preInputsParamsList = [
        { label: t.alerts.placeholders.name, value: selectedItem?.name, key: "alert-details-name" },
        { label: t.alerts.placeholders.id, value: selectedItem?.id, key: "alert-details-id" },
        {
            label: t.alerts.placeholders.displayAttributes,
            value: extractValues(displayAttributesMap, selectedItem?.display_attributes).join(", "),
            key: "alert-details-display-attributes",
        },
        {
            label: t.alerts.placeholders.type,
            value: `${selectedItem?.alert_type.toUpperCase()} alert`,
            key: "alert-details-type",
        },
        {
            label: t.alerts.placeholders.triggerText,
            value: selectedItem?.trigger_text,
            key: "alert-details-trigger-text",
        },
        {
            label: t.alerts.placeholders.tgGroup,
            value: selectedItem?.tg_group,
            is_hidden: selectedItem?.alert_type === "af",
            key: "alert-details-tg-group",
        },
        {
            label: t.alerts.placeholders.importance,
            value: capitalizeFirstLetter(selectedItem?.importance),
            key: "alert-details-importance",
        },
        { label: t.alerts.placeholders.createdAt, value: selectedItem?.created, key: "alert-details-created_at" },
        {
            label: t.alerts.placeholders.createdBy,
            value: selectedItem?.created_by_name,
            key: "alert-details-created_by",
        },
        {
            label: t.alerts.placeholders.partner,
            value: selectedItem?.partners.map((partner) => partner.name).join(", "),
            key: "alert-details-partners",
        },
        {
            label: t.alerts.placeholders.service,
            value: selectedItem?.services.map((service) => service.name).join(", "),
            key: "alert-details-services",
        },
        {
            label: t.alerts.placeholders.processing,
            value: (selectedItem?.processing as AlertProcessingTypes)?.name,
            key: "alert-details-processing",
        },
    ];

    useEffect(() => {
        if (alertId) alertsStore.fetchSelectedItem(alertId);

        return () => {
            alertsStore.clearSelectedItem();
        };
    }, [alertId]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.alerts.text.partOfLink} ${t.details.title}`}
                        permission={permissionsMap.alerts.change}
                        isStatusActive={selectedItem?.is_active}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map(({ is_hidden, ...input }) => (
                            is_hidden ? null : <PreInput {...input} />))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.alerts.placeholders.message} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <EditAlertModal
                    title={t.alerts.edit.title}
                    buttonText={t.buttons.save}
                    isOpen={isEditModalVisible}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
            <GlobalLoader
                isLoading={alertsStore.isGettingItemDetailsInProgress}
                size="32"
                variant="tertiary"
            />
        </>

    );
};

export default observer(AlertDetails);
