import { TransactionStatus } from "../../../../types/transactionTypes";
import { StatusCircle } from "./StatusCircleComponents";

interface DataType {
    status: TransactionStatus;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const ColoredDecision = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <StatusCircle variant={dataItem.status} />
);

export default ColoredDecision;
