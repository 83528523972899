import React from "react";
import useTranslation from "../../hooks/useTranslation";
import { ReactComponent as FASLogo } from "../../resources/icons/FASLogo.svg";
import { WelcomeText } from "../ui/TextStyle";
import { LeftPageSide } from "./AuthorizationLayoutStyles";

const WelcomeCard: React.FC = () => {
    const { t } = useTranslation();
    return (
        <LeftPageSide>
            <FASLogo />
            <WelcomeText>
                {t.welcomeCard.text.welcome}
                <br />
                {t.welcomeCard.text.startKJourney}
            </WelcomeText>
        </LeftPageSide>
    );
};

export default WelcomeCard;
