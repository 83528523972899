import { observer } from "mobx-react-lite";
import useTranslation from "../../../../../../hooks/useTranslation";
import TableLoader from "../../../../../ui/table/components/TableLoader/TableLoader";
import {
    EmptyTable, Table as StyledTable, TableContainer, TD, TH, THContainer, TR,
} from "./TableComponents";
import SmallButton from "../../../../../ui/button/SmallButton";
import permissionsStore from "../../../../../../store/permissions/permissionsStore";
import { ReactComponent as PlusIcon } from "../../../../../../resources/icons/secondHeader/plus.svg";
import { ReactComponent as BinIcon } from "../../../../../../resources/icons/bin.svg";

export interface PermissionsTableProps {
    variant: "add" | "delete";
    isEditable?: boolean;
}

const PermissionsTableEditable = ({ variant, isEditable = true }: PermissionsTableProps) => {
    const { t, translate } = useTranslation();

    const tableDataMap = {
        add: {
            leftColumnTitle: t.roles.table.header.possibilities,
            rightColumnTitle: t.roles.table.header.add,
            buttonIcon: <PlusIcon />,
            emptyTableTitle: t.roles.table.empty.add.header,
            emptyTableText: t.roles.table.empty.add.text,
            permissionsData: permissionsStore.notAddedItems,
        },
        delete: {
            leftColumnTitle: t.roles.table.header.added,
            rightColumnTitle: t.roles.table.header.delete,
            buttonIcon: <BinIcon />,
            emptyTableTitle: t.roles.table.empty.delete.header,
            emptyTableText: t.roles.table.empty.delete.text,
            permissionsData: permissionsStore.addedItems,
        },
    };

    const {
        leftColumnTitle, rightColumnTitle, buttonIcon, permissionsData, emptyTableTitle, emptyTableText,
    } = tableDataMap[variant];

    const loadingPermissionsData = permissionsStore.loading;

    if (!loadingPermissionsData && !permissionsData.length) {
        return (
            <EmptyTable>
                <h3>{emptyTableTitle}</h3>
                <p>{emptyTableText}</p>
            </EmptyTable>
        );
    }

    return (
        <TableContainer $shrinkHeight={!permissionsData.length || !!loadingPermissionsData}>
            <StyledTable>
                <thead>
                    <TR>
                        <TH>
                            <THContainer>{leftColumnTitle}</THContainer>
                        </TH>
                        <TH>
                            {isEditable && (<THContainer>{rightColumnTitle}</THContainer>)}
                        </TH>
                    </TR>
                </thead>

                <tbody>
                    {!loadingPermissionsData && permissionsData?.map((permission) => {
                        const { id, codename } = permission;

                        return (
                            <TR key={id}>
                                <>
                                    <TD>{translate(`roles.permissions.${codename}`)}</TD>
                                    <TD>
                                        {isEditable
                                        && (
                                            <SmallButton
                                                type="button"
                                                shape="square"
                                                variant="secondary"
                                                onClick={() => permissionsStore.toggleItem(id)}
                                            >
                                                {buttonIcon}
                                            </SmallButton>
                                        )}
                                    </TD>
                                </>
                            </TR>
                        );
                    })}
                </tbody>
            </StyledTable>

            {loadingPermissionsData && <TableLoader />}
        </TableContainer>
    );
};

export default observer(PermissionsTableEditable);
