import { AlertsTypes, EditAlertTypes } from "../../types/alertsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: AlertsTypes,
}

const patchAlert = (data: EditAlertTypes, alertId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `alerts/${alertId}/`,
    data,
});

export default patchAlert;
