import styled from "styled-components";
import { colors } from "../../styles/variables";

export const Container = styled.div`
    min-height: 100vh;
    height: 100%;
    padding: 0 20px 20px;
    box-sizing: border-box;

    display: grid;
    grid-template-areas: "menu header header"
                         "menu content content"
                         "menu content content";
    grid-template-rows: 106px auto auto;
    grid-template-columns: 220px auto auto;
    column-gap: 20px;

    background-color: ${colors.mainLayoutBackground};
`;

export const Main = styled.main`
    grid-area: content;
`;
