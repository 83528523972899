import FiltersDataStore from "../../common/filtersStore";
import { getServicesAccountManagers } from "../../../api/services/filters/getAccountManagers";

class FiltersServicesManagersStore extends FiltersDataStore<string> {
    protected fetchListMethod = getServicesAccountManagers;

    public get options() {
        return this.data?.map((item) => ({
            value: item,
            label: item,
        }));
    }
}

const filtersServicesManagersStore = new FiltersServicesManagersStore();

export default filtersServicesManagersStore;
