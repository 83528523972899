import { ElementsTypes, NewElementType } from "../../types/elementsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: ElementsTypes,
}

export const postElements = (data: NewElementType) => request<ResponseType>({
    method: "POST",
    uri: "elements/",
    data,
});
