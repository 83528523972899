import { permissionsMap } from "../../../../../../constants/permissions";
import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionFieldsTypes, TransactionInfoProps } from "../../../../../../types/transactionTypes";
import {
    Box, DetailsBodyWrapper, DetailsSectionWrapper, Row,
} from "../../../../../ui/details/DetailsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import ProtectedOperationButtons from "../operationButtons/ProtectedOperationButtons";
import HyperlinkFilterTextInput from "../../../../../ui/textInput/HyperlinkFilterTextInput";
import routes from "../../../../../../constants/routes";
import transactionStore from "../../../../../../store/transaction/transactionStore";

const CustomerInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const componentDataMap = {
        preInputsList: [
            { label: t.table.header.address, value: transaction?.address, key: "customer-field-address" },
            { label: t.transactions.placeholders.city, value: transaction?.city, key: "customer-field-city" },
            { label: t.transactions.placeholders.zip, value: transaction?.zip, key: "customer-field-zip" },
            { label: t.transactions.placeholders.state, value: transaction?.state, key: "customer-field-state" },
            { label: t.table.header.country, value: transaction?.country, key: "customer-field-country" },
            {
                label: t.table.header.countryByIP,
                value: transaction?.country_by_ip,
                key: "customer-field-country-by-ip",
            },
            {
                label: t.table.header.countryByCard,
                value: transaction?.country_by_card,
                key: "customer-field-country-by-card",
            },
        ],
        operationButtonsParamsList: [
            {
                key: "customer-field-email",
                label: t.table.header.email,
                value: transaction?.email,
                filterName: "email",
                fieldType: "E-mail",
                listInfo: transaction?.email_list_info,
            },
            {
                key: "customer-field-phone",
                label: t.table.header.phone,
                value: transaction?.phone,
                filterName: "phone",
                fieldType: "Phone",
                listInfo: transaction?.phone_list_info,
            },
            {
                key: "customer-field-id",
                label: t.handbooks.customerId,
                value: transaction?.customer_id,
                filterName: "customer_id",
                fieldType: "Customer ID",
                listInfo: transaction?.customer_id_list_info,
            },
        ],
    };

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.customerTitle}
            </DetailPageHeader>

            <DetailsBodyWrapper>
                {componentDataMap.preInputsList.map((input) => <PreInput {...input} />)}
            </DetailsBodyWrapper>

            <Box>
                {componentDataMap.operationButtonsParamsList.map((input) => {
                    const {
                        key, label, value, fieldType, listInfo, filterName,
                    } = input;

                    return (
                        <Row key={key}>
                            <HyperlinkFilterTextInput
                                navigateTo={routes.transactions.href}
                                store={transactionStore}
                                filterName={filterName}
                                label={label}
                                hyperlinkName={value}
                            />
                            <ProtectedOperationButtons
                                label={label}
                                value={value}
                                permission={permissionsMap.elements.add}
                                fieldType={fieldType as TransactionFieldsTypes}
                                listInfo={listInfo}
                                transaction={transaction}
                            />
                        </Row>
                    );
                })}
            </Box>
        </DetailsSectionWrapper>
    );
};

export default CustomerInfo;
