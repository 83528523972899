import React, { memo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Table from "../../../../../ui/table/Table";
import { PartnerTypes } from "../../../../../../types/partnerTypes";
import TableFieldsDetailsLink from "../../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import routes from "../../../../../../constants/routes";
import processingPartnersStore from "../../../../../../store/entities/processing/processingPartnersStore";
import { ColumnType } from "../../../../../ui/table/types";
import withPermission from "../../../../../../hocs/withPermissions";
import useTranslation from "../../../../../../hooks/useTranslation";
import TabHeader from "../../../../../tabHeader/TabHeader";
import { TableMainLink } from "../../../../../ui/tableFields/nameLink/TableMainLink";
import { Tabs } from "../../../../../../constants/tabs";

interface ProtectedProcessingPartnersTableProps {
    permission: string;
    partnerId: number
}

type WrappedComponentProps = Pick<ProtectedProcessingPartnersTableProps, "partnerId">

const columns: ColumnType<PartnerTypes>[] = [
    {
        header: "table.header.partnerName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${routes.partners.href}/${dataItem.id}`}
                state={{ tab: Tabs.NOT_SPECIFIED }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.partnerId", field: "id", sortable: true },
    {
        header: "table.header.services",
        field: "services_count",
        sortable: true,
        cellCenter: true,
    },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "phone" },
    {
        header: "table.header.accountManager",
        field: "account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.timezone", field: "timezone" },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.processingId", field: "processing", sortable: true },
    {
        header: "table.header.processingName",
        field: "processing_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={routes.partners.href}
                state={{ tab: Tabs.NOT_SPECIFIED }}
            />
        ),
    },
];

const WrappedComponent = observer(({ partnerId }: WrappedComponentProps) => {
    const { t } = useTranslation();

    const tablePagination = {
        ...processingPartnersStore.pagination,
        setPaginationPage: processingPartnersStore.setPaginationPage,
        setPaginationSize: processingPartnersStore.setPaginationSize,
    };

    useEffect(() => {
        if (partnerId) {
            processingPartnersStore.fetchData(partnerId);
        }
    }, [processingPartnersStore.pagination, processingPartnersStore.ordering]);

    return (
        <>
            <TabHeader title={t.entities.processing.details.partners} />
            <Table
                columns={columns}
                pagination={tablePagination}
                data={processingPartnersStore.data}
                sortMode={processingPartnersStore.ordering}
                loading={processingPartnersStore.loading}
                onSort={processingPartnersStore.toggleSort}
            />
        </>

    );
});

const ProtectedProcessingPartnersTable = ({ partnerId, permission }: ProtectedProcessingPartnersTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission partnerId={partnerId} />;
};

export default memo(ProtectedProcessingPartnersTable);
