import { observer } from "mobx-react-lite";
import React from "react";
import useTranslation from "../../hooks/useTranslation";
import snackbarStore from "../../store/snackbar/snackbarStore";
import {
    CloseIcon, Container, Item, Text,
} from "./SnackbarComponents";

const Snackbar: React.FC = () => {
    const { translate } = useTranslation();
    const handleClose = (id: number) => snackbarStore.remove(id);

    return (
        <Container>
            {snackbarStore.all.map((message) => (
                <Item
                    key={message.id}
                    $variant={message.variant}
                    $active={message.active}
                >
                    <Text>{translate(message.text)}</Text>
                    <CloseIcon onClick={() => handleClose(message.id)} />
                </Item>
            ))}
        </Container>
    );
};

export default observer(Snackbar);
