import request from "../request";

export interface GetFileResponseType {
    message: string;
    data: Blob;
}

export const getListsElementsFile = (fileId: string) => request<GetFileResponseType>({
    method: "GET",
    uri: `elements/files/download/${fileId}/`,
    responseType: "blob",
});
