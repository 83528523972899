import { observer } from "mobx-react-lite";
import useTranslation from "../../../../hooks/useTranslation";
import { ReactComponent as CopyIcon } from "../../../../resources/icons/copyIcon.svg";
import { CopyButton, CopyKeyContainer } from "../../../pages/firstLogin/setUpTFA/SetUpTFAStyle";
import { InputFieldReadOnly, InputTFAKey } from "../../../ui/textInput/InputStyles";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { CopyAndAgreeWrapper, ModalText } from "../../../ui/modal/modalStyle";

const SaveRecoveryKeyModal = ({
    isOpen,
    setOn,
    secretKey,
    title,
    buttonText,
    navigateTo,
    handleClose,
}: { secretKey: string } & ModalProps) => {
    const { t } = useTranslation();

    const copyTFAKey = () => {
        navigator.clipboard.writeText(secretKey).then();
    };

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={buttonText}
            navigateTo={navigateTo}
            handleClose={handleClose}
            hasChildModal
            modalBodyText={
                (
                    <ModalText>
                        {t.modal.modalBody.saveRecoveryKey}
                    </ModalText>
                )
            }
        >
            <CopyAndAgreeWrapper>
                <CopyKeyContainer>
                    <InputTFAKey type="text" value={secretKey} readOnly />
                    <CopyButton onClick={copyTFAKey}>
                        <CopyIcon />
                    </CopyButton>
                </CopyKeyContainer>
            </CopyAndAgreeWrapper>
        </Modal>
    );
};

export default observer(SaveRecoveryKeyModal);
