import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import tgGroupsStore from "../../../../../store/alerts/tgGroups/tgGroupsStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import TgGroupDetailsHyperlink from "../../components/TgGroupDetailsHyperLink";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedTgGroupsDetailsAlertsTable from "./ProtectedTgGroupDetailsAlertsTable";
import TgGroupEditModal from "./TgGroupEditModal";

const TgGroupDetails = () => {
    const { t } = useTranslation();
    const { id: tgGroupId } = useParams();
    const { selectedItem } = tgGroupsStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = tgGroupId ? `${t.tgGroups.text.partOfLink} №${tgGroupId}` : "";
    const headerTitle = generateHeaderName(t.tgGroups.title, currentTitle);

    const { ActionModalComponent, actionHeaderContentList, tabActionsList } = useTabsActions(tgGroupId);

    const preInputsParamsList = [
        { label: t.tgGroups.placeholders.name, value: selectedItem?.name, key: "tg-group-details-name" },
        { label: t.tgGroups.placeholders.id, value: selectedItem?.id, key: "tg-group-details-id" },
        {
            label: t.tgGroups.placeholders.chatLink,
            route: selectedItem?.chat_link,
            value: selectedItem?.chat_link,
            key: "tg-group-details-chat-link",
        },
        { label: t.tgGroups.placeholders.chatId, value: selectedItem?.chat_id, key: "tg-group-details-chat-id" },
        { label: t.tgGroups.placeholders.threadId, value: selectedItem?.thread_id, key: "tg-group-details-thread-id" },
        { label: t.tgGroups.placeholders.createdAt, value: selectedItem?.created, key: "tg-group-details-created-at" },
        {
            label: t.tgGroups.placeholders.createdBy,
            value: selectedItem?.created_by_name,
            key: "tg-group-details-created-by",
        },
        {
            label: t.tgGroups.placeholders.tgProcessing,
            value: selectedItem?.processing_name,
            key: "tg-group-details-processing",
        },
    ];

    useEffect(() => {
        if (tgGroupId) tgGroupsStore.fetchSelectedItem(tgGroupId);
    }, [tgGroupId]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.tgGroups.text.partOfLink} ${t.details.title}`}
                        permission={permissionsMap.tg_groups.change}
                        isStatusActive={selectedItem?.is_active}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map((input) => {
                            const {
                                value, label, key, route,
                            } = input;

                            return (
                                route ? (
                                    <TgGroupDetailsHyperlink
                                        key={key}
                                        placeholder={label}
                                        route={route}
                                        title={value}
                                    />
                                ) : (
                                    <PreInput {...input} />
                                )
                            );
                        })}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.tgGroups.placeholders.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <ProtectedTgGroupsDetailsAlertsTable
                    permission={permissionsMap.alerts.view}
                    tgGroupId={tgGroupId}
                    actionsContent={tabActionsList}
                />

                <TgGroupEditModal
                    isOpen={isEditModalVisible}
                    title={t.tgGroups.edit.title}
                    buttonText={t.buttons.save}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>

            {ActionModalComponent}
        </>

    );
};

export default observer(TgGroupDetails);
