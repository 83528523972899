import styled from "styled-components";
import { colors } from "../../../../../styles/variables";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const Box = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
`;

export const ModalTitle = styled.p`
    color: ${colors.activeText};
    font-size: 16px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
`;
