import request from "../request";

type DataType = {
    email: string,
    password: string,
}

type ResponseType = {
    message: string;
    response: {
        refresh: string;
        access: string;
        need_tfa: boolean;
        is_active: boolean;
        first_login: boolean;
        email: string[];
        password: string[];
    }
}

export const postCredentials = (data: DataType) => request<ResponseType>({
    useAuthorization: false,
    method: "POST",
    uri: "auth/token/",
    data,
});
