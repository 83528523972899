import request from "../request";
import { EmployeesTypes, NewEmployeeType } from "../../types/employeesTypes";

interface ResponseType {
    message: string,
    response: EmployeesTypes,
}

export const postEmployees = (data: NewEmployeeType) => request<ResponseType>({
    method: "POST",
    uri: "users/",
    data,
});
