import request from "../request";
import { TransactionTypes } from "../../types/transactionTypes";

interface ResponseType {
    message: string,
    response: TransactionTypes,
}

export const getSelectedTransaction = (transactionId: string) => request<ResponseType>({
    method: "GET",
    uri: `transactions/${transactionId}/`,
});
