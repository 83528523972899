import styled, { css } from "styled-components";
import { colors } from "../../../../../../styles/variables";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h2`
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    color: ${colors.activeText};
    line-height: 105%;
    margin: 0;

`;

export const Body = styled.div`
    min-height: 421px;
    min-width: 924px;
    margin: 24px 0 0;
`;

export const permissionsHeaderStyle = css`
    padding: 13px 16px;
    line-height: 105%;
`;

export const permissionsCellStyle = css`
    color: ${colors.activeText} !important;
    font-weight: 600;
    padding: 13px 16px;
    height: fit-content;
    line-height: 105%;
    white-space: normal;
    position: sticky;
    left: 0;
    top: -55px;
    z-index: 1;
    background-color: inherit;
`;

export const headerStyle = css`
    color: ${colors.activeText} !important;
    padding: 13px 16px;
    line-height: 105%;
    display: inline-block;
    max-width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const cellStyle = css`
    height: fit-content;
    padding: 13px 16px;
    line-height: 105%;
`;

export const tableContainerStyle = css`
    border-radius: 12px;
    border: 1px solid ${colors.inputDefaultBorder};
    height: 77vh;

    @media only screen and (min-height: 1080px) {
        height: 85vh;
    }
`;
