import request from "../../request";

type ResponseType = {
    message: string;
    response: {
        tfa_uri: string;
    }
}

export const getTfaUri = (token: string) => request<ResponseType>({
    useAuthorization: false,
    headers: {
        "Authorization": `Bearer ${token}`,
    },
    method: "GET",
    uri: "auth/tfa/configure/",
});
