import { useEffect } from "react";

const useConfirmOnExitAndReload = () => {
    useEffect(() => {
        window.onbeforeunload = () => true;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);
};

export default useConfirmOnExitAndReload;
