import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const deactivateLimits = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "limits/deactivate/",
    data,
});

export default deactivateLimits;
