import styled from "styled-components";
import { Container as DefaultContainer, FormWrapper as DefaultFormWrapper } from "../../../ui/new/NewComponents";
import { ReactComponent as DefaultInfoIcon } from "../../../../resources/icons/info-circle.svg";

export const Container = styled(DefaultContainer)`
    background-color: inherit;
    gap: 0;
    padding: 0;
`;

export const ContainerBody = styled(DefaultContainer)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;
export const FormContainerBody = styled(DefaultFormWrapper).attrs({
    as: "div",
})`
    background-color: #FFFFFF;
    padding: 0 20px 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
`;

export const FormWrapper = styled.form`
    display: flex;
    flex-direction: column;
`;

export const CodeContainer = styled(DefaultContainer)`
    margin-top: 20px;
`;

export const InfoIcon = styled(DefaultInfoIcon)`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const CodeTitleContainer = styled.div`
    display: flex;
    gap: 10px
`;

export const CommonErrorFieldWrapper = styled.div`
    grid-column: 1 / 3;
`;
