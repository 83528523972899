import styled from "styled-components";
import { colors } from "../../../../../styles/variables";

export const HeaderSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    max-width: 420px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: ${colors.textDefault};

    div: {
    max-width: 295px;
}
`;

export const TFABody = styled.div`
    display: flex;
    flex-direction: column;
    width: 420px;
    gap: 50px;
`;
