import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect } from "react";
import * as Yup from "yup";

import { FieldMode } from "../../../../constants/selectFields";
import useTranslation from "../../../../hooks/useTranslation";
import filtersServicesStore from "../../../../store/filters/servicesFilterStore";
import rulesPackServicesStore from "../../../../store/rulesPack/rulesPackServicesStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import Button from "../../../ui/button/Button";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { AddButtonWrapper, NewItemFormWrapper } from "./NewRulesPackComponents";

const SERVICES_FIELD_NAME = "services";

interface FormTypes {
    services: number[] | null,
}

interface AddRulesPacksServiceModalProps {
    ruleId?: string;
    closeModal: () => void;
}

const AddRulesPacksServiceModal = ({ ruleId, closeModal }: AddRulesPacksServiceModalProps) => {
    const { t } = useTranslation();

    const formik = useFormik<FormTypes>({
        initialValues: { services: null },
        validationSchema: Yup.object({
            services: Yup.array().required(t.validation.required),
        }),
        enableReinitialize: true,
        onSubmit: async (values) => {
            const data = { ...values, rules_pack: Number(ruleId) };

            await rulesPackServicesStore.postData(data, [SERVICES_FIELD_NAME]);

            if (rulesPackServicesStore.isPostRequestSuccess) {
                closeModal();
            } else {
                snackbarStore.add({ variant: "error", text: rulesPackServicesStore.postErrors?.common });
                rulesPackServicesStore.resetPostItemState();
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    useEffect(() => {
        filtersServicesStore.fetchById(Number(ruleId));
    }, []);

    return (
        <NewItemFormWrapper onSubmit={handleSubmit} $gap="24">
            <FloatSelect
                formik={formik}
                disabled={rulesPackServicesStore.loading}
                name={SERVICES_FIELD_NAME}
                mode={FieldMode.Multiple}
                isSelectAllOption
                options={filtersServicesStore.options}
                placeholder={t.filters.fields.service}
                serverErrors={rulesPackServicesStore.postErrors}
                resetServerError={rulesPackServicesStore.resetPostError}
            />

            <AddButtonWrapper>
                <Button
                    isLoading={rulesPackServicesStore.loading}
                    variant="primary"
                    type="submit"
                >
                    {t.buttons.add}
                </Button>
            </AddButtonWrapper>
        </NewItemFormWrapper>
    );
};

export default observer(AddRulesPacksServiceModal);
