import styled from "styled-components";

import { determineColor } from "../../../../../../helpers/determineColor";
import { colors } from "../../../../../../styles/variables";
import { StatusBadgeComponent } from "../../../../../ui/textField/StatusBadgeComponents";
import PreInput from "../../../../../ui/textInput/PreInput";

const WideTextField = styled(PreInput)`
    grid-column: auto / span 2;
`;

const EmptyTitle = styled.h4`
    color: ${colors.textDefault};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    margin: 0;
`;

const ColoredTextField = styled(PreInput)<{ value?: string | number }>`
    .ant-input {
        color: ${({ value }) => determineColor(value)};
    }
`;

const TransactionDetailsStatusBadge = styled(StatusBadgeComponent)`
    width: 154px;
    height: 29px;
    padding: 6px, 12px, 6px, 12px;
    border-radius: 80px;
    font-size: 16px;
    font-weight: 600;
`;

const Box = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 16px;
`;

const RadioButtonLabel = styled.p<{ $checked: boolean }>`
    color: ${({ $checked }) => ($checked ? colors.black : colors.textDefault)};
    font-style: normal;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 105%;
`;

const RadioButtonRow = styled.div`
    display: flex;
    align-items: baseline;
`;

const ModalButtonRow = styled.div<{ $marginTop: string }>`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    margin-top: ${({ $marginTop }) => $marginTop};
`;

const ModalTextLabel = styled.span`
    color: ${colors.activeText};
`;

export {
    WideTextField,
    EmptyTitle,
    ColoredTextField,
    TransactionDetailsStatusBadge,
    Box,
    RadioButtonLabel,
    RadioButtonRow,
    ModalButtonRow,
    ModalTextLabel,
};
