import dataStore from "../../common/dataStore";
import { NewProcessingType, ProcessingFiltersTypes, ProcessingTypes } from "../../../types/processingTypes";
import { getProcessing } from "../../../api/processing/getProcessing";
import { getSelectedProcessing } from "../../../api/processing/getSelectedProcessing";
import deactivateProcessing from "../../../api/processing/deactivateProcessing";
import { postProcessing } from "../../../api/processing/postProcessing";
import patchEditProcessing from "../../../api/processing/patchEditProcessing";
import activateProcessing from "../../../api/processing/activateProcessing";

class ProcessingStore extends dataStore<ProcessingTypes, ProcessingFiltersTypes, NewProcessingType> {
    constructor() {
        super("ProcessingStore");
    }

    protected fetchDataMethod = getProcessing;

    protected fetchSelectedItemMethod = getSelectedProcessing;

    protected deactivateSelectedItemsMethod = deactivateProcessing;

    protected activateSelectedItemsMethod = activateProcessing;

    protected postDataMethod = postProcessing;

    protected patchDataMethod = patchEditProcessing;
}

const processingStore = new ProcessingStore();

export default processingStore;
