import Modal, { ModalProps } from "../ui/modal/Modal";
import useTranslation from "../../hooks/useTranslation";

const FiltersModal = ({
    isOpen,
    setOn,
    title,
    scrollable,
    children,
    width = "376px",
}: ModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            width={width}
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={t.buttons.apply}
            submitButton={false}
            position="top-right"
            scrollable={scrollable}
            isFilter
        >
            {children}
        </Modal>
    );
};

export default FiltersModal;
