import { ColoredTextField } from "./ColoredCellComponents";

interface DataType {
    risk_score: number;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const ColoredRisk = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <ColoredTextField value={dataItem.risk_score}>
        {dataItem.risk_score}
    </ColoredTextField>
);

export default ColoredRisk;
