import request from "../request";
import { ListsTypes, NewListType } from "../../types/listsTypes";

interface ResponseType {
    message: string,
    response: ListsTypes,
}

export const postLists = (data: NewListType) => request<ResponseType>({
    method: "POST",
    uri: "lists/",
    data,
});
