import styled from "styled-components";
import { colors } from "../../styles/variables";

export const HeaderText = styled.h2`
    margin: 0;
    
    color: ${colors.activeText};
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
`;

export const WelcomeText = styled.div`
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 59px;
    font-family: Roboto, sans-serif;
    color: ${colors.textPrimary};
    max-width: calc(100% - 105px);
    background: linear-gradient(180deg, ${colors.textPrimary} 0%, ${colors.welcomeText} 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const TextLink = styled.a`
    color: ${colors.textPrimary};
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
`;

export const TextError = styled.h5`
    color: ${colors.error};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin: 0;
`;

export const ButtonWithErrorsSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

export const Icon = styled.button`
    position: absolute;
    right: 20px;
    border: none;
    background: none;
    cursor: pointer;
`;

export const DetailPageHeader = styled.div`
    color: ${colors.activeText};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
`;

export const ModalBodyText = styled.p`
    color: ${colors.activeText};
    font-size: 16px;
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
`;

export const ModalSubText = styled.p<{ $margin?: string }>`
    font-size: 16px;
    font-weight: 500;
    margin: ${(props) => props.$margin || 0};
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const RulesMessageBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: start;
`;
