import styled from "styled-components";

export const PasswordConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
`;

export const PasswordConfirmHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const CheckIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 56px;
  height: 56px;
  border: none;
  background: none;
`;

export const RulesStyled = styled.div<{$color: string}>`
  color: ${(props) => props.$color};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const AccountErrorsWrapper = styled.div`
  margin-top: 20px;
`;
