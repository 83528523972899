import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTranslation from "../../../hooks/useTranslation";
import filtersCreatedByStore from "../../../store/filters/createdByFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import rulesPacksFiltersStore from "../../../store/filters/rulesPacksFiltersStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedRulesPacksTable from "./ProtectedRulesPacksTable";

const PagesRulesPacks = () => {
    const { t } = useTranslation();

    const currentTabTitle = t.rulesPacks.title;
    const headerTitle = generateHeaderName(currentTabTitle);

    const {
        FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    } = useTabsActions();

    useEffect(() => {
        rulesPacksFiltersStore.fetch();
        filtersProcessingStore.fetch();
        filtersCreatedByStore.fetch();
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedRulesPacksTable permission={permissionsMap.rules_packs.view} />
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesRulesPacks);
