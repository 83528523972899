/* eslint-disable camelcase */
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { validateDateRange } from "../../../../helpers/validationHelper";
import useTranslation from "../../../../hooks/useTranslation";
import filtersCreatedByStore from "../../../../store/filters/createdByFilterStore";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import rulesPackStore from "../../../../store/rulesPack/rulesPackStore";
import { RulesPacksFiltersTypes } from "../../../../types/rulesPacksTypes";
import {
    FilterModalContainer,
    FormContainer,
    HeaderContainer,
    HeaderTitle,
    SectionTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { FloatDatePicker } from "./RulesPackFiltersComponents";

interface RulesFiltersProps {
    closeModal: () => void;
}

const RulesPackFilters = ({ closeModal }: RulesFiltersProps) => {
    const { t, translate } = useTranslation();

    const formik = useFormik<RulesPacksFiltersTypes>({
        initialValues: { ...rulesPackStore.filters },
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
            updated_to: validateDateRange(translate, "updated_from"),
        }),
        initialTouched: {
            created_to: !!rulesPackStore.filters?.created_to,
            updated_to: !!rulesPackStore.filters?.updated_to,
        },
        enableReinitialize: true,
        onSubmit: (fields) => {
            rulesPackStore.updateFilters(fields);
            closeModal();
        },
    });

    const resetFilters = () => {
        rulesPackStore.resetFilters();
        formik.resetForm();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    return (
        <FilterModalContainer>
            <HeaderContainer>
                <HeaderTitle>{t.rulesPacks.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={rulesPacksFiltersStore.options}
                    placeholder={t.filters.fields.rulesPack}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    options={filtersProcessingStore.options}
                    placeholder={t.filters.fields.processing}
                />
                <SectionTitle>{t.filters.sectionTitles.creationDate}</SectionTitle>
                <FloatDatePicker
                    formik={formik}
                    name="created_from"
                    maxDate={formik.values.created_to}
                    placeholder={t.filters.fields.from}
                />
                <FloatDatePicker
                    formik={formik}
                    name="created_to"
                    minDate={formik.values.created_from}
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <FloatSelect
                    formik={formik}
                    name="created_by"
                    options={filtersCreatedByStore.options}
                    placeholder={t.filters.fields.createdBy}
                />
                <SectionTitle>{t.filters.sectionTitles.lastUpdate}</SectionTitle>
                <FloatDatePicker
                    formik={formik}
                    name="updated_from"
                    maxDate={formik.values.updated_to}
                    placeholder={t.filters.fields.from}
                />
                <FloatDatePicker
                    formik={formik}
                    name="updated_to"
                    minDate={formik.values.updated_from}
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <Button variant="primary" isLoading={rulesPackStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </FilterModalContainer>
    );
};

export default observer(RulesPackFilters);
