import { TgGroupEditTypes, TgGroupsTypes } from "../../types/tgGroupsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: TgGroupsTypes,
}

const patchTgGroups = (data: TgGroupEditTypes, tgGroupId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `telegram-groups/${tgGroupId}/`,
    data,
});

export default patchTgGroups;
