export function filterEmptyFields<T extends Record<string, any>>(fields: T, ...exceptionFields: string[]): Partial<T> {
    const filteredFields: Partial<T> = {};

    Object.keys(fields).forEach((key: keyof T) => {
        const field = fields[key];

        if (field || exceptionFields?.includes(key as string)) {
            filteredFields[key] = field;
        }
    });

    return filteredFields;
}
