import { EditElementType, ElementsTypes } from "../../types/elementsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: ElementsTypes,
}

const patchEditElement = (data: Partial<EditElementType>, elementId: string) => request<ResponseType>({
    useAuthorization: true,
    method: "PATCH",
    uri: `elements/${elementId}/`,
    data,
});

export default patchEditElement;
