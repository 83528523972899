import request from "../../request";

type DataType = {
    new_password: string;
    new_password_conf: string;
}

type ResponseType = {
    message: string;
    response: {
        details: string;
        access: string;
    }
}
export const putNewPassword = (token: string, data: DataType) => request<ResponseType>({
    useAuthorization: false,
    headers: {
        "Authorization": `Bearer ${token}`,
    },
    method: "PUT",
    uri: "auth/password/change/",
    data,
});
