import { observer } from "mobx-react-lite";
import React from "react";
import useTranslation from "../../../../../../hooks/useTranslation";
import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import {
    ChangePasswordModalBody,
    ChangePasswordModalText,
    ChangePasswordModalTitle,
} from "../../../PagesSettingsComponents";
import MailIcon from "../../../../../../resources/icons/mailFilledIcon.svg";

const EditPersonalInfoModal = ({
    isOpen, setOn,
}: ModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            buttonText={t.buttons.ok}
            gap="0"
            iconURL={MailIcon}
        >
            <ChangePasswordModalBody>
                <ChangePasswordModalTitle>{t.passwordRecovery.text.checkInbox}</ChangePasswordModalTitle>
                <ChangePasswordModalText>{t.settings.security.text.changePassword.modalText}</ChangePasswordModalText>
            </ChangePasswordModalBody>
        </Modal>
    );
};

export default observer(EditPersonalInfoModal);
