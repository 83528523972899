import { FC } from "react";
import { ModalTextLabel } from "../sections/SectionsComponents";
import { ListType } from "../../../../../../types/transactionTypes";

interface AddToListModalBodyProps {
    action: "add" | "remove",
    type: ListType | "globalBlacklisted",
    label: string,
    translate: (text: string) => string
    actionType?: "single" | "plural"
}

const AddToListModalBody: FC<AddToListModalBodyProps> = ({
    action,
    type,
    label,
    translate,
    actionType = "single",
}) => (
    <>
        {translate(`transactions.modal.${action}.${actionType}.action`)}
        &nbsp;
        <ModalTextLabel>{label}</ModalTextLabel>
        &nbsp;
        {translate(`transactions.modal.${action}.preposition`)}
        &nbsp;
        {translate(`transactions.modal.${type}`)}
    </>
);

export default AddToListModalBody;
