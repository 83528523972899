import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "../types/locationTypes";
import Routes from "../constants/routes";

type NavigateToProps = LocationState & {
    to: string;
}

type NavigationState = Omit<NavigateToProps, "to" | "history">
type NavigationStateWithoutPath = Omit<NavigateToProps, "history">

const getHistoryWithoutLatestLocation = (history?: string[]) => history?.slice(0, history.length - 1);
const getHistoryWithCurrentLocation = (pathname: string, history?: string[]) => [...history || [], pathname];
const addExistingOptionsToState = (options?: NavigationState, newHistory?: string[]) => {
    const {
        tab, id, selectedType, previousTab,
    } = options || {};
    return {
        history: newHistory,
        ...(tab && { tab }),
        ...(id && { id }),
        ...(selectedType && { selectedType }),
        ...(previousTab && { previousTab }),
    };
};

export function useNavigation() {
    const navigate = useNavigate();
    const location = useLocation();
    const currentLocation = location.pathname;
    const currentState: LocationState = location?.state;
    const history = currentState?.history;

    const getUpdatedState = (options?: NavigationState) => {
        const newHistory = getHistoryWithCurrentLocation(currentLocation, history);
        return addExistingOptionsToState(options ?? currentState, newHistory);
    };

    const getPreviousPath = () => {
        const previousPath = history?.at(-1);
        if (previousPath) return previousPath;
        return currentLocation.split("/").slice(0, -1).join("/");
    };

    const navigateBackward = (options?: NavigationState) => {
        const previousPath = getPreviousPath();
        const newHistory = getHistoryWithoutLatestLocation(history);
        let state = options ?? currentState;
        if (state?.previousTab && !state.tab) {
            state.tab = state.previousTab;
        }
        state = addExistingOptionsToState(state, newHistory);
        navigate(previousPath, { state });
    };

    const navigateTo = (options: NavigationStateWithoutPath) => {
        const toPath = options.to || Routes.home.href;
        const state = getUpdatedState(options ?? currentState);
        navigate(toPath, { state });
    };

    return { navigateTo, navigateBackward, getUpdatedState };
}
