import { ReactComponent as BinIcon } from "../../../../../../resources/icons/bin.svg";
import { ReactComponent as ErrorTickIcon } from "../../../../../../resources/icons/closeCircle.svg";
import { ReactComponent as TickIcon } from "../../../../../../resources/icons/tickIcon.svg";
import { colors } from "../../../../../../styles/variables";
import { FormikTypes } from "../../../../../../types/formikTypes";
import SmallButton from "../../../../../ui/button/SmallButton";
import { UploadedFileContainer, TitleComponent, TitleWrapper } from "./UploadListsElementsModalComponents";

interface UploadedAreaContentTypes {
    formik: FormikTypes;
    onClick: () => void;
}

const UploadedAreaContent = ({ formik, onClick }: UploadedAreaContentTypes) => (
    <UploadedFileContainer>
        {formik.errors.file ? <ErrorTickIcon /> : <TickIcon />}
        <TitleWrapper>
            <TitleComponent $color={colors.buttonPrimary}>{formik.values.file?.name}</TitleComponent>
        </TitleWrapper>
        <SmallButton variant="secondary" shape="square" onClick={onClick}>
            <BinIcon />
        </SmallButton>
    </UploadedFileContainer>
);

export default UploadedAreaContent;
