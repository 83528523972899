import request from "../../request";

type DataType = {
    use_tfa: boolean,
    key: string,
}

type ResponseType = {
    message: string;
    response: {
        use_tfa: boolean;
        access: string;
        refresh: string;
    };
}

export const updateTFAStateInAuthPath = (data: DataType, token: string) => request<ResponseType>({
    useAuthorization: false,
    token,
    method: "PUT",
    uri: "auth/tfa/configure/",
    data,
});
