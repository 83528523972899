import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import processingStore from "../../../../store/entities/processing/processingStore";
import { ProcessingTypes } from "../../../../types/processingTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedProcessingsTableProps {
    isCheckboxVisible: boolean;
    permission: string;
}

type WrappedComponentProps = Pick<ProtectedProcessingsTableProps, "isCheckboxVisible">

const columns: ColumnType<ProcessingTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.processingName",
        field: "name",
        isResizable: true,
        width: "440px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.processing.href}/${dataItem.id}`}>{dataItem.name}</TableMainLink>
        ),
    },
    { header: "table.header.processingId", field: "id", sortable: true },
    { header: "table.header.partners", field: "partners_count" },
    { header: "table.header.isActive", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.ipAddresses",
        field: "ip_addresses",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.processing.href} />
        ),
    },
];

const WrappedComponent = observer(({ isCheckboxVisible }: WrappedComponentProps) => {
    const tablePagination = {
        ...processingStore.pagination,
        setPaginationPage: processingStore.setPaginationPage,
        setPaginationSize: processingStore.setPaginationSize,
    };

    useEffect(() => {
        processingStore.fetch();
    }, [processingStore.pagination, processingStore.filters, processingStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={processingStore.data}
            pagination={tablePagination}
            checkedItems={processingStore.checkedItems}
            sortMode={processingStore.ordering}
            loading={processingStore.loading}
            onSort={processingStore.toggleSort}
            onCheckboxChange={processingStore.toggleItem}
            onMainCheckboxChange={processingStore.toggleMainItem}
            isItemSelectionAllowed={isCheckboxVisible}
        />
    );
});

const ProtectedProcessingsTable = ({ isCheckboxVisible, permission }: ProtectedProcessingsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isCheckboxVisible={isCheckboxVisible} />;
};

export default memo(ProtectedProcessingsTable);
