import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const activateProcessing = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "entities/processings/activate/",
    data,
});

export default activateProcessing;
