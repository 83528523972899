import { flow } from "mobx";

import deactivateAlerts from "../../../api/alerts/deactivateAlerts";
import { getAlerts } from "../../../api/alerts/getAlerts";
import { getSelectedAlert } from "../../../api/alerts/getSelectedAlert";
import patchAlert from "../../../api/alerts/patchAlert";
import { postAlert } from "../../../api/alerts/postAlert";
import { parseDateFromServer } from "../../../helpers";
import { AlertsFiltersTypes, AlertsTypes, NewAlertTypes } from "../../../types/alertsTypes";
import dataStore from "../../common/dataStore";
import activateAlerts from "../../../api/alerts/activateAlerts";

class AlertsStore extends dataStore<AlertsTypes, AlertsFiltersTypes, NewAlertTypes> {
    constructor() {
        super("AlertsStore");
    }

    protected fetchDataMethod = getAlerts;

    protected postDataMethod = postAlert;

    protected fetchSelectedItemMethod = getSelectedAlert;

    protected patchDataMethod = patchAlert;

    protected deactivateSelectedItemsMethod = deactivateAlerts;

    protected activateSelectedItemsMethod = activateAlerts;

    patchSelectedItem = flow(
        function* patchData(this: AlertsStore, data: Partial<AlertsTypes>, id: number | string, fieldsList: string[]) {
            const response = yield this.patchDataWithoutSet(data, id, fieldsList);
            if (response?.data.response) {
                [this._selectedItem] = parseDateFromServer<AlertsTypes>(
                    [response.data.response],
                    "created",
                );
            }
        },
    );

    fetchSelectedItem = flow(function* fetchSelectedItem(this: AlertsStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);
        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<AlertsTypes>(
                [response.data.response],
                "created",
            );
        }
    });

    fetchData = flow(function* fetchData(this: AlertsStore) {
        const response = yield this.fetchWithoutSet();
        if (response?.data.response.results) {
            this._data = parseDateFromServer<AlertsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
            this._pagination.totalActiveCount = response.data.response.total_active_count;
        }
    });
}

const alertsStore = new AlertsStore();

export default alertsStore;
