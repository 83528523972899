import FiltersDataStore from "../../common/filtersStore";
import { getPartnersAccountManagers } from "../../../api/partners/filters/getAccountManagers";

class FiltersPartnersManagersStore extends FiltersDataStore<string> {
    protected fetchListMethod = getPartnersAccountManagers;

    public get options() {
        return this.data?.map((item) => ({
            value: item,
            label: item,
        }));
    }
}

const filtersPartnersManagersStore = new FiltersPartnersManagersStore();

export default filtersPartnersManagersStore;
