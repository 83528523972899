import React from "react";
import { ReactComponent as CheckedCheckbox } from "../../../../resources/icons/table/checkedCheckbox.svg";
import { ReactComponent as UncheckedCheckbox } from "../../../../resources/icons/table/uncheckedCheckbox.svg";

interface DataType {
    roles: {
        [key: string]: boolean
    }
}

type PropsType<D extends DataType> = {
    dataItem: D;
    name: string
}

const TableFieldsCheckbox = <D extends DataType>({ dataItem, name }: PropsType<D>) => {
    if (dataItem.roles?.[name]) {
        return <span><CheckedCheckbox /></span>;
    }

    return <span><UncheckedCheckbox /></span>;
};

export default TableFieldsCheckbox;
