import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import routes from "../../../../constants/routes";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import listStore from "../../../../store/list/listStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { CloneIcon, FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import CloneListModal from "../components/CloneListModal";
import ListsFilters from "../components/ListsFilters";
import ProtectedListsMenu from "../components/ProtectedListsMenu";
import Link from "../../../ui/link/Link";

export function useTabsActions() {
    const { t } = useTranslation();
    const { checkedItems } = listStore;

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const [isCloneModalVisible, setCloneModalVisibility] = useState(false);
    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });

    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);
    const toggleCloneModal = () => setCloneModalVisibility((prev) => !prev);
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const filtersCount = objectToValuesList(listStore.filters || {}).length;

    const handleCloseCloneModal = () => {
        if (listStore.pagination.page !== 1) {
            listStore.setPaginationPage(1);
        } else {
            listStore.fetchData();
        }
        snackbarStore.add({ variant: "success", text: t.lists.snackbar.addSuccess });
    };

    const AddButtonComponent = (
        <Link to={`${routes.lists.href}/new`}>
            <ProtectedSmallButton
                title={t.lists.text.partOfLink}
                permission={permissionsMap.lists.add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
            />
        </Link>
    );
    const CloneButtonComponent = (
        <ProtectedSmallButton
            title={t.buttons.clone}
            variant="secondary"
            permission={permissionsMap.lists.add}
            disabled={!(checkedItems.length === 1)}
            iconLeft={<CloneIcon />}
            onClick={toggleCloneModal}
        />
    );
    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFilterModal}
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuComponent = (
        <ProtectedListsMenu
            store={listStore}
            permission={permissionsMap.lists.changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "clone-button-component", content: CloneButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuComponent },
    ];

    const CloneListModalComponent = (
        <CloneListModal
            title={t.lists.text.clone}
            buttonText={t.buttons.clone}
            navigateTo={routes.lists.href}
            isOpen={isCloneModalVisible}
            setOn={toggleCloneModal}
            handleClose={handleCloseCloneModal}
        />
    );

    const ActionModalComponent = (
        <ActionModal
            store={listStore}
            entityName={t.lists.text.partOfLink}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal
            isOpen={isFilterModalVisible}
            setOn={toggleFilterModal}
            width="400px"
            scrollable
        >
            <ListsFilters closeModal={toggleFilterModal} />
        </FiltersModal>
    );

    return {
        CloneListModalComponent, FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    };
}
