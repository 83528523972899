import { flow } from "mobx";
import { LimitsFiltersTypes, LimitsTypes, NewLimitType } from "../../types/limitsTypes";
import { getLimits } from "../../api/limits/getLimits";
import dataStore from "../common/dataStore";
import { getSelectedLimit } from "../../api/limits/getSelectedLimit";
import deactivateLimits from "../../api/limits/deactivateLimits";
import { postLimits } from "../../api/limits/postLimits";
import patchLimits from "../../api/limits/patchLimits";
import { parseDateFromServer } from "../../helpers";
import activateLimits from "../../api/limits/activateLimits";

class LimitStore extends dataStore<LimitsTypes, LimitsFiltersTypes, NewLimitType> {
    constructor() {
        super("LimitStore");
    }

    protected fetchDataMethod = getLimits;

    protected fetchSelectedItemMethod = getSelectedLimit;

    protected deactivateSelectedItemsMethod = deactivateLimits;

    protected activateSelectedItemsMethod = activateLimits;

    protected postDataMethod = postLimits;

    protected patchDataMethod = patchLimits;

    fetchData = flow(function* fetchData(this: LimitStore) {
        const response = yield this.fetchWithoutSet();
        if (response?.data.response.results) {
            this._data = parseDateFromServer<LimitsTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const limitStore = new LimitStore();

export default limitStore;
