import StatusBadge from "../../textField/StatusBadge";

interface DataType {
    is_checked?: boolean;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const TableFieldsCheckStatusBadge = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <StatusBadge
        variant={dataItem.is_checked ? "inactive" : "primary"}
        multiLanguageText={dataItem.is_checked ? "status.checked" : "status.notChecked"}
    />
);

export default TableFieldsCheckStatusBadge;
