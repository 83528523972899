import { HyperLinkInputContainer, InputLabel, Text } from "./InputStyles";
import ProtectedHyperLink from "./ProtectedHyperlink";

interface HyperlinkTextInputProps {
    permission?: string;
    elementId?: number | null;
    hyperlinkName?: string | null;
    navigateTo?: string;
    label: string;
    isGrid?: boolean;
    value?: string;
}

const HyperlinkTextInput = ({
    permission,
    elementId,
    hyperlinkName,
    navigateTo,
    label,
    isGrid,
    value = "",
}: HyperlinkTextInputProps) => (
    <HyperLinkInputContainer $isGrid={isGrid}>
        <InputLabel>{label}</InputLabel>

        <Text>
            {value}
            &nbsp;
            {hyperlinkName && elementId ? (
                <ProtectedHyperLink
                    navigateTo={`${navigateTo}/${elementId}`}
                    permission={permission}
                    linkName={hyperlinkName}
                />
            ) : (
                "-"
            )}
        </Text>
    </HyperLinkInputContainer>
);

export default HyperlinkTextInput;
