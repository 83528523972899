import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";

import { getTranslatedHandbook, statusOptionsHandbook } from "../../../../constants/handbooks";
import { FieldMode } from "../../../../constants/selectFields";
import useTranslation from "../../../../hooks/useTranslation";
import serviceStore from "../../../../store/entities/service/serviceStore";
import filtersServicesManagersStore from "../../../../store/filters/entities/servicesManagersFilterStore";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import processingFilterStore from "../../../../store/filters/processingFilterStore";
import filtersProcessingServicesStore from "../../../../store/filters/processingServicesFiltersStore";
import filtersServicesStore from "../../../../store/filters/servicesFilterStore";
import filtersTimezonesStore from "../../../../store/filters/timezonesFilterStore";
import { ServicesFiltersTypes } from "../../../../types/servicesTypes";
import { FormContainer, HeaderContainer, HeaderTitle } from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";

interface ServicesFiltersProps {
    closeModal: () => void;
}

const ServicesFilters = ({ closeModal }: ServicesFiltersProps) => {
    const { t, translate } = useTranslation();

    const initialValues: ServicesFiltersTypes = {
        ...serviceStore.filters,
    };

    const formik = useFormik<ServicesFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            serviceStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        serviceStore.resetFilters();
        formik.resetForm();
    };

    const statusOptions = getTranslatedHandbook(translate, statusOptionsHandbook);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.entities.services.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={filtersServicesStore.options}
                    placeholder={t.filters.fields.service}
                />
                <FloatSelect
                    formik={formik}
                    name="service_id"
                    options={filtersProcessingServicesStore.options}
                    placeholder={t.filters.fields.processingService}
                />
                <FloatSelect
                    formik={formik}
                    name="is_active"
                    options={statusOptions}
                    placeholder={t.filters.fields.isActive}
                />
                <FloatSelect
                    formik={formik}
                    name="partner"
                    options={filtersPartnersStore.options}
                    placeholder={t.filters.fields.partner}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    mode={FieldMode.Multiple}
                    options={processingFilterStore.options}
                    placeholder={t.filters.fields.processing}
                />
                <FloatInput
                    formik={formik}
                    name="email"
                    placeholder={t.filters.fields.email}
                />
                <FloatInput
                    formik={formik}
                    name="phone"
                    placeholder={t.filters.fields.phone}
                />
                <FloatSelect
                    formik={formik}
                    name="account_manager"
                    options={filtersServicesManagersStore.options}
                    placeholder={t.filters.fields.accountManager}
                />
                <FloatSelect
                    formik={formik}
                    name="timezone"
                    options={filtersTimezonesStore.options}
                    placeholder={t.filters.fields.timezone}
                />
                <Button variant="primary" isLoading={serviceStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(ServicesFilters);
