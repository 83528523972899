import {
    action, computed, makeObservable, observable, runInAction,
} from "mobx";
import { getTransactionLists } from "../../api/transactions/getTransactionLists";
import { FetchStatusesType } from "../../types/fetchStatuses";
import { ListsTypes } from "../../types/listsTypes";

interface FetchListResponseType<T> {
    message: string;
    response: T[];
}

class FiltersTransactionListsStore {
    data: ListsTypes[] | null = null;

    private fetchState: FetchStatusesType = FetchStatusesType.unset;

    private controller = new AbortController();

    constructor() {
        makeObservable<FiltersTransactionListsStore, "data" | "fetchState">(this, {
            data: observable,
            fetchState: observable,
            options: computed,
            loading: computed,
            fetch: action,
        });
    }

    public fetch = async (id: string | number, listType: string) => {
        try {
            this.clear();
            this.controller.abort();
            this.controller = new AbortController();
            this.fetchState = FetchStatusesType.pending;
            const response = await getTransactionLists(id, listType.toLowerCase(), this.controller.signal);

            runInAction(() => {
                this.data = (response.data as FetchListResponseType<ListsTypes>).response;

                this.fetchState = FetchStatusesType.success;
            });
        } catch (error) {
            runInAction(() => {
                this.fetchState = FetchStatusesType.failed;
            });
        }
    };

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
            is_global: item.is_global,
            category: item.category,
            type: item.type,
        }));
    }

    public get loading() {
        return this.fetchState === FetchStatusesType.pending;
    }

    public clear() {
        this.data = [];
    }
}

const filtersTransactionListsStore = new FiltersTransactionListsStore();

export default filtersTransactionListsStore;
