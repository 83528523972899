import { CustomCircleIcon } from "./CustomCircleIconStyles";

interface CircleIconProps {
    isActive: boolean;
    count: number | string;
}

const CircleIcon = ({ isActive, count }: CircleIconProps) => (
    <CustomCircleIcon $isActive={isActive} $isCircle={count < 10}>
        {count}
    </CustomCircleIcon>
);

export default CircleIcon;
