import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import Routes from "../../../../../../constants/routes";
import withPermission from "../../../../../../hocs/withPermissions";
import serviceRulesPacksStore from "../../../../../../store/entities/service/serviceRulesPacksStore";

import { RulesPacksTypes } from "../../../../../../types/rulesPacksTypes";
import TableFieldsDetailsLink from "../../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { ColumnType } from "../../../../../ui/table/types";
import useTranslation from "../../../../../../hooks/useTranslation";
import TabHeader from "../../../../../tabHeader/TabHeader";
import Table from "../../../../../ui/table/Table";
import { TableMainLink } from "../../../../../ui/tableFields/nameLink/TableMainLink";
import { Tabs } from "../../../../../../constants/tabs";

interface ProtectedServiceRulesPacksTableProps {
    permission: string;
    serviceId: number
}

type WrappedComponentProps = Pick<ProtectedServiceRulesPacksTableProps, "serviceId">

const columns: ColumnType<RulesPacksTypes>[] = [
    {
        header: "table.header.ruleName",
        field: "name",
        isResizable: true,
        width: "666px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.rulesPacks.href}/${dataItem.id}`}
                state={{ tab: Tabs.NOT_SPECIFIED }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.ruleId", field: "id", sortable: true },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={Routes.rulesPacks.href}
                state={{ tab: Tabs.NOT_SPECIFIED }}
            />
        ),
    },
];

const WrappedComponent = observer(({ serviceId }: WrappedComponentProps) => {
    const { t } = useTranslation();

    const tablePagination = {
        ...serviceRulesPacksStore.pagination,
        setPaginationPage: serviceRulesPacksStore.setPaginationPage,
        setPaginationSize: serviceRulesPacksStore.setPaginationSize,
    };

    useEffect(() => {
        if (serviceId) {
            serviceRulesPacksStore.fetchData(serviceId);
        }
    }, [serviceRulesPacksStore.pagination, serviceRulesPacksStore.ordering]);

    return (
        <>
            <TabHeader title={t.entities.partners.details.rulesPacks} />
            <Table
                columns={columns}
                data={serviceRulesPacksStore.data}
                pagination={tablePagination}
                sortMode={serviceRulesPacksStore.ordering}
                loading={serviceRulesPacksStore.loading}
                onSort={serviceRulesPacksStore.toggleSort}
            />
        </>

    );
});

const ProtectedServiceRulesPacksTable = ({ serviceId, permission }: ProtectedServiceRulesPacksTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission serviceId={serviceId} />;
};

export default memo(ProtectedServiceRulesPacksTable);
