import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import {
    alertDisplayAttributesOptionsHandbook,
    alertImportanceOptionsHandbook,
    alertTypeOptionsHandbook,
    getTranslatedHandbook,
} from "../../../../../constants/handbooks";
import { permissionsMap } from "../../../../../constants/permissions";
import { FieldMode } from "../../../../../constants/selectFields";
import {
    MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH, MAX_TRIGGER_FILED_LENGTH,
} from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import filtersTgGroupsStore from "../../../../../store/filters/tgGroupsFiltersStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import {
    AlertPartnerTypes, AlertProcessingTypes, AlertServiceTypes, EditAlertTypes,
} from "../../../../../types/alertsTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof EditAlertTypes> = [
    "name",
    "importance",
    "display_attributes",
    "trigger_text",
    "services",
    "alert_type",
    "partners",
    "tg_group",
    "processing",
    "description",
    "is_active",
];

const EditAlertModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: alertId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = alertsStore;

    const partnersIdsList = selectedItem?.partners.map((partner) => partner.id);
    const servicesIdsList = selectedItem?.services.map((service) => service.id);
    const processingId = (selectedItem?.processing as AlertProcessingTypes)?.id;

    const alertImportanceOptions = getTranslatedHandbook(translate, alertImportanceOptionsHandbook);
    const alertDisplayAttributesOptions = getTranslatedHandbook(translate, alertDisplayAttributesOptionsHandbook);
    const alertTypesOptions = getTranslatedHandbook(translate, alertTypeOptionsHandbook);

    const formik = useFormik<EditAlertTypes & { processing?: number }>({
        initialValues: {},
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required(t.validation.required)
                .max(
                    MAX_FIELD_NAME_LENGTH,
                    translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
                ),
            trigger_text: Yup.string()
                .required(t.validation.required)
                .max(
                    MAX_TRIGGER_FILED_LENGTH,
                    translate(`validation.inputFieldMaxLengthError.${MAX_TRIGGER_FILED_LENGTH}`),
                ),
            importance: Yup.string().required(t.validation.required),
            alert_type: Yup.string().required(t.validation.required),
            tg_group: Yup.string()
                .when("alert_type", ([alert_type], schema) => ((alert_type === "tg")
                    ? schema.required(t.validation.required)
                    : schema.nullable())),

            display_attributes: Yup.array().required(t.validation.required),

            partners: Yup.array().when(["services", "processing"], ([services, processing], schema) => (
                ((services && services.length) || (processing && processing.length))
                    ? schema.nullable() : schema.required(t.validation.required))),

            services: Yup.array().when(["partners", "processing"], ([partners, processing], schema) => (
                ((partners && partners.length) || (processing && processing.length))
                    ? schema.nullable() : schema.required(t.validation.required))),

            processing: Yup.string().when(["partners", "services"], ([partners, services], schema) => (
                ((partners && partners.length) || (services && services.length))
                    ? schema.nullable() : schema.required(t.validation.required))),
            description: Yup.string()
                .max(
                    MAX_DESCRIPTION_FILED_LENGTH,
                    translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
                ),
        }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]]),

        onSubmit: async (values) => {
            if (alertId) {
                const partners = (values.partners || []) as AlertPartnerTypes[];
                const services = (values.services || []) as AlertServiceTypes[];

                const fieldsData = { ...values, partners, services };

                await alertsStore.patchSelectedItem(fieldsData, alertId, fieldsList);

                if (alertsStore.isEditItemSuccess) {
                    setOn(!isOpen);
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                } else if (editItemErrors?.common) {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
            }
        },
    });

    const isTgAlertType = formik.values.alert_type === "tg";

    const handleCloseModal = () => {
        alertsStore.unsetEditState();
        formik.resetForm();
    };

    const toggleAlertStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const clearTgGroupFieldValue = () => {
        if (!isTgAlertType) formik.setFieldValue("tg_group", null);
    };

    const isPartnersFieldRequired = !formik.values.services?.length || !formik.values.processing;
    const isServicesFieldRequired = !formik.values.partners?.length || !formik.values.processing;
    const isProcessingFieldRequired = !formik.values.services?.length || !formik.values.partners?.length;

    const isPartnersFieldDisabled = alertsStore.loading
        || !!formik.values.services?.length
        || !!formik.values.processing;

    const isServicesFieldDisabled = alertsStore.loading
        || !!formik.values.partners?.length
        || !!formik.values.processing;

    const isProcessingFieldDisabled = alertsStore.loading
        || !!formik.values.partners?.length
        || !!formik.values.services?.length;

    useEffect(() => {
        formik.setValues({
            ...selectedItem,
            partners: partnersIdsList,
            services: servicesIdsList,
            processing: processingId,
        });
    }, [selectedItem, isOpen]);

    useEffect(() => {
        if (isOpen) {
            filtersPartnersStore.fetch();
            filtersServicesStore.fetch();
            filtersProcessingStore.fetch();
            filtersTgGroupsStore.fetch();
        }

        return () => handleCloseModal();
    }, [isOpen]);

    return (
        <Modal
            isForm
            gap="0"
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={alertsStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
        >
            <EditSectionWrapper $margin="32px 0">
                <FloatInput
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="name"
                    isRequired
                    placeholder={t.alerts.placeholders.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="trigger_text"
                    isRequired
                    placeholder={t.alerts.placeholders.triggerText}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="alert_type"
                    isRequired
                    disabled={alertsStore.loading}
                    options={alertTypesOptions}
                    placeholder={t.alerts.placeholders.alertType}
                    serverErrors={alertsStore.postErrors}
                    resetServerError={alertsStore.resetPostError}
                    onChange={clearTgGroupFieldValue}
                />
                {isTgAlertType && (
                    <FloatSelect
                        formik={formik}
                        name="tg_group"
                        isRequired
                        disabled={alertsStore.loading}
                        placeholder={t.filters.fields.tgGroup}
                        options={filtersTgGroupsStore.options}
                        serverErrors={editItemErrors}
                        resetServerError={resetEditError}
                    />
                )}
                <FloatSelect
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="importance"
                    isRequired
                    placeholder={t.alerts.placeholders.importance}
                    options={alertImportanceOptions}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="display_attributes"
                    mode={FieldMode.Multiple}
                    isRequired
                    placeholder={t.alerts.placeholders.displayAttributes}
                    options={alertDisplayAttributesOptions}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="partners"
                    mode={FieldMode.Multiple}
                    isRequired={isPartnersFieldRequired}
                    disabled={isPartnersFieldDisabled}
                    placeholder={t.alerts.placeholders.partner}
                    options={filtersPartnersStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="services"
                    mode={FieldMode.Multiple}
                    isRequired={isServicesFieldRequired}
                    disabled={isServicesFieldDisabled}
                    placeholder={t.alerts.placeholders.service}
                    options={filtersServicesStore.options}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    isRequired={isProcessingFieldRequired}
                    disabled={isProcessingFieldDisabled}
                    options={filtersProcessingStore.options}
                    placeholder={t.alerts.placeholders.processing}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={alertsStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.alerts.placeholders.message}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />

                    <ProtectedSwitch
                        permission={permissionsMap.alerts.changeStatus}
                        disabled={alertsStore.loading}
                        checked={formik.values.is_active}
                        onChange={toggleAlertStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditAlertModal);
