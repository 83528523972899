import { FC, ReactNode } from "react";
import { LabeledValue } from "antd/es/select";
import { ReactComponent as ArrowLeft } from "../../../resources/icons/table/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../resources/icons/table/arrow-right.svg";
import useTranslation from "../../../hooks/useTranslation";
import {
    AntDPaginationComponent,
    ArrowComponent,
    PaginationContainer,
    PaginationSelect,
    SelectContainer,
} from "./PaginationComponents";
import { defaultPageSizeOptions } from "../../../constants/pagination";

export interface PaginationProps {
    page: number
    size: number
    count: number
    is_lazy_count?: boolean
    setPaginationPage: (count: number) => void
    setPaginationSize: (page: number) => void
}

const itemRender = (_: number, type: string, originalElement: ReactNode) => {
    if (type === "prev") {
        return <ArrowComponent><ArrowLeft /></ArrowComponent>;
    }
    if (type === "next") {
        return <ArrowComponent><ArrowRight /></ArrowComponent>;
    }
    return originalElement;
};

const Pagination: FC<PaginationProps> = ({
    page,
    size,
    count,
    is_lazy_count,
    setPaginationPage,
    setPaginationSize,
}) => {
    const { t } = useTranslation();

    const onSizeChange = (value: string | number | LabeledValue) => {
        setPaginationSize(+value);
    };

    const onPageChange = (newPage: number) => {
        setPaginationPage(newPage);
    };

    const totalCount = is_lazy_count ? count - 1 : count;

    return (
        <PaginationContainer>
            <AntDPaginationComponent
                simple
                total={totalCount}
                pageSize={size}
                current={page}
                locale={{ items_per_page: "" }}
                itemRender={itemRender}
                onChange={onPageChange}
                showSizeChanger={false}
                $isLazyCount={is_lazy_count}
            />
            <SelectContainer>
                <p>{t.table.pagination.itemsPerPage}</p>
                <PaginationSelect
                    onSelect={onSizeChange}
                    options={defaultPageSizeOptions}
                    value={size}
                />
            </SelectContainer>
        </PaginationContainer>

    );
};

export default Pagination;
