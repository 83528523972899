import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent, useEffect } from "react";
import * as Yup from "yup";

import { RegExpressions } from "../../../../../constants/regex";
import {
    MAX_DESCRIPTION_FILED_LENGTH, MAX_EMAIL_LENGTH, MAX_FIELD_NAME_LENGTH, MAX_PHONE_LENGTH,
} from "../../../../../constants/validationRules";
import { filterEmptyFields } from "../../../../../helpers/filterEmptyFields";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import partnerStore from "../../../../../store/entities/partner/partnerStore";
import filtersCountriesStore from "../../../../../store/filters/countriesFiltersStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { NewPartnerType } from "../../../../../types/partnerTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import { DescriptionFieldWrapper } from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import {
    Container, FormWrapper, Row, Title,
} from "./NewPartnersComponents";
import { useNavigation } from "../../../../../hooks/useNavigation";

const fieldsList = [
    "name", "partner_id", "country", "address", "email",
    "phone", "account_manager", "timezone", "processing", "description",
];

const initialValues: NewPartnerType = {
    name: "",
    country: null,
    address: "",
    email: "",
    phone: "",
    account_manager: "",
    partner_id: null,
    timezone: null,
    processing: null,
    description: "",
    is_active: true,
};

const PagesNewPartners = () => {
    const { t, translate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const handleNavigateToPrevPage = () => navigateBackward();

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        partner_id: Yup.string().required(t.validation.required),
        country: Yup.string().required(t.validation.required),
        phone: Yup.string().matches(RegExpressions.phoneWithSpaces, t.validation.phone)
            .max(MAX_PHONE_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_PHONE_LENGTH}`)),
        timezone: Yup.string().required(t.validation.required),
        processing: Yup.string().required(t.validation.required),
        email: Yup.string().matches(RegExpressions.emailWithSpaces, t.validation.email)
            .max(MAX_EMAIL_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`)),
        account_manager: Yup.string().matches(RegExpressions.emailWithSpaces, t.validation.email)
            .max(254, translate(`validation.inputFieldMaxLengthError.${254}`)),
        address: Yup.string().nullable()
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
    });

    const formik = useFormik<NewPartnerType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const filteredData = filterEmptyFields(fields, "is_active");
            const trimmedData = {
                ...filteredData,
                email: filteredData.email?.trim(),
                account_manager: filteredData.account_manager?.trim(),
            };

            await partnerStore.postData(trimmedData, fieldsList);

            if (partnerStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                partnerStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.entities.partners.snackbar.addSuccess });
            } else if (partnerStore.postErrors.common) {
                snackbarStore.add({ variant: "error", text: partnerStore.postErrors.common });
            }
        },
    });

    const togglePartnerStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const actionHeaderContentList = [
        {
            key: "cancel_button",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, partnerStore.isPostRequestSuccess);

    useEffect(() => {
        filtersCountriesStore.fetch();
        filtersTimezonesStore.fetch();
        filtersProcessingStore.fetch();

        return () => partnerStore.resetPostErrors();
    }, []);

    return (
        <>
            <SecondHeader title={t.partners.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.partners.text.title}</Title>
                    <Row>
                        <Title>{t.partners.text.status}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={partnerStore.loading}
                            checked={formik.values.is_active}
                            onChange={togglePartnerStatus}
                        />
                    </Row>
                </Row>

                <FormWrapper onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        name="name"
                        isRequired
                        disabled={partnerStore.loading}
                        placeholder={t.entities.partners.placeholder.name}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        name="partner_id"
                        isRequired
                        disabled={partnerStore.loading}
                        placeholder={t.entities.partners.placeholder.partnerId}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        isRequired
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.country}
                        name="country"
                        options={filtersCountriesStore.options}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        name="address"
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.address}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        name="email"
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.email}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        name="phone"
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.phone}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        name="account_manager"
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.accountManager}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        isRequired
                        disabled={partnerStore.loading}
                        placeholder={t.placeholder.timezone}
                        name="timezone"
                        options={filtersTimezonesStore.options}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        placeholder={t.placeholder.processing}
                        name="processing"
                        isRequired
                        disabled={partnerStore.loading}
                        options={filtersProcessingStore.options}
                        serverErrors={partnerStore.postErrors}
                        resetServerError={partnerStore.resetPostError}
                    />

                    <DescriptionFieldWrapper>
                        <FloatTextArea
                            formik={formik}
                            disabled={partnerStore.loading}
                            name="description"
                            placeholder={t.placeholder.description}
                            serverErrors={partnerStore.postErrors}
                            resetServerError={partnerStore.resetPostError}
                            autoSize
                            maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        />
                    </DescriptionFieldWrapper>

                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={partnerStore.loading}
                        disabled={partnerStore.loading}
                    >
                        {t.buttons.add}
                    </Button>
                </FormWrapper>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(PagesNewPartners);
