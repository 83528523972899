import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import Routes from "../../../../../constants/routes";
import { Tabs } from "../../../../../constants/tabs";
import rulesPacksDetailsLimitsStore from "../../../../../store/rulesPack/rulesPackLimitsStore";
import { LimitsTypes } from "../../../../../types/limitsTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import TableFieldsTypeBadge from "../../../../ui/tableFields/typeBadge/TableFieldTypeBadge";

interface RulesPacksLimitsTableProps {
    isCheckboxVisible?: boolean;
    ruleId?: string;
}

const columns: ColumnType<LimitsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.limitName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.limits.href}/${dataItem.id}`}
                state={{ previousTab: Tabs.LIMITS }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.limitId", field: "id", sortable: true },
    {
        header: "table.header.order", field: "order", padding: "13px 16px", sortable: true,
    },
    { header: "table.header.rulesPackId", field: "rules_pack", sortable: true },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.lastUpdate",
        field: "last_update",
        sortable: true,
    },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.type", field: "channel_check", Component: TableFieldsTypeBadge },
    { header: "table.header.isActive", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={Routes.limits.href}
                state={{ previousTab: Tabs.LIMITS }}
            />
        ),
    },
];

const RulesPacksLimitsTable = ({ ruleId, isCheckboxVisible }: RulesPacksLimitsTableProps) => {
    const { setPaginationPage, setPaginationSize } = rulesPacksDetailsLimitsStore;

    const tablePagination = {
        ...rulesPacksDetailsLimitsStore.pagination,
        setPaginationPage,
        setPaginationSize,
    };

    useEffect(() => {
        if (ruleId) rulesPacksDetailsLimitsStore.fetchData(Number(ruleId));
    }, [rulesPacksDetailsLimitsStore.pagination, rulesPacksDetailsLimitsStore.ordering]);

    return (
        <Table
            columns={columns}
            data={rulesPacksDetailsLimitsStore.data}
            pagination={tablePagination}
            checkedItems={rulesPacksDetailsLimitsStore.checkedItems}
            sortMode={rulesPacksDetailsLimitsStore.ordering}
            loading={rulesPacksDetailsLimitsStore.loading}
            onSort={rulesPacksDetailsLimitsStore.toggleSort}
            onCheckboxChange={rulesPacksDetailsLimitsStore.toggleItem}
            onMainCheckboxChange={rulesPacksDetailsLimitsStore.toggleMainItem}
            isItemSelectionAllowed={isCheckboxVisible}
        />
    );
};

export default observer(RulesPacksLimitsTable);
