import { ChangeEvent } from "react";
import withPermission from "../../../hocs/withPermissions";
import ControlledSwitch from "./ControlledSwitch";

interface WrappedComponentProps {
    isChecked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

type ProtectedControlledSwitchProps = WrappedComponentProps & {
    permission: string;
}

const WrappedComponent = ({ isChecked, onChange }: WrappedComponentProps) => (
    <ControlledSwitch isChecked={isChecked} onChange={onChange} />
);

const ProtectedControlledSwitch = ({ isChecked, permission, ...props }: ProtectedControlledSwitchProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isChecked={isChecked} {...props} />;
};

export default ProtectedControlledSwitch;
