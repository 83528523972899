import styled from "styled-components";
import { colors } from "../../../../../../styles/variables";

export const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;

export const TableContainer = styled.div<{ $shrinkHeight: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: max-content;
    overflow-x: auto;
    min-height: ${(props) => (props.$shrinkHeight ? "200px" : "initial")};
`;

export const EmptyTable = styled.div`
    display: flex;
    padding: 64px 20px;
    flex-direction: column;
    height: fit-content;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    border: 2px dashed ${colors.inputDefaultBorder};

    h3, p {
        margin: 0;
    }

`;

export const Table = styled.table`
    table-layout: auto;
    border-collapse: collapse;
    position: relative;
`;

export const TH = styled.th`
    background-color: ${colors.white};
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    white-space: nowrap;

    *:not(svg) {
        color: ${colors.textDefault};
    }

    &&:first-child {
        border-radius: 12px 0 0 0;
    }

    &&:last-child {
        border-radius: 0 12px 0 0;
    }

`;

export const THContainer = styled.span`
    display: flex;
    padding: 13px 16px;
`;

export const TR = styled.tr`
    cursor: default;
    background-color: ${colors.white};

    &:nth-of-type(odd) {
        background-color: ${colors.tableRowZebraBackground}
    }

    &&:last-child td:first-child {
        border-bottom-left-radius: 12px;
    }

    &&:last-child td:last-child {
        border-bottom-right-radius: 12px;
    }

`;

export const TD = styled.td`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font: 400 14px Roboto, sans-serif;
    height: 64px;
    padding: 0 16px;
    color: ${colors.activeText};
`;

export const TableLoaderContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    z-index: 1000;


    p {
        margin: 4px 0 0;
    }
`;
