import { css } from "styled-components";
import { colors } from "../../../../../styles/variables";

export const tableContainerStyle = css`
    max-height: 67vh;
    overflow: auto;
    border-radius: 12px;
    margin-top: 24px;
    border: 1px solid ${colors.inputDefaultBorder};

    @media only screen and (min-height: 900px) {
        max-height: 70vh;
    }
`;

export const propertiesCellStyle = css`
    height: 40px;
    position: sticky;
    left: 0;
    top: -55px;
    z-index: 100;
    background-color: inherit;
`;
