import request from "../request";
import { NewProcessingType, ProcessingTypes } from "../../types/processingTypes";

interface ResponseType {
    message: string,
    response: ProcessingTypes,
}

export const postProcessing = (data: NewProcessingType) => request<ResponseType>({
    method: "POST",
    uri: "entities/processings/",
    data,
});
