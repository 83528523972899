/* eslint-disable camelcase */
import { useFormik } from "formik";
import { FC, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import { FormContainer, HeaderContainer, HeaderTitle } from "../../../filters/FiltersModalComponents";
import { TextButton } from "../../../ui/button/ButtonStyles";
import useTranslation from "../../../../hooks/useTranslation";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import processingStore from "../../../../store/entities/processing/processingStore";
import processingFilterStore from "../../../../store/filters/processingFilterStore";
import { getTranslatedHandbook, statusOptionsHandbook } from "../../../../constants/handbooks";
import Button from "../../../ui/button/Button";
import { ProcessingFiltersTypes } from "../../../../types/processingTypes";

interface ProcessingFiltersProps {
    closeModal: () => void;
}

const ProcessingFilters: FC<ProcessingFiltersProps> = ({ closeModal }) => {
    const { t, translate } = useTranslation();

    const initialValues: ProcessingFiltersTypes = {
        ...processingStore.filters,
    };

    const formik = useFormik<ProcessingFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            processingStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        processingStore.resetFilters();
        formik.resetForm();
    };

    const statusOptions = getTranslatedHandbook(translate, statusOptionsHandbook);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.entities.processing.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={processingFilterStore.options}
                    placeholder={t.filters.fields.processing}
                />
                <FloatInput
                    formik={formik}
                    name="email"
                    placeholder={t.filters.fields.email}
                />
                <FloatSelect
                    formik={formik}
                    name="is_active"
                    options={statusOptions}
                    placeholder={t.filters.fields.isActive}
                />
                <Button variant="primary" isLoading={processingStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(ProcessingFilters);
