import { FC, ReactNode } from "react";
import type { InputProps } from "antd";
import { TextInputProps } from "./TextInput";
import {
    AsteriskComponent,
    FloatInputCommonContainer, FloatInputContainer, FloatInputLabel, FloatInputStyle, IconContainer,
} from "./InputStyles";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import useFloatInput from "../../../hooks/useFloatInputs";

export type FloatInputProps = {
    value?: string
    isRequired?: boolean
    postIcon?: ReactNode
} & InputProps & TextInputProps

const FloatInput: FC<FloatInputProps> = ({
    formik,
    serverErrors,
    placeholder,
    isRequired,
    name = "",
    isCommonError,
    resetServerError,
    postIcon,
    ...props
}) => {
    const inputArguments = {
        fieldProps: props, name, formik, serverErrors, isCommonError, resetServerError,
    };

    const {
        isFieldError, fieldError, isOccupied, fieldValue, setFocus, handleChange, handleBlur,
    } = useFloatInput(inputArguments);

    return (
        <FloatInputCommonContainer>
            <FloatInputContainer
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
            >
                <FloatInputStyle
                    value={fieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    $isError={isFieldError}
                    name={name}
                    {...props}
                />
                <FloatInputLabel $label={isOccupied} htmlFor={name} $isError={isFieldError}>
                    {placeholder}
                    {isRequired && <AsteriskComponent>*</AsteriskComponent>}
                </FloatInputLabel>
                {postIcon && <IconContainer>{postIcon}</IconContainer>}
            </FloatInputContainer>
            {isFieldError && fieldError && ErrorLabel(fieldError || "")}
        </FloatInputCommonContainer>
    );
};

export default FloatInput;
