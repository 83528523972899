import { AddIcon, RemoveIcon } from "../textInput/InputStyles";
import { Box } from "./SelectComponents";

const Icon = () => (
    <Box>
        <AddIcon className="select-add-icon" />
        <RemoveIcon className="select-remove-icon" />
    </Box>
);

export default Icon;
