import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

export const deactivateElements = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "elements/deactivate/",
    data,
});
