import { useState, useEffect } from "react";
import Locales from "../constants/locales";
import en from "../resources/locales/en.json";

interface InsertedValueType {
    [name: string]: string | number | boolean;
}

const translations = {
    [Locales.en]: en,
};

const localLang = localStorage.getItem("lang") as Locales;
const defaultLang: Locales = translations[localLang] ? localLang : Locales.en;

let callbacks: Array<(language: Locales) => void> = [];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslation = () => {
    const [language, setLangState] = useState<Locales>(defaultLang);
    const t = translations[language];

    const setLanguage = (lang: Locales) => {
        localStorage.setItem("lang", lang);
        callbacks.forEach((callback) => callback(lang));
    };

    const translate = (id?: string) => {
        if (typeof id === "string") {
            const keys: string[] = id.split(".");
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = keys.reduce((acc: any, key: string) => (
                typeof acc === "object" ? acc[key] : acc
            ), t);

            return value ?? id;
        }

        return id;
    };

    const concatenate = (text: string, insertedValue: InsertedValueType): string => (
        text.replace(/\{[A-Za-z]*\}/ig, (match: string) => {
            const name = match.substring(1, match.length - 1);
            return insertedValue[name]?.toString() ?? "";
        })
    );

    useEffect(() => {
        callbacks.push(setLangState);

        return () => {
            callbacks = callbacks.filter((lang) => lang !== setLangState);
        };
    }, [setLangState]);

    return {
        t,
        language,
        concatenate,
        translate,
        setLanguage,
    };
};

export default useTranslation;
