import React, { FC, useEffect, useState } from "react";

import { EditorProps } from "@monaco-editor/react";
import { Editor } from "./CodeEditorComponents";
import { FormikTypes } from "../../../types/formikTypes";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import snackbarStore from "../../../store/snackbar/snackbarStore";
import useTranslation from "../../../hooks/useTranslation";

export interface CodeEditorProps extends EditorProps {
    name?: string
    formik?: FormikTypes;
    error?: boolean;
    helperText?: React.ReactNode;
    serverErrors?: Record<string, string>;
    onlyNumber?: boolean,
    maxIntegerDigits?: number,
    maxDecimalDigits?: number,
    disabled?: boolean
    showDisabledSnackbar?: boolean
    resetServerError?: (field: string) => void;
}

const MAX_SNACKBAR_COUNT = 4;

const CodeEditor: FC<CodeEditorProps> = ({
    formik,
    serverErrors,
    name,
    disabled,
    showDisabledSnackbar = true,
    value,
    resetServerError,
    onChange,
    ...props
}) => {
    let isError;
    let error;
    const field = name || "";

    const { t } = useTranslation();
    const [code, setCode] = useState<string>(value || "");

    if (formik) {
        error = serverErrors?.[field] || (formik?.touched[field] && formik.errors[field]);
        isError = !!error;
    }

    const editorOptions: EditorProps["options"] = {
        scrollbar: { vertical: "hidden" },
        minimap: { enabled: false },
        domReadOnly: disabled,
        readOnly: disabled,
    };

    const handleEditorChange: EditorProps["onChange"] = (newValue, event) => {
        formik?.setFieldTouched(name || "", false);
        resetServerError?.(name || "");
        onChange?.(newValue, event);
        formik?.setFieldValue(name || "", newValue);
    };

    const handleDisabledEditor = () => {
        if (disabled && showDisabledSnackbar && snackbarStore.all.length <= MAX_SNACKBAR_COUNT) {
            snackbarStore.add({
                variant: "warning",
                text: t.limits.text.editCodePopup,
            });
        }
    };

    useEffect(() => {
        if (value || value === "") {
            setCode(value);
        } else if (formik?.values[field]) {
            setCode(formik.values[field]);
        }
    }, [formik?.values[field], value, field]);

    return (
        <div role="presentation" onClick={handleDisabledEditor}>
            <Editor
                {...props}
                height="100%"
                defaultLanguage="go"
                value={code}
                onChange={handleEditorChange}
                options={editorOptions}
            />
            {isError && ErrorLabel(error || "")}
        </div>
    );
};

export default CodeEditor;
