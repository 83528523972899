import { MenuProps } from "antd";
import { observer } from "mobx-react-lite";
import { memo } from "react";

import { getDeactivateAndActivateMenuItems } from "../../../../../helpers/menuHelper";
import withPermission from "../../../../../hocs/withPermissions";
import useTranslation from "../../../../../hooks/useTranslation";
import notificationsStore from "../../../../../store/alerts/notifications/notificationsStore";
import authStore from "../../../../../store/auth/authStore";
import MenuDropdown from "../../../../ui/menuDropdown/MenuDropdown";
import { NotificationAction } from "../../data";
import { TabTitlesKeys } from "../../hooks/useTabsActions";
import { AlertsSidebarComponentsProps } from "../../types";
import { getNotificationsMenuItems } from "./alertsMenuHelpers";

type ProtectedAlertsMenuProps = AlertsSidebarComponentsProps & {
    permission: string | string[];
}

type MenuDropdownItems = {
    [K in TabTitlesKeys]: MenuProps["items"];
};

const WrappedComponent = observer(({
    store,
    currentTabTitle,
    handleRequestResult,
    toggleActionModal,
}: AlertsSidebarComponentsProps) => {
    const { t, translate } = useTranslation();
    const { userPermissions } = authStore;

    const responseStatusMessageMap = {
        [NotificationAction.Check]: { success: t.snackbar.checkSuccess, error: t.snackbar.checkError },
        [NotificationAction.Uncheck]: { success: t.snackbar.uncheckSuccess, error: t.snackbar.uncheckError },
    };

    const toggleNotificationsStatus = async (action: NotificationAction) => {
        const result = await notificationsStore.toggleSelectedItemsStatus(action);
        const { success: successMessage, error: errorMessage } = responseStatusMessageMap[action];

        handleRequestResult(result, successMessage, errorMessage);
    };

    const alertsAndTgGroupsItemsList = getDeactivateAndActivateMenuItems(translate, toggleActionModal, store);
    const notificationsItemsList = getNotificationsMenuItems(
        store,
        userPermissions,
        translate,
        toggleNotificationsStatus,
        toggleActionModal,
    );

    const menuDropdownItemsMap: MenuDropdownItems = {
        alerts: alertsAndTgGroupsItemsList,
        notifications: notificationsItemsList,
        tg_groups: alertsAndTgGroupsItemsList,
    };

    return <MenuDropdown items={menuDropdownItemsMap[currentTabTitle]} />;
});

const ProtectedAlertsMenu = ({ permission, ...props }: ProtectedAlertsMenuProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default memo(ProtectedAlertsMenu);
