import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import routes from "../../../../../constants/routes";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import notificationsStore from "../../../../../store/alerts/notifications/notificationsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsHeaderWithButton, DetailsHeaderWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import ProtectedControlledSwitch from "../../../../ui/switch/ProtectedControlledSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import HyperlinkTextInput from "../../../../ui/textInput/HyperlinkTextInput";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import { DetailPageHeader } from "../../../../ui/TextStyle";
import { NotificationAction } from "../../data";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

const NotificationDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = notificationsStore;

    const [isNotificationChecked, setNotificationCheck] = useState<boolean | null>(null);

    const currentTitle = id ? `${t.notifications.text.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.notifications.title, currentTitle);

    const handleNavigate = () => navigateBackward({ tab: Tabs.NOTIFICATIONS });

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const toggleNotificationStatus = async () => {
        const toggleAction = selectedItem?.is_checked ? NotificationAction.Uncheck : NotificationAction.Check;

        await notificationsStore.toggleSelectedItemsStatus(toggleAction);

        if (notificationsStore.isEditItemSuccess) {
            setNotificationCheck(!isNotificationChecked);
        } else {
            snackbarStore.add({ variant: "error", text: t.snackbar.checkError });
        }
    };

    useEffect(() => {
        if (id) notificationsStore.fetchSelectedItem(id);

        return () => notificationsStore.clearSelectedItem();
    }, [id]);

    useEffect(() => {
        if (selectedItem?.is_checked !== undefined) setNotificationCheck(selectedItem?.is_checked);
    }, [selectedItem?.is_checked]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeaderWrapper>
                        <DetailsHeaderWithButton>
                            <DetailPageHeader>
                                {`${t.notifications.text.partOfLink} ${t.details.title}`}
                            </DetailPageHeader>
                        </DetailsHeaderWithButton>

                        <DetailsHeaderWithButton>
                            <DetailPageHeader>
                                {t.details.status}
                            </DetailPageHeader>

                            <StatusBadge
                                variant={isNotificationChecked ? "inactive" : "primary"}
                                multiLanguageText={isNotificationChecked ? "status.checked" : "status.notChecked"}
                            />

                            {(isNotificationChecked !== null) && (
                                <ProtectedControlledSwitch
                                    permission={permissionsMap.notifications.changeStatus}
                                    isChecked={isNotificationChecked}
                                    onChange={toggleNotificationStatus}
                                />
                            )}
                        </DetailsHeaderWithButton>
                    </DetailsHeaderWrapper>

                    <DetailsBodyWrapper>
                        <PreInput
                            label={t.alerts.placeholders.name}
                            value={selectedItem?.alert.name}
                        />
                        <PreInput
                            label={t.alerts.placeholders.id}
                            value={selectedItem?.alert.id}
                        />
                        <HyperlinkTextInput
                            permission={permissionsMap.transactions.view}
                            label={t.table.header.transactionId}
                            hyperlinkName={selectedItem?.tr_id?.toString() || null}
                            elementId={selectedItem?.tr_id || null}
                            navigateTo={routes.transactions.href}
                        />
                        <PreInput
                            label={t.notifications.placeholders.partner}
                            value={selectedItem?.partner_name}
                        />
                        <PreInput
                            label={t.notifications.placeholders.service}
                            value={selectedItem?.service_name}
                        />
                        <PreInput
                            label={t.notifications.placeholders.takenBy}
                            value={selectedItem?.taken_by}
                        />
                        <PreInput
                            label={t.notifications.placeholders.importance}
                            value={selectedItem?.alert.importance}
                        />
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow
                        label={t.notifications.placeholders.message}
                        value={selectedItem?.message}
                    />

                    <TextareaLabelInRow
                        label={t.notifications.placeholders.attributes}
                        value={selectedItem?.attributes}
                    />
                </DetailsSectionWrapper>
            </DetailsPageWrapper>
        </>

    );
};

export default observer(NotificationDetails);
