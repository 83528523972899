import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import partnerStore from "../../../../store/entities/partner/partnerStore";
import processingStore from "../../../../store/entities/processing/processingStore";
import serviceStore from "../../../../store/entities/service/serviceStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import { PermissionKeys } from "../../../../types/permissionsTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import ProtectedMenu from "../components/EntitiesMenu/ProtectedMenu";
import PartnersFilters from "../partners/PartnersFilters";
import ProcessingFilters from "../processing/ProcessingFilters";
import ServicesFilters from "../services/ServicesFilters";
import { TabsType } from "../../../../types/tabs";
import Link from "../../../ui/link/Link";
import Routes from "../../../../constants/routes";

type TabTitlesKeys = Extract<PermissionKeys, "partners" | "services" | "processings">;

export function useTabsActions(currentTabTitle: PermissionKeys, currentTab?: TabsType) {
    const { t } = useTranslation();

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const [isFiltersModalVisible, setFiltersModalVisibility] = useState(false);

    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);
    const toggleFiltersModal = () => setFiltersModalVisibility((prev) => !prev);

    const tabsPropertiesMap = {
        partners: {
            store: partnerStore,
            entityName: t.entities.partners.partOfLink,
            filters: <PartnersFilters closeModal={toggleFiltersModal} />,
        },
        services: {
            store: serviceStore,
            entityName: t.entities.services.partOfLink,
            filters: <ServicesFilters closeModal={toggleFiltersModal} />,
        },
        processings: {
            store: processingStore,
            entityName: t.entities.processing.partOfLink,
            filters: <ProcessingFilters closeModal={toggleFiltersModal} />,
        },
    };

    const { store, entityName, filters } = tabsPropertiesMap[currentTabTitle as TabTitlesKeys];

    const currentAddButtonTitle = currentTabTitle?.slice(0, currentTabTitle.length - 1);
    const filtersCount = objectToValuesList(store.filters || {}).length;

    const AddButtonComponent = (
        <Link to={`${Routes.entities.href}/${currentTabTitle}/new`} state={{ tab: currentTab }}>
            <ProtectedSmallButton
                variant="tertiary"
                iconLeft={<PlusIcon />}
                title={currentAddButtonTitle}
                permission={permissionsMap[currentTabTitle].add}
            />
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFiltersModal}
            iconLeft={<FilterIcon />}
            iconRight={
                !!filtersCount && <CircleIcon isActive count={filtersCount} />
            }
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuDropdownComponent = (
        <ProtectedMenu
            store={store}
            permission={permissionsMap[currentTabTitle].changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const ActionModalComponent = (
        <ActionModal
            store={store}
            entityName={entityName}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal isOpen={isFiltersModalVisible} setOn={toggleFiltersModal}>
            {filters}
        </FiltersModal>
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-button-component", content: MenuDropdownComponent },
    ];

    return {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    };
}
