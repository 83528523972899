import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import {
    alertDisplayAttributesOptionsHandbook,
    alertImportanceOptionsHandbook,
    alertTypeOptionsHandbook,
    getTranslatedHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import {
    MAX_FIELD_NAME_LENGTH, MAX_TRIGGER_FILED_LENGTH, MAX_DESCRIPTION_FILED_LENGTH,
} from "../../../../../constants/validationRules";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import alertsStore from "../../../../../store/alerts/alerts/alertsStore";
import filtersAlertsTgGroupsStore from "../../../../../store/filters/alerts/alertsTgGroupsFiltersStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { NewAlertTypes } from "../../../../../types/alertsTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    Container, GridItemThree, Row, ThreeColumnForm, Title,
} from "../../../../ui/new/NewComponents";
import Switch from "../../../../ui/switch/Switch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { LocationState } from "../../../../../types/locationTypes";
import { useNavigation } from "../../../../../hooks/useNavigation";

enum AlertTypeValue {
    AF = "af",
    TG = "tg",
}

const fieldsList: Array<keyof NewAlertTypes> = [
    "name", "display_attributes", "alert_type", "trigger_text",
    "tg_group", "importance", "partners", "services", "description",
];

const NewAlert = () => {
    const { t, translate } = useTranslation();

    const { navigateBackward } = useNavigation();
    const state: LocationState = useLocation()?.state;
    const tgGroupId = state?.id;

    const handleNavigateToPrevPage = () => navigateBackward();

    const alertTypesOptions = getTranslatedHandbook(translate, alertTypeOptionsHandbook);
    const alertImportanceOptions = getTranslatedHandbook(translate, alertImportanceOptionsHandbook);
    const alertDisplayAttributesOptions = getTranslatedHandbook(translate, alertDisplayAttributesOptionsHandbook);

    const initialValues: NewAlertTypes = {
        name: "",
        display_attributes: null,
        alert_type: tgGroupId ? AlertTypeValue.TG : null,
        trigger_text: "",
        tg_group: Number(tgGroupId) || null,
        importance: null,
        partners: null,
        services: null,
        processing: null,
        description: "",
        is_active: true,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),
        display_attributes: Yup.array().required(t.validation.required),
        alert_type: Yup.string().required(t.validation.required),
        trigger_text: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_TRIGGER_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_TRIGGER_FILED_LENGTH}`),
            ),
        importance: Yup.string().required(t.validation.required),
        tg_group: Yup.string()
            .when("alert_type", ([alert_type], schema) => ((
                alert_type === AlertTypeValue.TG
            )
                ? schema.required(t.validation.required)
                : schema.nullable())),

        partners: Yup.array().when(["services", "processing"], ([services, processing], schema) => (
            ((services && services.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        services: Yup.array().when(["partners", "processing"], ([partners, processing], schema) => (
            ((partners && partners.length) || (processing && processing.length))
                ? schema.nullable() : schema.required(t.validation.required))),

        processing: Yup.string().when(["partners", "services"], ([partners, services], schema) => (
            ((partners && partners.length) || (services && services.length))
                ? schema.nullable() : schema.required(t.validation.required))),
        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    }, [["partners", "services"], ["partners", "processing"], ["services", "processing"]]);

    const formik = useFormik<NewAlertTypes>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            const partners = fields.partners || [];
            const services = fields.services || [];

            const fieldsData = { ...fields, partners, services };

            await alertsStore.postData(fieldsData, fieldsList);

            if (alertsStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                alertsStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.alerts.snackbar.addSuccess });
            } else if (alertsStore.postErrors?.common) {
                snackbarStore.add({ variant: "error", text: t.snackbar.addError });
            }
        },
    });

    const isTgAlertType = formik.values.alert_type === AlertTypeValue.TG;

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const toggleAlertStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const clearTgGroupFieldValue = () => {
        if (!isTgAlertType && formik.values.tg_group) formik.setFieldValue("tg_group", null);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, alertsStore.isPostRequestSuccess);

    useEffect(() => {
        filtersPartnersStore.fetch();
        filtersServicesStore.fetch();
        filtersAlertsTgGroupsStore.fetch();
        filtersProcessingStore.fetch();

        return () => alertsStore.resetPostErrors();
    }, []);

    const isPartnersFieldRequired = !formik.values.services || !formik.values.processing;
    const isServicesFieldRequired = !formik.values.partners || !formik.values.processing;
    const isProcessingFieldRequired = !formik.values.services || !formik.values.partners;

    const isPartnersFieldDisabled = alertsStore.loading || !!formik.values.services || !!formik.values.processing;
    const isServicesFieldDisabled = alertsStore.loading || !!formik.values.partners || !!formik.values.processing;
    const isProcessingFieldDisabled = alertsStore.loading || !!formik.values.partners || !!formik.values.services;

    return (
        <>
            <SecondHeader title={t.alerts.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.alerts.text.title}</Title>
                    <Row>
                        <Title>{t.alerts.text.status}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            disabled={alertsStore.loading}
                            checked={formik.values.is_active}
                            onChange={toggleAlertStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="name"
                        isRequired
                        placeholder={t.alerts.placeholders.name}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="display_attributes"
                        options={alertDisplayAttributesOptions}
                        isRequired
                        mode={FieldMode.Multiple}
                        placeholder={t.alerts.placeholders.displayAttributes}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="alert_type"
                        isRequired
                        disabled={!!tgGroupId || alertsStore.loading}
                        options={alertTypesOptions}
                        placeholder={t.alerts.placeholders.alertType}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                        onChange={clearTgGroupFieldValue}
                    />
                    {isTgAlertType && (
                        <FloatSelect
                            formik={formik}
                            name="tg_group"
                            isRequired
                            disabled={!!tgGroupId || alertsStore.loading}
                            options={filtersAlertsTgGroupsStore.options}
                            placeholder={t.alerts.placeholders.tgGroup}
                            serverErrors={alertsStore.postErrors}
                            resetServerError={alertsStore.resetPostError}
                        />
                    )}
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="trigger_text"
                        isRequired
                        placeholder={t.alerts.placeholders.triggerText}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="importance"
                        options={alertImportanceOptions}
                        isRequired
                        placeholder={t.alerts.placeholders.importance}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="partners"
                        mode={FieldMode.Multiple}
                        isRequired={isPartnersFieldRequired}
                        disabled={isPartnersFieldDisabled}
                        options={filtersPartnersStore.options}
                        placeholder={t.alerts.placeholders.partner}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="services"
                        mode={FieldMode.Multiple}
                        isRequired={isServicesFieldRequired}
                        disabled={isServicesFieldDisabled}
                        options={filtersServicesStore.options}
                        placeholder={t.alerts.placeholders.service}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="processing"
                        isRequired={isProcessingFieldRequired}
                        disabled={isProcessingFieldDisabled}
                        options={filtersProcessingStore.options}
                        placeholder={t.alerts.placeholders.processing}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />
                    <FloatInput
                        formik={formik}
                        disabled={alertsStore.loading}
                        name="description"
                        placeholder={t.alerts.placeholders.message}
                        serverErrors={alertsStore.postErrors}
                        resetServerError={alertsStore.resetPostError}
                    />

                    {isTgAlertType ? (
                        <GridItemThree>
                            <Button
                                type="submit"
                                variant="primary"
                                isLoading={alertsStore.loading}
                                disabled={alertsStore.loading}
                            >
                                {t.buttons.add}
                            </Button>
                        </GridItemThree>
                    ) : (
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={alertsStore.loading}
                            disabled={alertsStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    )}
                </ThreeColumnForm>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewAlert);
