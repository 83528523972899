import { flow } from "mobx";

import { getServices } from "../../api/services/filters/getServices";
import { ServicesTypes } from "../../types/servicesTypes";
import FiltersDataStore from "../common/filtersStore";

class FiltersServicesStore extends FiltersDataStore<ServicesTypes> {
    protected fetchListMethod = getServices;

    protected fetchDataByIdMethod = getServices;

    fetchById = flow(function* fetchData(this: FiltersServicesStore, id: number, isLinked?: boolean) {
        yield this.fetchDataById({ rules_pack: id, is_linked: isLinked });
    });

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersServicesStore = new FiltersServicesStore();

export default filtersServicesStore;
