import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useTranslation from "../../../../../hooks/useTranslation";
import limitStore from "../../../../../store/limit/limitStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import BlockModal from "../../../../common/modal/warning/blockModal/BlockModal";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import { InfoIcon } from "../../../../ui/button/ButtonComponents";
import SmallButton from "../../../../ui/button/SmallButton";
import CodeEditor from "../../../../ui/codeEditor/CodeEditor";
import Modal from "../../../../ui/modal/Modal";
import { Icon, Row, Title } from "../../../../ui/new/NewComponents";
import { CodeContainer, CodeTitleContainer } from "../../new/NewLimitComponents";
import CommandsModal from "../commandsModal/CommandsModal";
import { useNavigation } from "../../../../../hooks/useNavigation";

const fieldsList = ["code"];

const LimitDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { selectedItem } = limitStore;
    const { navigateBackward } = useNavigation();

    const handleNavigateToPrevPage = () => navigateBackward();

    const [isCommandsModalVisible, setCommandsModalVisibility] = useState(false);
    const toggleCommandsModal = () => setCommandsModalVisibility(!isCommandsModalVisible);

    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);
    const toggleWarningModal = () => setWarningModalVisibility(!isWarningModalVisible);

    const formik = useFormik<{ code?: string }>({
        initialValues: {},
        onSubmit: async (values) => {
            if (id) {
                await limitStore.patchData(values, id, fieldsList);

                if (limitStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.limits.snackbar.addCodeSuccess });
                    handleNavigateToPrevPage();
                } else {
                    snackbarStore.add({ variant: "error", text: t.limits.snackbar.addCodeError });
                }
            }
        },
    });

    const handleSubmit = () => formik.handleSubmit();

    const BackButtonComponent = (
        <SmallButton variant="secondary" onClick={toggleWarningModal}>
            {t.buttons.cancel}
        </SmallButton>
    );

    const SaveButtonComponent = (
        <SmallButton variant="primary" onClick={handleSubmit}>
            {t.buttons.save}
        </SmallButton>
    );

    const actionHeaderContent = [
        { key: "cancel-button", content: BackButtonComponent },
        { key: "save-button", content: SaveButtonComponent },
    ];

    useEffect(() => {
        if (id) limitStore.fetchSelectedItem(id);

        return () => limitStore.resetEditError("code");
    }, []);

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem]);

    return (
        <>
            <SecondHeader title={t.limits.text.editCode} actionsContent={actionHeaderContent} />
            <CodeContainer>
                <Row $spacing>
                    <CodeTitleContainer>
                        <Title>{t.limits.text.code}</Title>
                    </CodeTitleContainer>

                    <CodeTitleContainer>
                        <SmallButton
                            variant="tertiary"
                            type="button"
                            onClick={toggleCommandsModal}
                            iconRight={<InfoIcon />}
                        >
                            {t.limits.text.commands}
                        </SmallButton>
                    </CodeTitleContainer>
                </Row>

                <CodeEditor
                    name="code"
                    formik={formik}
                    serverErrors={limitStore.editItemErrors}
                    resetServerError={limitStore.resetEditError}
                />
            </CodeContainer>

            <Modal
                width="auto"
                isOpen={isCommandsModalVisible}
                setOn={setCommandsModalVisibility}
                submitButton={false}
                gap="0"
            >
                <CommandsModal />
            </Modal>

            <BlockModal
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={handleNavigateToPrevPage}
            />
        </>
    );
};

export default observer(LimitDetails);
