import Switch from "../switch/Switch";
import { FormikTypes } from "../../../types/formikTypes";
import { PopoverItem } from "./CustomPopoverComponents";

interface CustomPopoverItemProps {
    title: string
    formik: FormikTypes;
    name?: string
    display?: boolean
    disabled?: boolean
}

const CustomPopoverItem = ({
    title, name, formik, display, disabled,
}: CustomPopoverItemProps) => {
    if (!display) {
        return null;
    }

    return (
        <PopoverItem $disabled={disabled}>
            <span>{title}</span>
            <Switch formik={formik} name={name} disabled={disabled} />
        </PopoverItem>
    );
};

export default CustomPopoverItem;
