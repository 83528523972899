import StatusBadge from "../../textField/StatusBadge";

interface DataType {
    channel_check?: boolean;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const TableFieldsTypeBadge = <D extends DataType>({ dataItem }: PropsType<D>) => (
    dataItem.channel_check
        ? (
            <StatusBadge
                variant="primary"
                multiLanguageText="table.header.channel"
            />
        ) : null
);

export default TableFieldsTypeBadge;
