import {
    ChangeEvent, InputHTMLAttributes, useEffect, useState,
} from "react";

import { SwitchCheckbox, SwitchContainer, SwitchSlider } from "./SwitchComponent";
import { FormikTypes } from "../../../types/formikTypes";

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    variant?: "success" | "danger"
    formik?: FormikTypes;
}

const Switch = ({
    checked = false, onChange, name, formik, ...props
}: SwitchProps) => {
    const defaultValue = name ? !!formik?.values[name] : checked;
    const [isChecked, setIsChecked] = useState(defaultValue);

    const toggleSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if (name) {
            formik?.setFieldValue(name, event.target.checked);
            formik?.setFieldTouched(name, false);
        }
        onChange?.(event);
    };

    useEffect(() => {
        setIsChecked(defaultValue);
    }, [defaultValue]);

    return (
        <SwitchContainer>
            <SwitchCheckbox
                {...props}
                name={name}
                type="checkbox"
                checked={isChecked}
                onChange={toggleSwitch}
            />
            <SwitchSlider />
        </SwitchContainer>
    );
};

export default Switch;
