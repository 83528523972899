import request from "../../request";
import { ListsTypes } from "../../../types/listsTypes";

interface GetListsResponse {
    message: string,
    response: {
        count: number
        results: ListsTypes[];
    }
}

export const getLists = () => request<GetListsResponse>({
    method: "GET",
    uri: "entities/filters/lists/",
});
