import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import partnerStore from "../../../../../store/entities/partner/partnerStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditPartnerModal from "./EditPartnerModal";
import ProtectedPartnerDetailsTable from "./components/ProtectedPartnerDetailsTable/ProtectedPartnerDetailsTable";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

const DETAILS_TABLE_PERMISSIONS_LIST = [
    permissionsMap.services.view,
    permissionsMap.rules_packs.view,
];

const PartnerDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = partnerStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTabTitle = id ? `${t.entities.partners.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.entities.title, t.entities.partners.title, currentTabTitle);

    const preInputsList = [
        { label: t.table.header.partnerName, value: selectedItem?.name || "", key: "partner-details-name" },
        { label: t.table.header.partnerId, value: selectedItem?.id.toString() || "", key: "partner-details-id" },
        {
            key: "partner-details-country",
            label: t.table.header.country,
            value: selectedItem?.country?.toString() || "",
        },
        { label: t.table.header.address, value: selectedItem?.address || "", key: "partner-details-address" },
        { label: t.table.header.email, value: selectedItem?.email || "", key: "partner-details-email" },
        { label: t.table.header.phone, value: selectedItem?.phone || "", key: "partner-details-phone" },
        {
            key: "partner-details-timezone",
            label: t.table.header.timezone,
            value: selectedItem?.timezone?.toString() || "",
        },
        {
            key: "partner-details-account-manager",
            label: t.table.header.accountManager,
            value: selectedItem?.account_manager || "",
        },
        {
            key: "partner-details-processing-id",
            label: t.table.header.processingId,
            value: selectedItem?.processing?.toString() || "",
        },
        {
            key: "partner-details-processing-name",
            label: t.table.header.processingName,
            value: selectedItem?.processing_name || "",
        },
    ];

    const handleNavigate = () => navigateBackward({ tab: Tabs.PARTNERS });

    const actionHeaderContentList = [
        {
            key: "partner-details-back-button",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) {
            partnerStore.fetchSelectedItem(id);
        }
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.entities.partners.title} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.partners.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsList.map((input) => <PreInput {...input} />)}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow
                        label={t.table.header.description}
                        value={selectedItem?.description || ""}
                    />
                </DetailsSectionWrapper>

                <ProtectedPartnerDetailsTable permissions={DETAILS_TABLE_PERMISSIONS_LIST} />

                <EditPartnerModal
                    isOpen={isEditModalVisible}
                    buttonText={t.buttons.save}
                    title={t.entities.partners.edit.title}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(PartnerDetails);
