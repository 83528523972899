import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const FormWrapper = styled.form`
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 420px;
`;

export const LogInFooter = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 20px;

  color: ${colors.textDefault};

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
`;
