import request from "../request";
import { ServicesFiltersTypes, ServicesTypes } from "../../types/servicesTypes";

type GetServicesRequest = ServicesFiltersTypes & {
    limit?: number,
    offset?: number,
    ordering?: string,
}

export interface GetServicesResponse {
    message: string,
    response: {
        count: number,
        results: ServicesTypes[],
    }
}

export const getServices = (params?: GetServicesRequest) => request<GetServicesResponse>({
    method: "GET",
    uri: "entities/services/",
    params,
});
