import { FC } from "react";
import CircleIcon from "../../../resources/icons/circleIcon/CustomCircleIcon";

interface TransactionCountProps {
    count: string | null
}

const TransactionsCount: FC<TransactionCountProps> = ({ count }) => {
    if (!count) return null;

    return (
        <CircleIcon isActive={false} count={count} />
    );
};

export default TransactionsCount;
