import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const CustomCircleIcon = styled.label<{
    $isActive?: boolean,
    $isCircle?: boolean,
}>`
    display: flex;
    min-height: max-content;
    min-width: max-content;
    padding: 3px 5px;
    line-height: 105%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    color: ${(props) => (props.$isActive ? colors.textPrimary : colors.white)};
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;

    border-radius: 8px;
    background: ${(props) => (props.$isActive ? colors.white : colors.buttonPrimary)};

    ${(props) => {
        if (props.$isCircle) {
            return `
                  border-radius: 50%;
                  min-height: 16px;
                  min-width: 16px;
                  padding: 0;
                `;
        }
        return "";
    }}

`;
