import { observer } from "mobx-react-lite";
import { memo, ReactNode, useEffect } from "react";

import routes from "../../../../../constants/routes";
import withPermission from "../../../../../hocs/withPermissions";
import useTranslation from "../../../../../hooks/useTranslation";
import tgGroupsAlertsStore from "../../../../../store/alerts/tgGroupsAlertsStore/tgGroupsAlertsStore";
import { AlertsTypes } from "../../../../../types/alertsTypes";
import TabHeader from "../../../../tabHeader/TabHeader";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import TableFieldsImportanceStatus from "../../components/TableFieldImportanceStatus/TableFieldsImportanceStatus";

interface WrappedComponentProps {
    tgGroupId?: string;
    actionsContent?: { content: ReactNode, key: string }[];
}

type ProtectedTgGroupsDetailsAlertsTableProps = WrappedComponentProps & {
    permission: string;
}

const columns: ColumnType<AlertsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.name",
        field: "name",
        isResizable: true,
        width: "200px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.alert.href}/${dataItem.id}`}>
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.alertId", field: "id" },
    {
        header: "table.header.importance",
        field: "importance",
        getField: (dataItem) => (<TableFieldsImportanceStatus status={dataItem.importance} />),
    },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.triggerText", field: "trigger_text" },
    {
        header: "table.header.partnerId",
        field: "partners",
        getField: (dataItem) => dataItem.partners.map((partner) => partner.id).join(", "),
    },
    {
        header: "table.header.serviceId",
        field: "services",
        getField: (dataItem) => dataItem.services.map((service) => service.id).join(", "),
    },
    { header: "table.header.isActive", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (<TableFieldsDetailsLink dataItem={dataItem} href={routes.alert.href} />),
    },
];

const WrappedComponent = observer(({ tgGroupId, actionsContent }: WrappedComponentProps) => {
    const { t } = useTranslation();

    const totalAlertsCount = tgGroupsAlertsStore.pagination.count;
    const activeAlertsCount = tgGroupsAlertsStore.pagination.totalActiveCount;

    const tablePagination = {
        ...tgGroupsAlertsStore.pagination,
        setPaginationPage: tgGroupsAlertsStore.setPaginationPage,
        setPaginationSize: tgGroupsAlertsStore.setPaginationSize,
    };

    useEffect(() => {
        if (tgGroupId) tgGroupsAlertsStore.fetchData(tgGroupId);
    }, [tgGroupsAlertsStore.pagination, tgGroupsAlertsStore.filters, tgGroupsAlertsStore.ordering]);

    return (
        <>
            <TabHeader
                title={t.tgGroups.edit.tabTitle}
                count={{ from: activeAlertsCount, to: totalAlertsCount }}
                actionsContent={actionsContent}
            />

            <Table
                columns={columns}
                data={tgGroupsAlertsStore.data}
                pagination={tablePagination}
                checkedItems={tgGroupsAlertsStore.checkedItems}
                sortMode={tgGroupsAlertsStore.ordering}
                loading={tgGroupsAlertsStore.loading}
                onSort={tgGroupsAlertsStore.toggleSort}
                onCheckboxChange={tgGroupsAlertsStore.toggleItem}
                onMainCheckboxChange={tgGroupsAlertsStore.toggleMainItem}
            />
        </>
    );
});

const ProtectedTgGroupsDetailsAlertsTable = ({ permission, ...props }: ProtectedTgGroupsDetailsAlertsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default memo(ProtectedTgGroupsDetailsAlertsTable);
