import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import TFALink from "../../../../../authorizationLayout/TFA/TFALink/TFALink";

const ActivateTFAModal = ({
    isOpen, setOn,
}: ModalProps) => (
    <Modal
        isOpen={isOpen}
        setOn={setOn}
        submitButton={false}
        gap="0"
    >
        <TFALink isModalStyle closeModal={() => setOn(false)} />
    </Modal>
);

export default ActivateTFAModal;
