import request from "../../request";

type DataType = {
    new_password: string,
    new_password_conf: string,
}

type ResponseType = {
    message: string;
    response: {
        access: string;
        refresh: string;
        detail: string;
    };
}

export const postPasswordReset = (data: DataType, token: string) => request<ResponseType>({
    useAuthorization: false,
    token,
    method: "POST",
    uri: "auth/password/reset/confirm/",
    data,
});
