import request from "../request";
import { EmployeesFiltersTypes, EmployeesTypes } from "../../types/employeesTypes";

type GetEmployeesRequest = EmployeesFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetEmployeesResponse {
    message: string,
    response: {
        count: number
        results: EmployeesTypes[];
    }
}

export const getEmployees = (params?: GetEmployeesRequest) => request<GetEmployeesResponse>({
    method: "GET",
    uri: "users/",
    params,
});
