import request from "../request";
import { LimitsTypes, NewLimitType } from "../../types/limitsTypes";

interface ResponseType {
    message: string,
    response: LimitsTypes,
}

export const postLimits = (data: NewLimitType) => request<ResponseType>({
    method: "POST",
    uri: "limits/",
    data,
});
