import { NotificationsTypes } from "../../types/notificationsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: NotificationsTypes,
}

export const getSelectedNotification = (notificationId: string) => request<ResponseType>({
    method: "GET",
    uri: `notifications/${notificationId}/`,
});
