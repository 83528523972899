import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
    useParams,
} from "react-router-dom";

import { permissionsMap } from "../../../../constants/permissions";
import { generateHeaderName } from "../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../hooks/useTranslation";
import limitStore from "../../../../store/limit/limitStore";
import SecondHeader from "../../../secondHeader/SecondHeader";
import { InfoIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import CodeEditor from "../../../ui/codeEditor/CodeEditor";
import {
    DetailsBodyWrapper, DetailsHeaderWithButton, DetailsHeaderWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../ui/details/DetailsComponents";
import Modal from "../../../ui/modal/Modal";
import { Row, Title } from "../../../ui/new/NewComponents";
import StatusBadge from "../../../ui/textField/StatusBadge";
import PreInput from "../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../ui/textInput/TextareaLabelInRow";
import { DetailPageHeader } from "../../../ui/TextStyle";
import CommandsModal from "../components/commandsModal/CommandsModal";
import { CodeContainer, CodeTitleContainer } from "../new/NewLimitComponents";
import EditLimitModal from "./EditLimitModal";
import { useNavigation } from "../../../../hooks/useNavigation";
import Link from "../../../ui/link/Link";
import Routes from "../../../../constants/routes";

const LimitDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();

    const { selectedItem } = limitStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const [isCommandsModalVisible, setCommandsModalVisibility] = useState(false);

    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);
    const toggleCommandsModal = () => setCommandsModalVisibility((prev) => !prev);

    const currentTitle = id ? `${t.limits.text.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.limits.title, currentTitle);

    const preInputParamsList = [
        {
            key: "limit-details-name",
            label: t.table.header.limitName,
            value: selectedItem?.name,
        },
        {
            key: "limit-details-id",
            label: t.table.header.limitId,
            value: selectedItem?.id.toString(),
        },
        {
            key: "limit-details-order",
            label: t.table.header.limitOrder,
            value: selectedItem?.order.toString(),
        },
        {
            key: "limit-details-rules-pack-name",
            label: t.limits.placeholders.rulesPackName,
            value: selectedItem?.rules_pack_name,
        },
        {
            key: "limit-details-rules-pack-id",
            label: t.table.header.rulesPackId,
            value: selectedItem?.rules_pack?.toString(),
        },
    ];

    const handleNavigate = () => navigateBackward();

    const actionHeaderContentList = [
        {
            key: "limit-details-back-button",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) limitStore.fetchSelectedItem(id);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeaderWrapper>
                        <DetailsHeaderWithButton>
                            <DetailPageHeader>
                                {`${t.limits.text.partOfLink} ${t.details.title}`}
                            </DetailPageHeader>

                            <ProtectedSmallButton
                                title={t.buttons.edit}
                                variant="secondary"
                                permission={permissionsMap.limits.change}
                                disabled={false}
                                onClick={toggleEditModal}
                            />
                        </DetailsHeaderWithButton>

                        <DetailsHeaderWithButton>
                            {selectedItem?.channel_check
                                && (
                                    <>
                                        <DetailPageHeader>{t.details.type}</DetailPageHeader>
                                        <StatusBadge variant="primary" multiLanguageText={t.table.header.channel} />
                                    </>
                                )}
                            &nbsp;
                            <DetailPageHeader>{t.details.status}</DetailPageHeader>

                            <StatusBadge
                                variant={selectedItem?.is_active ? "success" : "inactive"}
                                multiLanguageText={selectedItem?.is_active ? "status.active" : "status.inactive"}
                            />
                        </DetailsHeaderWithButton>
                    </DetailsHeaderWrapper>

                    <DetailsBodyWrapper>
                        {preInputParamsList.map((input) => <PreInput {...input} />)}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.table.header.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>
            </DetailsPageWrapper>

            <CodeContainer>
                <Row $spacing>
                    <CodeTitleContainer>
                        <Title>{t.limits.text.code}</Title>

                        <Link to={`${Routes.limits.href}/code/${id}`}>
                            <ProtectedSmallButton
                                title={t.details.buttons.edit}
                                variant="secondary"
                                permission={permissionsMap.limits.change}
                            />
                        </Link>
                    </CodeTitleContainer>

                    <CodeTitleContainer>
                        <SmallButton
                            variant="tertiary"
                            type="button"
                            iconRight={<InfoIcon />}
                            onClick={toggleCommandsModal}
                        >
                            {t.limits.text.commands}
                        </SmallButton>
                    </CodeTitleContainer>
                </Row>

                <CodeEditor disabled value={selectedItem?.code} />
            </CodeContainer>

            <EditLimitModal
                isOpen={isEditModalVisible}
                setOn={toggleEditModal}
                title={t.limits.edit.title}
                buttonText={t.buttons.save}
            />

            <Modal
                width="auto"
                isOpen={isCommandsModalVisible}
                setOn={toggleCommandsModal}
                submitButton={false}
                gap="0"
            >
                <CommandsModal />
            </Modal>
        </>

    );
};

export default observer(LimitDetails);
