import { FC, useState } from "react";
import Button from "../../../../../ui/button/Button";
import { Row } from "../../../../../ui/details/DetailsComponents";
import useTranslation from "../../../../../../hooks/useTranslation";
import Modal from "../../../../../ui/modal/Modal";
import { Box, TransactionDetailsStatusBadge } from "../sections/SectionsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import { ListType, TransactionTypes } from "../../../../../../types/transactionTypes";
import AddToListModalBody from "./AddToListModalBody";

interface TransactionOperationListedButtonsProps {
    label: string,
    listType?: ListType,
    transaction: TransactionTypes | null,
}

const TransactionListedOperationButtons: FC<TransactionOperationListedButtonsProps> = ({
    label, listType, transaction,
}) => {
    const { t, translate } = useTranslation();

    const isInfoButtonShown = false;

    const [isRemoveModal, setRemoveModal] = useState(false);
    const handleToggleRemoveModal = () => setRemoveModal(!isRemoveModal);

    const [isConfirmModal, setConfirmModal] = useState(false);
    const handleToggleConfirmModal = () => setConfirmModal(!isConfirmModal);

    const handleRemoveElement = () => {
        handleToggleConfirmModal();
        handleToggleRemoveModal();
    };

    const title = translate(`transactions.buttons.lists.remove.${listType}`);
    const badgeTitle = translate(`transactions.badge.${listType}`);
    const modalBodyText = (
        <AddToListModalBody
            action="remove"
            type={listType || "blacklisted"}
            label={label}
            translate={translate}
        />
    );

    return (
        <>
            <TransactionDetailsStatusBadge $variant={listType || "inactive"}>
                {badgeTitle}
            </TransactionDetailsStatusBadge>

            {isInfoButtonShown
                && (
                    <Button
                        variant="secondary"
                        onClick={handleToggleRemoveModal}
                    >
                        {t.transactions.buttons.info}
                    </Button>
                )}

            <Modal
                submitButton={false}
                isOpen={isRemoveModal}
                setOn={handleToggleRemoveModal}
                title={t.transactions.modal.removeModalTitle}
                handleSubmit={handleToggleConfirmModal}
            >
                <Box>
                    <PreInput
                        label={t.table.header.phone}
                        value={transaction?.phone}
                    />
                    <PreInput
                        label={t.transactions.placeholders.city}
                        value={transaction?.city}
                    />
                    <PreInput
                        label={t.transactions.placeholders.zip}
                        value={transaction?.zip}
                    />
                    <PreInput
                        label={t.transactions.placeholders.state}
                        value={transaction?.state}
                    />
                    <PreInput
                        label={t.table.header.country}
                        value={transaction?.country}
                    />
                </Box>
                <Button
                    variant="primary"
                    type="button"
                    onClick={handleToggleConfirmModal}
                >
                    {title}
                </Button>
            </Modal>

            <Modal
                width="520px"
                submitButton={false}
                title={title}
                modalBodyText={modalBodyText}
                isOpen={isConfirmModal}
                setOn={handleToggleConfirmModal}
            >
                <Row>
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={handleToggleConfirmModal}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={handleRemoveElement}
                    >
                        {t.buttons.remove}
                    </Button>
                </Row>
            </Modal>
        </>
    );
};

export default TransactionListedOperationButtons;
