import request from "../request";

type DataType = {
    refresh: string,
}

type ResponseType = {
    message: string,
    response: {
        access: string;
    }
}

export const postRefreshToken = (data: DataType) => request<ResponseType>({
    useAuthorization: false,
    method: "POST",
    uri: "auth/token/refresh/",
    data,
});
