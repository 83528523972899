import request from "../../request";
import { ProcessingTypes } from "../../../types/processingTypes";

interface GetProcessingResponse {
    message: string,
    response: {
        count: number,
        results: ProcessingTypes[],
    }
}

export const getProcessing = () => request<GetProcessingResponse>({
    method: "GET",
    uri: "entities/filters/processing/",
});
