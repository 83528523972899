import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import routes from "../../../../../constants/routes";
import { Tabs } from "../../../../../constants/tabs";
import rulesPackListsStore from "../../../../../store/rulesPack/rulesPackListsStore";
import { ListsTypes } from "../../../../../types/listsTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsGroupBadge from "../../../../ui/tableFields/groupBadge/TableFieldsGroupBadge";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";

interface RulesPacksListsTableProps {
    isCheckboxVisible?: boolean;
    ruleId?: string;
}

const columns: ColumnType<ListsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.listName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${routes.lists.href}/${dataItem.id}`}
                state={{ previousTab: Tabs.LISTS }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.listId", field: "id", sortable: true },
    {
        header: "table.header.category",
        field: "category",
        sortable: true,
    },
    {
        header: "table.header.type",
        field: "type",
        sortable: true,
    },
    {
        header: "table.header.elements",
        field: "elements",
        sortable: true,
    },
    {
        header: "table.header.createdAt",
        field: "created",
        sortable: true,
    },
    {
        header: "table.header.lastUpdate",
        field: "last_update",
        sortable: true,
    },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.isGlobal",
        field: "is_global",
        sortable: true,
        Component: TableFieldsGroupBadge,
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={routes.lists.href}
                state={{ previousTab: Tabs.LISTS }}
            />
        ),
    },
];

const RulesPacksListsTable = ({ ruleId, isCheckboxVisible }: RulesPacksListsTableProps) => {
    const { setPaginationPage, setPaginationSize } = rulesPackListsStore;

    const tablePagination = {
        ...rulesPackListsStore.pagination,
        setPaginationPage,
        setPaginationSize,
    };

    useEffect(() => {
        if (ruleId) rulesPackListsStore.fetchData(ruleId);
    }, [rulesPackListsStore.pagination, rulesPackListsStore.ordering]);

    return (
        <Table
            columns={columns}
            data={rulesPackListsStore.data}
            pagination={tablePagination}
            checkedItems={rulesPackListsStore.checkedItems}
            sortMode={rulesPackListsStore.ordering}
            loading={rulesPackListsStore.loading}
            onSort={rulesPackListsStore.toggleSort}
            onCheckboxChange={rulesPackListsStore.toggleItem}
            onMainCheckboxChange={rulesPackListsStore.toggleMainItem}
            isItemSelectionAllowed={isCheckboxVisible}
        />
    );
};

export default observer(RulesPacksListsTable);
