import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../../constants/permissions";
import { RegExpressions } from "../../../../../constants/regex";
import {
    MAX_DESCRIPTION_FILED_LENGTH, MAX_EMAIL_LENGTH, MAX_FIELD_NAME_LENGTH, MAX_PHONE_LENGTH, MAX_SITE_URL_LENGTH,
} from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import serviceStore from "../../../../../store/entities/service/serviceStore";
import filtersCountriesStore from "../../../../../store/filters/countriesFiltersStore";
import partnersFilterStore from "../../../../../store/filters/partnersFilterStore";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { EditServiceType } from "../../../../../types/servicesTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import { Title } from "../../partners/new/NewPartnersComponents";

const fieldsList: Array<keyof EditServiceType> = ["name", "is_active", "address", "phone", "email", "country",
    "description", "account_manager", "timezone", "partner", "service_id"];

const EditServiceModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = serviceStore;

    const countries = filtersCountriesStore.options;
    const timezones = filtersTimezonesStore.options;
    const partners = partnersFilterStore.options;

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        email: Yup.string().email(t.validation.invalidEmail).nullable()
            .max(MAX_EMAIL_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`)),
        phone: Yup.string().matches(RegExpressions.phoneWithSpaces, t.validation.phone).nullable()
            .max(MAX_PHONE_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_PHONE_LENGTH}`)),
        account_manager: Yup.string().email(t.validation.invalidEmail).nullable()
            .max(254, translate(`validation.inputFieldMaxLengthError.${254}`)),
        country: Yup.string().required(t.validation.required),
        timezone: Yup.string().required(t.validation.required),
        partner: Yup.string().required(t.validation.required),
        service_id: Yup.string().required(t.validation.required),
        site_url: Yup.string()
            .max(MAX_SITE_URL_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_SITE_URL_LENGTH}`))
            .nullable().url(t.validation.url),
        address: Yup.string().nullable()
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
    });

    const formik = useFormik<EditServiceType>({
        initialValues: {},
        validationSchema,
        onSubmit: async (values) => {
            if (id) {
                const trimmedData = { ...values, phone: values.phone?.trim() };

                await serviceStore.patchData(trimmedData, id, fieldsList);

                if (serviceStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    setOn(false);
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            }
        },
    });

    const handleModalClose = () => {
        serviceStore.unsetEditState();
        formik.resetForm();
    };

    const toggleServiceStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        if (isOpen) {
            if (!countries?.length) filtersCountriesStore.fetch();
            if (!timezones?.length) filtersTimezonesStore.fetch();
            if (!partners?.length) partnersFilterStore.fetch();
        }

        return () => handleModalClose();
    }, [isOpen]);

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={buttonText}
            handleSubmit={formik.handleSubmit}
            handleClose={handleModalClose}
            isLoading={serviceStore.loading}
            isForm
        >
            <EditSectionWrapper>
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="name"
                    isRequired
                    placeholder={t.table.header.serviceName}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="service_id"
                    isRequired
                    placeholder={t.table.header.processingServiceId}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="partner"
                    options={partners}
                    isRequired
                    placeholder={t.table.header.partnerIdName}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="email"
                    placeholder={t.table.header.email}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="phone"
                    placeholder={t.table.header.phone}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="account_manager"
                    placeholder={t.table.header.accountManager}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="country"
                    disabled={serviceStore.loading}
                    options={countries}
                    isRequired
                    placeholder={t.table.header.country}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="address"
                    placeholder={t.table.header.address}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="timezone"
                    disabled={serviceStore.loading}
                    options={timezones}
                    isRequired
                    placeholder={t.table.header.timezone}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="site_url"
                    placeholder={t.table.header.siteUrl}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={serviceStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.table.header.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        checked={formik.values.is_active}
                        disabled={serviceStore.loading}
                        permission={permissionsMap.services.changeStatus}
                        onChange={toggleServiceStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditServiceModal);
