import { NewRulePackPartnerType, RulesPacksPartnersTypes } from "../../types/rulesPacksPartnersTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: RulesPacksPartnersTypes,
}

const postRulesPacksPartners = (data: NewRulePackPartnerType) => request<ResponseType>({
    method: "POST",
    uri: "rules-packs/partners/",
    data,
});

export default postRulesPacksPartners;
