import { observer } from "mobx-react-lite";

import withPermission from "../../../../../hocs/withPermissions";
import { PermissionsTablePropsType } from "../../../../../types/permissionsType";
import PermissionsTable from "../new/Table/PermissionsTableEditable";
import PermissionsTableReadonly from "../new/Table/PermissionsTableReadonly";
import { Row } from "../new/Table/TableComponents";

interface WrappedComponentProps {
    isEditable: boolean;
}

type ProtectedPermissionTableProps = WrappedComponentProps & {
    permission: string;
}

const WrappedComponent = observer(({ isEditable }: WrappedComponentProps) => {
    const permissionTablePropsList: PermissionsTablePropsType[] = [
        { variant: "add", key: "permission-table-add" },
        { variant: "delete", key: "permission-table-delete" },
    ];

    return (
        <Row>
            {permissionTablePropsList.map(({ key, variant }) => (
                <PermissionsTable key={key} variant={variant} isEditable={isEditable} />
            ))}
        </Row>
    );
});

const ProtectedPermissionTable = ({ permission, ...props }: ProtectedPermissionTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission, false, PermissionsTableReadonly);
    return <ComponentWithPermission {...props} />;
};

export default ProtectedPermissionTable;
