import React from "react";
import StatusBadge from "../../textField/StatusBadge";

interface DataType {
    is_global?: boolean | null | number;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const TableFieldsGroupBadge = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <StatusBadge
        variant={dataItem.is_global ? "primary" : "inactive"}
        multiLanguageText={(dataItem.is_global) ? "group.global" : "group.local"}
    />
);

export default TableFieldsGroupBadge;
