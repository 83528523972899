import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { LoaderVariantType } from "../../../types/loaderTypes";

export const BaseLoaderCircle = styled.circle`
    stroke-width: 25;
    fill: none;
    stroke-linecap: round;
`;

export const MainLoaderCircle = styled(BaseLoaderCircle)<{ $variant: LoaderVariantType }>`
    stroke-dasharray: 800 800;
    
    ${({ $variant }) => {
        switch ($variant) {
            case "primary":
                return `
                stroke: ${colors.white};
        `;
            case "secondary":
                return `
            stroke: ${colors.buttonPrimary};
        `;
            case "error":
                return `
                stroke: ${colors.white};
        `;
            case "tertiary":
                return `
                stroke: ${colors.buttonPrimary};
        `;
            default: return "";
        }
    }}
`;

export const SecondaryLoaderCircle = styled(BaseLoaderCircle)<{$variant: LoaderVariantType}>`
    stroke-dasharray: 200 800;

    ${({ $variant }) => {
        switch ($variant) {
            case "primary":
                return `
                stroke: ${colors.buttonSecondaryHover};
        `;
            case "secondary":
                return `
                stroke: ${colors.white};
        `;
            case "error":
                return `
                stroke: ${colors.buttonSecondaryHover};
        `;
            case "tertiary":
                return `
                stroke: ${colors.white};
        `;
            default: return "";
        }
    }}
`;

export const GlobalLoaderWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 1000;
`;
