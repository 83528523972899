import React, { FC } from "react";
import { SelectProps as AntDSelectProps } from "antd";
import { DefaultOptionType as AntDOptionsType } from "antd/es/select";
import { SelectComponent } from "./SelectComponents";
import { ReactComponent as ArrowDown } from "../../../resources/icons/select/arrow-down.svg";

type SelectProps = AntDSelectProps;

export type OptionsType = AntDOptionsType;

const Select: FC<SelectProps> = (props) => (
    <SelectComponent {...props} suffixIcon={<ArrowDown />} />);

export default Select;
