import styled from "styled-components";
import { colors } from "../../../../../styles/variables";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h2`
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    color: ${colors.activeText};
    line-height: 105%;
    margin: 0;

`;

export const Body = styled.div`
    min-height: 421px;
    min-width: 924px;
    margin: 16px 0 0;
`;
