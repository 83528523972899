import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
    createBrowserRouter, createRoutesFromElements, Route, RouterProvider,
} from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { configurePersistable } from "mobx-persist-store";
import AppRoutes from "./routes/AppRoutes";
import accountStore from "../store/user/account/accountStore";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", { weekStart: 1 });
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

configurePersistable(
    {
        storage: window.localStorage,
        stringify: true,
        debugMode: false,
    },
    { delay: 200, fireImmediately: false },
);

const router = createBrowserRouter(
    createRoutesFromElements(<Route path="/*" element={<AppRoutes />} />),
);

const App = () => {
    useEffect(() => {
        accountStore.getUserProfile();
    }, []);
    return <RouterProvider router={router} />;
};

export default observer(App);
