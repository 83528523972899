import { capitalizeFirstLetter } from "../../../../../../helpers";
import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper } from "../../../../../ui/details/DetailsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../../ui/textInput/TextareaLabelInRow";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import { ColoredTextField } from "./SectionsComponents";

const OrderInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const preInputParamsList = [
        {
            key: "order-info-transaction-id",
            label: t.table.header.transactionId,
            value: transaction?.id.toString(),
        },
        {
            key: "order-info-transaction-type",
            label: t.table.header.transactionType,
            value: transaction?.type && capitalizeFirstLetter(transaction?.type),
        },
        {
            key: "order-info-partner-name",
            label: t.table.header.partnerName,
            value: transaction?.partner_name,
        },
        {
            key: "order-info-partner-id",
            label: t.table.header.partnerId,
            value: transaction?.partner.toString(),
        },
        {
            key: "order-info-service-name",
            label: t.table.header.serviceName,
            value: transaction?.service_name,
        },
        {
            key: "order-info-service-id",
            label: t.table.header.serviceId,
            value: transaction?.service.toString(),
        },
        {
            key: "order-info-processing-name",
            label: t.table.header.processingName,
            value: transaction?.processing_name,
        },
        {
            key: "order-info-processing-id",
            label: t.table.header.processingId,
            value: transaction?.processing?.toString(),
        },
        {
            key: "order-info-transaction-subtype",
            label: t.table.header.transactionSubtype,
            value: transaction?.subtype,
        },
        {
            key: "order-info-order-id",
            label: t.table.header.orderId,
            value: transaction?.order_id.toString(),
        },
        {
            key: "order-info-create-time",
            label: t.table.header.createdAt,
            value: transaction?.create_time,
        },
        {
            key: "order-info-last-update",
            label: t.table.header.lastUpdate,
            value: transaction?.last_update,
        },
        {
            key: "order-info-finalize-time",
            label: t.table.header.finalizeTime,
            value: transaction?.finalize_time,
        },
        {
            key: "order-info-service-site-url",
            label: t.transactions.placeholders.siteURL,
            value: transaction?.service_site_url,
        },
        {
            key: "order-info-gate-id",
            label: t.transactions.placeholders.gateId,
            value: transaction?.gate_id?.toString(),
        },
        {
            key: "order-info-channel-id",
            label: t.table.header.channelId,
            value: transaction?.channel_id?.toString(),
        },
        {
            key: "order-info-status",
            label: t.table.header.status,
            value: capitalizeFirstLetter(transaction?.status || ""),
        },
        {
            key: "order-info-risk",
            label: t.table.header.risk,
            value: transaction?.risk_score,
        },
        {
            key: "order-info-status-details",
            label: t.table.header.statusDetails,
            value: transaction?.status_details,
        },
        {
            key: "order-info-code",
            label: t.table.header.code,
            value: transaction?.code,
        },
        {
            key: "order-info-amount",
            label: t.transactions.placeholders.amount,
            value: `${transaction?.amount} ${transaction?.currency}`,
        },
    ];

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.orderTitle}
            </DetailPageHeader>

            <DetailsBodyWrapper>
                {preInputParamsList.map((input) => {
                    const isColoredField = input.label === "Status" || input.label === "Risk";

                    return isColoredField ? <ColoredTextField {...input} /> : <PreInput {...input} />;
                })}
            </DetailsBodyWrapper>

            <TextareaLabelInRow
                label={t.table.header.description}
                value={transaction?.description}
            />
        </DetailsSectionWrapper>
    );
};

export default OrderInfo;
