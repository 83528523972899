import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../../constants/permissions";
import {
    MAX_CHAT_ID_LENGTH,
    MAX_CHAT_LINK_LENGTH,
    MAX_DESCRIPTION_FILED_LENGTH,
    MAX_FIELD_NAME_LENGTH,
    MAX_THREAD_ID_LENGTH,
} from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import tgGroupsStore from "../../../../../store/alerts/tgGroups/tgGroupsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { TgGroupEditTypes } from "../../../../../types/tgGroupsTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof TgGroupEditTypes> = [
    "name",
    "chat_link",
    "chat_id",
    "thread_id",
    "description",
    "is_active",
];

const TgGroupEditModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = tgGroupsStore;

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`),
            ),
        chat_link: Yup.string()
            .required(t.validation.required)
            .url(t.validation.url)
            .max(
                MAX_CHAT_LINK_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_CHAT_LINK_LENGTH}`),
            ),
        chat_id: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_CHAT_ID_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_CHAT_ID_LENGTH}`),
            ),
        thread_id: Yup.string()
            .max(
                MAX_THREAD_ID_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_THREAD_ID_LENGTH}`),
            ),
        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    });

    const formik = useFormik<TgGroupEditTypes>({
        initialValues: {},
        validationSchema,

        onSubmit: async (values) => {
            if (id) {
                await tgGroupsStore.patchData(values, id, fieldsList);

                if (tgGroupsStore.isEditItemSuccess) {
                    setOn(!isOpen);
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
            }
        },
    });

    const handleCloseModal = () => {
        tgGroupsStore.unsetEditState();
        formik.resetForm();
    };

    const toggleTgGroupStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isForm
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={tgGroupsStore.loading}
            setOn={setOn}
            handleClose={handleCloseModal}
            handleSubmit={formik.handleSubmit}
        >
            <EditSectionWrapper>
                <FloatInput
                    formik={formik}
                    disabled={tgGroupsStore.loading}
                    name="name"
                    isRequired
                    placeholder={t.tgGroups.placeholders.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={tgGroupsStore.loading}
                    name="chat_link"
                    isRequired
                    placeholder={t.tgGroups.placeholders.chatLink}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={tgGroupsStore.loading}
                    name="chat_id"
                    placeholder={t.tgGroups.placeholders.chatId}
                    isRequired
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={tgGroupsStore.loading}
                    name="thread_id"
                    placeholder={t.tgGroups.placeholders.threadId}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={tgGroupsStore.loading}
                    name="description"
                    placeholder={t.tgGroups.placeholders.description}
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />

                    <ProtectedSwitch
                        permission={permissionsMap.tg_groups.changeStatus}
                        disabled={tgGroupsStore.loading}
                        checked={formik.values.is_active}
                        onChange={toggleTgGroupStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(TgGroupEditModal);
