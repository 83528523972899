import { AxiosResponse } from "axios";
import {
    action, computed, flow, makeObservable, observable, runInAction,
} from "mobx";

import { OptionsType } from "../../components/ui/select/Select";
import { FetchStatusesType } from "../../types/fetchStatuses";

export type FetchListResponseType<D> = {
    message: string;
    response: {
        count: number;
        results: D[];
    }
};

type SimpleFetchListResponseType<D> = {
    message: string;
    response: D[];
};

export type RequestParamsType = Record<string, unknown>;

abstract class FiltersDataStore<D> {
    protected data: D[] = [];

    protected fetchState: FetchStatusesType = FetchStatusesType.unset;

    constructor() {
        makeObservable<FiltersDataStore<D>, "data" | "fetchState">(this, {
            data: observable,
            fetchState: observable,
            options: computed,
            loading: computed,
            fetch: action,
        });
    }

    public abstract get options(): OptionsType[];

    public get loading() {
        return this.fetchState === FetchStatusesType.pending;
    }

    public fetch = flow(function* fetch(this: FiltersDataStore<unknown>) {
        if (this.fetchListMethod) {
            try {
                this.fetchState = FetchStatusesType.pending;
                const response = yield this.fetchListMethod();

                runInAction(() => {
                    this.data = response.data?.response;
                    this.fetchState = FetchStatusesType.success;
                });
            } catch (error) {
                runInAction(() => {
                    this.fetchState = FetchStatusesType.failed;
                });
            }
        } else {
            console.error("Using fetch without creating abstract fetchListMethod in subclass");
        }
        return undefined;
    });

    protected fetchListMethod?: () => (
        Promise<AxiosResponse<FetchListResponseType<D> | SimpleFetchListResponseType<D>>>
    );

    public fetchDataById = flow(function* fetch(this: FiltersDataStore<unknown>, params: RequestParamsType) {
        const response = yield this.fetchDataByIdWithoutSet(params);

        runInAction(() => {
            this.data = response.data?.response;
            this.fetchState = FetchStatusesType.success;
        });
    });

    protected fetchDataByIdWithoutSet = flow(function* fetchDataByIdWithoutSet(
        this: FiltersDataStore<unknown>,
        params: RequestParamsType,
    ) {
        if (this.fetchDataByIdMethod) {
            try {
                this.fetchState = FetchStatusesType.pending;
                const response = yield this.fetchDataByIdMethod(params);
                this.fetchState = FetchStatusesType.success;

                return response;
            } catch (error) {
                this.fetchState = FetchStatusesType.failed;
            }
        } else {
            console.error("Using fetchDataByIdWithoutSet without creating abstract fetchDataByIdMethod in subclass");
        }
        return undefined;
    });

    protected fetchDataByIdMethod?: (request: RequestParamsType) => (
        Promise<AxiosResponse<FetchListResponseType<D> | SimpleFetchListResponseType<D>>>
    );
}

export default FiltersDataStore;
