import styled from "styled-components";
import { colors } from "../../../styles/variables";

export type Position = "center" | "top-right";

export const ModalOverlay = styled.div`
    position: fixed;
    inset: 0;
    background: ${colors.modalOverlayBackground};
    z-index: 1000;
`;

export const ModalBox = styled.div<{
    $position: Position,
    $scrollable?: boolean,
    $width?: string,
    $isFilter?: boolean
}>`
    display: block;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: ${(props) => (props.$isFilter ? "16px" : "22px")};
    width: ${(props) => (props.$width || "480px")};
    background-color: ${colors.white};
    padding: 30px;
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1000;

    ${(props) => {
        if (props.$scrollable) {
            return `
                  height: calc(100% - 80px);
                `;
        }
        return "";
    }}

    ${(props) => {
        if (props.$position === "top-right") {
            return `
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                  transform: initial;
                  top: 0;
                  right: 0;
                  left: initial;
                `;
        }
        return "";
    }}
`;

export const HeaderSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    max-width: 420px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    color: ${colors.textDefault};
`;

export const ModalBody = styled.div<{ $position: Position, $gap?: string }>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => {
        if (props.$gap && props.$gap !== "0px") {
            return props.$gap;
        }
        return props.$position === "center" ? "30px" : "0";
    }};
    height: 100%;
`;

export const CloseIcon = styled.button<{ $position: Position }>`
    background: none;
    border: none;
    position: absolute;
    padding: 0;
    width: 24px;
    height: 24px;
    right: 20px;
    top: 20px;
    ${(props) => {
        if (props.$position === "top-right") {
            return `
       right: 10px;
       top: 10px;
    `;
        }
        return "";
    }}
    cursor: pointer;
`;

export const ModalText = styled.div`
    color: ${colors.textDefault};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
`;

export const ActiveModalText = styled(ModalText)`
  color: ${colors.activeText};
`;

export const CopyAndAgreeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const EditSectionWrapper = styled.form<{ $margin?: string }>`
    display: flex;
    flex-direction: column;
    margin: ${(props) => (props.$margin ? props.$margin : "0")};
    gap: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    align-items: stretch;
    max-height: 416px;
`;
