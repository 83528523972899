import authStore from "../../../store/auth/authStore";
import { Hyperlink, Text } from "./InputStyles";

interface ProtectedHyperLinkType {
    navigateTo: string;
    linkName: string;
    permission?: string;
}

const ProtectedHyperLink = ({
    navigateTo,
    linkName,
    permission = "",
}: ProtectedHyperLinkType) => {
    const { userPermissions } = authStore;
    const hasPermission = userPermissions.includes(permission);

    return (
        hasPermission ? (
            <Hyperlink to={navigateTo}>
                {linkName}
            </Hyperlink>
        ) : (
            <Text>{linkName}</Text>
        )
    );
};

export default ProtectedHyperLink;
