import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import useTranslation from "../../../../hooks/useTranslation";
import filtersListsStore from "../../../../store/filters/lists/listsFiltersStore";
import rulesPackListsStore from "../../../../store/rulesPack/rulesPackListsStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { EditListType, NewListType } from "../../../../types/listsTypes";
import { SwitchButtonContainer } from "../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { ActiveModalText, EditSectionWrapper } from "../../../ui/modal/modalStyle";
import { Box, Icon, Title } from "../../../ui/new/NewComponents";
import ControlledSwitch from "../../../ui/switch/ControlledSwitch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import BlockModal from "../../../common/modal/warning/blockModal/BlockModal";

interface ClonedListType extends NewListType {
    id: string | null,
}

const initialValues: ClonedListType = {
    name: "",
    description: "",
    is_active: true,
    rules_pack: null,
    category: null,
    type: null,
    is_global: false,
    id: null,
};

const fieldsList: Array<keyof EditListType> = ["name"];

const CloneRulesPackListModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: rulesPackId } = useParams();

    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);
    const toggleWarningModal = () => setWarningModalVisibility((prev) => !prev);

    const localLists = filtersListsStore.options?.filter((list) => !list.is_global);

    const formik = useFormik<ClonedListType>({
        initialValues,
        validationSchema: Yup.object({
            id: Yup.string().required(t.validation.required),
            name: Yup.string().required(t.validation.required)
                .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        }),
        onSubmit: async (values) => {
            if (values.id && formik.isValid) {
                const list = localLists.find((item) => item.value === values.id);

                const fieldsData: NewListType = {
                    ...list,
                    rules_pack: Number(rulesPackId),
                    name: values.name,
                    description: values.description,
                    is_active: values.is_active,
                };

                await rulesPackListsStore.cloneData(fieldsData, values.id, fieldsList);

                if (rulesPackListsStore.isCloneRequestSuccess) {
                    setOn(false);
                    snackbarStore.add({ variant: "success", text: t.rulesPacks.snackbar.cloneSuccess });
                    if (rulesPackId) {
                        rulesPackListsStore.fetchData(rulesPackId);
                    }
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            }
        },
    });

    const toggleListStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleCloseModal = () => {
        toggleWarningModal();
        setOn(false);
    };

    useEffect(() => {
        if (isOpen) {
            filtersListsStore.fetch();
        }

        return () => {
            formik.resetForm();
            rulesPackListsStore.resetPostErrors();
        };
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            hasChildModal
            isForm
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={toggleWarningModal}
            isLoading={rulesPackListsStore.isCloning}
            modalBodyText={
                (
                    <ActiveModalText>
                        {t.modal.modalBody.cloneAndAddListToRP}
                    </ActiveModalText>
                )
            }
        >
            <EditSectionWrapper>
                <FloatSelect
                    formik={formik}
                    disabled={rulesPackListsStore.isCloning}
                    name="id"
                    options={localLists}
                    isRequired
                    placeholder={t.filters.fields.listForClone}
                    serverErrors={rulesPackListsStore.postErrors}
                    resetServerError={rulesPackListsStore.resetPostError}
                />
                <Box>
                    <FloatInput
                        formik={formik}
                        disabled={rulesPackListsStore.isCloning}
                        name="name"
                        isRequired
                        placeholder={t.filters.fields.newListName}
                        serverErrors={rulesPackListsStore.postErrors}
                        resetServerError={rulesPackListsStore.resetPostError}
                    />
                </Box>
                <FloatTextArea
                    formik={formik}
                    name="description"
                    placeholder={t.placeholder.description}
                    serverErrors={rulesPackListsStore.postErrors}
                    resetServerError={rulesPackListsStore.resetPostError}
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    disabled={!formik.values.id || rulesPackListsStore.loading || rulesPackListsStore.isCloning}
                />
                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ControlledSwitch
                        disabled={rulesPackListsStore.isCloning}
                        isChecked={!!formik.values.is_active}
                        onChange={toggleListStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>

            <BlockModal
                width="520px"
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={handleCloseModal}
            />
        </Modal>
    );
};

export default observer(CloneRulesPackListModal);
