import { Dispatch, SetStateAction } from "react";
import { ActionModalState, ActionModalType } from "../types/actionModalTypes";

export const toggleActionModalState = (
    setState: Dispatch<SetStateAction<ActionModalState>>,
    type?: ActionModalType,
) => (
    setState((prevState) => ({
        isVisible: !prevState.isVisible,
        type: type ?? prevState.type,
    }))
);
