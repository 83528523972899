import request from "../request";
import { EditLimitType, LimitsTypes } from "../../types/limitsTypes";

interface ResponseType {
    message: string,
    response: LimitsTypes,
}

const patchLimits = (data: EditLimitType, limitId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `limits/${limitId}/`,
    data,
});

export default patchLimits;
