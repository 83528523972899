import styled from "styled-components";

import { determineColor } from "../../../../helpers/determineColor";
import { TransactionDecision, TransactionProxy, TransactionStatus } from "../../../../types/transactionTypes";

type ColoredTextFieldProps = { value?: TransactionStatus | TransactionDecision | TransactionProxy | number }

export const ColoredTextField = styled.span<ColoredTextFieldProps>`
    color: ${({ value }) => determineColor(value)};
`;
