import { FC } from "react";
import TransactionsCount from "../TransactionsCount";
import { ArrowButtonComponent } from "./ArrowButtonStyles";
import { SmallButtonProps } from "../../../ui/button/SmallButton";
import useTranslation from "../../../../hooks/useTranslation";
import { ReactComponent as ArrowClockwise } from "../../../../resources/icons/arrowClockwise.svg";

interface ArrowButtonProps extends Omit<SmallButtonProps, "variant"> {
    count: string | null
}

export const ArrowButton: FC<ArrowButtonProps> = ({
    onClick, isLoading, disabled, count,
}) => {
    const { t } = useTranslation();

    return (
        <ArrowButtonComponent
            variant="tertiary"
            iconRight={<TransactionsCount count={count} />}
            iconLeft={<ArrowClockwise />}
            onClick={onClick}
            isLoading={isLoading}
            disabled={disabled}
        >
            {t.buttons.amount}
        </ArrowButtonComponent>
    );
};
