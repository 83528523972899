import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { RegExpressions } from "../../../../constants/regex";
import { AuthRoutes } from "../../../../constants/routes";
import useTranslation from "../../../../hooks/useTranslation";
import authStore from "../../../../store/auth/authStore";
import PasswordValidation from "../../../common/passwordValidation/PasswordValidation";
import { PrimaryButton } from "../../../ui/button/ButtonStyles";
import ErrorLabel from "../../../ui/errors/ValidationErrorBox/ErrorLabel";
import PasswordInput from "../../../ui/passwordInput/PasswordInput";
import { InputSection } from "../../../ui/textInput/InputStyles";
import { HeaderText, RulesMessageBox } from "../../../ui/TextStyle";
import { FormWrapper } from "../../auth/AuthStyles";
import { RightSidePageWrapper } from "../../CommonPagesStyles";
import { PasswordRecoveryContent } from "../../auth/password/recovery/PasswordRecoveryStyle";
import useConfirmOnExitAndReload from "../../../../hooks/useConfirmOnExitAndReload";
import { AccountErrorsWrapper } from "./PasswordConfirmationStyle";

type PasswordFieldTypes = {
    new_password: string,
    new_password_conf: string,
};

const PageChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useConfirmOnExitAndReload();

    const unsetErrors = () => {
        authStore.unsetState();
    };

    const formik = useFormik<PasswordFieldTypes>({
        initialValues: {
            new_password: "",
            new_password_conf: "",
        },
        validationSchema: Yup.object({
            new_password: Yup.string().required(t.validation.required)
                .matches(RegExpressions.lowercaseAndUppercase, t.validation.lowercaseAndUppercase)
                .matches(RegExpressions.specialSymbolAndNumber, t.validation.specialCharacterAndNumber)
                .matches(RegExpressions.passwordLength, t.validation.passwordLength),
            new_password_conf: Yup.string().required(t.validation.required)
                .oneOf([Yup.ref("new_password")], t.validation.passwordsMustMatch),
        }),
        onSubmit: (values) => {
            authStore.createNewPassword(values).then(() => {
                if (!authStore.authErrors) {
                    navigate(AuthRoutes.firstLoginChangedPasswordSuccess.href);
                }
            });
        },
        onReset: () => {
            unsetErrors();
            formik.setErrors({});
        },
    });

    const errors = authStore.authErrors;
    const passwordError = errors?.new_password || errors?.common;

    const unsetAuthStoreState = () => {
        authStore.unsetState();
    };

    return (
        <RightSidePageWrapper>
            <PasswordRecoveryContent>
                <HeaderText>{t.passwordRecovery.text.changePassword}</HeaderText>
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <InputSection>
                        <PasswordInput
                            formik={formik}
                            name="new_password"
                            placeholder={t.changeTemporaryPassword.inputs.newPassword}
                            resetServerError={unsetAuthStoreState}
                            serverErrors={errors}
                            isCommonError={errors?.common}
                            regex={RegExpressions.password}
                            showFiledError={false}
                        />
                        <PasswordInput
                            formik={formik}
                            name="new_password_conf"
                            placeholder={t.changeTemporaryPassword.inputs.confirmPassword}
                            resetServerError={unsetAuthStoreState}
                            serverErrors={errors}
                            isCommonError={errors?.common}
                            regex={RegExpressions.password}
                        />
                        <div>
                            <PasswordValidation formik={formik} fieldName="new_password" />
                            <RulesMessageBox>
                                <AccountErrorsWrapper>
                                    {passwordError && ErrorLabel(passwordError)}
                                </AccountErrorsWrapper>
                            </RulesMessageBox>
                        </div>
                    </InputSection>
                    <PrimaryButton
                        type="submit"
                        disabled={!formik.isValid || errors}
                    >
                        {t.password.confirm}
                    </PrimaryButton>
                </FormWrapper>
            </PasswordRecoveryContent>
        </RightSidePageWrapper>
    );
};

export default observer(PageChangePassword);
