import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../../constants/permissions";
import { RegExpressions } from "../../../../../constants/regex";
import {
    MAX_DESCRIPTION_FILED_LENGTH, MAX_EMAIL_LENGTH, MAX_FIELD_NAME_LENGTH,
} from "../../../../../constants/validationRules";
import { fromStringWithCommasToArray } from "../../../../../helpers";
import useTranslation from "../../../../../hooks/useTranslation";
import processingStore from "../../../../../store/entities/processing/processingStore";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { FormProcessingType, ProcessingTypes, RequestProcessingType } from "../../../../../types/processingTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import { Title } from "../../partners/new/NewPartnersComponents";

const fieldsList: Array<keyof FormProcessingType> = ["name", "is_active", "email",
    "description", "timezone", "ip_addresses"];

const getFromValues = (mobxSelectedItem: ProcessingTypes | null) => ({
    ...mobxSelectedItem,
    ip_addresses: mobxSelectedItem?.ip_addresses?.join(", ") || "",
});

const EditProcessingModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = processingStore;
    const timezones = filtersTimezonesStore.options;

    const validationSchema = Yup.object({
        timezone: Yup.string().required(t.validation.required),
        email: Yup.string().email(t.validation.invalidEmail).nullable()
            .max(MAX_EMAIL_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`)),
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
    });

    const formik = useFormik<FormProcessingType>({
        initialValues: {},
        validationSchema,
        onSubmit: async (values) => {
            if (id) {
                const ip_addresses = fromStringWithCommasToArray(values.ip_addresses || "");
                const resultValues: RequestProcessingType = { ...values, ip_addresses };

                await processingStore.patchData(resultValues, id, fieldsList);

                if (processingStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    setOn(false);
                }
            }
        },
        validate: (values) => {
            const errors: Pick<FormProcessingType, "ip_addresses"> = {};
            const ip = fromStringWithCommasToArray(values.ip_addresses || "");
            ip.forEach((item) => {
                if (!(item.match(RegExpressions.ipv4Address) || item.match(RegExpressions.ipv6Address))) {
                    errors.ip_addresses = t.validation.ipAddress;
                }
            });
            return errors;
        },
    });

    const handleModalClose = () => {
        processingStore.unsetEditState();
        formik.resetForm();
    };

    const toggleProcessingStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        if (isOpen) {
            if (!timezones?.length) filtersTimezonesStore.fetch();
        }

        return () => handleModalClose();
    }, [isOpen]);

    useEffect(() => {
        formik.setValues(getFromValues(selectedItem));
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={buttonText}
            handleSubmit={formik.handleSubmit}
            handleClose={handleModalClose}
            isLoading={processingStore.loading}
            isForm
        >
            <EditSectionWrapper>
                <FloatInput
                    formik={formik}
                    disabled={processingStore.loading}
                    name="name"
                    defaultValue={selectedItem?.name}
                    isRequired
                    placeholder={t.table.header.processingName}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={processingStore.loading}
                    name="email"
                    placeholder={t.table.header.email}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatSelect
                    formik={formik}
                    name="timezone"
                    disabled={processingStore.loading}
                    options={timezones}
                    isRequired
                    placeholder={t.table.header.timezone}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatInput
                    formik={formik}
                    disabled={processingStore.loading}
                    name="ip_addresses"
                    placeholder={t.table.header.ipAddresses}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={processingStore.loading}
                    name="description"
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    autoSize
                    placeholder={t.table.header.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        checked={formik.values.is_active}
                        disabled={processingStore.loading}
                        permission={permissionsMap.processings.changeStatus}
                        onChange={toggleProcessingStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditProcessingModal);
