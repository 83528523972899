import { ChangeEvent } from "react";

import useTranslation from "../../../../../../hooks/useTranslation";
import { ReactComponent as FileIcon } from "../../../../../../resources/icons/fileIcon.svg";
import { colors } from "../../../../../../styles/variables";
import { SUPPORTED_FORMAT } from "./UploadListsElementsModal";
import { FileInputComponent, SubtitleComponent, TitleComponent } from "./UploadListsElementsModalComponents";

interface NotUploadedAreaContentTypes {
    file: File | null;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const NotUploadedAreaContent = ({ file, onChange }: NotUploadedAreaContentTypes) => {
    const { t } = useTranslation();

    return (
        <>
            <FileInputComponent
                id={file ? "file-upload" : ""}
                $isFile={!!file}
                name="file"
                type="file"
                accept={SUPPORTED_FORMAT}
                onChange={onChange}
            />
            <FileIcon />
            <TitleComponent $color={colors.buttonPrimary}>
                {t.lists.text.uploadModalContentTextPrefix}
                <TitleComponent>{t.lists.text.uploadModalContentText}</TitleComponent>
            </TitleComponent>
            <SubtitleComponent>XLSX</SubtitleComponent>
        </>
    );
};

export default NotUploadedAreaContent;
