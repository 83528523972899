import styled from "styled-components";
import { colors } from "../../styles/variables";

export const HeaderContainer = styled.header`
    position: sticky;
    top: 0;
    z-index: 10;

    grid-area: header;
    display: grid;
    grid-template-columns: 4fr 1fr;
    width: 100%;
    gap: 20px;
    height: fit-content;
    margin-bottom: 40px;
    background-color: ${colors.mainLayoutBackground};
    border-bottom: 20px solid ${colors.mainLayoutBackground};
    border-top: 20px solid ${colors.mainLayoutBackground};
`;

/*  TODO: Replace with real search bar  */
export const HeaderSearchBar = styled.div`
  display: block;
  border-radius: 12px;
  padding: 15px 20px;
  background: ${colors.white};
`;

export const HeaderPersonalArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.white};
  border-radius: 12px;
  align-self: stretch;
  padding: 10px;
`;

export const HeaderIconAndText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const HeaderTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: ${colors.activeText};
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  
  div {
    color: ${colors.textDefault};
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }
`;

export const IconButton = styled.button`
  width: 32px;
  height: 32px;
  background: none;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${colors.inputHoverBorder};
  cursor: pointer;
`;
