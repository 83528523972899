import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import authStore from "../../../store/auth/authStore";
import Routes from "../../../constants/routes";

interface ProtectedElementProps {
    element: ReactElement;
    requiredPermission: string | string[];
}

const ProtectedElement = ({ element, requiredPermission }: ProtectedElementProps) => {
    const { userPermissions } = authStore;

    const hasPermission = Array.isArray(requiredPermission)
        ? requiredPermission.some((permission) => userPermissions.includes(permission))
        : userPermissions.includes(requiredPermission);

    return hasPermission ? element : <Navigate to={Routes.home.href} />;
};

export default ProtectedElement;
