import FiltersDataStore from "../common/filtersStore";
import { getProcessing } from "../../api/processing/filters/getProcessing";
import { ProcessingTypes } from "../../types/processingTypes";

class FiltersProcessingStore extends FiltersDataStore<ProcessingTypes> {
    protected fetchListMethod = getProcessing;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersProcessingStore = new FiltersProcessingStore();

export default filtersProcessingStore;
