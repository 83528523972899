import { AlertsTypes } from "../../types/alertsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: AlertsTypes,
}

export const getSelectedAlert = (alertId: string) => request<ResponseType>({
    method: "GET",
    uri: `alerts/${alertId}/`,
});
