import { useLocation } from "react-router-dom";
import partnerServicesStore from "../../../../../../../../store/entities/partner/partnerServicesStore";
import partnerRulesPacksStore from "../../../../../../../../store/entities/partner/partnerRulesPacksStore";
import ProtectedPartnerServicesTable from "../../../tabsTables/ProtectedPartnerServicesTable";
import ProtectedPartnerRulesPacksTable from "../../../tabsTables/ProtectedPartnerRulesPacksTable";
import { permissionsMap } from "../../../../../../../../constants/permissions";
import useTranslation from "../../../../../../../../hooks/useTranslation";
import useTabs from "../../../../../../../../hooks/useTabs";
import { LocationState } from "../../../../../../../../types/locationTypes";
import { Tabs } from "../../../../../../../../constants/tabs";

export function useTabsActions(
    partnerId: string,
) {
    const { t } = useTranslation();

    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const tabsPropsList = [
        {
            title: t.entities.services.title,
            name: Tabs.SERVICES,
            itemsCount: partnerServicesStore.pagination.count,
            permission: permissionsMap.services.view,
            content: (
                <ProtectedPartnerServicesTable
                    permission={permissionsMap.services.view}
                    partnerId={partnerId}
                />),
        },
        {
            title: t.rulesPacks.title,
            name: Tabs.RULES_PACKS,
            itemsCount: partnerRulesPacksStore.pagination.count,
            permission: permissionsMap.rules_packs.view,
            content: (
                <ProtectedPartnerRulesPacksTable
                    permission={permissionsMap.rules_packs.view}
                    partnerId={partnerId}
                />
            ),
        },
    ];

    const { TabsLayout, activeTab } = useTabs(tabsPropsList, defaultActiveTab);

    return {
        activeTab,
        TabsLayout,
    };
}
