import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect } from "react";

import useTranslation from "../../../../../hooks/useTranslation";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import rulesPackServicesStore from "../../../../../store/rulesPack/rulesPackServicesStore";
import { ServicesFiltersTypes } from "../../../../../types/servicesTypes";
import { FormContainer, HeaderContainer, HeaderTitle } from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { FieldMode } from "../../../../../constants/selectFields";

interface RulesPacksServicesFiltersProps {
    ruleId?: string;
    closeModal: () => void;
}

const RulesPacksServicesFilters = ({ ruleId, closeModal }: RulesPacksServicesFiltersProps) => {
    const { t } = useTranslation();

    const initialValues: ServicesFiltersTypes = {
        ...rulesPackServicesStore.filters,
    };

    const formik = useFormik<ServicesFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            rulesPackServicesStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        rulesPackServicesStore.resetFilters();
        formik.resetForm();
    };

    useEffect(() => {
        filtersServicesStore.fetchById(Number(ruleId), true);
    }, []);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.entities.services.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="service"
                    options={filtersServicesStore.options}
                    mode={FieldMode.Multiple}
                    placeholder={t.filters.fields.service}
                />
                <Button
                    variant="primary"
                    isLoading={rulesPackServicesStore.loading}
                    type="submit"
                >
                    {t.filters.apply}
                </Button>
            </FormContainer>
        </>
    );
};

export default observer(RulesPacksServicesFilters);
