import styled from "styled-components";
import { Select } from "antd";
import { SelectProps } from "antd/es/select";
import { colors } from "../../../styles/variables";

export const SelectComponent = styled(Select)<SelectProps>`
    width: 280px;
    height: 56px;

    &.ant-select-open .ant-select-arrow {
        transform: rotate(180deg);
    }

    &&.ant-select .ant-select-selector {
        border: 1px solid ${colors.inputDefaultBorder};
        background: ${colors.white};
        box-shadow: none;
        border-radius: 12px;
        padding: 20px;

        .ant-select-selection-search-input {
            padding: 10px;
        }

    }

    div&&.ant-select.ant-select-focused .ant-select-selector {
        border-color: ${colors.inputDefaultBorder} !important;
        box-shadow: none !important;
    }

    &&:not(.ant-select-disabled).ant-select {
        &:hover {
            .ant-select-selector {
                background-color: ${colors.inputHoverBackground};
                border-color: ${colors.inputHoverBorder};
            }
        }
    }

    &&.ant-select-disabled.ant-select .ant-select-selector {
        background-color: ${colors.selectDisabledBackground};
        border-color: ${colors.selectDisabledBackground};
    }

`;

export const Box = styled.div`
    display: flex;
`;
