import type { DatePickerProps as AntDDatePickerProps } from "antd";
import { FocusEvent } from "react";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePickerComponent, DatePickerContainer } from "./DatePickerComponents";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { FormikTypes } from "../../../types/formikTypes";
import { DATE_BACK_FORMAT, DATE_FORMAT, DATE_INPUT_FORMAT } from "../../../constants/dates";

dayjs.extend(utc);

export type DatePickerProps = {
    value?: string | Dayjs;
    formik?: FormikTypes;
    error?: boolean;
    serverErrors?: Record<string, string>;
    addonBeforeText?: string;
    maxDate?: Dayjs | string;
    minDate?: Dayjs | string;
    isNowButtonShown?: boolean;
    isAddMilliseconds?: boolean;
    onChange?: (date: string | string[]) => void;
    resetServerError?: (field: string) => void;
} & Omit<AntDDatePickerProps, "value" | "onOk" | "maxDate" | "minDate">

const DatePicker = ({
    formik,
    serverErrors,
    resetServerError,
    onChange,
    isAddMilliseconds,
    ...props
}: DatePickerProps) => {
    let isError = false;
    let error;
    let {
        value,
        minDate,
        maxDate,
    } = props;
    const name = props.name || "";

    if (formik) {
        const formikValue = formik.values[name];

        if (formikValue && typeof formikValue === "string") {
            value = dayjs(formikValue, [DATE_FORMAT, DATE_BACK_FORMAT, DATE_INPUT_FORMAT]);
        }
        error = serverErrors?.[name] || (formik?.touched[name] && formik.errors[name]);
        isError = !!error;
    }

    if (minDate && typeof minDate === "string") {
        minDate = dayjs(minDate);
    }

    if (maxDate && typeof maxDate === "string") {
        maxDate = dayjs(maxDate);
    }

    const handleChange = (_: unknown, date: string | string[]) => {
        if (typeof date === "string") {
            let parsedDate = date;
            if (date) {
                let parsedDayjsDate = dayjs(date, [DATE_FORMAT, DATE_INPUT_FORMAT, DATE_BACK_FORMAT]);
                if (isAddMilliseconds) {
                    parsedDayjsDate = parsedDayjsDate.add(999, "millisecond");
                }
                parsedDate = parsedDayjsDate.format(DATE_BACK_FORMAT);
            }

            formik?.setFieldTouched(name, true);
            formik?.setFieldValue(name, parsedDate);
            resetServerError?.(name);
            onChange?.(parsedDate);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLElement, Element>) => {
        formik?.handleBlur(event as FocusEvent<HTMLInputElement>);
    };

    return (
        <DatePickerContainer className="date-picker-container">
            <DatePickerComponent
                {...props}
                showTime
                value={value}
                minDate={minDate as Dayjs}
                maxDate={maxDate as Dayjs}
                $isError={isError}
                onBlurCapture={handleBlur}
                onChange={handleChange}
                format={{
                    format: DATE_INPUT_FORMAT,
                    type: "mask",
                }}
            />
            {isError && ErrorLabel(error || "")}
        </DatePickerContainer>
    );
};

export default DatePicker;
