import request from "../request";
import { TransactionFiltersTypes, TransactionTypes } from "../../types/transactionTypes";

type GetTransactionsRequest = TransactionFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetTransactionsResponse {
    message: string,
    response: {
        count: number
        is_lazy_count: boolean
        results: TransactionTypes[];
    }
}

export const getTransactions = (params?: GetTransactionsRequest) => request<GetTransactionsResponse>({
    method: "GET",
    uri: "transactions/",
    params,
});
