import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import Routes from "../../../../../../constants/routes";
import partnerServicesStore from "../../../../../../store/entities/partner/partnerServicesStore";
import { ServicesTypes } from "../../../../../../types/servicesTypes";
import Table from "../../../../../ui/table/Table";
import TableFieldsDetailsLink from "../../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { ColumnType } from "../../../../../ui/table/types";
import withPermission from "../../../../../../hocs/withPermissions";
import { TableMainLink } from "../../../../../ui/tableFields/nameLink/TableMainLink";
import { Tabs } from "../../../../../../constants/tabs";

interface ProtectedPartnerServicesTableProps {
    permission: string;
    partnerId?: string;
}

type WrappedComponentProps = Pick<ProtectedPartnerServicesTableProps, "partnerId">

const columns: ColumnType<ServicesTypes>[] = [
    {
        header: "table.header.serviceName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.services.href}/${dataItem.id}`}
                state={{ previousTab: Tabs.SERVICES }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.serviceId", field: "id", sortable: true },
    {
        header: "table.header.isActive",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.partnerName",
        field: "partner_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.partnerId", field: "partner", sortable: true },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "phone" },
    {
        header: "table.header.accountManager",
        field: "account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.timezone", field: "timezone" },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={Routes.services.href}
                state={{ previousTab: Tabs.SERVICES }}
            />
        ),
        cellStyles: { width: "0px" },
    },
];

const WrappedComponent = observer(({ partnerId }: WrappedComponentProps) => {
    const tablePagination = {
        ...partnerServicesStore.pagination,
        setPaginationPage: partnerServicesStore.setPaginationPage,
        setPaginationSize: partnerServicesStore.setPaginationSize,
    };

    useEffect(() => {
        partnerServicesStore.fetchData(partnerId || "");
    }, [partnerServicesStore.pagination, partnerServicesStore.ordering]);

    return (
        <Table
            columns={columns}
            pagination={tablePagination}
            data={partnerServicesStore.data}
            sortMode={partnerServicesStore.ordering}
            loading={partnerServicesStore.loading}
            onSort={partnerServicesStore.toggleSort}
        />
    );
});

const ProtectedPartnerServicesTable = ({ partnerId, permission }: ProtectedPartnerServicesTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission partnerId={partnerId} />;
};

export default memo(ProtectedPartnerServicesTable);
