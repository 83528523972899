import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import listStore from "../../../../store/list/listStore";
import { ListsTypes } from "../../../../types/listsTypes";
import Table from "../../../ui/table/Table";
import { tableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsGroupBadge from "../../../ui/tableFields/groupBadge/TableFieldsGroupBadge";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedListsTableProps {
    isCheckboxVisible: boolean;
    permission: string;
}

type WrappedComponentProps = Pick<ProtectedListsTableProps, "isCheckboxVisible">

const columns: ColumnType<ListsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.listName",
        field: "name",
        sortable: true,
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.lists.href}/${dataItem.id}`}>{dataItem.name}</TableMainLink>
        ),
    },
    { header: "table.header.listId", field: "id", sortable: true },
    { header: "table.header.category", field: "category" },
    { header: "table.header.type", field: "type" },
    { header: "table.header.elements", field: "elements" },
    {
        header: "table.header.ruleName",
        field: "rules_pack_name",
        width: "180px",
        isResizable: true,
    },
    {
        header: "table.header.createdAt",
        field: "created",
        sortable: true,
    },
    {
        header: "table.header.lastUpdate",
        field: "last_update",
        sortable: true,
    },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        width: "180px",
        isResizable: true,
    },
    {
        header: "table.header.description",
        field: "description",
        width: "180px",
        isResizable: true,
    },
    {
        header: "table.header.isGlobal",
        field: "is_global",
        sortable: true,
        Component: TableFieldsGroupBadge,
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        sortable: true,
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.lists.href} />
        ),
    },
];

const WrappedComponent = observer(({ isCheckboxVisible }: WrappedComponentProps) => {
    const tablePagination = {
        ...listStore.pagination,
        setPaginationPage: listStore.setPaginationPage,
        setPaginationSize: listStore.setPaginationSize,
    };

    useEffect(() => {
        listStore.fetchData();
    }, [listStore.pagination, listStore.filters, listStore.ordering]);

    return (
        <Table
            scrollable
            columns={columns}
            tableContainerStyles={tableContainerStyle}
            isItemSelectionAllowed={isCheckboxVisible}
            data={listStore.data}
            pagination={tablePagination}
            checkedItems={listStore.checkedItems}
            sortMode={listStore.ordering}
            loading={listStore.loading}
            onSort={listStore.toggleSort}
            onCheckboxChange={listStore.toggleItem}
            onMainCheckboxChange={listStore.toggleMainItem}
        />
    );
});

const ProtectedListsTable = ({ isCheckboxVisible, permission }: ProtectedListsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isCheckboxVisible={isCheckboxVisible} />;
};

export default memo(ProtectedListsTable);
