import { permissionsMap } from "../../../../../../constants/permissions";
import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper, Row } from "../../../../../ui/details/DetailsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import ProtectedOperationButtons from "../operationButtons/ProtectedOperationButtons";
import routes from "../../../../../../constants/routes";
import transactionStore from "../../../../../../store/transaction/transactionStore";
import HyperlinkFilterTextInput from "../../../../../ui/textInput/HyperlinkFilterTextInput";

const PaymentInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const preInputsList = [
        {
            label: t.transactions.placeholders.paymentSystem,
            value: transaction?.payment_system,
            key: "payment-field-payment-system",
        },
        {
            label: t.transactions.placeholders.cardHolder,
            value: transaction?.cardholder,
            key: "payment-field-card-holder",
        },
        { label: t.transactions.placeholders.bankName, value: transaction?.bank_name, key: "payment-field-bank-name" },
        {
            label: t.transactions.placeholders.bankCountry,
            value: transaction?.card_country,
            key: "payment-field-bank-country",
        },
        { label: t.transactions.placeholders.acquirer, value: transaction?.acquirer, key: "payment-field-acquirer" },
        { label: t.transactions.placeholders.RRN, value: transaction?.rrn, key: "payment-field-rrn" },
        {
            label: t.transactions.placeholders.bankDeclineCode,
            value: transaction?.bank_decline_code,
            key: "payment-field-bank-decline-code",
        },
        {
            label: t.transactions.placeholders.declineCode,
            value: transaction?.decline_code,
            key: "payment-field-decline-code",
        },
        { label: t.transactions.placeholders.authCode, value: transaction?.auth_code, key: "payment-field-auth-code" },
        {
            label: t.transactions.placeholders.is3ds,
            value: transaction?.is_3ds?.toString(),
            key: "payment-field-is-3ds",
        },
        { label: t.transactions.placeholders.eci, value: transaction?.eci, key: "payment-field-eci" },
        {
            label: t.transactions.placeholders._3dsState,
            value: transaction?._3ds_state,
            key: "payment-field-3ds-state",
        },
        { label: t.table.header.cardMask, value: transaction?.card_mask_hask, key: "payment-field-card-mask" },
    ];

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.paymentTitle}
            </DetailPageHeader>

            <DetailsBodyWrapper>
                {preInputsList.map((input) => <PreInput {...input} />)}
            </DetailsBodyWrapper>

            <Row>
                <HyperlinkFilterTextInput
                    navigateTo={routes.transactions.href}
                    store={transactionStore}
                    filterName="card_hash"
                    label={t.table.header.cardHash}
                    hyperlinkName={transaction?.card_hash?.toLowerCase()}
                />
                <ProtectedOperationButtons
                    label={t.table.header.cardHash}
                    value={transaction?.card_hash}
                    permission={permissionsMap.elements.add}
                    fieldType="Card"
                    listInfo={transaction?.card_hash_list_info}
                    transaction={transaction}
                />
            </Row>
        </DetailsSectionWrapper>
    );
};

export default PaymentInfo;
