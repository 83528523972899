import { ReactNode, useState } from "react";
import styled from "styled-components";

import { Row } from "../components/pages/rulesPacks/new/NewRulesPackComponents";
import SmallButton from "../components/ui/button/SmallButton";
import CircleIcon from "../resources/icons/circleIcon/CustomCircleIcon";
import authStore from "../store/auth/authStore";
import { PermissionKeys } from "../types/permissionsTypes";
import { TabsType } from "../types/tabs";

const TabsContainer = styled.section`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

interface TabType {
    title: string;
    name: TabsType;
    content: ReactNode;
    permission: string | string[],
    itemsCount?: number;
    actionButtons?: ReactNode[];
}

const useTabs = (tabs: TabType[], defaultTab?: TabsType) => {
    const { userPermissions } = authStore;

    const protectedTabsList = tabs.filter(({ permission }) => {
        if (Array.isArray(permission)) {
            return permission.every((item) => userPermissions.includes(item));
        }

        return userPermissions.includes(permission);
    });

    const realDefaultTab = protectedTabsList.find((tab) => tab.name === defaultTab)?.name
        || protectedTabsList.at(0)?.name;

    const [currentTab, setCurrentTab] = useState(realDefaultTab);

    const TabsLayout = (
        <TabsContainer>
            <Row>
                {protectedTabsList.map(({ title, itemsCount, name }) => (
                    <SmallButton
                        variant={name === currentTab ? "primary" : "secondary"}
                        key={title}
                        onClick={() => setCurrentTab(name)}
                        iconRight={!!itemsCount && <CircleIcon isActive={name === currentTab} count={itemsCount} />}
                    >
                        {title}
                    </SmallButton>
                ))}
            </Row>

            <Row>
                {protectedTabsList.map(({ actionButtons, name }) => {
                    if (name === currentTab && actionButtons?.length) {
                        return (
                            actionButtons?.map((button) => button)
                        );
                    }
                    return null;
                })}
            </Row>
        </TabsContainer>
    );

    const activeTab = protectedTabsList.find((tab) => tab.name === currentTab);
    const activeTabContent = activeTab?.content;
    const currentTabTitle = activeTab?.title.toLowerCase() as PermissionKeys;

    return {
        TabsLayout,
        activeTab: activeTabContent,
        currentTabTitle,
        currentTab,
    };
};

export default useTabs;
