import { ReactComponent as ClosedEye } from "../../../resources/icons/closedEyeIcon.svg";
import { ReactComponent as OpenedEye } from "../../../resources/icons/openedEyeIcon.svg";
import { InputPassword } from "./InputStyles";
import { BoxProps } from "./testInputTypes";

const renderEyeIcon = (isVisible :boolean) => (
    isVisible ? <ClosedEye /> : <OpenedEye />
);

const PasswordBox = ({
    label,
    isReadOnly = false,
    value,
}: BoxProps) => (
    <InputPassword
        addonBefore={label}
        readOnly={isReadOnly}
        value={value}
        visibilityToggle
        iconRender={renderEyeIcon}
    />
);

export default PasswordBox;
