import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useClearStorage } from "../../hooks/useClearStorage";
import authStore from "../../store/auth/authStore";
import FirstLoginRoutes from "./FirstLoginRoutes";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import NotAuthenticatedRoutes from "./NotAuthenticatedRoutes";

const AppRoutes = () => {
    const { pathname } = useLocation();
    const { clearPreviousLocationStorage } = useClearStorage();

    useEffect(() => {
        clearPreviousLocationStorage(pathname);
        window.scrollTo(0, 0);
    }, [pathname]);

    if (authStore.isFirstLogin) {
        return <FirstLoginRoutes />;
    }

    if (authStore.isAuthenticated) {
        return <AuthenticatedRoutes />;
    }

    return <NotAuthenticatedRoutes />;
};

export default observer(AppRoutes);
