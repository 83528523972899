import { flow } from "mobx";

import deactivateTgGroups from "../../../api/tgGroups/deactivateTgGroups";
import { getSelectedTgGroup } from "../../../api/tgGroups/getSelectedTgGroup";
import { getTgGroups } from "../../../api/tgGroups/getTgGroups";
import patchTgGroups from "../../../api/tgGroups/patchTgGroup";
import { postTgGroup } from "../../../api/tgGroups/postTgGroup";
import { parseDateFromServer } from "../../../helpers";
import { NewTgGroupTypes, TgGroupsFiltersTypes, TgGroupsTypes } from "../../../types/tgGroupsTypes";
import dataStore from "../../common/dataStore";
import activateTgGroups from "../../../api/tgGroups/activateTgGroup";

class TgGroupsStore extends dataStore<TgGroupsTypes, TgGroupsFiltersTypes, NewTgGroupTypes> {
    constructor() {
        super("TgGroupsStore");
    }

    protected fetchDataMethod = getTgGroups;

    protected fetchSelectedItemMethod = getSelectedTgGroup;

    protected patchDataMethod = patchTgGroups;

    protected postDataMethod = postTgGroup;

    protected deactivateSelectedItemsMethod = deactivateTgGroups;

    protected activateSelectedItemsMethod = activateTgGroups;

    fetchSelectedItem = flow(function* fetchSelectedItem(this: TgGroupsStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);
        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<TgGroupsTypes>(
                [response.data.response],
                "created",
            );
        }
    });
}

const tgGroupsStore = new TgGroupsStore();

export default tgGroupsStore;
