import request from "../../request";

type DataType = {
    key: string,
}

type ResponseType = {
    message: string;
    response: {
        refresh: string;
        access: string;
        key?: {
            key: string;
            tfa_error_counter: string;
            tfa_key: string;
        }
    }
}

export const postTFA = (data: DataType, token: string) => request<ResponseType>({
    useAuthorization: false,
    token,
    method: "POST",
    uri: "auth/tfa/",
    data,
});
