import styled from "styled-components";

import { colors } from "../../styles/variables";

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Title = styled.div`
    color: ${colors.activeText};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
`;
