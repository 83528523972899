import { ChangeStatusResponseType } from "../../types/commonFiltersType";
import request from "../request";

interface RequestType {
    id: number[],
}

const activateTgGroups = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "telegram-groups/activate/",
    data,
});

export default activateTgGroups;
