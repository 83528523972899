import useTranslation from "../../../../../hooks/useTranslation";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";

const PasswordLimitModal = ({
    isOpen,
    setOn,
    title,
    buttonText,
    iconURL,
    handleClose,
}: ModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            title={title}
            buttonText={buttonText}
            iconURL={iconURL}
            handleClose={handleClose}
            modalBodyText={(
                <span>
                    {t.modal.modalBody.unlockAccount}
                </span>
            )}
        />
    );
};

export default PasswordLimitModal;
