import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { FormFieldType } from "../../../../../constants/filterTypes";
import {
    getTranslatedHandbook,
    proxyOptionsHandbook,
    transactionDecisionOptionsHandbook,
    transactionStatusOptionsHandbook,
    transactionSubTypeOptionsHandbook,
    transactionTypeOptionsHandbook,
} from "../../../../../constants/handbooks";
import { FieldMode } from "../../../../../constants/selectFields";
import { validateDateRange } from "../../../../../helpers/validationHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersCountriesStore from "../../../../../store/filters/countriesFiltersStore";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import filtersProcessingServicesStore from "../../../../../store/filters/processingServicesFiltersStore";
import rulesPacksFiltersStore from "../../../../../store/filters/rulesPacksFiltersStore";
import filtersServicesStore from "../../../../../store/filters/servicesFilterStore";
import transactionsStore from "../../../../../store/transaction/transactionStore";
import { TransactionFiltersTypes } from "../../../../../types/transactionTypes";
import {
    ButtonStickyContainer, FormContainer, HeaderContainer, HeaderTitle, ScrollableFormContainer, SubTitle,
} from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import DatePicker from "../../../../ui/datepicker/DatePicker";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatInputWithProperties from "../../../../ui/textInput/FloatInputWithProperties";
import FloatSelect from "../../../../ui/textInput/FloatSelect";

interface TransactionsFiltersProps {
    closeModal: () => void;
}

const TransactionsFilters = ({ closeModal }: TransactionsFiltersProps) => {
    const { t, translate } = useTranslation();

    const decisionOptions = getTranslatedHandbook(translate, transactionDecisionOptionsHandbook);
    const statusOptions = getTranslatedHandbook(translate, transactionStatusOptionsHandbook);
    const typeOptions = getTranslatedHandbook(translate, transactionTypeOptionsHandbook);
    const proxyOptions = getTranslatedHandbook(translate, proxyOptionsHandbook);
    const subTypeOptions = getTranslatedHandbook(translate, transactionSubTypeOptionsHandbook);

    const initialValues: TransactionFiltersTypes = {
        ...transactionsStore.filters,
        id: transactionsStore?.filters?.id ?? null,
    };

    const formik = useFormik<TransactionFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
            updated_to: validateDateRange(translate, "updated_from"),
            finalize_to: validateDateRange(translate, "finalize_from"),
        }),
        initialTouched: {
            created_to: !!transactionsStore.filters?.created_to,
            updated_to: !!transactionsStore.filters?.updated_to,
            finalize_to: !!transactionsStore.filters?.finalize_to,
        },
        onSubmit: (fields) => {
            transactionsStore.updateFiltersWithClear(fields);
            transactionsStore.resetCount();
            closeModal();
        },
    });

    const formFieldsList = [
        { type: FormFieldType.subTitle, content: t.filters.fields.order, key: "transaction-filter-order" },
        {
            type: FormFieldType.floatInput,
            name: "id",
            placeholder: t.table.header.transactionId,
            key: "transaction-filter-id",
        },
        {
            type: FormFieldType.floatSelect,
            name: "type",
            mode: FieldMode.Multiple,
            options: typeOptions,
            placeholder: t.table.header.transactionType,
            key: "transaction-filter-type",
        },
        {
            type: FormFieldType.floatSelect,
            name: "subtype",
            options: subTypeOptions,
            placeholder: t.table.header.transactionSubtype,
            key: "transaction-filter-subtype",
        },
        {
            type: FormFieldType.floatInput,
            name: "order_id",
            placeholder: t.table.header.orderId,
            key: "transaction-filter-order-id",
        },
        {
            type: FormFieldType.floatSelect,
            name: "status",
            mode: FieldMode.Multiple,
            options: statusOptions,
            placeholder: t.table.header.status,
            key: "transaction-filter-status",
        },
        {
            type: FormFieldType.floatInput,
            name: "status_details",
            placeholder: t.table.header.statusDetails,
            key: "transaction-filter-status-details",
        },
        {
            type: FormFieldType.floatInput,
            name: "code",
            placeholder: t.table.header.code,
            key: "transaction-filter-code",
        },
        {
            type: FormFieldType.floatInput,
            name: "gate_id",
            placeholder: t.filters.fields.gateId,
            key: "transaction-filter-gate-id",
        },
        {
            type: FormFieldType.floatInput,
            name: "channel_id",
            placeholder: t.table.header.channelId,
            key: "transaction-filter-channel-id",
        },
        {
            type: FormFieldType.floatInput,
            name: "auth_code",
            placeholder: t.table.header.authCode,
            key: "transaction-filter-auth-code",
        },
        {
            type: FormFieldType.floatInput,
            name: "rrn",
            placeholder: t.table.header.rrn,
            key: "transaction-filter-rrn",
        },
        { type: FormFieldType.subTitle, content: t.filters.fields.customer, key: "transaction-filter-customer" },
        {
            type: FormFieldType.floatInputWithProperties,
            name: "customer_id",
            placeholder: t.table.header.customerId,
            key: "transaction-filter-customer-id",
        },
        {
            type: FormFieldType.floatInputWithProperties,
            name: "email",
            placeholder: t.table.header.email,
            key: "transaction-filter-email",
        },
        {
            type: FormFieldType.floatInputWithProperties,
            name: "ip",
            partialMatch: false,
            placeholder: t.table.header.ipAddresses,
            key: "transaction-filter-ip",
        },
        {
            type: FormFieldType.floatInputWithProperties,
            name: "phone",
            placeholder: t.table.header.phone,
            key: "transaction-filter-phone",
        },
        {
            type: FormFieldType.floatSelect,
            name: "country",
            mode: FieldMode.Multiple,
            options: filtersCountriesStore.options,
            placeholder: t.table.header.country,
            key: "transaction-country",
        },
        {
            type: FormFieldType.floatSelect,
            name: "country_by_card",
            mode: FieldMode.Multiple,
            options: filtersCountriesStore.options,
            placeholder: t.table.header.countryByCard,
            key: "transaction-country-by-card",
        },
        {
            type: FormFieldType.floatSelect,
            name: "country_by_ip",
            mode: FieldMode.Multiple,
            options: filtersCountriesStore.options,
            placeholder: t.table.header.countryByIP,
            key: "transaction-country-by-ip",
        },
        {
            type: FormFieldType.floatInput,
            name: "device_id",
            placeholder: t.table.header.deviceId,
            key: "transaction-filter-device-id",
        },
        {
            type: FormFieldType.floatInput,
            name: "card_mask_hask",
            placeholder: t.table.header.cardMask,
            key: "transaction-filter-card-mask-hask",
        },
        {
            type: FormFieldType.floatInput,
            name: "card_hash",
            placeholder: t.table.header.cardHash,
            key: "transaction-filter-card-hash",
        },
        {
            type: FormFieldType.floatInputWithProperties,
            name: "cardholder",
            isEmpty: false,
            placeholder: t.table.header.cardholder,
            key: "transaction-filter-cardholder",
        },
        { type: FormFieldType.subTitle, content: t.filters.fields.amount, key: "transaction-filter-amount" },
        {
            type: FormFieldType.floatInput,
            name: "amount_from",
            placeholder: t.filters.fields.from,
            key: "transaction-filter-amount-from",
        },
        {
            type: FormFieldType.floatInput,
            name: "amount_to",
            placeholder: t.filters.fields.to,
            key: "transaction-filter-amount-to",
        },
        {
            type: FormFieldType.floatInput,
            name: "currency",
            placeholder: t.table.header.currency,
            key: "transaction-filter-currency",
        },
        {
            type: FormFieldType.subTitle,
            content: t.filters.fields.creationDate,
            key: "transaction-filter-creation-date",
        },
        {
            type: FormFieldType.datePicker,
            name: "created_from",
            maxDate: formik.values.created_to,
            placeholder: t.filters.fields.from,
            key: "transaction-filter-created-from",
        },
        {
            type: FormFieldType.datePicker,
            name: "created_to",
            minDate: formik.values.created_from,
            placeholder: t.filters.fields.to,
            key: "transaction-filter-created-to",
            isAddMilliseconds: true,
        },
        { type: FormFieldType.subTitle, content: t.filters.fields.lastUpdate, key: "transaction-filter-lastUpdate" },
        {
            type: FormFieldType.datePicker,
            name: "updated_from",
            maxDate: formik.values.updated_to,
            placeholder: t.filters.fields.from,
            key: "transaction-filter-updated-from",
        },
        {
            type: FormFieldType.datePicker,
            name: "updated_to",
            minDate: formik.values.updated_from,
            placeholder: t.filters.fields.to,
            key: "transaction-filter-updated-to",
            isAddMilliseconds: true,
        },
        { type: FormFieldType.subTitle, content: t.table.header.finalizeTime, key: "transaction-filter-finalizeTime" },
        {
            type: FormFieldType.datePicker,
            name: "finalize_from",
            maxDate: formik.values.finalize_to,
            placeholder: t.filters.fields.from,
            key: "transaction-filter-finalize-from",
        },
        {
            type: FormFieldType.datePicker,
            name: "finalize_to",
            minDate: formik.values.finalize_from,
            placeholder: t.filters.fields.to,
            key: "transaction-filter-finalize-to",
            isAddMilliseconds: true,
        },
        {
            type: FormFieldType.subTitle,
            content: t.filters.fields.partnersAndServices,
            key: "transaction-filter-partners-and-services",
        },
        {
            type: FormFieldType.floatSelect,
            name: "partner",
            mode: FieldMode.Multiple,
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
            key: "transaction-filter-partner",
        },
        {
            type: FormFieldType.floatSelect,
            name: "service",
            mode: FieldMode.Multiple,
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
            key: "transaction-filter-service",
        },
        {
            type: FormFieldType.floatSelect,
            name: "processing_service_id",
            mode: FieldMode.Multiple,
            options: filtersProcessingServicesStore.options,
            placeholder: t.filters.fields.processingService,
            key: "transaction-filter-processing_service",
        },
        {
            type: FormFieldType.floatSelect,
            name: "processing",
            mode: FieldMode.Multiple,
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
            key: "transaction-filter-processing",
        },
        {
            type: FormFieldType.subTitle,
            content: t.entities.partners.details.rulesPacks,
            key: "transaction-filter-rules-packs",
        },
        {
            type: FormFieldType.floatSelect,
            name: "rules_pack",
            mode: FieldMode.Multiple,
            options: rulesPacksFiltersStore.options,
            placeholder: t.filters.fields.rulesPack,
            key: "transaction-filter-rules-pack",
        },
        {
            type: FormFieldType.floatSelect,
            name: "decision",
            mode: FieldMode.Multiple,
            options: decisionOptions,
            placeholder: t.table.header.decision,
            key: "transaction-filter-decision",
        },
        {
            type: FormFieldType.floatInput,
            name: "marker",
            placeholder: t.table.header.marker,
            key: "transaction-filter-marker",
        },
        {
            type: FormFieldType.subTitle,
            content: t.table.header.technicalInfo,
            key: "transaction-filter-technical-info",
        },
        {
            type: FormFieldType.floatSelect,
            name: "is_proxy",
            options: proxyOptions,
            placeholder: t.filters.fields.isProxy,
            key: "transaction-filter-is-proxy",
        },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        transactionsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.transactions.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit} $scrollable>
                <ScrollableFormContainer>
                    {formFieldsList.map((field) => {
                        const {
                            type,
                            key,
                            content,
                            name,
                            placeholder,
                            options,
                            mode,
                            maxDate,
                            minDate,
                            partialMatch,
                            isEmpty,
                            isAddMilliseconds,
                        } = field;

                        switch (type) {
                            case FormFieldType.subTitle:
                                return <SubTitle key={key}>{content}</SubTitle>;
                            case FormFieldType.floatInput:
                                return (
                                    <FloatInput
                                        key={key}
                                        formik={formik}
                                        name={name}
                                        placeholder={placeholder}
                                    />
                                );
                            case FormFieldType.floatInputWithProperties:
                                return (
                                    <FloatInputWithProperties
                                        key={key}
                                        formik={formik}
                                        name={name}
                                        placeholder={placeholder}
                                        partialMatch={partialMatch}
                                        isEmpty={isEmpty}
                                    />
                                );
                            case FormFieldType.floatSelect:
                                return (
                                    <FloatSelect
                                        key={key}
                                        formik={formik}
                                        name={name}
                                        mode={mode}
                                        options={options}
                                        placeholder={placeholder}
                                    />
                                );
                            case FormFieldType.datePicker:
                                return (
                                    <DatePicker
                                        key={key}
                                        formik={formik}
                                        name={name}
                                        maxDate={maxDate}
                                        minDate={minDate}
                                        placeholder={placeholder}
                                        isAddMilliseconds={isAddMilliseconds}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                </ScrollableFormContainer>

                <ButtonStickyContainer>
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={transactionsStore.loading}
                    >
                        {t.filters.apply}
                    </Button>
                </ButtonStickyContainer>
            </FormContainer>
        </>
    );
};

export default observer(TransactionsFilters);
