import styled from "styled-components";
import { Pagination as AntDPagination } from "antd";
import { colors } from "../../../styles/variables";
import Select from "../select/Select";

export const ArrowComponent = styled.button`
    display: flex;
    height: 32px;
    border-radius: 8px;
    background-color: ${colors.buttonDefaultBackground};
    border-color: ${colors.buttonDefaultBackground};
    outline: none;
    align-items: center;
    justify-content: center;
`;

export const AntDPaginationComponent = styled(AntDPagination)<{ $isLazyCount?: boolean }>`
    font-family: inherit;
    color: ${colors.activeText};

    && .ant-pagination-simple-pager {
        height: 100%;

        ${(props) => {
        if (props.$isLazyCount) {
            return `
                   &::after {
                        content: '+'
                    }
            `;
        }
        return "";
    }}
        .ant-pagination-slash {
            margin-inline-end: 3px;
        }

        input {
            color: ${colors.textPrimary};
            width: 56px;
            align-items: center;
            margin-inline-end: 0px;
            padding: 8px 16px;
            text-align: left;
            border-radius: 8px;
            border: 1px solid;
            font-size: 14px;
            font-family: Roboto;
            border-color: ${colors.inputDefaultBorder}
        }
    }

    && .ant-pagination-next,
    && .ant-pagination-prev {
        height: 100%;
    }

    && button {
        border: 1px solid ${colors.buttonDefaultBackground};

        &:hover {
            background-color: ${colors.buttonSecondaryHover};
        }
    }

    && .ant-pagination-disabled button {
        cursor: not-allowed;
        background-color: ${colors.buttonDisabledBackground};
        border-color: ${colors.buttonDisabledBackground};

        svg {
            stroke: ${colors.textDisabled};
        }
    }

    && .ant-select-selector {
        color: ${colors.textPrimary};
    }
`;

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    font: 500 14px Roboto, sans-serif;
    height: max-content;
`;

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    color: ${colors.activeText};
    input {
        font: inherit;
    }
`;

export const PaginationSelect = styled(Select)`
    display: flex;
    height: fit-content;
    width: fit-content;
    min-width: 73px;

    &&.ant-select .ant-select-selector {
        border-radius: 8px;
        padding: 0 10px;
        color: ${colors.textPrimary};

        .ant-select-selection-item {
            font: 500 14px Roboto, sans-serif;
        }

        .ant-select-selection-search-input {
            padding: 0;
        }
    }

    .ant-select-arrow {
        inset-inline-start: calc(100% - 25px);
        inset-inline-end: 10px;
    }
`;
