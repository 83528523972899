import { observer } from "mobx-react-lite";
import {
    memo,
} from "react";
import { useParams } from "react-router-dom";

import { useTabsActions } from "./hooks/useTabsActions";
import { Dashboard } from "../../../../../../ui/details/DetailsComponents";
import withPermission from "../../../../../../../hocs/withPermissions";

interface ProtectedRulesDetailsTableProps {
    permissions: string[];
}

const WrappedComponent = observer(() => {
    const { id: partnerId } = useParams();

    const {
        activeTab,
        TabsLayout,
    } = useTabsActions(partnerId || "");

    return (
        <>
            <Dashboard>{TabsLayout}</Dashboard>
            {activeTab}
        </>
    );
});

const ProtectedPartnerDetailsTable = ({ permissions }: ProtectedRulesDetailsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permissions);
    return <ComponentWithPermission />;
};

export default memo(ProtectedPartnerDetailsTable);
