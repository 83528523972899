import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { TR } from "../../TableComponents";
import { DataType, RowProps } from "../../types";
import TableCell from "../TableCell/TableCell";

const DraggableRow = <D extends DataType>({
    item, checkedItems, columns, onCheckboxChange, columnWidths, isItemSelectionAllowed,
}: RowProps<D>) => {
    const { id } = item;

    const {
        transform,
        transition,
        setNodeRef,
        isDragging,
        attributes,
        listeners,
    } = useSortable({
        id,
    });

    const style: CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
        cursor: isDragging ? "grabbing" : "grab",
        position: "relative",
    };

    return (
        <TR
            ref={setNodeRef}
            style={style}
            $selected={checkedItems?.includes(+id)}
            {...listeners}
            {...attributes}
        >
            {columns?.map((column) => (
                <TableCell
                    key={column.field}
                    item={item}
                    column={column}
                    width={columnWidths[column.field]}
                    onCheckboxChange={onCheckboxChange}
                    checkedItems={checkedItems}
                    isItemSelectionAllowed={isItemSelectionAllowed}
                />
            ))}
        </TR>
    );
};

export default DraggableRow;
