import { ElementsTypes, FiltersElementsType } from "../../types/elementsTypes";
import request from "../request";

type GetElementsRequest = Omit<FiltersElementsType, "list"> & {
    limit?: number;
    offset?: number;
    ordering?: string;
    list?: string | number | null
}

interface GetElementsResponse {
    message: string;
    response: {
        count: number;
        results: ElementsTypes[];
    }
}

export const getElements = (params?: GetElementsRequest) => request<GetElementsResponse>({
    method: "GET",
    uri: "elements/",
    params,
});
