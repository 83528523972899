import { flow } from "mobx";

import { getPartners } from "../../api/partners/filters/getPartners";
import { PartnerTypes } from "../../types/partnerTypes";
import FiltersDataStore from "../common/filtersStore";

class FiltersPartnersStore extends FiltersDataStore<PartnerTypes> {
    protected fetchListMethod = getPartners;

    protected fetchDataByIdMethod = getPartners;

    fetchById = flow(function* fetchData(this: FiltersPartnersStore, id: number, isLinked?: boolean) {
        yield this.fetchDataById({ rules_pack: id, is_linked: isLinked });
    });

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersPartnersStore = new FiltersPartnersStore();

export default filtersPartnersStore;
