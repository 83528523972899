import { observer } from "mobx-react-lite";
import { FunctionComponent, SVGProps } from "react";

import { permissionsMap } from "../../constants/permissions";
import Routes, { IRoute } from "../../constants/routes";
import useTranslation from "../../hooks/useTranslation";
import { ReactComponent as HomeIcon } from "../../resources/icons/homeIcon.svg";
import { ReactComponent as AlertsIcon } from "../../resources/icons/menu/alerts.svg";
import { ReactComponent as ElementsIcon } from "../../resources/icons/menu/elements.svg";
import { ReactComponent as EmployeesIcon } from "../../resources/icons/menu/employees.svg";
import { ReactComponent as EntitiesIcon } from "../../resources/icons/menu/entities.svg";
import { ReactComponent as LimitsIcon } from "../../resources/icons/menu/limits.svg";
import { ReactComponent as ListsIcon } from "../../resources/icons/menu/lists.svg";
import { ReactComponent as RulesPacksIcon } from "../../resources/icons/menu/rules-packs.svg";
import { ReactComponent as SettingsIcon } from "../../resources/icons/menu/settings.svg";
import { ReactComponent as TransactionIcon } from "../../resources/icons/menu/transaction.svg";
import LogoutButton from "./LogoutButton";
import {
    MenuContainer,
    Logo,
    MenuItemSectionContainer,
    MenuItemsListContainer,
    MenuItemsBottomContainer,
    MenuItem,
    MenuItemTitle,
} from "./MenuComponents";
import MenuItemComponent from "./MenuItemComponent";
import ProtectedMenuItem from "./ProtectedMenuItem";

export interface MenuItemTypes {
    key: string;
    route: IRoute;
    title?: string;
    isItemShown?: boolean;
    permissionsList?: string | string[];
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

const menuItemsList: Array<MenuItemTypes> = [
    {
        key: "menu-item-home-route",
        route: Routes.home,
        Icon: HomeIcon,
        isItemShown: true,
    },
    {
        key: "menu-item-lists-route",
        route: Routes.lists,
        Icon: ListsIcon,
        permissionsList: permissionsMap.lists.view,
    },
    {
        key: "menu-item-limit-route",
        route: Routes.limits,
        Icon: LimitsIcon,
        permissionsList: permissionsMap.limits.view,
    },
    {
        key: "menu-item-elements-route",
        route: Routes.elements,
        Icon: ElementsIcon,
        permissionsList: permissionsMap.elements.view,
    },
    {
        key: "menu-item-entities-route",
        route: Routes.entities,
        Icon: EntitiesIcon,
        permissionsList: [permissionsMap.partners.view, permissionsMap.services.view, permissionsMap.processings.view],
    },
    {
        key: "menu-item-transactions-route",
        route: Routes.transactions,
        Icon: TransactionIcon,
        permissionsList: permissionsMap.transactions.view,
    },
    {
        key: "menu-item-rules-packs-route",
        route: Routes.rulesPacks,
        Icon: RulesPacksIcon,
        permissionsList: permissionsMap.rules_packs.view,
    },
    {
        key: "menu-item-alerts-route",
        route: Routes.alerts,
        Icon: AlertsIcon,
        permissionsList: [permissionsMap.alerts.view, permissionsMap.notifications.view, permissionsMap.tg_groups.view],
    },
];

const Menu = () => {
    const { translate } = useTranslation();

    return (
        <MenuContainer>
            <Logo />
            <MenuItemSectionContainer>
                <MenuItemsListContainer>
                    {menuItemsList.map((menuItem) => (
                        <MenuItemComponent {...menuItem} />
                    ))}
                </MenuItemsListContainer>

                <MenuItemsBottomContainer>
                    <ProtectedMenuItem
                        title={translate(Routes.employees.t)}
                        route={Routes.employees.href}
                        permission={[permissionsMap.employees.view, permissionsMap.roles.view]}
                        Icon={EmployeesIcon}
                    />
                    <MenuItem to={Routes.settings.href}>
                        <SettingsIcon />
                        <MenuItemTitle>{translate(Routes.settings.t)}</MenuItemTitle>
                    </MenuItem>
                    <LogoutButton />
                </MenuItemsBottomContainer>
            </MenuItemSectionContainer>
        </MenuContainer>
    );
};

export default observer(Menu);
