import { ReactNode } from "react";
import { Popover, PopoverProps } from "antd";
import { overlayInnerStyle, overlayStyle, PopoverMenuContainer } from "./CustomPopoverComponents";

interface CustomPopoverProps extends PopoverProps {
    items: ReactNode[]
    children: ReactNode
}

const CustomPopover = ({ items, children, ...props }: CustomPopoverProps) => (
    <Popover
        content={<PopoverMenuContainer>{items.map((item) => item)}</PopoverMenuContainer>}
        trigger="click"
        placement="bottom"
        overlayStyle={overlayStyle}
        overlayInnerStyle={overlayInnerStyle}
        arrow={false}
        {...props}
    >
        {children}
    </Popover>
);

export default CustomPopover;
