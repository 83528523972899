import { NewTgGroupTypes, TgGroupsTypes } from "../../types/tgGroupsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: TgGroupsTypes,
}

export const postTgGroup = (data: NewTgGroupTypes) => request<ResponseType>({
    method: "POST",
    uri: "telegram-groups/",
    data,
});
