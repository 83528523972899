import { useBlocker } from "react-router-dom";
import { useEffect, useState } from "react";
import { Icon } from "../components/ui/new/NewComponents";
import BlockModal from "../components/common/modal/warning/blockModal/BlockModal";

export function usePreventRedirect(dirty: boolean, isPostRequestSuccess: boolean) {
    const [isBlockModalVisible, setBlockModalVisibility] = useState(false);
    const toggleBlockModal = () => setBlockModalVisibility((prev) => !prev);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => dirty && !isPostRequestSuccess
            && currentLocation.pathname !== nextLocation.pathname,
    );

    const handleNavigateProceed = () => {
        blocker.proceed?.();
    };

    useEffect(() => {
        if (blocker.state === "blocked") {
            toggleBlockModal();
        }
    }, [blocker]);

    const RedirectBlockModalComponent = (
        <BlockModal
            isOpen={isBlockModalVisible}
            title={<Icon />}
            submitButton={false}
            setOn={toggleBlockModal}
            toggleModal={toggleBlockModal}
            handleNavigate={handleNavigateProceed}
        />
    );

    return { RedirectBlockModalComponent };
}
