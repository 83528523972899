import dayjs from "dayjs";

import { DATE_INPUT_FORMAT } from "../constants/dates";

function fromStringWithCommasToArray(string: string): string[] {
    const stringArray = string.trim().split(",");
    if (stringArray.length === 1 && stringArray[0] === "") return [];
    return stringArray.map((word) => word.replace(" ", ""));
}

function parseDate(date?: string) {
    const parsedDate = dayjs(date);
    if (parsedDate.isValid()) {
        return parsedDate.format(DATE_INPUT_FORMAT) as any;
    }
    return undefined;
}

function parseDateFromServer<T extends Record<string, any>>(data: T[], ...keys: (keyof T)[]) {
    return data.map((dataObject) => {
        const clonedObject = { ...dataObject };
        keys.forEach((key) => {
            clonedObject[key] = parseDate(clonedObject[key]);
        });
        return clonedObject;
    });
}

function capitalizeFirstLetter(text?: string): string {
    if (text && typeof text === "string") {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return "";
}

function objectToValuesList<T>(obj: Record<string, T>): T[] {
    return Object.values(obj)
        .filter((value) => ![undefined, null, ""]
            .includes(value as string) && (value as string).length !== 0);
}

function isEmptyArray<T>(entities: T): boolean {
    return Array.isArray(entities) && !entities.length;
}

function extractValues<T extends Record<string, any>>(obj: T, inputList?: (keyof T)[]): T[keyof T][] {
    const outputList: T[keyof T][] = [];

    if (inputList) {
        inputList.forEach((field) => {
            if (field in obj) {
                outputList.push(obj[field]);
            }
        });
    }

    return outputList;
}

function selectObjectProperties<T extends Record<string, any>>(obj: T, itemsList: Array<keyof T>): Partial<T> {
    const outputObj = {} as T;

    itemsList.forEach((item) => {
        if (item in obj) outputObj[item] = obj[item];
    });

    return outputObj;
}

export {
    fromStringWithCommasToArray,
    parseDate,
    parseDateFromServer,
    capitalizeFirstLetter,
    objectToValuesList,
    isEmptyArray,
    extractValues,
    selectObjectProperties,
};
