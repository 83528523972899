import FiltersDataStore from "../common/filtersStore";
import { RulesPacksTypes } from "../../types/rulesPacksTypes";
import getRulesPacks from "../../api/rulesPacks/filters/getRulesPacks";

class RulesPacksFiltersStore extends FiltersDataStore<RulesPacksTypes> {
    protected fetchListMethod = getRulesPacks;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const rulesPacksFiltersStore = new RulesPacksFiltersStore();

export default rulesPacksFiltersStore;
