import { makeAutoObservable } from "mobx";
import { SnackbarMessageType } from "../../types/snackbarTypes";

const removeTimeout = 5000;
const animateTimeout = 300;

class SnackbarStore {
    private data: SnackbarMessageType[] = [];

    private id = 0;

    constructor() {
        makeAutoObservable(this);
    }

    private _setActive = (id: number, active: boolean) => {
        const item = this.data.find((message) => message.id === id);
        if (!item) {
            return;
        }

        item.active = active;
    };

    private _add = (message: SnackbarMessageType) => {
        this.data.unshift(message);
    };

    private _remove = (id: number) => {
        this.data = this.data.filter((message) => message.id !== id);
    };

    public add = (message: Omit<SnackbarMessageType, "id">) => {
        this.id += 1;
        const newMessage: SnackbarMessageType = {
            ...message,
            id: this.id,
        };
        this._add(newMessage);
        setTimeout(() => this._setActive(newMessage.id, true));
        setTimeout(() => this.remove(newMessage.id), removeTimeout);
    };

    public remove = (id: number) => {
        this._setActive(id, false);
        setTimeout(() => this._remove(id), animateTimeout);
    };

    public get all() {
        return this.data;
    }
}

const snackbarStore = new SnackbarStore();

export default snackbarStore;
