import { flow } from "mobx";
import { getSelectedList } from "../../api/lists/getSelectedList";
import postCloneList from "../../api/lists/postCloneList";
import dataStore from "../common/dataStore";
import { ListsTypes, NewListType } from "../../types/listsTypes";
import { getLists } from "../../api/lists/getLists";
import deactivateLists from "../../api/lists/deactivateLists";
import patchList from "../../api/lists/patchList";
import { parseDateFromServer } from "../../helpers";
import activateLists from "../../api/lists/activateLists";

class RulesPackListsStore extends dataStore<ListsTypes, any, NewListType> {
    protected patchDataMethod = patchList;

    protected deactivateSelectedItemsMethod = deactivateLists;

    protected activateSelectedItemsMethod = activateLists;

    protected fetchDataByIdMethod = getLists;

    protected fetchSelectedItemMethod = getSelectedList;

    protected cloneDataMethod = postCloneList;

    fetchData = flow(function* fetchData(this: RulesPackListsStore, id: number | string) {
        const response = yield this.fetchDataByIdWithoutSet({ rules_pack: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<ListsTypes>(
                response.data.response.results,
                "created",
                "last_update",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const rulesPackListsStore = new RulesPackListsStore();

export default rulesPackListsStore;
