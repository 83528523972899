import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
    categoryOptionsHandbook,
    getTranslatedHandbook,
    groupOptionsHandbook,
    typeOptionsHandbook,
} from "../../../../constants/handbooks";
import Routes from "../../../../constants/routes";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import { filterEmptyFields } from "../../../../helpers/filterEmptyFields";
import { usePreventRedirect } from "../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../hooks/useTranslation";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import limitStore from "../../../../store/limit/limitStore";
import listStore from "../../../../store/list/listStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { NewListType } from "../../../../types/listsTypes";
import SecondHeader from "../../../secondHeader/SecondHeader";
import Button from "../../../ui/button/Button";
import SmallButton from "../../../ui/button/SmallButton";
import {
    Box, Container, Row, SubmitButtonWrapper, ThreeColumnForm, Title,
} from "../../../ui/new/NewComponents";
import Switch from "../../../ui/switch/Switch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof NewListType> = [
    "name", "rules_pack", "description", "is_active", "category", "type", "is_global",
];

const initialValues: NewListType = {
    name: "",
    description: "",
    is_active: true,
    rules_pack: null,
    category: null,
    type: null,
    is_global: null,
};

const NewList = () => {
    const { t, translate } = useTranslation();
    const navigation = useNavigate();

    const typeOptions = getTranslatedHandbook(translate, typeOptionsHandbook);
    const categoryOptions = getTranslatedHandbook(translate, categoryOptionsHandbook);
    const groupOptions = getTranslatedHandbook(translate, groupOptionsHandbook);
    const otherCategory = categoryOptionsHandbook.at(2)?.value;

    const handleNavigateToPrevPage = () => navigation(Routes.lists.href);

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        category: Yup.string().required(t.validation.required),
        type: Yup.string()
            .when("category", ([category], schema) => ((
                category === otherCategory
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),
        is_global: Yup.boolean()
            .when("category", ([category], schema) => ((
                category === otherCategory
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),
        rules_pack: Yup.number()
            .when(["is_global", "category"], ([is_global, category], schema) => (
                (
                    is_global || (category === otherCategory) || is_global === null
                )
                    ? schema.nullable()
                    : schema.required(t.validation.required))),
    });

    const formik = useFormik<NewListType>({
        initialValues,
        validationSchema,
        onSubmit: (fields) => {
            const filteredData = filterEmptyFields(fields, "is_active", "is_global");
            listStore.postData(
                filteredData,
                fieldsList,
            ).then(() => {
                if (listStore.isPostRequestSuccess) {
                    handleNavigateToPrevPage();
                    listStore.resetPostItemState();
                    snackbarStore.add({ variant: "success", text: t.lists.snackbar.addSuccess });
                }
            });
        },
    });

    const isOtherCategorySelected = formik.values.category === otherCategory;
    const isRulesPackSelectDisabled = formik.values.is_global || formik.values.is_global === null
        || isOtherCategorySelected;

    const toggleStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const handleListGroupChange = () => {
        if (!formik.values.is_global) formik.setFieldValue("rules_pack", null);
    };

    const handleCategoryChange = (newFormikValue: unknown) => {
        if (newFormikValue === otherCategory) {
            formik.setValues({
                ...formik.values,
                type: null,
                rules_pack: null,
                is_global: true,
                category: otherCategory,
            });
        }
    };

    const actionHeaderContentList = [
        {
            key: "cancel_button",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, listStore.isPostRequestSuccess);

    useEffect(() => {
        rulesPacksFiltersStore.fetch();

        return () => {
            listStore.resetPostErrors();
        };
    }, []);

    return (
        <>
            <SecondHeader title={t.lists.new.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.lists.text.title}</Title>
                    <Row>
                        <Title>{t.status.title}</Title>
                        <StatusBadge
                            variant={formik.values.is_active ? "success" : "inactive"}
                            multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                        />
                        <Switch
                            checked={formik.values.is_active}
                            disabled={listStore.loading}
                            onChange={toggleStatus}
                        />
                    </Row>
                </Row>

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <Box>
                        <FloatInput
                            formik={formik}
                            disabled={listStore.loading}
                            name="name"
                            placeholder={t.lists.placeholders.name}
                            isRequired
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            disabled={listStore.loading}
                            placeholder={t.lists.placeholders.category}
                            isRequired
                            name="category"
                            options={categoryOptions}
                            onChange={handleCategoryChange}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            disabled={isOtherCategorySelected || listStore.loading}
                            placeholder={t.lists.placeholders.type}
                            isRequired={!isOtherCategorySelected}
                            name="type"
                            options={typeOptions}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            disabled={isOtherCategorySelected || listStore.loading}
                            placeholder={t.lists.placeholders.group}
                            isRequired={!isOtherCategorySelected}
                            name="is_global"
                            onChange={handleListGroupChange}
                            options={groupOptions}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatSelect
                            formik={formik}
                            disabled={!!isRulesPackSelectDisabled || listStore.loading}
                            isRequired={!isRulesPackSelectDisabled}
                            placeholder={t.lists.placeholders.rulesPack}
                            name="rules_pack"
                            options={rulesPacksFiltersStore.options}
                            serverErrors={limitStore.postErrors}
                            resetServerError={limitStore.resetPostError}
                        />
                    </Box>
                    <Box>
                        <FloatTextArea
                            formik={formik}
                            disabled={listStore.loading}
                            name="description"
                            placeholder={t.placeholder.description}
                            serverErrors={listStore.postErrors}
                            resetServerError={listStore.resetPostError}
                            autoSize
                            maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        />
                    </Box>
                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={listStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewList);
