import { UserType } from "../../types/userType";
import request from "../request";

interface GetUserProfileResponse {
    message: string;
    response: UserType;
}

export const getUserProfile = (token?: string) => request<GetUserProfileResponse>({
    useAuthorization: !token,
    ...(token && {
        headers: {
            "Authorization": `Bearer ${token}`,
        },
    }),
    method: "GET",
    uri: "users/profile/",
});
