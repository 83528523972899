import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const DetailsPageWrapper = styled.div`
    display: flex;
    grid-area: content;
    flex-direction: column;
    gap: 20px;
    width: auto;
`;

const BaseDetailsPageItem = styled.div`
    border-radius: 12px;
    background: ${colors.white};
`;

export const DetailsSectionWrapper = styled(BaseDetailsPageItem)`
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: auto;
`;

export const DetailsHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const DetailsHeaderWithButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const DetailsBodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 20px 20px;
    align-self: stretch;
`;

export const Box = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
`;

export const ListsButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 520px
`;

export const Dashboard = styled(BaseDetailsPageItem)`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    padding: 15px 20px;
`;

export const SwitchButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
`;
