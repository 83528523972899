import { permissionsMap } from "../../../../../../constants/permissions";
import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper, Row } from "../../../../../ui/details/DetailsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import ProtectedOperationButtons from "../operationButtons/ProtectedOperationButtons";
import transactionStore from "../../../../../../store/transaction/transactionStore";
import HyperlinkFilterTextInput from "../../../../../ui/textInput/HyperlinkFilterTextInput";
import routes from "../../../../../../constants/routes";

const DeviceInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const preInputsList = [
        {
            key: "device-info-field-screen-resolution",
            label: t.transactions.placeholders.screenResolution,
            value: transaction?.screenResolution,
        },
        {
            key: "device-info-field-language",
            label: t.transactions.placeholders.language,
            value: transaction?.browserLanguage,
        },
        {
            key: "device-info-field-user-agent",
            label: t.transactions.placeholders.userAgent,
            value: transaction?.userAgent,
        },
    ];

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.deviceTitle}
            </DetailPageHeader>

            <DetailsBodyWrapper>
                {preInputsList.map((input) => <PreInput {...input} />)}
            </DetailsBodyWrapper>

            <Row>
                <HyperlinkFilterTextInput
                    navigateTo={routes.transactions.href}
                    store={transactionStore}
                    filterName="device_id"
                    label={t.handbooks.deviceId}
                    hyperlinkName={transaction?.device_id}
                />
                <ProtectedOperationButtons
                    label={t.handbooks.deviceId}
                    value={transaction?.device_id}
                    permission={permissionsMap.elements.add}
                    fieldType="Device ID"
                    listInfo={transaction?.device_id_list_info}
                    transaction={transaction}
                />
            </Row>
        </DetailsSectionWrapper>
    );
};

export default DeviceInfo;
