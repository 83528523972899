import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AuthRoutes } from "../../../../../constants/routes";
import useTranslation from "../../../../../hooks/useTranslation";
import authStore from "../../../../../store/auth/authStore";
import ErrorLabel from "../../../../ui/errors/ValidationErrorBox/ErrorLabel";
import PasswordInput from "../../../../ui/passwordInput/PasswordInput";
import { InputSection } from "../../../../ui/textInput/InputStyles";
import TextField from "../../../../ui/textInput/TextInput";
import { HeaderText, TextLink } from "../../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../../CommonPagesStyles";
import { FormWrapper, LogInFooter } from "../../AuthStyles";
import PasswordLimitModal from "./PasswordLimitModal";
import blockedPerson from "../../../../../resources/icons/blockedPerson.svg";
import Button from "../../../../ui/button/Button";
import { accountIsBlockedError } from "../../../../../constants/errors";
import { Box, Title as ModalTitle } from "../../../../common/modal/ModalComponents";

type FieldsTypes = {
    email: string,
    password: string,
}
const PagesLogin: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isPasswordLimitModalVisible, setPasswordLimitModalVisibility] = useState<boolean>(false);

    const errors = authStore.authErrors;
    const commonErrors = errors?.common;
    let isAccountBlocked = authStore.authErrors?.common === accountIsBlockedError;

    const navigateToPasswordRecovery = () => {
        authStore.unsetState();
        navigate(AuthRoutes.passwordReset.href);
    };

    const formik = useFormik<FieldsTypes>({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t.validation.required),
            password: Yup.string().required(t.validation.required),
        }),
        onSubmit: (fields) => {
            const { email, password } = fields;
            authStore.authorizeUser({ email, password }).then(() => {
                if (!authStore.authErrors) {
                    navigate(authStore.isFirstLogin
                        ? AuthRoutes.firstLoginSetUpTFA.href : AuthRoutes.loginTFA.href);
                }
                isAccountBlocked = authStore.authErrors?.common === accountIsBlockedError;

                if (isAccountBlocked) {
                    setPasswordLimitModalVisibility(true);
                }
            });
        },
        onReset: () => {
            authStore.unsetState();
            formik.setErrors({});
        },
    });

    const handleCloseModal = () => {
        setPasswordLimitModalVisibility(false);
        formik.resetForm();
    };

    return (
        <RightSidePageWrapper>

            <HeaderText>{t.login.text.mustLogin}</HeaderText>

            <FormWrapper onSubmit={formik.handleSubmit}>
                <InputSection>
                    <TextField
                        formik={formik}
                        name="email"
                        placeholder={t.login.inputs.username}
                        resetServerError={authStore.unsetState}
                        serverErrors={errors}
                        isCommonError={commonErrors}
                        showFiledError={false}
                    />
                    <PasswordInput
                        formik={formik}
                        name="password"
                        placeholder={t.login.inputs.password}
                        resetServerError={authStore.unsetState}
                        serverErrors={errors}
                        isCommonError={commonErrors}
                    />
                    {isAccountBlocked ? ErrorLabel(t.login.errorMessage)
                        : (authStore.isAuthError && ErrorLabel(t.login.errorMessageCommon))}
                </InputSection>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty) || authStore.isAuthError}
                >
                    {t.login.submit}
                </Button>
                <LogInFooter>
                    <div>
                        {t.login.text.forgotPassword}
                        &nbsp;
                        <TextLink onClick={navigateToPasswordRecovery}>
                            {t.login.text.recoverPassword}
                        </TextLink>
                    </div>
                </LogInFooter>
            </FormWrapper>

            <PasswordLimitModal
                isOpen={isPasswordLimitModalVisible}
                setOn={setPasswordLimitModalVisibility}
                title={(
                    <Box>
                        <ModalTitle>{t.modal.modalTitle.blockedAccount}</ModalTitle>
                    </Box>
                )}
                iconURL={blockedPerson}
                buttonText={t.login.close}
                navigateTo={AuthRoutes.login.href}
                handleClose={handleCloseModal}
            />

        </RightSidePageWrapper>
    );
};

export default observer(PagesLogin);
