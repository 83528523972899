import styled from "styled-components";
import { colors } from "../../../../styles/variables";
import Link from "../../link/Link";

export const TableDetailsLinkComponent = styled(Link)`
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${colors.buttonDefaultBackground};
`;
