import {
    ReactNode, SetStateAction, useEffect, useRef, useState,
} from "react";

import { Bar, BarContainer, TableHeaderContainer } from "../../TableComponents";

interface EventType {
    clientX: SetStateAction<number>;
    preventDefault(): void;
}

interface TableHeaderProps {
    width?: number | null;
    children: ReactNode;
    onResize: (width: number) => void;
}

const ResizableTableHeader = ({ width, children, onResize }: TableHeaderProps) => {
    const headerRef = useRef<HTMLDivElement | null>(null);
    const initialWidth = useRef(width).current;

    const [isResizing, setResizing] = useState(false);
    const [startX, setStartX] = useState(0);

    const onMouseDown = (event: EventType) => {
        setResizing(true);
        setStartX(event.clientX);
    };

    const onMouseMove = (event: EventType) => {
        if (isResizing && headerRef.current && width && initialWidth) {
            const calculatedWidth = width + (Number(event.clientX) - startX);
            const newWidth = initialWidth <= calculatedWidth ? calculatedWidth : initialWidth;

            onResize(newWidth);
            setStartX(event.clientX);
        }
    };

    const onMouseUp = () => setResizing(false);

    const onDragStart = (event: EventType) => event.preventDefault();

    useEffect(() => {
        if (isResizing) {
            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        }

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, [isResizing]);

    return (
        <TableHeaderContainer ref={headerRef} $width={width}>
            {children}
            {width && (
                <BarContainer onMouseDown={onMouseDown} onDragStart={onDragStart}>
                    <Bar />
                </BarContainer>
            )}
        </TableHeaderContainer>
    );
};

export default ResizableTableHeader;
