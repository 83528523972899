import { AxiosError } from "axios";
import {
    computed, flow, makeObservable, observable,
} from "mobx";

import { activateElements } from "../../api/elements/activateElements";
import { deactivateElements } from "../../api/elements/deactivateElements";
import { getElements } from "../../api/elements/getElements";
import { generateListsElementsFile } from "../../api/lists/generateListsElementsFile";
import { GetFileResponseType, getListsElementsFile } from "../../api/lists/getListsElementsFile";
import { GetFileIdResponse, getListsElementsFileId } from "../../api/lists/getListsElementsFileId";
import { getUploadFileId, UploadFileResponse } from "../../api/lists/getUploadFileId";
import { parseDateFromServer } from "../../helpers";
import { downloadFile } from "../../helpers/downloadFile";
import { ElementsTypes, NewElementType } from "../../types/elementsTypes";
import { FetchStatusesType } from "../../types/fetchStatuses";
import dataStore from "../common/dataStore";

class ListElementsStore extends dataStore<ElementsTypes, any, NewElementType> {
    protected deactivateSelectedItemsMethod = deactivateElements;

    protected activateSelectedItemsMethod = activateElements;

    protected fetchDataByIdMethod = getElements;

    @observable private downloadFileState: FetchStatusesType = FetchStatusesType.unset;

    @observable private uploadFileState: FetchStatusesType = FetchStatusesType.unset;

    constructor() {
        super("ListElementsStore");
        makeObservable(this);
    }

    @computed
    public get isFileDownloading() {
        return this.downloadFileState === FetchStatusesType.pending;
    }

    @computed
    public get isDownloadFileSuccess() {
        return this.downloadFileState === FetchStatusesType.success;
    }

    @computed
    public get isDownloadFileFailed() {
        return this.downloadFileState === FetchStatusesType.failed;
    }

    @computed
    public get isFileUploading() {
        return this.uploadFileState === FetchStatusesType.pending;
    }

    @computed
    public get isUploadFileSuccess() {
        return this.uploadFileState === FetchStatusesType.success;
    }

    @computed
    public get isUploadFileFailed() {
        return this.uploadFileState === FetchStatusesType.failed;
    }

    fetchData = flow(function* fetchData(this: ListElementsStore, id: number | string) {
        const response = yield this.fetchDataByIdWithoutSet({ list: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<ElementsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
        }
    });

    downloadData = flow(function* downloadData(this: ListElementsStore, id: string) {
        try {
            this.downloadFileState = FetchStatusesType.pending;

            const fileIdResponse: GetFileIdResponse = yield getListsElementsFileId({
                list_id: id, element_ids: this.checkedItems,
            });

            yield generateListsElementsFile(fileIdResponse.data.response.task_id);

            const getFileResponse: GetFileResponseType = yield getListsElementsFile(
                fileIdResponse.data.response.task_id,
            );

            downloadFile(getFileResponse.data, `list_${id}_elements_${new Date().getTime()}.xlsx`);

            this.downloadFileState = FetchStatusesType.success;
        } catch (error) {
            this.downloadFileState = FetchStatusesType.failed;
        }
    });

    uploadData = flow(function* uploadData(this: ListElementsStore, file: FormData) {
        try {
            this.uploadFileState = FetchStatusesType.pending;

            const fileIdResponse: UploadFileResponse = yield getUploadFileId(file);

            yield generateListsElementsFile(fileIdResponse.data.response.task_id);

            this.uploadFileState = FetchStatusesType.success;
        } catch (error) {
            const axiosError = error as AxiosError;
            this._postErrors = axiosError.message;
            this.uploadFileState = FetchStatusesType.failed;
        }
    });
}

const listElementsStore = new ListElementsStore();

export default listElementsStore;
