import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { capitalizeFirstLetter } from "../../../helpers";
import transactionsStore from "../../../store/transaction/transactionStore";
import { TransactionTypes } from "../../../types/transactionTypes";
import Table from "../../ui/table/Table";
import { tableContainerStyle } from "../../ui/table/TableComponents";
import { ColumnType } from "../../ui/table/types";
import ColoredDecision from "../../ui/tableFields/coloredCell/ColoredDecision";
import ColoredProxy from "../../ui/tableFields/coloredCell/ColoredProxy";
import ColoredRisk from "../../ui/tableFields/coloredCell/ColoredRisk";
import ColoredStatus from "../../ui/tableFields/coloredCell/ColoredStatus";
import StatusCircle from "../../ui/tableFields/statusCircle/StatusCircle";
import DetailsLinkCell from "./table/DetailsLinkCell";
import NameLinkCell from "./table/NameLinkCell";

interface TransactionsTableProps {
    isFilterApplied: boolean
}

const alwaysVisibleFields = ["details"];

const TransactionsTable = ({ isFilterApplied }: TransactionsTableProps) => {
    const allColumns: ColumnType<TransactionTypes>[] = [
        {
            header: "", field: "status_circle", Component: StatusCircle, realName: "status",
        },
        { header: "table.header.risk", field: "risk_score", Component: ColoredRisk },
        { header: "table.header.id", field: "id", Component: NameLinkCell },
        {
            header: "table.header.type",
            field: "type",
            getField: (dataItem) => capitalizeFirstLetter(dataItem.type),
        },
        {
            header: "table.header.processing",
            field: "processing_name",
            isResizable: true,
            width: "180px",
        },
        {
            header: "table.header.partnerName",
            field: "partner_name",
            isResizable: true,
            width: "180px",
        },
        { header: "table.header.partnerId", field: "partner" },
        {
            header: "table.header.serviceName",
            field: "service_name",
            isResizable: true,
            width: "180px",
        },
        { header: "table.header.serviceId", field: "service" },
        { header: "table.header.subtype", field: "subtype" },
        {
            header: "table.header.orderId",
            field: "order_id",
            isResizable: true,
            width: "180px",
        },
        { header: "table.header.createdAt", field: "create_time", sortable: true },
        { header: "table.header.lastUpdate", field: "last_update" },
        { header: "table.header.finalizeTime", field: "finalize_time", sortable: true },
        {
            header: "table.header.rulesPack",
            field: "rules_packs",
            isResizable: true,
            width: "180px",
            getField: (dataItem) => dataItem.rules_packs_names.join(", "),
        },
        { header: "table.header.decision", field: "decision", Component: ColoredDecision },
        { header: "table.header.status", field: "status", Component: ColoredStatus },
        {
            header: "table.header.statusDetails",
            field: "status_details",
            isResizable: true,
            width: "180px",
        },
        { header: "table.header.code", field: "code" },
        {
            header: "table.header.marker",
            field: "markers",
            isResizable: true,
            width: "180px",
            getField: (dataItem) => dataItem.markers?.join(", "),
        },
        { header: "table.header.amount", field: "amount" },
        { header: "table.header.currency", field: "currency" },
        { header: "table.header.customerId", field: "customer_id", sortable: isFilterApplied },
        {
            header: "table.header.email",
            field: "email",
            sortable: isFilterApplied,
            isResizable: true,
            width: "180px",
        },
        { header: "table.header.ipAddresses", field: "ip" },
        { header: "table.header.isProxy", field: "is_proxy", Component: ColoredProxy },
        { header: "table.header.phone", field: "phone" },
        { header: "table.header.country", field: "country" },
        { header: "table.header.countryByIP", field: "country_by_ip" },
        { header: "table.header.countryByCard", field: "country_by_card" },
        { header: "table.header.deviceId", field: "device_id" },
        { header: "table.header.channelId", field: "channel_id" },
        { header: "table.header.cardMask", field: "card_mask_hask" },
        {
            header: "table.header.cardHash",
            field: "card_hash",
            isResizable: true,
            width: "180px",
            getField: (dataItem) => dataItem.card_hash?.toLowerCase(),
        },
        {
            header: "table.header.cardholder",
            field: "cardholder",
        },
        {
            header: "table.header.details",
            field: "details",
            cellStyles: { width: "0px" },
            Component: DetailsLinkCell,
        },
    ];

    const tablePagination = {
        ...transactionsStore.pagination,
        setPaginationPage: transactionsStore.setPaginationPage,
        setPaginationSize: transactionsStore.setPaginationSize,
    };

    const [columns, setColumns] = useState(allColumns);

    const showIdColumn = () => {
        const visibleColumns = allColumns.filter((column) => (
            column.field === "id" || alwaysVisibleFields.includes(column.field)
        ));
        if (visibleColumns) {
            setColumns(visibleColumns);
        }
    };

    const reinitializeTableColumnsVisibility = () => {
        const visibleColumns = allColumns.filter((column) => {
            if (alwaysVisibleFields.includes(column.field)) return true;
            if (column.realName
                && transactionsStore.visibleColumns.includes(column.realName)) {
                return true;
            }
            return transactionsStore.visibleColumns.includes(column.field);
        });
        setColumns(visibleColumns);
    };

    const toggleVisibleColumns = () => {
        if (!transactionsStore.visibleColumns?.length) {
            showIdColumn();
        } else {
            reinitializeTableColumnsVisibility();
        }
    };

    useEffect(() => {
        transactionsStore.fetchData();
    }, [transactionsStore.pagination, transactionsStore.filters, transactionsStore.ordering]);

    useEffect(() => {
        toggleVisibleColumns();
    }, [transactionsStore.visibleColumns]);

    return (
        <Table
            scrollable
            columns={columns}
            data={transactionsStore.data}
            pagination={tablePagination}
            checkedItems={transactionsStore.checkedItems}
            sortMode={transactionsStore.ordering}
            loading={transactionsStore.loading}
            tableContainerStyles={tableContainerStyle}
            onSort={transactionsStore.toggleSort}
            onCheckboxChange={transactionsStore.toggleItem}
            onMainCheckboxChange={transactionsStore.toggleMainItem}
        />
    );
};

export default observer(TransactionsTable);
