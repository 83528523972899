import styled from "styled-components";
import { colors } from "../../../../styles/variables";
import { ReactComponent as AlertsIcon } from "../../../../resources/icons/warningIcon.svg";

type RowPropType = {
    $spacing?: boolean,
}
export const Container = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: right;
    margin: 0;
    padding: 20px;

    background-color: ${colors.white};
    border-radius: 12px;
`;

export const FormWrapper = styled.form`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
`;

export const Row = styled.div<RowPropType>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    justify-content: ${(props) => (props.$spacing ? "space-between" : "flex-start")};
`;

export const Box = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
`;

export const Title = styled.h3`
    font-family: Roboto;
    font-size: 24px;
    color: ${colors.activeText};
    margin: 0;
    font-weight: 600;
    letter-spacing: 0em;
`;

export const Icon = styled(AlertsIcon)`
    padding: 18px;
    color: ${colors.buttonPrimary};
    border-radius: 12px;
    background-color: ${colors.buttonDefaultBackground};
    width: 26px;
    height: 26px;

    path {
        fill: currentColor;
    }
`;

export const DescriptionFieldWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
    flex-direction: column;
`;
