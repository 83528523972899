import { TableDetailsLinkComponent } from "./TableFieldsDetailsLinkComponents";
import { ReactComponent as Details } from "../../../../resources/icons/table/details.svg";
import { LocationState } from "../../../../types/locationTypes";

interface DataType {
    id: number | string;
    state?: string;
}

type PropsType<D extends DataType> = {
    dataItem?: D;
    href: string;
    state?: LocationState;
}

const TableFieldsDetailsLink = <D extends DataType>({ dataItem, href, state }: PropsType<D>) => (
    <TableDetailsLinkComponent to={dataItem ? `${href}/${dataItem?.id}` : href} state={state}>
        <Details />
    </TableDetailsLinkComponent>
);

export default TableFieldsDetailsLink;
