import dataStore from "../../common/dataStore";
import { NewServicesType } from "../../../types/partnerTypes";
import { ServicesFiltersTypes, ServicesTypes } from "../../../types/servicesTypes";
import { getServices } from "../../../api/services/getServices";
import { getSelectedService } from "../../../api/services/getSelectedService";
import deactivateServices from "../../../api/services/deactivateServices";
import { postServices } from "../../../api/services/postServices";
import patchEditService from "../../../api/services/patchEditService";
import activateServices from "../../../api/services/activateServices";

class ServiceStore extends dataStore<ServicesTypes, ServicesFiltersTypes, NewServicesType> {
    constructor() {
        super("ServiceStore");
    }

    protected fetchDataMethod = getServices;

    protected fetchSelectedItemMethod = getSelectedService;

    protected deactivateSelectedItemsMethod = deactivateServices;

    protected activateSelectedItemsMethod = activateServices;

    protected postDataMethod = postServices;

    protected patchDataMethod = patchEditService;
}

const serviceStore = new ServiceStore();

export default serviceStore;
