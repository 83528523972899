import useTranslation from "../../hooks/useTranslation";
import { MenuItemTypes } from "./Menu";
import { MenuItemTitle, MenuItem } from "./MenuComponents";
import ProtectedMenuItem from "./ProtectedMenuItem";

const MenuItemComponent = ({
    isItemShown,
    route,
    permissionsList,
    Icon,
}: MenuItemTypes) => {
    const { translate } = useTranslation();

    if (isItemShown) {
        return (
            <MenuItem to={route.href}>
                <Icon />
                <MenuItemTitle>{translate(route.t)}</MenuItemTitle>
            </MenuItem>
        );
    }

    return (
        <ProtectedMenuItem
            title={translate(route.t)}
            route={route.href}
            permission={permissionsList}
            Icon={Icon}
        />
    );
};

export default MenuItemComponent;
