import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import routes from "../../../../../constants/routes";
import { MAX_EMAIL_LENGTH, MAX_FIELD_USER_NAME_LENGTH } from "../../../../../constants/validationRules";
import { usePreventRedirect } from "../../../../../hooks/usePreventRedirect";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersProcessingStore from "../../../../../store/filters/processingFilterStore";
import rolesFilterStore from "../../../../../store/filters/rolesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import accountStore from "../../../../../store/user/account/accountStore";
import employeeStore from "../../../../../store/user/employee/employeeStore";
import { NewEmployeeType } from "../../../../../types/employeesTypes";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import Button from "../../../../ui/button/Button";
import { InfoIcon } from "../../../../ui/button/ButtonComponents";
import SmallButton from "../../../../ui/button/SmallButton";
import Modal from "../../../../ui/modal/Modal";
import {
    Container,
    DoubleCellFieldWrapper,
    Row,
    SubmitButtonWrapper,
    ThreeColumnForm,
    Title,
} from "../../../../ui/new/NewComponents";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { ModalSubText } from "../../../../ui/TextStyle";
import RolesModal from "./rolesModal/RolesModal";

const fieldsList: Array<keyof NewEmployeeType> = [
    "username", "role", "email",
];

const NewEmployee = () => {
    const { t, concatenate, translate } = useTranslation();
    const navigation = useNavigate();

    const [isRolesModalVisible, setRolesModalVisibility] = useState(false);

    const handleNavigateToPrevPage = () => {
        employeeStore.resetPostErrors();
        navigation(routes.employees.href);
    };

    const toggleRolesModal = () => setRolesModalVisibility((prev) => !prev);

    const userProcessingId = accountStore.userProfile?.processing || null;

    const initialValues: NewEmployeeType = {
        username: "",
        role: null,
        email: "",
        processing: userProcessingId,
    };

    const validationSchema = Yup.object({
        username: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_USER_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_USER_NAME_LENGTH}`),
            ),
        role: Yup.string().required(t.validation.required),
        email: Yup.string()
            .email(t.validation.invalidEmail)
            .required(t.validation.required)
            .max(
                MAX_EMAIL_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_EMAIL_LENGTH}`),
            ),
    });

    const formik = useFormik<NewEmployeeType>({
        initialValues,
        validationSchema,
        onSubmit: async (fields) => {
            await employeeStore.postData(fields, fieldsList);

            if (employeeStore.isPostRequestSuccess) {
                handleNavigateToPrevPage();
                employeeStore.resetPostItemState();
                snackbarStore.add({ variant: "success", text: t.employees.snackbar.addSuccess });
                const snackbarSuccessEmailText = concatenate(
                    t.employees.snackbar.addSuccessEmail,
                    { email: fields.email || "" },
                );
                snackbarStore.add({ variant: "success", text: snackbarSuccessEmailText });
            } else if (employeeStore.postErrors?.common) {
                snackbarStore.add({ variant: "error", text: t.employees.snackbar.addError });
                const snackbarFailedEmailText = concatenate(
                    t.employees.snackbar.addErrorEmail,
                    { email: fields.email || "" },
                );
                snackbarStore.add({ variant: "error", text: snackbarFailedEmailText });
            }
        },
    });

    const processingPlaceholder = formik.values.processing
        ? t.placeholder.processing
        : t.placeholder.allProcessings;

    const actionHeaderContentList = [
        {
            key: "cancel-button-component",
            content: (
                <SmallButton variant="secondary" type="button" onClick={handleNavigateToPrevPage}>
                    {t.buttons.cancel}
                </SmallButton>
            ),
        },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const { RedirectBlockModalComponent } = usePreventRedirect(formik.dirty, employeeStore.isPostRequestSuccess);

    useEffect(() => {
        rolesFilterStore.fetch();
        filtersProcessingStore.fetch();

        return () => employeeStore.resetPostErrors();
    }, []);

    return (
        <>
            <SecondHeader title={t.employees.text.headerTitle} actionsContent={actionHeaderContentList} />

            <Container>
                <Row $spacing>
                    <Title>{t.employees.text.title}</Title>
                    <div>
                        <SmallButton
                            variant="tertiary"
                            iconRight={<InfoIcon />}
                            type="button"
                            onClick={toggleRolesModal}
                        >
                            {t.roles.title}
                        </SmallButton>
                    </div>
                </Row>

                {!userProcessingId && (
                    <ModalSubText>{t.employees.processingWarning}</ModalSubText>
                )}

                <ThreeColumnForm onSubmit={handleSubmit}>
                    <FloatInput
                        formik={formik}
                        disabled={employeeStore.loading}
                        name="username"
                        isRequired
                        placeholder={t.employees.placeholders.name}
                        serverErrors={employeeStore.postErrors}
                        resetServerError={employeeStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        disabled={employeeStore.loading}
                        name="role"
                        options={rolesFilterStore.options}
                        isRequired
                        placeholder={t.table.header.role}
                        serverErrors={employeeStore.postErrors}
                        resetServerError={employeeStore.resetPostError}
                    />
                    <FloatSelect
                        formik={formik}
                        name="processing"
                        placeholder={processingPlaceholder}
                        disabled={!!userProcessingId || employeeStore.loading}
                        options={filtersProcessingStore.options}
                        serverErrors={employeeStore.postErrors}
                        resetServerError={employeeStore.resetPostError}
                    />
                    <DoubleCellFieldWrapper>
                        <FloatInput
                            formik={formik}
                            disabled={employeeStore.loading}
                            name="email"
                            isRequired
                            placeholder={t.employees.placeholders.email}
                            serverErrors={employeeStore.postErrors}
                            resetServerError={employeeStore.resetPostError}
                        />
                    </DoubleCellFieldWrapper>
                    <SubmitButtonWrapper>
                        <Button
                            type="submit"
                            variant="primary"
                            isLoading={employeeStore.loading}
                            disabled={employeeStore.loading}
                        >
                            {t.buttons.add}
                        </Button>
                    </SubmitButtonWrapper>
                </ThreeColumnForm>
            </Container>

            <Modal
                isOpen={isRolesModalVisible}
                setOn={setRolesModalVisibility}
                submitButton={false}
                gap="0"
                width="auto"
                scrollable
            >
                <RolesModal />
            </Modal>
            {RedirectBlockModalComponent}
        </>
    );
};

export default observer(NewEmployee);
