import { ReactNode } from "react";
import { TableCheckBoxComponent, TD } from "../../TableComponents";
import { ColumnType, DataType, TableProps } from "../../types";
import RadioButton from "../../../radioButton/RadioButton";

interface TableCellProps<D extends DataType>
    extends Pick<TableProps<D>, "checkedItems" | "onCheckboxChange" | "isItemSelectionAllowed"> {
    item: D,
    column: ColumnType<D>
    width: number | null
}

const getCell = <D extends DataType>(column: ColumnType<D>, dataItem: D): ReactNode => {
    if (column.Component) {
        return <column.Component dataItem={dataItem} name={column.field.toString()} />;
    }
    if (column.getField) {
        return column.getField(dataItem);
    }
    return dataItem[column.field] as ReactNode;
};

const TableCell = <D extends DataType>({
    item, column, checkedItems, onCheckboxChange, width, isItemSelectionAllowed,
}: TableCellProps<D>) => {
    const {
        cellStyles, cellCenter, padding, isResizable,
    } = column;

    const handleClickRadiobutton = (
        itemId: number,
    ) => {
        if (checkedItems?.[0] === itemId) {
            onCheckboxChange?.(null);
        } else {
            onCheckboxChange?.(itemId);
        }
    };

    const getCellContent = (): ReactNode => {
        if (column.field === "checkbox" && isItemSelectionAllowed) {
            return (
                <TableCheckBoxComponent
                    checked={checkedItems?.includes(item.id)}
                    onChange={() => onCheckboxChange?.(item.id)}
                />
            );
        }
        if (column.field === "radio_button" && isItemSelectionAllowed) {
            return (
                <RadioButton
                    checked={checkedItems?.[0] === item.id}
                    onClick={() => handleClickRadiobutton(item.id)}
                />
            );
        }
        return getCell(column, item);
    };

    const cellContent = getCellContent();

    return (
        <TD
            $styles={cellStyles}
            $center={cellCenter}
            $padding={padding}
            {...(isResizable && { $width: width })}
        >
            {cellContent}
        </TD>
    );
};

export default TableCell;
