import styled from "styled-components";
import { colors } from "../../styles/variables";

export const RightSidePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 50px;
`;

export const PageWrapper = styled.main`
  margin: 40px 40px;
  background: ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: calc(100vh - 80px);
  box-sizing: border-box;
`;

export const RightPageSide = styled.section`
  margin: auto;
  box-sizing: border-box;
  display: flex;
  width: calc(100vw/2);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  overflow-y: hidden;
`;
