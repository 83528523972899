import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { RegExpressions } from "../../../../../constants/regex";
import routes, { AuthRoutes } from "../../../../../constants/routes";
import useTranslation from "../../../../../hooks/useTranslation";
import authStore from "../../../../../store/auth/authStore";
import PasswordValidation from "../../../../common/passwordValidation/PasswordValidation";
import ErrorLabel from "../../../../ui/errors/ValidationErrorBox/ErrorLabel";
import PasswordInput from "../../../../ui/passwordInput/PasswordInput";
import { InputSection } from "../../../../ui/textInput/InputStyles";
import { ButtonWithErrorsSection, HeaderText, RulesMessageBox } from "../../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../../CommonPagesStyles";
import { AccountErrorsWrapper } from "../../../firstLogin/changePassword/PasswordConfirmationStyle";
import { FormWrapper } from "../../AuthStyles";
import { PasswordRecoveryContent } from "./PasswordRecoveryStyle";
import Button from "../../../../ui/button/Button";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";

export interface PasswordFieldTypes {
    new_password: string;
    new_password_conf: string;
}

const PagesChangePassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token") || "";

    const errors = authStore.authErrors;
    const commonErrors = errors?.common;
    const accountError = errors?.new_password || commonErrors;

    const formik = useFormik<PasswordFieldTypes>({
        initialValues: {
            new_password: "",
            new_password_conf: "",
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .required(t.validation.required)
                .matches(RegExpressions.lowercaseAndUppercase, t.validation.lowercaseAndUppercase)
                .matches(RegExpressions.specialSymbolAndNumber, t.validation.specialCharacterAndNumber)
                .matches(RegExpressions.passwordLength, t.validation.passwordLength),
            new_password_conf: Yup.string().required(t.validation.required)
                .oneOf([Yup.ref("new_password")], t.validation.passwordsMustMatch),
        }),
        onSubmit: async (fields) => {
            await authStore.changePassword({ ...fields }, token.toString());
            if (authStore.isChangingPasswordSuccess) {
                snackbarStore.add({ variant: "success", text: t.settings.security.text.changePassword.success });
                navigate(authStore.useTFA
                    ? AuthRoutes.loginTFA.href : routes.settings.href);
            }
        },
        onReset: () => {
            authStore.unsetState();
            formik.setErrors({});
        },
    });

    useEffect(() => {
        if (!token) navigate(AuthRoutes.login.href);
    }, []);

    const isSubmitDisabled = !formik.isValid || authStore.isChangingPasswordInProgress;

    return (
        <RightSidePageWrapper>
            <PasswordRecoveryContent>
                <HeaderText>{t.passwordRecovery.text.changePassword}</HeaderText>
                <FormWrapper onSubmit={formik.handleSubmit}>
                    <InputSection>
                        <PasswordInput
                            formik={formik}
                            name="new_password"
                            placeholder={t.passwordRecovery.input.newPassword}
                            resetServerError={authStore.unsetState}
                            serverErrors={errors}
                            isCommonError={commonErrors}
                            regex={RegExpressions.password}
                            showFiledError={false}
                        />
                        <PasswordInput
                            formik={formik}
                            name="new_password_conf"
                            placeholder={t.passwordRecovery.input.confirmPassword}
                            resetServerError={authStore.unsetState}
                            serverErrors={errors}
                            isCommonError={commonErrors}
                            regex={RegExpressions.password}
                        />
                        <div>
                            <PasswordValidation formik={formik} fieldName="new_password" />
                            <RulesMessageBox>
                                <AccountErrorsWrapper>
                                    {
                                        accountError && ErrorLabel(accountError)
                                    }
                                </AccountErrorsWrapper>
                            </RulesMessageBox>
                        </div>
                    </InputSection>
                    <ButtonWithErrorsSection>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitDisabled}
                        >
                            {t.password.confirm}
                        </Button>
                    </ButtonWithErrorsSection>
                </FormWrapper>
            </PasswordRecoveryContent>
        </RightSidePageWrapper>
    );
};

export default observer(PagesChangePassword);
