import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../../constants/permissions";
import withPermission from "../../../../../../hocs/withPermissions";
import authStore from "../../../../../../store/auth/authStore";
import rulesPackLimitsStore from "../../../../../../store/rulesPack/rulesPackLimitsStore";
import rulesPackListsStore from "../../../../../../store/rulesPack/rulesPackListsStore";
import rulesPackPartnersStore from "../../../../../../store/rulesPack/rulesPackPartnersStore";
import rulesPackServicesStore from "../../../../../../store/rulesPack/rulesPackServicesStore";
import { Dashboard } from "../../../../../ui/details/DetailsComponents";
import { useTabsActions } from "./hooks/useTabsActions";

interface ProtectedRulesDetailsTableProps {
    permissions: string[];
}

const WrappedComponent = observer(() => {
    const { id: ruleId } = useParams();
    const { userPermissions } = authStore;

    const hasPartnerViewPermission = userPermissions.includes(permissionsMap.rules_pack_partner.view);
    const hasServicesViewPermission = userPermissions.includes(permissionsMap.rules_pack_services.view);
    const hasListsViewPermission = userPermissions.includes(permissionsMap.lists.view);
    const hasLimitsViewPermission = userPermissions.includes(permissionsMap.limits.view);

    const {
        activeTab,
        TabsLayout,
        FilterModalComponent,
        ActionModalComponent,
        CloneRulesPackListModalComponent,
        RulesPacksLimitsMoveModalComponent,
        AddEntityModalComponent,
    } = useTabsActions(ruleId || "", userPermissions);

    useEffect(() => {
        if (ruleId) {
            if (hasPartnerViewPermission) rulesPackPartnersStore.fetchData(ruleId);
            if (hasServicesViewPermission) rulesPackServicesStore.fetchData(ruleId);
            if (hasListsViewPermission) rulesPackListsStore.fetchData(ruleId);
            if (hasLimitsViewPermission) rulesPackLimitsStore.fetchData(Number(ruleId));
        }
    }, []);

    return (
        <>
            <Dashboard>{TabsLayout}</Dashboard>
            {activeTab}
            {AddEntityModalComponent}
            {FilterModalComponent}
            {ActionModalComponent}
            {CloneRulesPackListModalComponent}
            {RulesPacksLimitsMoveModalComponent}
        </>
    );
});

const ProtectedRulesDetailsTable = ({ permissions }: ProtectedRulesDetailsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permissions);
    return <ComponentWithPermission />;
};

export default memo(ProtectedRulesDetailsTable);
