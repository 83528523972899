import { ChangeEvent } from "react";
import withPermission from "../../../hocs/withPermissions";
import Switch from "./Switch";

interface WrappedComponentProps {
    disabled?: boolean;
    checked?: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

type ProtectedSwitchProps = WrappedComponentProps & {
    permission: string;
}

const WrappedComponent = ({ disabled, checked, onChange }: WrappedComponentProps) => (
    <Switch disabled={disabled} checked={checked} onChange={onChange} />
);

const ProtectedSwitch = ({ permission, ...props }: ProtectedSwitchProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default ProtectedSwitch;
