import { RulesPacksLimitsType } from "../../types/limitsTypes";
import request from "../request";

interface GetRulesPacksLimitsResponse {
    message: string,
    response: {
        count: number,
        results: RulesPacksLimitsType[],
    }
}

const getRulesPacksLimits = (id?: number | null) => request<GetRulesPacksLimitsResponse>({
    method: "GET",
    uri: `rules-packs/${id}/limits/`,
});

export default getRulesPacksLimits;
