import React, { ChangeEvent } from "react";
import { FormikTypes } from "../../../types/formikTypes";
import { StyledTextInputFiled } from "./InputStyles";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    formik?: FormikTypes;
    error?: boolean;
    helperText?: React.ReactNode;
    serverErrors?: Record<string, string>;
    onlyNumber?: boolean,
    regex?: RegExp,
    maxIntegerDigits?: number,
    maxDecimalDigits?: number,
    showFiledError?: boolean;
    isCommonError?: string;
    resetServerError?: (field: string) => void;
}

const TextInput = ({
    formik,
    showFiledError = true,
    serverErrors,
    isCommonError,
    regex,
    resetServerError,
    ...props
}: TextInputProps) => {
    let isError;
    let error;
    const fieldName = props.name || "";

    let { value, onChange, onBlur } = props;

    if (formik) {
        value = formik.values[fieldName];
        onChange = onChange ?? formik.handleChange;
        onBlur = onBlur ?? formik.handleBlur;
        error = serverErrors?.[fieldName] || (formik?.touched[fieldName] && formik.errors[fieldName]);
        isError = !!error || !!isCommonError;
    }

    const handlePaste = () => formik?.setFieldTouched(fieldName, false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (regex && !regex.test(event.target.value)) {
            return;
        }

        formik?.setFieldTouched(fieldName, false);
        resetServerError?.(fieldName);
        onChange?.(event);
    };

    return (
        <>
            <StyledTextInputFiled
                value={value}
                autoComplete="off"
                $isError={isError}
                onPaste={handlePaste}
                onChange={handleChange}
                onBlur={onBlur}
                {...props}
            />
            {(isError && error && showFiledError) && ErrorLabel(error || "")}
        </>
    );
};

export default TextInput;
