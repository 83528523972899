import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    ButtonContainer,
    Description,
    SecionContentWrapper,
    SectionHeader,
    SectionWrapper,
    SecurityButton,
    SecurityContainer,
} from "../../PagesSettingsComponents";
import useTranslation from "../../../../../hooks/useTranslation";
import PasswordResetModal from "./Password/PasswordResetModal";
import authStore from "../../../../../store/auth/authStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import DeactivateTFAModal from "./TFA/DeactivateTFAModal";
import ActivateTFAModal from "./TFA/ActivateTFAModal";

const Security = () => {
    const { t } = useTranslation();

    const isTFAEnabled = authStore.useTFA;
    const TFAButtonText = isTFAEnabled
        ? t.settings.security.text.tfa.buttonDeactivate
        : t.settings.security.text.tfa.buttonActivate;
    const TFAButtonVariant = isTFAEnabled ? "secondary" : "primary";

    const [isChangePasswordModalVisible, setChangePasswordModalVisibility] = useState(false);
    const [isDeactivateTFAModalVisible, setDeactivateTFAModalVisibility] = useState(false);
    const [isActivateTFAModalVisible, setActivateTFAModalVisibility] = useState(false);

    const openTFAModal = () => {
        if (isTFAEnabled) {
            return setDeactivateTFAModalVisibility(true);
        }
        return setActivateTFAModalVisibility(true);
    };

    const changePassword = () => {
        authStore.changeUserPassword().then(() => {
            if (!authStore.isAuthError) {
                setChangePasswordModalVisibility(true);
            } else {
                snackbarStore.add({ variant: "error", text: t.employees.snackbar.addError });
            }
        });
    };

    return (
        <>
            <SectionWrapper>
                <SectionHeader>
                    {t.settings.security.title}
                </SectionHeader>
                <SecionContentWrapper>
                    <SecurityContainer>
                        <ButtonContainer>
                            <SecurityButton
                                variant={TFAButtonVariant}
                                onClick={openTFAModal}
                            >
                                {TFAButtonText}
                            </SecurityButton>
                            <Description>{t.settings.security.text.tfa.description}</Description>
                        </ButtonContainer>
                        <ButtonContainer>
                            <SecurityButton
                                variant="secondary"
                                onClick={changePassword}
                                isLoading={authStore.loading}
                            >
                                {t.settings.security.text.changePassword.button}
                            </SecurityButton>
                            <Description>{t.settings.security.text.changePassword.description}</Description>
                        </ButtonContainer>
                    </SecurityContainer>
                </SecionContentWrapper>
            </SectionWrapper>
            <PasswordResetModal isOpen={isChangePasswordModalVisible} setOn={setChangePasswordModalVisibility} />
            <DeactivateTFAModal isOpen={isDeactivateTFAModalVisible} setOn={setDeactivateTFAModalVisibility} />
            <ActivateTFAModal isOpen={isActivateTFAModalVisible} setOn={setActivateTFAModalVisibility} />
        </>
    );
};

export default observer(Security);
