import {
    ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState,
} from "react";
import { SplitInputCell, SplitInputStyle } from "./InputStyles";

interface SplitInputProps {
    length: number;
    onChange: (inputValues: Array<string[6]>) => void;
    isError: boolean;
    disabled?: boolean;
}

const SplitInput = ({
    length, onChange, isError, disabled,
}: SplitInputProps) => {
    const [values, setValues] = useState<string[]>(Array(length).fill(""));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const generateKey = (prefix: string, index: number) => `${prefix}_${index}`;

    const updateValues = (val: string, index: number) => {
        const newValues = [...values];
        newValues[index] = val;
        setValues(newValues);
    };

    useEffect(() => {
        onChange(values);
    }, [values]);

    const focusInput = (index: number) => {
        if (index >= 0 && index < length) {
            inputRefs.current[index]?.focus();
        }
    };

    const handleKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "ArrowLeft") {
            focusInput(index - 1);
        } else if (event.key === "ArrowRight") {
            focusInput(index + 1);
        } else if (event.key === "Backspace" && !values[index]) {
            focusInput(index - 1);
        }
    };

    const handleChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        if (!/^\d*$/.test(val)) {
            return;
        }
        updateValues(val, index);
        if (val && index < length - 1) {
            focusInput(index + 1);
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const paste = event.clipboardData.getData("text").slice(0, length);
        const pasteValues = paste.split("").filter((c) => !Number.isNaN(c));
        const newValues = pasteValues.slice(0, length);
        while (newValues.length < length) {
            newValues.push("");
        }
        setValues(newValues);
        const nextIndexToFocus = pasteValues.length < length ? pasteValues.length : length - 1;
        setTimeout(() => {
            focusInput(nextIndexToFocus);
        }, 0);
    };

    return (
        <SplitInputStyle onPaste={handlePaste}>
            {values.map((value, index) => (
                <SplitInputCell
                    key={generateKey("input_cell", index)}
                    ref={(el) => {
                        inputRefs.current[index] = el;
                    }}
                    autoFocus={index === 0}
                    $isError={isError}
                    type="text"
                    value={value}
                    onKeyDown={handleKeyDown(index)}
                    onChange={handleChange(index)}
                    disabled={disabled}
                    maxLength={1}
                    pattern="\d*"
                    placeholder="0"
                />
            ))}
        </SplitInputStyle>
    );
};

export default SplitInput;
