import { getLists } from "../../../api/lists/filters/getLists";
import { ListsTypes } from "../../../types/listsTypes";
import FiltersDataStore from "../../common/filtersStore";

class ListsFiltersStore extends FiltersDataStore<ListsTypes> {
    protected fetchListMethod = getLists;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
            type: item.type,
            is_global: item.is_global,
            category: item.category,
            is_active: item.is_active,
        }));
    }
}

const filtersListsStore = new ListsFiltersStore();

export default filtersListsStore;
