import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../resources/icons/closeCircleIcon.svg";
import { colors } from "../../../styles/variables";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const Box = styled.div`
    display: flex;
    flex: 1;
    gap: 16px;
    flex-direction: column;
`;

export const Title = styled.h2`
    font-family: Roboto, sans-serif;
    font-size: 24px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0em;
`;

export const IconContainer = styled.div`
    padding: 12px;
    border-radius: 12px;
    background-color: ${colors.buttonDefaultBackground};
    width: 32px;
    height: 32px;
`;

export const Icon = styled(CloseIcon)`
    padding: 12px;
    color: ${colors.buttonPrimary};
    border-radius: 12px;
    background-color: ${colors.buttonDefaultBackground};
    width: 32px;
    height: 32px;

    path {
        fill: currentColor;
    }
`;
