const categoryOptionsHandbook = [
    { label: "handbooks.black", value: "Black" },
    { label: "handbooks.white", value: "White" },
    { label: "handbooks.other", value: "Other" }];

const statusOptionsHandbook = [
    { label: "status.active", value: true },
    { label: "status.inactive", value: false },
];

const proxyOptionsHandbook = [
    { label: "proxy.yes", value: "true" },
    { label: "proxy.no", value: "false" },
];

const groupOptionsHandbook = [
    { label: "group.global", value: true },
    { label: "group.local", value: false },
];

const typeOptionsHandbook = [
    { label: "table.header.ipAddresses", value: "IP" },
    { label: "handbooks.card", value: "Card" },
    { label: "table.header.email", value: "E-mail" },
    { label: "handbooks.customerId", value: "Customer ID" },
    { label: "table.header.phone", value: "Phone" },
    { label: "table.header.deviceId", value: "Device ID" },
];

const limitsTypeOptionsHandbook = [
    { label: "table.header.all", value: "All" },
    { label: "table.header.channel", value: true },
    { label: "table.header.notChannel", value: false },
];

const elementCardOptionsHandbook = [
    { label: "placeholder.cardHash", value: false },
    { label: "placeholder.cardPlain", value: true },
];

const transactionDecisionOptionsHandbook = [
    { label: "decision.decline", value: "decline" },
    { label: "decision.approve", value: "approve" },
    { label: "transactionStatus.onGoing", value: "on-going" },
];

const transactionStatusOptionsHandbook = [
    { label: "transactionStatus.decline", value: "decline" },
    { label: "transactionStatus.success", value: "success" },
    { label: "transactionStatus.onGoing", value: "on-going" },
];

const transactionTypeOptionsHandbook = [
    { label: "transactionType.payment", value: "payment" },
    { label: "transactionType.payout", value: "payout" },
    { label: "transactionType.refund", value: "refund" },
];

const transactionSubTypeOptionsHandbook = [
    { label: "transactionSubType.recurring", value: "recurring" },
    { label: "transactionSubType.initial", value: "initial" },
];

const alertTypeOptionsHandbook = [
    { label: "alertType.af", value: "af" },
    { label: "alertType.tg", value: "tg" },
];

const alertTriggerOptionsHandbook = [
    { label: "alertTrigger.marker", value: "marker" },
];

const alertImportanceOptionsHandbook = [
    { label: "alertImportance.high", value: "high" },
    { label: "alertImportance.medium", value: "medium" },
    { label: "alertImportance.low", value: "low" },
];

const alertDisplayAttributesOptionsHandbook = [
    { label: "Service ID", value: "service_id" },
    { label: "Partner ID", value: "partner_id" },
    { label: "Customer ID", value: "customer_id" },
    { label: "E-mail", value: "email" },
    { label: "Phone", value: "phone" },
    { label: "IP", value: "ip" },
    { label: "Card Hash", value: "card_hash" },
    { label: "Created at", value: "created" },
];

const notificationsStatusOptionsHandbook = [
    { label: "status.checked", value: true },
    { label: "status.notChecked", value: false },
];

const getTranslatedHandbook = (translate: (key: string) => string, handbook: Option[]) => (
    handbook.map((option) => ({ ...option, label: translate(option.label) }))
);

export {
    categoryOptionsHandbook,
    statusOptionsHandbook,
    proxyOptionsHandbook,
    groupOptionsHandbook,
    typeOptionsHandbook,
    limitsTypeOptionsHandbook,
    elementCardOptionsHandbook,
    transactionDecisionOptionsHandbook,
    transactionStatusOptionsHandbook,
    transactionTypeOptionsHandbook,
    transactionSubTypeOptionsHandbook,
    alertTypeOptionsHandbook,
    alertTriggerOptionsHandbook,
    alertImportanceOptionsHandbook,
    alertDisplayAttributesOptionsHandbook,
    notificationsStatusOptionsHandbook,
    getTranslatedHandbook,
};
