import { getTranslatedHandbook, proxyOptionsHandbook } from "../../../../constants/handbooks";
import { capitalizeFirstLetter } from "../../../../helpers";
import useTranslation from "../../../../hooks/useTranslation";
import { TransactionProxy } from "../../../../types/transactionTypes";
import { ColoredTextField } from "./ColoredCellComponents";

interface DataType {
    is_proxy: boolean;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const ColoredProxy = <D extends DataType>({ dataItem }: PropsType<D>) => {
    const { translate } = useTranslation();
    const proxyValue = getTranslatedHandbook(translate, proxyOptionsHandbook)
        .find(({ value }) => value && value === dataItem?.is_proxy?.toString())?.label;

    return (
        <ColoredTextField value={proxyValue as TransactionProxy}>
            {capitalizeFirstLetter(proxyValue)}
        </ColoredTextField>
    );
};

export default ColoredProxy;
