import styled from "styled-components";
import { colors } from "../../../../styles/variables";
import { RightSidePageWrapper } from "../../CommonPagesStyles";

export const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  color: ${colors.activeText};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 10px;
  text-align: start;
`;

export const FirstLoginWelcomePageWrapper = styled(RightSidePageWrapper)`
  align-items: center;
`;
