import axios, { AxiosError, AxiosHeaders } from "axios";
import authStore from "../store/auth/authStore";
import { getStorageTokens } from "../helpers/authHelper";

const privateAxiosApiInstance = axios.create();

privateAxiosApiInstance.interceptors.request.use(
    async (config) => {
        const tokens = getStorageTokens();
        const Authorization = tokens?.access ? `Bearer ${tokens.access}` : "";

        const newConfig = {
            ...config,
            headers: new AxiosHeaders({
                ...config.headers,
                ...{ Authorization },
            }),
        };
        return newConfig;
    },
    (error) => Promise.reject(error),
);

let isRefreshing = false;
let refreshSubscribers: { (): void; }[] = [];

const subscribeTokenRefresh = (callback: { (): void; }) => {
    refreshSubscribers.push(callback);
};

const clearSubscribers = () => {
    refreshSubscribers = [];
};

const onRefreshed = () => {
    refreshSubscribers.map((callback) => callback());
    clearSubscribers();
};

privateAxiosApiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig = error?.config;
        if (error instanceof AxiosError && error.response?.status === 401) {
            const retryRequestConfig = new Promise((resolve) => {
                subscribeTokenRefresh(() => {
                    resolve(privateAxiosApiInstance(originalConfig));
                });
            });

            if (!isRefreshing) {
                isRefreshing = true;
                await authStore.refreshAccessToken()
                    .then(() => onRefreshed())
                    .catch(() => clearSubscribers())
                    .finally(() => {
                        isRefreshing = false;
                    });
            }
            return retryRequestConfig;
        }
        return Promise.reject(error);
    },
);

export default privateAxiosApiInstance;
