import request from "../request";
import { ProcessingTypes } from "../../types/processingTypes";

interface ResponseType {
    message: string,
    response: ProcessingTypes,
}

const patchEditProcessing = (data: Partial<ProcessingTypes>, processingId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `entities/processings/${processingId}/`,
    data,
});

export default patchEditProcessing;
