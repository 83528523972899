import { colors } from "../styles/variables";

type StatusKey = keyof typeof statusColorsMap;

const statusColorsMap = {
    approve: colors.successBackground,
    success: colors.successBackground,
    no: colors.successBackground,
    decline: colors.error,
    yes: colors.error,
    skip: colors.warning,
};

const determineColor = (value: string | number = "") => {
    if (typeof value === "string") {
        const status = value.toLowerCase();
        return statusColorsMap[status as StatusKey] || colors.activeText;
    }

    if (typeof value === "number") {
        if (value >= 0 && value < 30) return colors.successBackground;
        if (value >= 30 && value < 70) return colors.warning;
        if (value >= 70 && value <= 100) return colors.error;
    }

    return colors.activeText;
};

export {
    determineColor,
};
