import React, { FC, Fragment, ReactNode } from "react";
import {
    ActionsContainer,
    Container,
    SecondaryTitle,
    SecondaryTitleActive,
    Title,
    TitleContainer,
} from "./TabHeaderComponents";
import useTranslation from "../../hooks/useTranslation";

export interface SecondHeaderProps {
    title: string
    count?: { from: number, to: number }
    actionsContent?: { content: ReactNode, key: string }[]
}

const TabHeader: FC<SecondHeaderProps> = ({ title, actionsContent, count }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <TitleContainer>
                <Title>{title}</Title>
                {count
                    && (
                        <>
                            <SecondaryTitleActive>
                                {t.status.active}
                                &nbsp;
                                {count.from}
                            </SecondaryTitleActive>
                            <SecondaryTitle>
                                /
                                {count.to}
                            </SecondaryTitle>
                        </>
                    )}
            </TitleContainer>
            <ActionsContainer>
                {actionsContent?.map((actionContent) => (
                    <Fragment
                        key={actionContent.key}
                    >
                        {actionContent.content}
                    </Fragment>
                ))}
            </ActionsContainer>

        </Container>
    );
};
export default TabHeader;
