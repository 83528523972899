import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../../../hooks/useTranslation";
import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import Button from "../../../../../ui/button/Button";
import { Row } from "../../../../../ui/details/DetailsComponents";
import authStore from "../../../../../../store/auth/authStore";
import attentionIcon from "../../../../../../resources/icons/attentionIcon.svg";
import { AuthRoutes } from "../../../../../../constants/routes";
import TFARecoveryModal from "../../../../../ui/modal/TFARecoveryModal";
import { DeactivateModalBody, DeactivateModalDescription } from "../../../PagesSettingsComponents";
import TFAForm from "../../../../../authorizationLayout/TFA/TFAForm/TFAForm";

const DeactivateTFAModal = ({
    isOpen, setOn,
}: ModalProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);

    const [isFormValid, setIsFormValid] = useState(true);
    const [enteredKey, setEnteredKey] = useState("");
    const [isTfaRecoveryModalVisible, setTfaRecoveryModalVisibility] = useState(false);

    const triggerRequestSubmit = () => {
        formRef.current?.requestSubmit();
    };
    const onFormSubmit = async () => {
        await authStore.configureTFA({ key: enteredKey, use_tfa: false });

        if (authStore.tfaAttemptLimitExceeded) {
            setTfaRecoveryModalVisibility(true);
            authStore.unsetState();
        } else if (!authStore.authErrors) {
            setOn(false);
        }
    };

    const logout = () => {
        setTfaRecoveryModalVisibility(false);
        authStore.unsetState();
        authStore.logout();
        navigate(AuthRoutes.login.href);
    };

    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            submitButton={false}
            title={t.settings.security.text.tfa.buttonDeactivate}
            modalBodyText={t.settings.security.text.tfa.modalDescription}
            gap="0"
        >

            <DeactivateModalDescription>{t.setUpTFA.text.enterGeneratedCode}</DeactivateModalDescription>

            <DeactivateModalBody>
                <TFAForm
                    formRef={formRef}
                    onSubmit={onFormSubmit}
                    setData={setEnteredKey}
                    setIsParentFormValid={setIsFormValid}
                />
            </DeactivateModalBody>

            <Row>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={() => setOn(false)}
                >
                    {t.buttons.cancel}
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    onClick={triggerRequestSubmit}
                    isLoading={authStore.isUpdatingTFA}
                    disabled={!isFormValid && !authStore.authErrors}
                >
                    {t.buttons.deactivate}
                </Button>
            </Row>

            <TFARecoveryModal
                isOpen={isTfaRecoveryModalVisible}
                setOn={setTfaRecoveryModalVisibility}
                title={t.modal.modalTitle.tfaRecovery}
                iconURL={attentionIcon}
                buttonText={t.login.tfaButtons.ok}
                handleClose={logout}
            />

        </Modal>
    );
};

export default observer(DeactivateTFAModal);
