import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { categoryOptionsHandbook, getTranslatedHandbook, groupOptionsHandbook } from "../../../../constants/handbooks";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import useTranslation from "../../../../hooks/useTranslation";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import limitStore from "../../../../store/limit/limitStore";
import listStore from "../../../../store/list/listStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { EditListType, NewListType } from "../../../../types/listsTypes";
import BlockModal from "../../../common/modal/warning/blockModal/BlockModal";
import { SwitchButtonContainer } from "../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../ui/modal/modalStyle";
import { Box, Icon, Title } from "../../../ui/new/NewComponents";
import Switch from "../../../ui/switch/Switch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof EditListType> = ["name", "is_active", "is_global", "rules_pack"];

const initialValues: NewListType = {
    name: "",
    description: "",
    is_active: true,
    rules_pack: null,
    category: null,
    type: null,
    is_global: null,
};

const CloneListModal = ({
    isOpen, title, buttonText, navigateTo, setOn, handleClose,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const navigate = useNavigate();

    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);
    const toggleWarningModal = () => setWarningModalVisibility((prev) => !prev);

    const groupOptions = getTranslatedHandbook(translate, groupOptionsHandbook);

    const { checkedItems, data, selectedItem } = listStore;
    const checkedItem = data.find((element) => element.id === checkedItems[0]);
    const sourceItem = checkedItem || selectedItem;
    const otherCategory = categoryOptionsHandbook.at(2)?.value;

    const validationSchema = Yup.object({
        name: Yup.string().required(t.validation.required)
            .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        category: Yup.string().required(t.validation.required),
        type: Yup.string()
            .when("category", ([category], schema) => ((
                category === otherCategory
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),
        is_global: Yup.boolean()
            .when("category", ([category], schema) => ((
                category === otherCategory
            )
                ? schema.nullable()
                : schema.required(t.validation.required))),
        rules_pack: Yup.number()
            .when(["is_global", "category"], ([is_global, category], schema) => (
                (
                    is_global || (category === otherCategory)
                )
                    ? schema.nullable()
                    : schema.required(t.validation.required))),
    });

    const resetCloning = () => {
        listStore.setCheckedItems([]);
        listStore.resetPostErrors();
    };

    const formik = useFormik<NewListType>({
        initialValues,
        validationSchema,
        onSubmit: (fields) => {
            listStore.cloneData(fields, sourceItem?.id.toString() || "", fieldsList).then(() => {
                if (listStore.isCloneRequestSuccess) {
                    setOn(false);
                    handleClose?.();
                    resetCloning();
                    formik.resetForm();
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                }
            });
        },
    });

    const isOtherCategorySelected = formik.values.category === otherCategory;

    const isRulesPackSelectDisabled = formik.values.is_global || formik.values.is_global === null;

    const toggleListStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleNavigate = () => {
        resetCloning();
        formik.resetForm();
        toggleWarningModal();
        setOn(false);
        if (navigateTo) {
            navigate(navigateTo);
        }
    };
    const handleListGroupChange = () => {
        if (!formik.values.is_global) formik.setFieldValue("rules_pack", null);
    };

    useEffect(() => {
        if (sourceItem?.category === otherCategory) {
            formik.setValues({
                ...sourceItem,
                type: null,
                rules_pack: null,
                is_global: true,
                name: "",
                category: otherCategory,
            });
        } else if (sourceItem) {
            formik.setValues({
                ...sourceItem,
                name: "",
            });
        }
    }, [sourceItem, isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            hasChildModal
            isForm
            isLoading={listStore.isCloning}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={toggleWarningModal}
        >
            <EditSectionWrapper>
                <Box>
                    <FloatInput
                        formik={formik}
                        name="name"
                        isRequired
                        placeholder={t.filters.fields.newListName}
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                        disabled={listStore.isCloning}
                    />
                </Box>

                {
                    !isOtherCategorySelected
                    && (
                        <>
                            <Box>
                                <FloatSelect
                                    formik={formik}
                                    placeholder={t.lists.placeholders.group}
                                    isRequired
                                    name="is_global"
                                    options={groupOptions}
                                    onChange={handleListGroupChange}
                                    serverErrors={listStore.postErrors}
                                    resetServerError={listStore.resetPostError}
                                    disabled={listStore.isCloning}
                                />
                            </Box>
                            <Box>
                                <FloatSelect
                                    formik={formik}
                                    disabled={!!isRulesPackSelectDisabled || listStore.isCloning}
                                    isRequired={!isRulesPackSelectDisabled}
                                    placeholder={t.lists.placeholders.rulesPack}
                                    name="rules_pack"
                                    options={rulesPacksFiltersStore.options}
                                    serverErrors={limitStore.postErrors}
                                    resetServerError={limitStore.resetPostError}
                                />
                            </Box>
                        </>
                    )
                }

                <Box>
                    <FloatTextArea
                        formik={formik}
                        name="description"
                        placeholder={t.placeholder.description}
                        serverErrors={listStore.postErrors}
                        resetServerError={listStore.resetPostError}
                        autoSize
                        maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                        disabled={listStore.isCloning}
                    />
                </Box>
                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <Switch
                        checked={formik.values.is_active}
                        onChange={toggleListStatus}
                        disabled={listStore.isCloning}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>

            <BlockModal
                width="520px"
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={handleNavigate}
            />
        </Modal>
    );
};

export default observer(CloneListModal);
