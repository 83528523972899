import request from "../../request";
import { RoleType } from "../../../types/rolesTypes";

interface GetRolesResponse {
    message: string,
    response: {
        count: number
        results: RoleType[];
    }
}

export const getRoles = () => request<GetRolesResponse>({
    method: "GET",
    uri: "entities/filters/roles",
});
