import { observer } from "mobx-react-lite";
import { memo } from "react";

import { getDeactivateAndActivateMenuItems } from "../../../../../../../helpers/menuHelper";
import withPermission from "../../../../../../../hocs/withPermissions";
import useTranslation from "../../../../../../../hooks/useTranslation";
import { ActionModalType } from "../../../../../../../types/actionModalTypes";
import MenuDropdown from "../../../../../../ui/menuDropdown/MenuDropdown";

interface MenuProps {
    store: any;
    toggleActionModal: (type?: ActionModalType) => void;
}

type ProtectedMenuProps = MenuProps & {
    permission: string;
}

const WrappedComponent = observer(({ store, toggleActionModal }: MenuProps) => {
    const { translate } = useTranslation();

    const menuDropdownItemsList = getDeactivateAndActivateMenuItems(translate, toggleActionModal, store);

    return <MenuDropdown items={menuDropdownItemsList} />;
});

const ProtectedMenu = ({ permission, ...props }: ProtectedMenuProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default memo(ProtectedMenu);
