import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import elementStore from "../../../../store/element/elementStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import Link from "../../../ui/link/Link";
import ProtectedMenu from "../components/ElementsMenu/ProtectedMenu";
import ElementsFilters from "../ElementsFilters";

export function useTabsActions() {
    const { t } = useTranslation();

    const [isFiltersModalVisible, setFiltersModalVisibility] = useState(false);
    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });

    const toggleFiltersModal = () => setFiltersModalVisibility((prev) => !prev);
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const filtersCount = objectToValuesList(elementStore.filters || {}).length;
    const currentTabTitle = t.elements.title.toLowerCase();
    const currentAddButtonTitle = currentTabTitle.slice(0, currentTabTitle.length - 1);

    const AddButtonComponent = (
        <Link to="new">
            <ProtectedSmallButton
                title={currentAddButtonTitle}
                permission={permissionsMap.elements.add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
            />
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFiltersModal}
            iconLeft={<FilterIcon />}
            iconRight={
                !!filtersCount && <CircleIcon isActive count={filtersCount} />
            }
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuDropdownComponent = (
        <ProtectedMenu
            store={elementStore}
            permission={permissionsMap.elements.changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuDropdownComponent },
    ];

    const ActionModalComponent = (
        <ActionModal
            store={elementStore}
            entityName={t.elements.partOfLink}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal isOpen={isFiltersModalVisible} setOn={toggleFiltersModal}>
            <ElementsFilters closeModal={toggleFiltersModal} />
        </FiltersModal>
    );

    return {
        FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    };
}
