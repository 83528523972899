import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { permissionsMap } from "../../../constants/permissions";
import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTabs from "../../../hooks/useTabs";
import useTranslation from "../../../hooks/useTranslation";
import authStore from "../../../store/auth/authStore";
import partnerStore from "../../../store/entities/partner/partnerStore";
import processingStore from "../../../store/entities/processing/processingStore";
import serviceStore from "../../../store/entities/service/serviceStore";
import filtersPartnersManagersStore from "../../../store/filters/entities/partnersManagersFilterStore";
import filtersServicesManagersStore from "../../../store/filters/entities/servicesManagersFilterStore";
import filtersPartnersStore from "../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../store/filters/processingFilterStore";
import filtersProcessingServicesStore from "../../../store/filters/processingServicesFiltersStore";
import filtersServicesStore from "../../../store/filters/servicesFilterStore";
import filtersTimezonesStore from "../../../store/filters/timezonesFilterStore";
import SecondHeader from "../../secondHeader/SecondHeader";
import { useTabsActions } from "./hooks/useTabsActions";
import ProtectedPartnersTable from "./partners/ProtectedPartnersTable";
import ProtectedProcessingsTable from "./processing/ProtectedProcessingsTable";
import ProtectedServicesTable from "./services/ProtectedServicesTable";
import { Tabs } from "../../../constants/tabs";
import { LocationState } from "../../../types/locationTypes";

const PagesEntities = () => {
    const { t } = useTranslation();
    const { userPermissions } = authStore;
    const state: LocationState = useLocation()?.state;
    const defaultActiveTab = state?.tab;

    const isPartnersTableCheckboxVisible = userPermissions.includes(permissionsMap.partners.changeStatus);
    const isServicesTableCheckboxVisible = userPermissions.includes(permissionsMap.services.changeStatus);
    const isProcessingsTableCheckboxVisible = userPermissions.includes(permissionsMap.processings.changeStatus);

    const tabsPropsList = [
        {
            title: t.entities.partners.title,
            name: Tabs.PARTNERS,
            permission: permissionsMap.partners.view,
            store: partnerStore,
            content: (
                <ProtectedPartnersTable
                    isCheckboxVisible={isPartnersTableCheckboxVisible}
                    permission={permissionsMap.partners.view}
                />
            ),
        },
        {
            title: t.entities.services.title,
            name: Tabs.SERVICES,
            permission: permissionsMap.services.view,
            store: serviceStore,
            content: (
                <ProtectedServicesTable
                    isCheckboxVisible={isServicesTableCheckboxVisible}
                    permission={permissionsMap.services.view}
                />
            ),
        },
        {
            title: t.entities.processing.title,
            name: Tabs.PROCESSINGS,
            permission: permissionsMap.processings.view,
            store: processingStore,
            content: (
                <ProtectedProcessingsTable
                    isCheckboxVisible={isProcessingsTableCheckboxVisible}
                    permission={permissionsMap.processings.view}
                />
            ),
        },
    ];

    const {
        TabsLayout, activeTab, currentTabTitle, currentTab,
    } = useTabs(tabsPropsList, defaultActiveTab);

    const headerTitle = generateHeaderName(t.entities.title, currentTabTitle);

    const {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    } = useTabsActions(currentTabTitle, currentTab);

    useEffect(() => {
        filtersPartnersStore.fetch();
        filtersProcessingStore.fetch();
        filtersServicesStore.fetch();
        filtersProcessingServicesStore.fetch();
        filtersTimezonesStore.fetch();

        filtersPartnersManagersStore.fetch();
        filtersServicesManagersStore.fetch();
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} tabs={TabsLayout} actionsContent={actionHeaderContentList} />
            {activeTab}
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesEntities);
