import { flow } from "mobx";

import getRulesPacksPartners from "../../api/rulesPacks/getRulesPacksPartners";
import postRulesPacksPartners from "../../api/rulesPacks/postRulePackPartner";
import unlinkRulesPacksPartners from "../../api/rulesPacks/unlinkRulesPacksPartners";
import { NewRulePackPartnerType, RulesPacksPartnersTypes } from "../../types/rulesPacksPartnersTypes";
import dataStore from "../common/dataStore";

class RulesPackPartnersStore extends dataStore<RulesPacksPartnersTypes, any, NewRulePackPartnerType> {
    constructor() {
        super("RulesPackPartnersStore");
    }

    protected postDataMethod = postRulesPacksPartners;

    protected unlinkSelectedItemMethod = unlinkRulesPacksPartners;

    protected fetchDataByIdMethod = getRulesPacksPartners;

    fetchData = flow(function* fetchData(this: RulesPackPartnersStore, id: number | string) {
        yield this.fetchDataById({ rules_pack: id });
    });
}

const rulesPackPartnersStore = new RulesPackPartnersStore();

export default rulesPackPartnersStore;
