import styled from "styled-components";
import { TransactionStatus } from "../../../../types/transactionTypes";
import { colors } from "../../../../styles/variables";

export const StatusCircle = styled.span<{ variant: TransactionStatus }>`
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;

    background-color: ${(props) => {
        const currenVariant = props.variant;
        if (currenVariant === "success") {
            return colors.successBackground;
        }
        if (currenVariant === "decline") {
            return colors.error;
        }
        return colors.inputDefaultBorder;
    }};

`;
