import { ReactElement, useMemo, useState } from "react";

import { arrayMove } from "@dnd-kit/sortable";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import TableBody from "../TableBody/TableBody";
import { ColumnType, DataType, TableProps } from "../../types";
import { Table as StyledTable } from "../../TableComponents";
import TableHead from "../TableHead/TableHead";

const calculateColumnsWidth = <D extends DataType>(columns: ColumnType<D>[]) => {
    const columnWidthsList: { [key: string]: number } = {};
    columns.forEach((column) => {
        if (column.isResizable && column.width) {
            columnWidthsList[column.field] = parseInt(column.width, 10);
        }
    });
    return columnWidthsList;
};

const TableContent = <D extends DataType>({
    data,
    setData,
    sensors,
    sortMode,
    columns,
    loading,
    checkedItems,
    onSort,
    onCheckboxChange,
    onMainCheckboxChange,
    isItemSelectionAllowed,
}: TableProps<D>): ReactElement => {
    const [columnWidths, setColumnWidths] = useState(calculateColumnsWidth(columns));

    const dataIds = useMemo(() => data?.map(({ id }) => id), [data]);

    const handleDragEnd = (event: DragEndEvent) => {
        if (setData) {
            const { active, over } = event;
            if (active && over && active.id !== over.id) {
                const oldIndex = dataIds.indexOf(active.id);
                const newIndex = dataIds.indexOf(over.id);
                const newData = arrayMove(data, oldIndex, newIndex);
                setData(newData);
            }
        }
    };

    const isTableRowsMovable = !!sensors;

    const tableContent = (
        <StyledTable>
            <TableHead
                data={data}
                sortMode={sortMode}
                columns={columns}
                checkedItems={checkedItems}
                onSort={onSort}
                onMainCheckboxChange={onMainCheckboxChange}
                columnWidths={columnWidths}
                setColumnWidths={setColumnWidths}
                isItemSelectionAllowed={isItemSelectionAllowed}
            />
            <TableBody
                data={data}
                loading={loading}
                checkedItems={checkedItems}
                columns={columns}
                onCheckboxChange={onCheckboxChange}
                columnWidths={columnWidths}
                dataIds={dataIds}
                isTableRowsMovable={isTableRowsMovable}
                isItemSelectionAllowed={isItemSelectionAllowed}
            />
        </StyledTable>
    );

    if (isTableRowsMovable) {
        return (
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                sensors={sensors}
            >
                {tableContent}
            </DndContext>
        );
    }

    return tableContent;
};

export default TableContent;
