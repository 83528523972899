import { FormikProps } from "formik";
import { RegExpressions } from "../../../constants/regex";
import useTranslation from "../../../hooks/useTranslation";
import { PasswordFieldTypes } from "../../pages/auth/password/recovery/PagesChangePassword";
import { RulesMessageBox } from "../../ui/TextStyle";
import PasswordRule from "./PasswordRule";

export interface PasswordValidationProps {
    formik: FormikProps<PasswordFieldTypes>;
    fieldName: keyof PasswordFieldTypes;
}

export interface RuleTemplateProps {
    translation: string;
    regex: RegExp;
    key: string;
}
const passwordRulesList: Array<RuleTemplateProps> = [
    {
        translation: "validation.passwordLength",
        regex: RegExpressions.passwordLength,
        key: "password-length",
    },
    {
        translation: "validation.lowercaseAndUppercase",
        regex: RegExpressions.lowercaseAndUppercase,
        key: "lowercase-uppercase",
    },
    {
        translation: "validation.specialCharacterAndNumber",
        regex: RegExpressions.specialSymbolAndNumber,
        key: "special-symbol-number",
    },
];

const PasswordValidation = ({ formik, fieldName }: PasswordValidationProps) => {
    const { translate } = useTranslation();
    return (
        <RulesMessageBox>
            {passwordRulesList.map((rule) => (
                <PasswordRule
                    translation={translate(rule.translation)}
                    regex={rule.regex}
                    key={rule.key}
                    formik={formik}
                    fieldName={fieldName}
                />
            ))}
        </RulesMessageBox>
    );
};

export default PasswordValidation;
