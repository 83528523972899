import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import alertsStore from "../../../../store/alerts/alerts/alertsStore";
import notificationsStore from "../../../../store/alerts/notifications/notificationsStore";
import tgGroupsStore from "../../../../store/alerts/tgGroups/tgGroupsStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import { FetchStatusesType } from "../../../../types/fetchStatuses";
import { PermissionKeys } from "../../../../types/permissionsTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import AlertsFilters from "../alerts/AlertsFilters";
import ProtectedAlertsMenu from "../components/AlertsMenu/ProtectedAlertsMenu";
import NotificationsFilters from "../notifications/NotificationsFilters";
import TgGroupsFilters from "../tgGroups/TgGroupsFilters";
import { TabsType } from "../../../../types/tabs";
import { Tabs } from "../../../../constants/tabs";
import Link from "../../../ui/link/Link";
import Routes from "../../../../constants/routes";

export type TabTitlesKeys = Extract<PermissionKeys, "alerts" | "notifications" | "tg_groups">;

export function useTabsActions(currentTabTitle: PermissionKeys, currentTab?: TabsType) {
    const { t, concatenate } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const modifiedCurrentTabTitle = currentTabTitle.split(" ").join("_").toLowerCase() as TabTitlesKeys;
    const currentAddButtonTitle = currentTabTitle?.slice(0, currentTabTitle.length - 1);

    const menuPermissionsList = [
        permissionsMap[modifiedCurrentTabTitle].changeStatus,
        permissionsMap[modifiedCurrentTabTitle].delete,
    ];

    const tabsPropertiesMap = {
        alerts: {
            store: alertsStore,
            entityName: t.alerts.text.partOfLink,
            isAddButtonShown: true,
            isScrollable: true,
            filters: <AlertsFilters closeModal={toggleFilterModal} />,
        },
        notifications: {
            store: notificationsStore,
            entityName: t.notifications.text.partOfLink,
            isAddButtonShown: false,
            isScrollable: false,
            filters: <NotificationsFilters closeModal={toggleFilterModal} />,
        },
        tg_groups: {
            store: tgGroupsStore,
            entityName: t.tgGroups.text.partOfLink,
            isAddButtonShown: true,
            isScrollable: false,
            filters: <TgGroupsFilters closeModal={toggleFilterModal} />,
        },
    };

    const {
        store, entityName, isAddButtonShown, filters, isScrollable,
    } = tabsPropertiesMap[modifiedCurrentTabTitle as TabTitlesKeys];

    const storesFilters = store.filters ?? {};
    const filtersCount = objectToValuesList(storesFilters).length;

    const handleRequestResult = async (
        result: FetchStatusesType | undefined,
        successMessage: string,
        errorMessage: string,
    ) => {
        if (result === FetchStatusesType.success) {
            await store.fetch();
            snackbarStore.add({ variant: "success", text: successMessage });
        } else {
            snackbarStore.add({ variant: "error", text: errorMessage });
        }
    };

    const AddButtonComponent = (
        <Link to={`${Routes.alerts.href}/${modifiedCurrentTabTitle}/new`} state={{ tab: currentTab }}>
            <ProtectedSmallButton
                variant="tertiary"
                iconLeft={<PlusIcon />}
                title={currentAddButtonTitle}
                permission={permissionsMap[modifiedCurrentTabTitle].add}
            />
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
            onClick={toggleFilterModal}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuComponent = (
        <ProtectedAlertsMenu
            store={store}
            currentTabTitle={modifiedCurrentTabTitle}
            permission={menuPermissionsList}
            handleRequestResult={handleRequestResult}
            toggleActionModal={toggleActionModal}
        />
    );

    const deleteItems = async () => {
        const result = await notificationsStore.deleteSelectedItems();
        toggleActionModal();
        handleRequestResult(result, t.snackbar.deleteSuccess, t.snackbar.deleteError);
    };

    const activateItems = async () => {
        const result = await store.activateSelectedItems();
        toggleActionModal();
        const snackbarSuccessText = concatenate(t.snackbar.activate.success, { itemsIdsList: store.activatedItems });
        handleRequestResult(result, snackbarSuccessText, t.snackbar.activate.error);
    };

    const deactivateItems = async () => {
        const result = await store.deactivateSelectedItems();
        toggleActionModal();
        const snackbarSuccessText = concatenate(
            t.snackbar.deactivate.success,
            { itemsIdsList: store.deactivatedItems },
        );
        handleRequestResult(result, snackbarSuccessText, t.snackbar.deactivate.error);
    };

    const tgGroups = actionModalState.type === "deactivate" ? t.tgGroups.text.deactivate : t.tgGroups.text.activate;
    const customTitles: Record<TabTitlesKeys, string | undefined> = {
        [Tabs.ALERTS]: undefined,
        [Tabs.NOTIFICATIONS]: undefined,
        [Tabs.TG_GROUPS]: tgGroups,
    };
    const customTitleText = currentTab ? customTitles[currentTab as TabTitlesKeys] : undefined;

    const ActionModalComponent = (
        <ActionModal
            handleDelete={deleteItems}
            handleDeactivate={deactivateItems}
            handleActivate={activateItems}
            entityName={entityName}
            customTitleText={customTitleText}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent, isDisplayed: isAddButtonShown },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuComponent },
    ];

    const FilterModalComponent = (
        <FiltersModal
            isOpen={isFilterModalVisible}
            scrollable={isScrollable}
            setOn={toggleFilterModal}
        >
            {filters}
        </FiltersModal>
    );

    return { FilterModalComponent, ActionModalComponent, actionHeaderContentList };
}
