import styled from "styled-components";
import { colors } from "../../styles/variables";

export const ComponentWrapper = styled.div`
    background-color: ${colors.buttonDisabledBackgroundSecondary};
    grid-area: secondHeader;
    position: sticky;
    top: 106px;
    z-index: 10;
    margin: 0 0 20px;
    height: max-content;
`;

export const Container = styled.header<{ $tabs: boolean }>`
    /* grid-area: secondHeader;
    position: sticky;
    top: 106px;
    z-index: 10;
    height: max-content;
    margin: 0 0 20px; */
    
    padding: 15px 20px;
    display: flex;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    background-color: ${colors.white};

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${(props) => {
        if (props.$tabs) {
            return `
             flex-direction: column;
             align-items: flex-start;
    `;
        }
        return "";
    }};
`;

export const ActionsAndTabsContainer = styled.section<{ $tabs: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${(props) => (props.$tabs ? "100%" : "fit-content")};
`;

export const ActionsContainer = styled.section`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

export const Title = styled.h2<{ $isCapitalized?: boolean }>`
    color: ${colors.textDefault};
    font-size: 36px;
    font-weight: 600;
    line-height: 105%;
    margin: 0;
    text-transform: ${(props) => (props.$isCapitalized ? "capitalize" : "none")};
    white-space: nowrap;
`;

export const ActiveTitle = styled(Title)`
    color: ${colors.textPrimary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    // TODO: Нужно переделать под адаптивную ширину
    width: calc(50vw - 260px);
`;

export const SubTitle = styled.h4`
    color: ${colors.textDefault};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    margin: 0;
`;

export const SubTitleActive = styled(SubTitle)`
    color: ${colors.activeText};
    margin-left: 16px;
`;
