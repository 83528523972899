import request from "../request";
import { ProcessingFiltersTypes, ProcessingTypes } from "../../types/processingTypes";

type GetProcessingRequest = ProcessingFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetProcessingResponse {
    message: string,
    response: {
        count: number,
        results: ProcessingTypes[],
    }
}

export const getProcessing = (params?: GetProcessingRequest) => request<GetProcessingResponse>({
    method: "GET",
    uri: "entities/processings/",
    params,
});
