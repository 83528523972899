import request from "../request";
import { RulePackFiltersTypes, RulesPacksTypes } from "../../types/rulesPacksTypes";

type GetRulesPacksRequest = RulePackFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string | null
}

interface GetRulesPacksResponse {
    message: string,
    response: {
        count: number,
        results: RulesPacksTypes[],
    }
}

const getRulesPacks = (params?: GetRulesPacksRequest) => request<GetRulesPacksResponse>({
    method: "GET",
    uri: "rules-packs/",
    params,
});

export default getRulesPacks;
