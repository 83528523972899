import styled from "styled-components";
import { colors } from "../../styles/variables";

export const LeftPageSide = styled.section`
    background: ${colors.loginPageBackground};
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 20px;
    padding: 40px 0 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100vw / 2);
    background: linear-gradient(to bottom, #F8F8F8 50%, #e3e8f9 100%);
`;

export const SplitInputSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
`;
