import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import { observer } from "mobx-react-lite";
import authStore from "../../../../store/auth/authStore";
import useTranslation from "../../../../hooks/useTranslation";
import useConfirmOnExitAndReload from "../../../../hooks/useConfirmOnExitAndReload";
import {
    CopyButton,
    CopyKeyContainer,
    HeaderAndQRSection,
    KeySection,
    LinkAndIcon,
    QR,
    SplitInputWithText,
    TFAPageWrapper,
} from "./TFALinkComponents";
import { HeaderSection } from "../../../ui/modal/modalStyle";
import { HeaderText, TextLink } from "../../../ui/TextStyle";
import { InputTFAKey } from "../../../ui/textInput/InputStyles";
import SplitInput from "../../../ui/textInput/SplitInput";
import ErrorLabel from "../../../ui/errors/ValidationErrorBox/ErrorLabel";
import { PrimaryButton } from "../../../ui/button/ButtonStyles";
import SaveRecoveryKeyModal from "./SaveRecoveryKeyModal";
import { ReactComponent as CopyIcon } from "../../../../resources/icons/copyIcon.svg";
import { SplitInputSection } from "../../AuthorizationLayoutStyles";
import routes, { AuthRoutes } from "../../../../constants/routes";
import attentionIcon from "../../../../resources/icons/attentionIcon.svg";
import TFARecoveryModal from "../../../ui/modal/TFARecoveryModal";
import { Box, Title as ModalTitle } from "../../../common/modal/ModalComponents";

const extractSecretKey = (url = "") => url.split("secret=").at(1) || "";

interface TFALinkProps {
    isModalStyle?: boolean
    closeModal?: () => void,
}

const TFALink: FC<TFALinkProps> = ({ isModalStyle, closeModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useConfirmOnExitAndReload();

    const [isSaveRecoveryKeyModalVisible, setSaveRecoveryKeyModalVisibility] = useState(false);
    const [isTfaRecoveryModalVisible, setTfaRecoveryModalVisibility] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);
    const [enteredKey, setEnteredKey] = useState("");
    const [tfaSecretKey, setTfaSecretKey] = useState("");

    const { tfaUri } = authStore;

    const navigateTo = isModalStyle ? routes.settings.href : undefined;
    const copyTFAKey = () => {
        navigator.clipboard.writeText(tfaSecretKey || "");
    };

    const resendTFAKey = () => {
        authStore.getTfaUri();
    };

    const handleCloseSaveRecoveryKeyModal = () => {
        authStore.setFirstLogin(false);
        closeModal?.();
    };

    const logout = () => {
        setTfaRecoveryModalVisibility(false);
        authStore.unsetState();
        authStore.logout();
        navigate(AuthRoutes.login.href);
    };

    const changeCurrentTFAKeyValue = (inputValues: Array<string[6]>) => {
        if (authStore.authErrors) authStore.unsetState();
        const isKeyCorrect = inputValues.every((value) => /^\d$/.test(value));
        setIsFormValid(isKeyCorrect);
        if (isKeyCorrect) {
            setEnteredKey(inputValues.join(""));
        }
    };

    const activateTFA = async () => {
        await authStore.configureTFA({ use_tfa: true, key: enteredKey }, !isModalStyle);

        if (authStore.authErrors) {
            setIsFormValid(false);
        } else {
            setSaveRecoveryKeyModalVisibility(true);
        }

        if (authStore.tfaAttemptLimitExceeded) {
            setTfaRecoveryModalVisibility(true);
            authStore.unsetState();
        }
    };

    const fetchTfaUri = async () => {
        await authStore.getTfaUri();
        if (!authStore.isGettingTfaUriSucceed) {
            authStore.setFirstLogin(false);
            navigate(routes.home.href);
        }
    };

    useEffect(() => {
        fetchTfaUri();
    }, []);

    useEffect(() => {
        if (tfaUri) {
            const canvas = document.getElementById("qr-canvas");
            QRCode.toCanvas(canvas, tfaUri, (error: any) => error && console.error(error));
            setTfaSecretKey(extractSecretKey(tfaUri));
        }
    }, [authStore.isGettingTfaUriInProgress]);

    return (
        <TFAPageWrapper>

            <HeaderAndQRSection>
                <HeaderSection>
                    <HeaderText>{t.setUpTFA.text.linkTFA}</HeaderText>
                    <div>{t.setUpTFA.text.scanQR}</div>
                </HeaderSection>
                <QR $isModalStyle={isModalStyle} id="qr-canvas" />
            </HeaderAndQRSection>

            <KeySection $isModalStyle={isModalStyle}>
                <div>{t.setUpTFA.text.enterKeyManually}</div>
                <CopyKeyContainer $isModalStyle={isModalStyle}>
                    <InputTFAKey type="text" value={tfaSecretKey || ""} readOnly />
                    <CopyButton onClick={copyTFAKey}>
                        <CopyIcon />
                    </CopyButton>
                </CopyKeyContainer>
            </KeySection>

            <SplitInputWithText $isModalStyle={isModalStyle}>
                <div>{t.setUpTFA.text.enterGeneratedCode}</div>
                <SplitInputSection>
                    <SplitInput
                        length={6}
                        onChange={changeCurrentTFAKeyValue}
                        isError={authStore.authErrors}
                        disabled={authStore.loading}
                    />
                    {authStore.authErrors && ErrorLabel(t.login.tfa.error)}
                </SplitInputSection>
            </SplitInputWithText>

            <PrimaryButton
                onClick={activateTFA}
                disabled={!isFormValid || authStore.loading}
            >
                {t.buttons.confirm}
            </PrimaryButton>

            <LinkAndIcon>
                <TextLink onClick={resendTFAKey}>{t.login.tfaButtons.resendCode}</TextLink>
            </LinkAndIcon>

            <SaveRecoveryKeyModal
                secretKey={extractSecretKey(tfaUri)}
                isOpen={isSaveRecoveryKeyModalVisible}
                isLoading={false}
                setOn={setSaveRecoveryKeyModalVisibility}
                title={(
                    <Box>
                        <ModalTitle>{t.modal.modalTitle.tfaSaveKey}</ModalTitle>
                    </Box>
                )}
                buttonText={t.login.tfaButtons.ok}
                navigateTo={navigateTo}
                handleClose={handleCloseSaveRecoveryKeyModal}
            />

            <TFARecoveryModal
                isOpen={isTfaRecoveryModalVisible}
                setOn={setTfaRecoveryModalVisibility}
                title={t.modal.modalTitle.tfaRecovery}
                iconURL={attentionIcon}
                buttonText={t.login.tfaButtons.ok}
                handleClose={logout}
            />

        </TFAPageWrapper>
    );
};

export default observer(TFALink);
