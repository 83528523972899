import { CustomPlaceholder, DescriptionInputWrapper, StyledTextarea } from "./InputStyles";
import { BoxProps } from "./testInputTypes";

const TextareaLabelInRow = ({ label, value }: BoxProps) => (
    <DescriptionInputWrapper>
        <CustomPlaceholder>
            {label}
        </CustomPlaceholder>
        <StyledTextarea
            rows={4}
            readOnly
            value={value || ""}
            autoSize={{ minRows: 1, maxRows: 16 }}
        />
    </DescriptionInputWrapper>
);
export default TextareaLabelInRow;
