import request from "../request";
import { FilterUserType } from "../../types/userType";

interface GetUsersResponse {
    message: string,
    response: {
        count: number
        results: FilterUserType[];
    }
}

export const getUsers = () => request<GetUsersResponse>({
    method: "GET",
    uri: "entities/filters/users/",
});
