import { observer } from "mobx-react-lite";

import useTranslation from "../../../../../hooks/useTranslation";
import { ReactComponent as DownloadIcon } from "../../../../../resources/icons/menu/download.svg";
import { ReactComponent as PropertiesIcon } from "../../../../../resources/icons/menu/settings.svg";
import transactionStore from "../../../../../store/transaction/transactionStore";
import { colors } from "../../../../../styles/variables";
import MenuDropdown from "../../../../ui/menuDropdown/MenuDropdown";
import MenuDropdownItem from "../../../../ui/menuDropdown/MenuDropdownItem";

interface TransactionsMenuProps {
    isDownloadButtonDisabled: boolean;
    downloadFile: () => Promise<void>;
    toggleParametersModal: () => void;
}

const TransactionsMenu = ({ isDownloadButtonDisabled, downloadFile, toggleParametersModal }: TransactionsMenuProps) => {
    const { t } = useTranslation();

    const menuDropdownItemsList = [
        {
            key: "download-item",
            disabled: isDownloadButtonDisabled,
            label: <MenuDropdownItem
                isLoading={transactionStore.isFileLoading}
                icon={<DownloadIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={t.transactions.buttons.download}
            />,
            onClick: downloadFile,
        },
        {
            key: "properties-item",
            label: <MenuDropdownItem
                icon={<PropertiesIcon width="16px" height="16px" fill={colors.buttonPrimary} />}
                title={t.transactions.buttons.parameters}
            />,
            onClick: toggleParametersModal,
        },
    ];

    return <MenuDropdown items={menuDropdownItemsList} />;
};

export default observer(TransactionsMenu);
