import withPermission from "../../../hocs/withPermissions";
import SmallButton, { SmallButtonProps } from "./SmallButton";

type ProtectedSmallButtonType = SmallButtonProps & {
    permission: string | string[];
    isFullPermissionsMatchingRequired?: boolean;
}

const WrappedComponent = ({
    title, variant, disabled, iconLeft, iconRight, onClick,
}: SmallButtonProps) => (
    <SmallButton iconLeft={iconLeft} iconRight={iconRight} variant={variant} disabled={disabled} onClick={onClick}>
        {title}
    </SmallButton>
);

const ProtectedSmallButton = ({
    permission,
    isFullPermissionsMatchingRequired,
    ...props
}: ProtectedSmallButtonType) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission, isFullPermissionsMatchingRequired);
    return <ComponentWithPermission {...props} />;
};

export default ProtectedSmallButton;
