import React from "react";
import { Outlet } from "react-router-dom";
import Snackbar from "../snackbar/Snackbar";
import Menu from "../menu/Menu";
import { Container, Main } from "./MainLayoutComponents";
import Header from "../header/Header";

const MainLayout = () => (
    <Container>
        <Menu />
        <Header />
        <Main>
            <Outlet />
        </Main>
        <Snackbar />
    </Container>
);

export default MainLayout;
