import axios, { GenericAbortSignal, Method, ResponseType } from "axios";
import envVariables from "../constants/environment";
import privateAxiosApiInstance from "./axios";

type OptionsType = {
    useAuthorization?: boolean;
    token?: string;
    method?: Method;
    uri: string;
    headers?: any;
    data?: any;
    params?: any;
    responseType?: ResponseType;
    signal?: GenericAbortSignal
}

const request = async <T>({
    useAuthorization = true,
    token,
    method,
    uri,
    headers,
    data,
    params,
    responseType,
    signal,
}: OptionsType) => {
    const axiosApiInstance = useAuthorization ? privateAxiosApiInstance : axios;

    try {
        const response = await axiosApiInstance<T>({
            method,
            url: `${envVariables.apiUrl}/${uri}`,
            params: {
                ...(params || {}),
            },
            data: data instanceof FormData ? data : { ...(data || {}) },
            headers: {
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
                ...(headers || {}),
            },
            responseType,
            signal,
        });

        return response;
    } catch (e) {
        console.warn("Request error", e);
        return Promise.reject(e);
    }
};

export default request;
