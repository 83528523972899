import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import employeeStore from "../../../../store/user/employee/employeeStore";
import { EmployeesTypes } from "../../../../types/employeesTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedEmployeeTableProps {
    permission: string;
}

const columns: ColumnType<EmployeesTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.name",
        field: "username",
        isResizable: true,
        width: "230px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.employee.href}/${dataItem.id}`}>{dataItem.username}</TableMainLink>
        ),
    },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.createdAt", field: "created_at", sortable: true },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.role",
        field: "role_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.isActive", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.employee.href} />
        ),
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...employeeStore.pagination,
        setPaginationPage: employeeStore.setPaginationPage,
        setPaginationSize: employeeStore.setPaginationSize,
    };

    useEffect(() => {
        employeeStore.fetchData();
    }, [employeeStore.pagination, employeeStore.filters, employeeStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={employeeStore.data}
            pagination={tablePagination}
            checkedItems={employeeStore.checkedItems}
            sortMode={employeeStore.ordering}
            loading={employeeStore.loading}
            onSort={employeeStore.toggleSort}
            onCheckboxChange={employeeStore.toggleItem}
            onMainCheckboxChange={employeeStore.toggleMainItem}
        />
    );
});

const ProtectedEmployeeTable = ({ permission }: ProtectedEmployeeTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedEmployeeTable);
