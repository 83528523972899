import useTranslation from "../../../../../hooks/useTranslation";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { ModalTitle, Row } from "./BlockModalComponents";
import Button from "../../../../ui/button/Button";

interface BlockModalProps extends ModalProps {
    toggleModal: () => void
    handleNavigate: () => void
}

const BlockModal = ({ ...props }: BlockModalProps) => {
    const { t } = useTranslation();
    const { toggleModal, handleNavigate } = props;

    return (
        <Modal {...props}>
            <ModalTitle>{t.modal.modalBody.dataIsNotSaved}</ModalTitle>

            <Row>
                <Button
                    variant="secondary"
                    type="button"
                    onClick={toggleModal}
                >
                    {t.buttons.back}
                </Button>
                <Button
                    variant="primary"
                    type="button"
                    onClick={handleNavigate}
                >
                    {t.buttons.exit}
                </Button>
            </Row>
        </Modal>
    );
};

export default BlockModal;
