import { DeactivateRulesPacksType } from "../../types/rulesPacksTypes";
import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

const deactivateRulesPacks = (data: DeactivateRulesPacksType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "rules-packs/deactivate/",
    data,
});

export default deactivateRulesPacks;
