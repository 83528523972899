import React from "react";
import useTranslation from "../../../hooks/useTranslation";
import { TextLink } from "../TextStyle";
import Modal, { ModalProps } from "./Modal";
import { Box, Title as ModalTitle } from "../../common/modal/ModalComponents";

const TFARecoveryModal = ({
    isOpen,
    setOn,
    title,
    buttonText,
    iconURL,
    navigateTo,
    handleClose,
}: ModalProps) => {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={isOpen}
            setOn={setOn}
            buttonText={buttonText}
            iconURL={iconURL}
            navigateTo={navigateTo}
            handleClose={handleClose}
            modalBodyText={
                (
                    <span>
                        {t.modal.modalBody.resetTFA}
                        &nbsp;
                        <TextLink href={`mailto:${t.modal.modalBody.supportMail}`}>
                            {t.modal.modalBody.supportMail}
                        </TextLink>
                    </span>
                )
            }
            title={(
                <Box>
                    <ModalTitle>{title}</ModalTitle>
                </Box>
            )}
        />
    );
};

export default TFARecoveryModal;
