import { observer } from "mobx-react-lite";
import React from "react";
import {
    HeaderContainer,
    HeaderIconAndText,
    HeaderPersonalArea,
    HeaderSearchBar,
    HeaderTextArea,
} from "./HeaderComponents";
import { ReactComponent as PersonAvatar } from "../../resources/icons/personAvatar.svg";
import accountStore from "../../store/user/account/accountStore";
// import { ReactComponent as BellIcon } from "../../resources/icons/bellIcon.svg";

const Header = () => (
    <HeaderContainer>
        <HeaderSearchBar />
        <HeaderPersonalArea>
            <HeaderIconAndText>
                <PersonAvatar />
                <HeaderTextArea>
                    {accountStore.userProfile?.email}
                    <div>{accountStore.userProfile?.username}</div>
                </HeaderTextArea>
            </HeaderIconAndText>
            {/* TODO: uncomment after added functionality to the button */}
            {/*  <IconButton>
                    <BellIcon />
                </IconButton> */}
        </HeaderPersonalArea>
    </HeaderContainer>
);

export default observer(Header);
