import request from "../request";
import { PartnerFiltersTypes, PartnerTypes } from "../../types/partnerTypes";

type GetPartnersRequest = PartnerFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetPartnersResponse {
    message: string,
    response: {
        count: number,
        results: PartnerTypes[],
    }
}

export const getPartners = (params?: GetPartnersRequest) => request<GetPartnersResponse>({
    method: "GET",
    uri: "entities/partners/",
    params,
});
