import { useFormik } from "formik";
import { FormEvent } from "react";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import useTranslation from "../../../../hooks/useTranslation";
import {
    FormContainer, HeaderContainer, HeaderTitle, SubTitle,
} from "../../../filters/FiltersModalComponents";
import { TextButton } from "../../../ui/button/ButtonStyles";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import DatePicker from "../../../ui/datepicker/DatePicker";
import createdByFilterStore from "../../../../store/filters/createdByFilterStore";
import Button from "../../../ui/button/Button";
import employeeStore from "../../../../store/user/employee/employeeStore";
import FloatInput from "../../../ui/textInput/FloatInput";
import { EmployeesFiltersTypes } from "../../../../types/employeesTypes";
import rolesFilterStore from "../../../../store/filters/rolesFilterStore";
import { validateDateRange } from "../../../../helpers/validationHelper";

interface EmployeesFiltersProps {
    closeModal: () => void;
}

const EmployeesFilters = ({ closeModal }: EmployeesFiltersProps) => {
    const { t, translate } = useTranslation();
    const initialValues: EmployeesFiltersTypes = { ...employeeStore.filters };

    const formik = useFormik<EmployeesFiltersTypes>({
        initialValues,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!employeeStore.filters?.created_to,
        },
        enableReinitialize: true,
        onSubmit: (fields) => {
            employeeStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        employeeStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.employees.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="role"
                    options={rolesFilterStore.options}
                    placeholder={t.filters.fields.role}
                />

                <FloatInput
                    formik={formik}
                    name="username"
                    placeholder={t.filters.fields.name}
                />

                <FloatInput
                    formik={formik}
                    name="email"
                    placeholder={t.filters.fields.email}
                />

                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                <DatePicker
                    formik={formik}
                    maxDate={formik.values.created_to}
                    name="created_from"
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    minDate={formik.values.created_from}
                    name="created_to"
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <FloatSelect
                    formik={formik}
                    name="created_by"
                    options={createdByFilterStore.options}
                    placeholder={t.filters.fields.createdBy}
                />

                <Button variant="primary" type="submit" isLoading={employeeStore.loading}>{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(EmployeesFilters);
