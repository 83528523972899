import { flow } from "mobx";

import deactivateAlerts from "../../../api/alerts/deactivateAlerts";
import { getAlerts } from "../../../api/alerts/getAlerts";
import { parseDateFromServer } from "../../../helpers";
import { AlertsTypes } from "../../../types/alertsTypes";
import dataStore from "../../common/dataStore";
import activateAlerts from "../../../api/alerts/activateAlerts";

class TgGroupsAlertsStore extends dataStore<AlertsTypes, null, null> {
    constructor() {
        super("TgGroupsAlertsStore");
    }

    protected fetchDataByIdMethod = getAlerts;

    protected deactivateSelectedItemsMethod = deactivateAlerts;

    protected activateSelectedItemsMethod = activateAlerts;

    fetchData = flow(function* fetchData(this: TgGroupsAlertsStore, id: string) {
        const response = yield this.fetchDataByIdWithoutSet({ tg_group: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<AlertsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
            this._pagination.totalActiveCount = response.data.response.total_active_count;
        }
    });
}

const tgGroupsAlertsStore = new TgGroupsAlertsStore();

export default tgGroupsAlertsStore;
