import { TgGroupsFiltersTypes, TgGroupsTypes } from "../../types/tgGroupsTypes";
import request from "../request";

type GetTgGroupsRequest = TgGroupsFiltersTypes & {
    limit?: number
    offset?: number
    ordering?: string
}

interface GetTgGroupsResponse {
    message: string,
    response: {
        count: number
        results: TgGroupsTypes[];
    }
}

export const getTgGroups = (params?: GetTgGroupsRequest) => request<GetTgGroupsResponse>({
    method: "GET",
    uri: "telegram-groups/",
    params,
});
