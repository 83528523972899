import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";

import * as Yup from "yup";
import useTranslation from "../../../../hooks/useTranslation";
import tgGroupsStore from "../../../../store/alerts/tgGroups/tgGroupsStore";
import createdByFilterStore from "../../../../store/filters/createdByFilterStore";
import filtersTgGroupsStore from "../../../../store/filters/tgGroupsFiltersStore";
import { TgGroupsFiltersTypes } from "../../../../types/tgGroupsTypes";
import {
    FormContainer, HeaderContainer, HeaderTitle, SubTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import DatePicker from "../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { validateDateRange } from "../../../../helpers/validationHelper";

interface TgGroupsFiltersProps {
    closeModal: () => void;
}

const TgGroupsFilters = ({ closeModal }: TgGroupsFiltersProps) => {
    const { t, translate } = useTranslation();

    const initialValues: TgGroupsFiltersTypes = { ...tgGroupsStore.filters };

    const formik = useFormik<TgGroupsFiltersTypes>({
        initialValues,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!tgGroupsStore.filters?.created_to,
        },
        enableReinitialize: true,
        onSubmit: (fields) => {
            tgGroupsStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        tgGroupsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.tgGroups.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    placeholder={t.filters.fields.groups}
                    options={filtersTgGroupsStore.options}
                />

                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                <DatePicker
                    formik={formik}
                    name="created_from"
                    maxDate={formik.values.created_to}
                    placeholder={t.filters.fields.from}
                />
                <DatePicker
                    formik={formik}
                    name="created_to"
                    minDate={formik.values.created_from}
                    placeholder={t.filters.fields.to}
                    isAddMilliseconds
                />
                <FloatSelect
                    formik={formik}
                    name="created_by"
                    options={createdByFilterStore.options}
                    placeholder={t.filters.fields.createdBy}
                />

                <Button variant="primary" type="submit" isLoading={tgGroupsStore.loading}>{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(TgGroupsFilters);
