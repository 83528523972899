import useTranslation from "../../../../../hooks/useTranslation";
import { TableLoaderContainer } from "../../TableComponents";
import Loader from "../../../loader/Loader";

const TableLoader = () => {
    const { t } = useTranslation();

    return (
        <TableLoaderContainer>
            <Loader size="32" variant="tertiary" />
            <p>{t.spinner.text}</p>
        </TableLoaderContainer>
    );
};

export default TableLoader;
