import { AUTH_STORE_LOCAL_STORAGE_KEY } from "../constants/authStore";

export const getStorageTokens = () => {
    const tokens = {
        access: "",
        refresh: "",
    };
    const authStoreData = localStorage.getItem(AUTH_STORE_LOCAL_STORAGE_KEY);
    if (authStoreData) {
        const storeTokens = JSON.parse(authStoreData);
        tokens.access = storeTokens._accessToken || tokens.access;
        tokens.refresh = storeTokens._refreshToken || tokens.refresh;
    }
    return tokens;
};
