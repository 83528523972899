/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { AxiosResponse } from "axios";

import { GetFileStatusResponseType, getListsElementsFileStatus } from "./getListsFileElementsStatus";

export async function generateListsElementsFile(
    fileId: string,
    maxRetries = 5_000,
    retryDelay = 2_000,
) {
    let attemptsCount = 0;

    while (attemptsCount < maxRetries) {
        const response: AxiosResponse<GetFileStatusResponseType, any> = await getListsElementsFileStatus(fileId);

        if (response.data.response.status === "SUCCESS") {
            return;
        }

        if (response.data.response.status === "ERROR") {
            throw new Error(response.data.response.message);
        }

        attemptsCount++;
        if (attemptsCount < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
    }
}
