import { Hyperlink, HyperLinkInputContainer, InputLabel } from "./InputStyles";

interface HyperlinkTextInputProps {
    store: any,
    navigateTo: string;
    label: string;
    filterName: any
    hyperlinkName?: string | null;
    withActionButtons?: boolean;
    isGrid?: boolean;
}

const HyperlinkFilterTextInput = ({
    hyperlinkName,
    store,
    filterName,
    navigateTo,
    label,
    isGrid,
    withActionButtons = true,
}: HyperlinkTextInputProps) => {
    const setFiltersOnPageRedirect = () => {
        let newFilters = { [filterName]: hyperlinkName };
        if (store.defaultFilters) {
            newFilters = { ...store.defaultFilters, ...newFilters };
        }
        store.setFilters(newFilters);
    };

    return (
        <HyperLinkInputContainer $isGrid={isGrid} $isFullWidth={withActionButtons}>
            <InputLabel>{label}</InputLabel>
            {
                hyperlinkName
                    ? (
                        <Hyperlink
                            to={navigateTo}
                            onClick={setFiltersOnPageRedirect}
                        >
                            {hyperlinkName}
                        </Hyperlink>
                    )

                    : <span>-</span>
            }
        </HyperLinkInputContainer>
    );
};
export default HyperlinkFilterTextInput;
