import styled from "styled-components";

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: baseline;
`;

export const WarningIconStyle = styled.div`
  height: 12px;
  width: 12px;
  margin-bottom: 1px;
`;
