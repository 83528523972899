import { ItemType } from "antd/es/menu/hooks/useItems";

import { permissionsMap } from "../../../../../constants/permissions";
import { ReactComponent as DangerIcon } from "../../../../../resources/icons/dangerIcon.svg";
import { ReactComponent as RedBinIcon } from "../../../../../resources/icons/redBinIcon.svg";
import { ReactComponent as TickCircleIcon } from "../../../../../resources/icons/tickCircleIcon.svg";
import { ActionModalType } from "../../../../../types/actionModalTypes";
import MenuDropdownItem from "../../../../ui/menuDropdown/MenuDropdownItem";
import { NotificationAction } from "../../data";

export const getNotificationsMenuItems = (
    store: any,
    userPermissions: string[],
    translate: (text: string) => string,
    toggleNotificationsStatus: (type: NotificationAction) => void,
    toggleActionModal: (type: ActionModalType) => void,
): ItemType[] => {
    const itemsPropsList = [
        {
            key: "check-notifications-component",
            label: <MenuDropdownItem icon={<TickCircleIcon />} title={translate("buttons.check")} />,
            disabled: !store?.hasUncheckedItems,
            permission: permissionsMap.notifications.changeStatus,
            onClick: () => toggleNotificationsStatus(NotificationAction.Check),
        },
        {
            key: "uncheck-notifications-component",
            label: <MenuDropdownItem icon={<DangerIcon />} title={translate("buttons.uncheck")} />,
            disabled: !store?.hasCheckedItems,
            permission: permissionsMap.notifications.changeStatus,
            onClick: () => toggleNotificationsStatus(NotificationAction.Uncheck),
        },
        {
            key: "delete-notifications-component",
            label: <MenuDropdownItem icon={<RedBinIcon />} title={translate("buttons.delete")} />,
            disabled: !store?.checkedItems.length,
            permission: permissionsMap.notifications.delete,
            onClick: () => toggleActionModal("delete"),
        },
    ];

    return itemsPropsList.filter((menuItem) => userPermissions.includes(menuItem.permission));
};
