import { observer } from "mobx-react-lite";
import { memo, ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";

import routes from "../../../../../constants/routes";
import withPermission from "../../../../../hocs/withPermissions";
import listElementsStore from "../../../../../store/list/listElementsStore";
import { ElementsTypes } from "../../../../../types/elementsTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import TabHeader from "../../../../tabHeader/TabHeader";
import useTranslation from "../../../../../hooks/useTranslation";

interface ProtectedElementsTableProps {
    permission: string;
    actionsContent: { content: ReactNode, key: string }[];
}

type WrappedComponentProps = Omit<ProtectedElementsTableProps, "permission">

const columns: ColumnType<ElementsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.elementName",
        field: "name",
        isResizable: true,
        width: "452px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${routes.elements.href}/${dataItem.id}`}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.elementId", field: "id" },
    {
        header: "table.header.createdAt",
        field: "created",
        sortable: true,
    },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        sortable: true,
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.status",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={routes.elements.href}
            />
        ),
    },
];

const WrappedComponent = observer(({ actionsContent }: WrappedComponentProps) => {
    const { id } = useParams();
    const { t } = useTranslation();

    const tablePagination = {
        ...listElementsStore.pagination,
        setPaginationPage: listElementsStore.setPaginationPage,
        setPaginationSize: listElementsStore.setPaginationSize,
    };

    useEffect(() => {
        listElementsStore.fetchData(id || "");
    }, [listElementsStore.pagination, listElementsStore.ordering]);

    return (
        <>
            <TabHeader
                title={t.lists.text.elements}
                actionsContent={actionsContent}
            />

            <Table
                columns={columns}
                pagination={tablePagination}
                data={listElementsStore.data}
                sortMode={listElementsStore.ordering}
                loading={listElementsStore.loading}
                checkedItems={listElementsStore.checkedItems}
                onSort={listElementsStore.toggleSort}
                onCheckboxChange={listElementsStore.toggleItem}
                onMainCheckboxChange={listElementsStore.toggleMainItem}
            />
        </>
    );
});

const ProtectedElementsTable = ({ permission, actionsContent }: ProtectedElementsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission actionsContent={actionsContent} />;
};

export default memo(ProtectedElementsTable);
