/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-await-in-loop */
import { AxiosResponse } from "axios";

import { GetFileStatusResponseType, getTransactionsFileStatus } from "./getTransactionsFileStatus";

export async function generateTransactionsFile(
    fileId: string,
    maxRetries = 5_000,
    retryDelay = 2_000,
) {
    let attemptsCount = 0;

    while (attemptsCount < maxRetries) {
        try {
            const response: AxiosResponse<GetFileStatusResponseType, any> = await getTransactionsFileStatus(fileId);

            if (response.data.response.status === "SUCCESS") return;
        } catch (error) {
            throw new Error(`Failed to generate the file after ${maxRetries} attempts`);
        }

        attemptsCount++;
        if (attemptsCount < maxRetries) {
            await new Promise((resolve) => setTimeout(resolve, retryDelay));
        }
    }
}
