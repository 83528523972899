import { getAlerts } from "../../../api/alerts/filters/getAlerts";
import { AlertsTypes } from "../../../types/alertsTypes";
import FiltersDataStore from "../../common/filtersStore";

class AlertsFiltersStore extends FiltersDataStore<AlertsTypes> {
    protected fetchListMethod = getAlerts;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersAlertsStore = new AlertsFiltersStore();

export default filtersAlertsStore;
