import dataStore from "../../common/dataStore";
import { getRolesPermissions } from "../../../api/roles/getRolesPermissions";
import { RolePermissionType } from "../../../types/rolesTypes";

class RolePermissionStore extends dataStore<RolePermissionType, null, null> {
    protected fetchDataMethod = getRolesPermissions;
}

const rolePermissionStore = new RolePermissionStore();

export default rolePermissionStore;
