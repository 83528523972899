import { FC } from "react";
import { TextAreaProps } from "antd/es/input/TextArea";
import {
    FloatInputCommonContainer, FloatInputLabel, FloatTextAreaContainer, FloatTextAreaStyle,
} from "./InputStyles";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { TextInputProps } from "./TextInput";
import useFloatInput from "../../../hooks/useFloatInputs";

export type FloatTextAreaProps = {
    value?: string
} & TextAreaProps & TextInputProps

const FloatTextArea: FC<FloatTextAreaProps> = ({
    formik,
    serverErrors,
    placeholder,
    name = "",
    resetServerError,
    isCommonError,
    ...props
}) => {
    const inputArguments = {
        fieldProps: props, name, formik, serverErrors, resetServerError, isCommonError,
    };

    const {
        isFieldError, fieldError, isOccupied, fieldValue, setFocus, handleChange, handleBlur,
    } = useFloatInput(inputArguments);

    return (
        <FloatInputCommonContainer>
            <FloatTextAreaContainer
                onBlur={() => setFocus(false)}
                onFocus={() => setFocus(true)}
            >
                <FloatTextAreaStyle
                    {...props}
                    value={fieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    $isError={isFieldError}
                    name={name}
                />
                <FloatInputLabel $label={isOccupied} htmlFor={name} $isError={isFieldError}>
                    {placeholder}
                </FloatInputLabel>
            </FloatTextAreaContainer>
            {isFieldError && fieldError && ErrorLabel(fieldError || "")}
        </FloatInputCommonContainer>
    );
};

export default FloatTextArea;
