import { flow } from "mobx";
import dataStore from "../../common/dataStore";
import { getServices } from "../../../api/services/getServices";
import { ServicesTypes } from "../../../types/servicesTypes";
import { NewServicesType } from "../../../types/partnerTypes";

class PartnerServicesStore extends dataStore<ServicesTypes, any, NewServicesType> {
    protected fetchDataByIdMethod = getServices;

    fetchData = flow(function* fetchData(this: PartnerServicesStore, id: number | string) {
        yield this.fetchDataById({ partner: id });
    });
}

const partnerServicesStore = new PartnerServicesStore();

export default partnerServicesStore;
