import styled, { css, CSSProperties, Interpolation } from "styled-components";

import { ReactComponent as SortIcon } from "../../../resources/icons/table/sort.svg";
import { colors } from "../../../styles/variables";
import Checkbox from "../checkbox/Checkbox";
import { GlobalLoaderWrapper } from "../loader/LoaderComponents";

export const SortedHeader = styled.span`
    font-weight: 600;
`;

export const TableRootContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`;

export const TableHeaderContainer = styled.div<{ $width?: number | null }>`
    width: ${(props) => `${props.$width}px`};
    position: relative;
`;

export const BarContainer = styled.div`
    position: absolute;
    right: 6px;
    top: 12px;
    bottom: 12px;
    width: 8px;
    display: flex;
    justify-content: center;
    cursor: col-resize;
`;

export const Bar = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${colors.inputDefaultBorder};
`;

export const TableContainer = styled.div<{
    $shrinkHeight: boolean,
    $styles: Interpolation<CSSProperties>,
    $scrollable?: boolean
}>`
    position: relative;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: max-content;
    overflow-x: auto;
    min-height: ${(props) => (props.$shrinkHeight ? "200px" : "initial")};

    ${(props) => {
        if (props.$scrollable) {
            return `
            th {
               position: sticky;
               top: -1px;
               z-index: 2;
               padding: 0;
            }
`;
        }
        return "";
    }}

    ${({ $styles }) => $styles};
`;

export const TableCheckBoxComponent = styled(Checkbox)`
    padding: 0px 8px 0px 12px;
`;

export const EmptyTable = styled.div`
    display: flex;
    padding: 64px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    border: 2px dashed ${colors.inputDefaultBorder};
    background: ${colors.emptyTableBackground};

    h3, p {
        margin: 0;
    }

`;

export const Table = styled.table`
    table-layout: auto;
    border-collapse: collapse;
    position: relative;
`;

export const TH = styled.th<{ $sortable?: boolean, $width?: number | null }>`
    width: ${(props) => props.$width && `${props.$width}px`};
    background-color: ${colors.white};
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    cursor: ${(props) => (props.$sortable ? "pointer" : "default")};

    *:not(svg) {
        color: ${colors.textDefault};
    }

    &&:first-child {
        border-radius: 12px 0 0 0;
    }

    &&:last-child {
        border-radius: 0 12px 0 0;
    }

`;

export const SortIconComponent = styled(SortIcon)<{ $colorizeASC?: boolean, $colorizeDESC?: boolean }>`
    && > path {
        fill: ${(props) => (props.$colorizeASC ? colors.sortIconActive : colors.sortIcon)};
    }

    path:nth-child(2) {
        fill: ${(props) => (props.$colorizeDESC ? colors.sortIconActive : colors.sortIcon)};
    }
`;

export const THContainer = styled.span<{
    $padding?: string,
    $styles: Interpolation<CSSProperties>,
    $isHoverActive?: boolean
}>`
    display: flex;
    align-items: center;
    padding: ${(props) => (props.$padding ? props.$padding : "13px 16px 13px 8px;")};
    ${({ $styles }) => $styles};

    &:hover {
        background-color: ${(props) => props.$isHoverActive && colors.tableRowZebraBackground};
    }
`;

export const TR = styled.tr<{ $selected?: boolean, $isHighlighted?: boolean }>`
    cursor: default;
    background-color: ${(props) => (props.$selected ? colors.tableRowSelectedBackground : colors.white)};

    ::selection {
        background-color: ${(props) => (props.$isHighlighted ? "default" : "transparent")};
    }

    &:nth-of-type(odd) {
        background-color: ${(props) => (
        props.$selected ? colors.tableRowSelectedBackground : colors.tableRowZebraBackground
    )}
    }

    &&:last-child td:first-child {
        border-bottom-left-radius: 12px;
    }

    &&:last-child td:last-child {
        border-bottom-right-radius: 12px;
    }
`;

TR.defaultProps = {
    $isHighlighted: true,
};

export const TD = styled.td<{
    $styles: Interpolation<CSSProperties>,
    $padding?: string,
    $center?: boolean,
    $width?: number | null,
}>`
    max-width: 180px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font: 400 14px Roboto, sans-serif;
    height: 64px;
    width: ${(props) => (props.$width)};
    padding: ${(props) => (props.$padding ? props.$padding : "0 16px 0 8px;")};
    text-align: ${(props) => (props.$center ? "center" : "left")};
    color: ${colors.activeText};
    ${({ $styles }) => $styles};
`;

export const TableLoaderContainer = styled(GlobalLoaderWrapper)`
    p {
        margin: 4px 0 0;
    }
`;

export const tableContainerStyle = css`
    max-height: 67vh;
    overflow: auto;

    @media only screen and (min-height: 900px) {
        max-height: 70vh;
    }
`;

export const multiplyTableContainerStyle = css`
    max-height: 61vh;
    overflow: auto;

    @media only screen and (min-height: 900px) {
        max-height: 64vh;
    }
`;
