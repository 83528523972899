import { NewRuleTypes, RulesPacksTypes } from "../../types/rulesPacksTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: RulesPacksTypes,
}

const postRulesPack = (data: NewRuleTypes) => request<ResponseType>({
    method: "POST",
    uri: "rules-packs/",
    data,
});

export default postRulesPack;
