import { Fragment } from "react";

import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper } from "../../../../../ui/details/DetailsComponents";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import { EmptyTitle } from "./SectionsComponents";

const MarkersInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const hasMarkers = transaction?.markers.length;

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.markersTitle}
            </DetailPageHeader>

            {hasMarkers
                ? (
                    <DetailsBodyWrapper>
                        {transaction?.markers.map((marker) => (
                            <Fragment key={marker?.id}>
                                <PreInput
                                    label={t.table.header.limitName}
                                    value={marker?.name}
                                />
                                <PreInput
                                    label={t.table.header.marker}
                                    value={marker?.marker}
                                />
                            </Fragment>
                        ))}
                    </DetailsBodyWrapper>
                )
                : <EmptyTitle>{t.transactions.text.markersEmpty}</EmptyTitle>}
        </DetailsSectionWrapper>
    );
};

export default MarkersInfo;
