import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthRoutes } from "../../../../constants/routes";
import useTranslation from "../../../../hooks/useTranslation";
import { PrimaryButton } from "../../../ui/button/ButtonStyles";
import { HeaderText } from "../../../ui/TextStyle";
import { HeaderSection } from "../../auth/login/authTFA/AuthTFAStyles";
import { FirstLoginWelcomePageWrapper, InfoSection } from "./WelcomeFistLoginStyle";
import authStore from "../../../../store/auth/authStore";

const PagesFirstLogin = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const navigateToNextStep = () => {
        navigate(AuthRoutes.firstLoginChangePassword.href);
    };

    useEffect(() => {
        const token = searchParams.get("token") || "";
        if (token) {
            authStore.initializeLoginFromEmail(token);
        } else {
            navigate(AuthRoutes.login.href);
        }
    }, [searchParams]);

    return (
        <FirstLoginWelcomePageWrapper>
            <HeaderSection>
                <HeaderText>{t.firstLoginWelcomePage.text.welcome}</HeaderText>
                <div>
                    {t.firstLoginWelcomePage.text.followSteps}
                </div>
            </HeaderSection>
            <InfoSection>
                <li>{t.firstLoginWelcomePage.text.step1}</li>
                <li>{t.firstLoginWelcomePage.text.step2}</li>
            </InfoSection>
            <PrimaryButton onClick={navigateToNextStep}>
                {t.buttons.ok}
            </PrimaryButton>
        </FirstLoginWelcomePageWrapper>
    );
};

export default PagesFirstLogin;
