import { getTranslatedHandbook, proxyOptionsHandbook } from "../../../../../../constants/handbooks";
import { permissionsMap } from "../../../../../../constants/permissions";
import routes from "../../../../../../constants/routes";
import { capitalizeFirstLetter } from "../../../../../../helpers";
import useTranslation from "../../../../../../hooks/useTranslation";
import transactionStore from "../../../../../../store/transaction/transactionStore";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper, Row } from "../../../../../ui/details/DetailsComponents";
import HyperlinkFilterTextInput from "../../../../../ui/textInput/HyperlinkFilterTextInput";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import ProtectedOperationButtons from "../operationButtons/ProtectedOperationButtons";
import { ColoredTextField } from "./SectionsComponents";

const TechnicalInfo = ({ transaction }: TransactionInfoProps) => {
    const { t, translate } = useTranslation();

    const isProxyOption = getTranslatedHandbook(translate, proxyOptionsHandbook)
        .find(({ value }) => value && value === transaction?.is_proxy?.toString())?.label;

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.technicalTitle}
            </DetailPageHeader>

            <DetailsBodyWrapper>
                <ColoredTextField
                    label={t.transactions.placeholders.isProxy}
                    value={capitalizeFirstLetter(isProxyOption)}
                />
                <PreInput
                    label={t.transactions.placeholders.type}
                    value={capitalizeFirstLetter(transaction?.ip_type)}
                />
                <PreInput
                    label={t.transactions.placeholders.timezoneOffset}
                    value={transaction?.timeZoneOffset}
                />
            </DetailsBodyWrapper>

            <Row>
                <HyperlinkFilterTextInput
                    navigateTo={routes.transactions.href}
                    store={transactionStore}
                    filterName="ip"
                    label={t.table.header.ipAddresses}
                    hyperlinkName={transaction?.ip}
                />
                <ProtectedOperationButtons
                    label={t.table.header.ipAddresses}
                    value={transaction?.ip}
                    permission={permissionsMap.elements.add}
                    fieldType="IP"
                    listInfo={transaction?.ip_list_info}
                    transaction={transaction}
                />
            </Row>
        </DetailsSectionWrapper>
    );
};

export default TechnicalInfo;
