import { Route, Routes } from "react-router-dom";
import AuthorizationLayout from "../authorizationLayout/AuthorizationLayout";
import { AuthRoutes } from "../../constants/routes";
import PagesFirstLogin from "../pages/firstLogin/WelcomePage/PagesFirstLogin";
import PagePasswordConfirmation from "../pages/firstLogin/changePassword/PagePasswordConfirmation";
import PageSetTFA from "../pages/firstLogin/setUpTFA/PageSetTFA";
import PageChangePassword from "../pages/firstLogin/changePassword/PageChangePassword";

const FirstLoginRoutes = () => (
    <Routes>
        <Route path="/" element={<AuthorizationLayout />}>
            <Route path={AuthRoutes.firstLoginWelcomePage.href} element={<PagesFirstLogin />} />
            <Route path={AuthRoutes.firstLoginChangePassword.href} element={<PageChangePassword />} />
            <Route
                path={AuthRoutes.firstLoginChangedPasswordSuccess.href}
                element={<PagePasswordConfirmation />}
            />
            <Route path={AuthRoutes.firstLoginSetUpTFA.href} element={<PageSetTFA />} />
        </Route>
    </Routes>
);

export default FirstLoginRoutes;
