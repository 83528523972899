import { FunctionComponent, SVGProps } from "react";

import withPermission from "../../hocs/withPermissions";
import { MenuItemTitle, MenuItem } from "./MenuComponents";

interface WrappedComponentType {
    route: string;
    title: string;
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

type ProtectedMenuItemProps = WrappedComponentType & {
    permission?: string | string[];
}

const WrappedComponent = ({ route, title, Icon }: WrappedComponentType) => (
    <MenuItem to={route}>
        <Icon />
        <MenuItemTitle>{title}</MenuItemTitle>
    </MenuItem>
);

const ProtectedMenuItem = ({ permission = [], ...props }: ProtectedMenuItemProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default ProtectedMenuItem;
