import { capitalizeFirstLetter } from "./index";

export const generateHeaderName = (...names: string[]) => names.map((name) => capitalizeFirstLetter(name)).join("/");

export const splitHeaderName = (header: string) => {
    const splitted = header.split("/");
    if (splitted.length > 1) {
        const lastElement = splitted.pop();
        return { title: splitted.join("/"), activeTitle: `/${lastElement}` };
    }
    return { activeTitle: header };
};
