import routes from "../../../../constants/routes";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";

interface DataType {
    id: string | number;
    name?: string;
    username?: string;
    message?: string;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const DetailsLinkCell = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <TableFieldsDetailsLink dataItem={dataItem} href={routes.transactions.href} />
);

export default DetailsLinkCell;
