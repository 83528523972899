import styled from "styled-components";
import { colors } from "../../styles/variables";

export const Container = styled.header`
    height: max-content;
    padding: 15px 20px;
    display: flex;
    gap: 20px;
    align-self: stretch;
    border-radius: 12px;
    background-color: ${colors.white};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ActionsAndTabsContainer = styled.section`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
`;

export const ActionsContainer = styled.section`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: baseline;
`;

export const Title = styled.h2`
    color: ${colors.activeText};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
    margin: 0;
    white-space: nowrap;
`;

export const SecondaryTitleActive = styled.h3`
    color: ${colors.activeText};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    margin: 0;
    white-space: nowrap;
    text-align: end;
    margin-left: 10px;
`;

export const SecondaryTitle = styled(SecondaryTitleActive)`
    color: ${colors.textDefault};
    margin: 0;
`;
