import { ButtonHTMLAttributes, ReactNode } from "react";
import { SmallButtonComponent } from "./ButtonComponents";
import Loader from "../loader/Loader";
import { ButtonVariantType } from "../../../types/buttonTypes";

export type SmallButtonShapeType = "square" | "rectangle";

export type SmallButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant: ButtonVariantType;
    shape?: SmallButtonShapeType;
    isLoading?: boolean;
    isCapitalized?: boolean;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
};

const SmallButton = ({
    isLoading, iconLeft, iconRight, shape, isCapitalized = true, ...props
}: SmallButtonProps) => (
    <SmallButtonComponent
        {...props}
        $shape={shape}
        $variant={props.variant}
        $isCapitalized={isCapitalized}
        $isLoading={isLoading}
    >
        <>
            {isLoading ? <Loader variant={props.variant} size="16" /> : iconLeft}
            {props.children}
            {iconRight}
        </>
    </SmallButtonComponent>
);

export default SmallButton;
