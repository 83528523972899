import { Body, Container, Title } from "./RolesModalComponents";
import useTranslation from "../../../../../../hooks/useTranslation";
import RolesTable from "./table/RolesTable";

const RolesModal = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Title>
                {t.roles.text.pluralTitle}
            </Title>
            <Body>
                <RolesTable />
            </Body>
        </Container>
    );
};

export default RolesModal;
