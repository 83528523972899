import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import Routes from "../../../../../constants/routes";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import serviceStore from "../../../../../store/entities/service/serviceStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import HyperlinkTextInput from "../../../../ui/textInput/HyperlinkTextInput";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditServiceModal from "./EditServiceModal";
import ProtectedServiceRulesPackTable from "./tabsTables/ProtectedServiceRulesPacksTable";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

const ServiceDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = serviceStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTabTitle = id ? `${t.entities.services.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.entities.title, t.entities.services.title, currentTabTitle);

    const preInputParamsList = [
        {
            key: "service-details-name",
            label: t.table.header.serviceName,
            value: selectedItem?.name,
        },
        {
            key: "service-details-id",
            label: t.table.header.serviceId,
            value: selectedItem?.id.toString(),
        },
        {
            key: "service-details-processing-service-id",
            label: t.table.header.processingServiceId,
            value: selectedItem?.service_id?.toString(),
        },
        {
            key: "service-details-partner-name",
            isHyperLink: true,
            label: t.table.header.partnerName,
            hyperlinkName: selectedItem?.partner_name.toString(),
            elementId: Number(selectedItem?.partner),
            permission: permissionsMap.partners.view,
            sourceId: id,
            navigateTo: Routes.partners.href,
            navigateFrom: Routes.services.href,
        },
        {
            key: "service-details-partner-id",
            label: t.table.header.partnerId,
            value: selectedItem?.partner?.toString(),
        },
        {
            key: "service-details-email",
            label: t.table.header.email,
            value: selectedItem?.email,
        },
        {
            key: "service-details-phone",
            label: t.table.header.phone,
            value: selectedItem?.phone,
        },
        {
            key: "service-details-account-manager",
            label: t.table.header.accountManager,
            value: selectedItem?.account_manager,
        },
        {
            key: "service-details-country",
            label: t.table.header.country,
            value: selectedItem?.country?.toString(),
        },
        {
            key: "service-details-address",
            label: t.table.header.address,
            value: selectedItem?.address,
        },
        {
            key: "service-details-timezone",
            label: t.table.header.timezone,
            value: selectedItem?.timezone?.toString(),
        },
        {
            key: "service-details-site-url",
            label: t.table.header.siteUrl,
            value: selectedItem?.site_url,
        },
    ];

    const handleNavigate = () => navigateBackward({ tab: Tabs.SERVICES });

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) serviceStore.fetchSelectedItem(id, true);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.entities.services.details.title} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.services.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputParamsList.map(({ isHyperLink, ...props }) => (isHyperLink
                            ? <HyperlinkTextInput {...props} /> : <PreInput {...props} />))}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.table.header.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <ProtectedServiceRulesPackTable
                    serviceId={Number(id)}
                    permission={permissionsMap.rules_packs.view}
                />

                <EditServiceModal
                    isOpen={isEditModalVisible}
                    buttonText={t.buttons.save}
                    title={t.entities.services.edit.title}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(ServiceDetails);
