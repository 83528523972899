import styled from "styled-components";

import { colors } from "../../../../../../styles/variables";

const gap = "8px";
const deleteButtonWidth = "32px";
const fileStatusIconWidth = "24px";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const UploadAreaComponent = styled.label<{ $isFile: boolean }>`
    position: relative;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: ${gap};
    align-self: stretch;

    border-radius: 12px;
    border: 1px dashed #D4D7E3;
    background: #FFF;

    ${(props) => {
        if (props.$isFile) {
            return `
                flex-direction: row;
                justify-content: space-between;
                height: 32px;
                border: 1px solid #D4D7E3;
            `;
        }
        return `
            flex-direction: column;
            height: 136px;
            justify-content: center;
            border: 1px dashed #D4D7E3;

            cursor: pointer;
            transition: border-color 0.3s ease;

            &:hover {
                border-color: ${colors.buttonPrimary};
            }
        `;
    }}
`;

const FileInputComponent = styled.input<{ $isFile: boolean }>`
    display: ${(props) => (props.$isFile ? "none" : "inline-block")};
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
`;

const UploadedFileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${gap};
    width: 100%;
`;

const TitleComponent = styled.span<{ $color?: string }>`
    color: ${(props) => (props.$color || colors.textDefault)};

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 105%;
`;

const SubtitleComponent = styled(TitleComponent)`
    font-size: 12px;
    font-weight: 400;
`;

const TitleWrapper = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - ${deleteButtonWidth} - ${fileStatusIconWidth} - 2 * ${gap});
`;

export {
    Box,
    FileInputComponent,
    UploadAreaComponent,
    UploadedFileContainer,
    TitleComponent,
    TitleWrapper,
    SubtitleComponent,
};
