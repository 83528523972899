import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import Routes from "../../../../../constants/routes";
import { Tabs } from "../../../../../constants/tabs";
import rulesPackServicesStore from "../../../../../store/rulesPack/rulesPackServicesStore";
import { RulesPacksServicesTypes } from "../../../../../types/rulesPacksServicesTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import StatusBadge from "../../../../ui/textField/StatusBadge";

interface RulesPacksServicesTableProps {
    isRadioButtonVisible?: boolean;
    ruleId?: string;
}

const columns: ColumnType<RulesPacksServicesTypes>[] = [
    {
        field: "radio_button",
        padding: "0px 8px 0px 12px",
        cellStyles: { width: "0px" },
    },
    {
        header: "table.header.serviceName",
        field: "service_name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.services.href}/${dataItem.service}`}
                state={{ previousTab: Tabs.SERVICES }}
            >
                {dataItem.service_name}
            </TableMainLink>
        ),
    },
    { header: "table.header.serviceId", field: "service", sortable: true },
    {
        header: "table.header.isActive",
        field: "service_is_active",
        Component: (field) => {
            const { dataItem } = field;
            return (
                <StatusBadge
                    variant={dataItem.service_is_active ? "success" : "inactive"}
                    multiLanguageText={dataItem.service_is_active ? "status.active" : "status.inactive"}
                />
            );
        },
    },
    {
        header: "table.header.partnerName",
        field: "service_partner_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.partnerId", field: "service_partner_id", sortable: true },
    {
        header: "table.header.email",
        field: "service_email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "service_phone" },
    {
        header: "table.header.accountManager",
        field: "service_account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.timezone", field: "service_timezone" },
    {
        header: "table.header.description",
        field: "service_description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                href={`${Routes.services.href}/${dataItem.service}`}
                state={{ previousTab: Tabs.SERVICES }}
            />
        ),
    },
];

const RulesPacksServicesTable = ({ ruleId, isRadioButtonVisible }: RulesPacksServicesTableProps) => {
    const { setPaginationPage, setPaginationSize } = rulesPackServicesStore;

    const tablePagination = {
        ...rulesPackServicesStore.pagination,
        setPaginationPage,
        setPaginationSize,
    };

    useEffect(() => {
        if (ruleId) rulesPackServicesStore.fetchData(ruleId);
    }, [
        rulesPackServicesStore.pagination,
        rulesPackServicesStore.filters,
        rulesPackServicesStore.ordering,
    ]);

    return (
        <Table
            columns={columns}
            data={rulesPackServicesStore.data}
            pagination={tablePagination}
            checkedItems={rulesPackServicesStore.checkedItems}
            sortMode={rulesPackServicesStore.ordering}
            loading={rulesPackServicesStore.loading}
            onSort={rulesPackServicesStore.toggleSort}
            onCheckboxChange={rulesPackServicesStore.toggleRadioItem}
            onMainCheckboxChange={rulesPackServicesStore.toggleMainItem}
            isItemSelectionAllowed={isRadioButtonVisible}
        />
    );
};

export default observer(RulesPacksServicesTable);
