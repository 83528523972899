export const downloadFile = (data: Blob, fileName: string) => {
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
};
