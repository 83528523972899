import FiltersDataStore from "../common/filtersStore";
import { getRoles } from "../../api/users/filters/getRoles";
import { RoleFiltersType } from "../../types/rolesTypes";

class FiltersRolesStore extends FiltersDataStore<RoleFiltersType> {
    protected fetchListMethod = getRoles;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: item.name,
        }));
    }
}

const filtersRolesStore = new FiltersRolesStore();

export default filtersRolesStore;
