import useTranslation from "../../hooks/useTranslation";
import ProtectedSmallButton from "../ui/button/ProtectedSmallButton";
import StatusBadge from "../ui/textField/StatusBadge";
import { HeaderContainer, Row, Title } from "./DetailsHeaderComponents";

interface DetailsHeaderProps {
  title: string;
  isStatusActive?: boolean;
  isEditAllowed?: boolean;
  // TODO: remove optional when all permissions are added, plus remove permission assignment in DetailsHeader props
  permission?: string;
  onEdit: () => void;
}

const DetailsHeader = ({
    title, isStatusActive, isEditAllowed, permission = "", onEdit,
}: DetailsHeaderProps) => {
    const { t } = useTranslation();

    return (
        <HeaderContainer>
            <Row>
                <Title>{title}</Title>
                <ProtectedSmallButton
                    title={t.buttons.edit}
                    variant="secondary"
                    permission={permission}
                    disabled={isEditAllowed}
                    onClick={onEdit}
                />
            </Row>

            <Row>
                <Title>{t.details.status}</Title>
                <StatusBadge
                    variant={isStatusActive ? "success" : "inactive"}
                    multiLanguageText={isStatusActive ? "status.active" : "status.inactive"}
                />
            </Row>
        </HeaderContainer>
    );
};

export default DetailsHeader;
