import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import styled from "styled-components";

import { ReactComponent as CloseCrossIcon } from "../../../resources/icons/closeCrossIcon.svg";
import { ReactComponent as PlusIcon } from "../../../resources/icons/select/plusIcon.svg";
import { colors } from "../../../styles/variables";
import Link from "../link/Link";
import { TextInputProps } from "./TextInput";

const InputSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 60px;
    max-width: 420px;
`;

const StyledTextInputFiled = styled.input<{ $isError?: boolean }>`
    display: flex;
    border-radius: 12px;
    border: 1px solid ${(props) => (props.$isError ? colors.error : colors.inputDefaultBorder)};
    background: ${colors.white};
    color: ${(props) => (props.$isError ? colors.error : colors.activeText)};
    padding: 20px 20px 19px;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    &:hover {
        border: 1px solid ${(props) => (props.$isError ? colors.error : colors.inputHoverBorder)};
        background: ${colors.inputHoverBackground};
    }

    &:focus {
        border: 1px solid ${(props) => (props.$isError ? colors.error : colors.buttonPrimary)};
        background: ${colors.inputHoverBackground};
        color: ${(props) => (props.$isError ? colors.error : colors.activeText)};
        outline: none;
    }

    &::placeholder {
        font-family: Roboto;
        font-weight: 500;
        font-size: 16px;
        opacity: 1;
        font-style: normal;
        color: ${(props) => (props.$isError ? colors.error : colors.textDefault)};
    }
`;

const SplitInputStyle = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
`;

const SplitInputCell = styled(StyledTextInputFiled)`
    width: 100%;
    height: 56px;
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    box-sizing: border-box;
    display: block;

    text-align: center;
`;

const InputErrorDisplay = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

const InputFieldReadOnly = styled.input<{ $isModalStyle?: boolean }>`
    &:focus {
        outline: none;
    }

    width: 90%;
    border: none;
`;

const InputTFAKey = styled(InputFieldReadOnly)`
    color: ${colors.activeText};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 105%;
`;

const FloatInputCommonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const FloatInputContainer = styled.div`
    position: relative;
    height: 56px !important;
    align-self: stretch;
`;

const FloatTextAreaContainer = styled.div`
    position: relative;
    align-self: stretch;
`;

const AsteriskComponent = styled.span`
    color: ${colors.error};
`;

const FloatInputLabel = styled.label<{ $label?: boolean, $isError?: boolean }>`
    position: absolute;
    pointer-events: none;
    font-size: 16px;
    font-weight: 500;
    color: ${colors.textDefault};
    left: 20px;
    top: 11px;
    background: inherit;
    transition: 0.2s ease all;

    ${(props) => {
        if (props.$label) {
            return `
                top: 10px;
                color: #BEBEBF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                padding: 0 4px;
                margin-left: -4px;
            `;
        }
        return `
            color: ${props.$isError ? colors.error : colors.textDefault};
            top: 50%;
            transform: translateY(-50%);
         `;
    }}
`;

const IconContainer = styled.span`
    position: absolute;
    top: 18px;
    right: 20px;
    height: 20px;
    width: 20px;
`;

const FloatInputStyle = styled(StyledTextInputFiled).attrs({ as: Input })`
    width: 100%;
    height: 100%;
    padding: 28px 20px 11px 20px;
`;

const FloatTextAreaStyle = styled(StyledTextInputFiled).attrs({ as: Input.TextArea })`
    width: 100%;
    line-height: 20px !important;
    padding: 23px 20px 11px 20px;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const OptionSpace = styled.span`
    height: 5px;
`;

const OptionInnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    height: 20px;
    color: ${colors.activeText};
`;

const FloatSelectStyle = styled(StyledTextInputFiled).attrs({ as: Select })<{ $disabled?: boolean }>`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    border: ${(props) => props.$disabled && "none"};

    &:hover {
        border: ${(props) => props.$disabled && "none"};
    }

    &&.ant-select .ant-select-selector:has(.ant-select-selection-overflow):has(input:disabled) {
        cursor: not-allowed !important;
        border-radius: 12px;
        background-color: ${colors.selectDisabledBackground} !important;
    }
    
    &&.ant-select.ant-select-multiple {
        height: 56px;

        .ant-select-selector {
            &:focus {
                border: 1px solid ${(props) => (props.$isError ? colors.error : colors.buttonPrimary)} !important;
            }
            
            padding: 0 32px 0 10px !important;
        }

        .ant-select-selection-search {
            inset-inline-start: 0;
            inset-inline-end: 0;

            input {
                padding-top: 15px;
                font: inherit;
                font-size: 16px;

                &:focus {
                    border: none;
                }
            }
        }
    }

    &&.ant-select-single .ant-select-selector {
        border: none !important;

        .ant-select-selection-search {
            inset-inline-start: 0;
            inset-inline-end: 0;

            input {
                padding: 28px 20px 11px 20px;
                font: inherit;
                font-size: 16px;
            }
        }
    }

    &:focus {
        border: 1px solid ${(props) => (props.$isError ? colors.error : colors.buttonPrimary)};
    }

    &&.ant-select .ant-select-selector {
        position: absolute;
        width: inherit;
        background: inherit !important;;
        box-shadow: none !important;;
        border-radius: inherit;
        padding: 0;
        
        &:not(:focus-within) {
            border: none !important;
        }

        input {
            cursor: default !important;
            color: transparent !important;
            text-shadow: 0 0 0 black;

            &:disabled {
                cursor: not-allowed !important;
                border-radius: 12px;
                background-color: ${colors.selectDisabledBackground};
            }
        }

        input:focus {
            box-shadow: none !important;
            border: 1px solid ${(props) => (props.$isError ? colors.error : colors.buttonPrimary)};
            border-radius: 12px;
        }

        .ant-select-selection-overflow {
            width: 100%;
            height: 35px;
            box-sizing: border-box;

            .ant-select-selection-item {
                margin: 15px -1px 0 6px;
                padding: 3px 6px;
                border-radius: 6px;
                background: ${colors.tableRowSelectedBackground};
                height: 25px;
            }

            .ant-select-selection-overflow-item-suffix {
                margin: 2px 0 0 4px;
            }

        }

        .ant-select-selection-item {
            padding: 23px 20px 11px 20px;
            font: inherit;
            font-size: 16px;
            height: 100%;
            color: ${colors.activeText};
        }
    }

    &&.ant-select {
        .ant-select-arrow {
            margin-right: 8px;
            font-size: 16px;
        }
        
        .ant-select-clear {
            margin-right: 8px;
        }
    }
`;

const AddIcon = styled(PlusIcon)`
    height: 20px;
    width: 20px;
`;

const RemoveIcon = styled(CloseCrossIcon)`
    height: 20px;
    width: 20px;
    stroke: ${colors.textDefault};
`;

const InputDisplayLabelInRowWrapper = styled.div`
    position: relative;
    border: 1px solid ${colors.inputDefaultBorder};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 19px;
    width: auto;
    align-self: stretch;
`;

const CustomPlaceholder = styled.label`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    pointer-events: none;
    padding: 0;
    color: ${colors.textDefault};
`;

const DescriptionInputWrapper = styled(InputDisplayLabelInRowWrapper)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
`;

const StyledTextarea = styled(TextArea)`
    border: none;
    outline: none;
    box-shadow: none !important;
    flex-grow: 1;
    background: transparent;
    padding: 0;
    color: ${colors.activeText};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: 17px;
    font-family: Roboto, sans-serif;
    text-align: left;
    width: auto;
    min-height: 34px !important;
    align-self: stretch;
`;

const PreTabInput = styled.input.attrs({ as: Input })<{ $isError?: boolean }>`
    display: flex;
    border-radius: 12px;
    background: ${colors.white};
    color: ${colors.activeText};
    padding: 16px 20px 16px;
    text-align: right;
    align-self: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    border: 1px solid ${colors.inputDefaultBorder};

    .ant-input {
        border: none;
        height: 17px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 0 0 0 16px;
        text-overflow: ellipsis;
        color: ${colors.activeText};
    }

    .ant-input, .ant-input-group {
        font-family: Roboto;
    }

    .ant-input-outlined {
        border: none;
        height: 22px;

        &:focus-within {
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .ant-input-affix-wrapper {
        align-items: center;
        gap: 10px;

    }

    .ant-input-group-addon {
        border: none;
        background: inherit;

        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        pointer-events: none;
        padding: 0;
        color: ${colors.textDefault};
    }

`;

const InputPassword = styled(PreTabInput).attrs({ as: Input.Password })<TextInputProps>`
    border-color: ${(props) => (props.$isError ? colors.error : colors.inputDefaultBorder)};
    color: ${(props) => (props.$isError ? colors.error : colors.activeText)};

    &:hover,
    .ant-input:hover,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper.focused {
        border-color: ${(props) => (props.$isError ? colors.error : colors.inputDefaultBorder)} !important;
        background: ${colors.inputHoverBackground};
        box-shadow: none !important;
    }

    .ant-input {
        height: 24px;

        &::placeholder {
            color: ${(props) => (props.$isError ? colors.error : colors.textDefault)};
        }
    }

    &:hover,
    .ant-input:hover,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper.focused[aria-readonly] {
        background: none;
    }

    &:focus,
    &:focus-within,
    .ant-input:focus,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper.focused {
        border-color: ${(props) => (props.$isError ? colors.error : colors.inputDefaultBorder)} !important;
        box-shadow: none !important;
        background: ${colors.inputHoverBackground};
        outline: none !important;
    }

    &:focus,
    &:focus-within,
    .ant-input:focus,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper.focused [aria-readonly] {
        background: none;
    }
`;

const HyperLinkInputContainer = styled.div<{ $isGrid?: boolean, $isFullWidth?: boolean }>`
    display: flex;
    overflow-x: hidden;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid ${colors.inputDefaultBorder};
    background: ${colors.white};
    grid-column: ${({ $isGrid }) => ($isGrid ? "auto / span 2" : "unset")};
    width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "unset")};
`;

const InputLabel = styled.div`
    color: ${colors.textDefault};
    font-size: 16px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
`;

const Hyperlink = styled(Link)`
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    color: ${colors.textPrimary};
    text-align: right;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
`;

const Text = styled.span`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    text-overflow: ellipsis;
    color: ${colors.activeText};
`;

export {
    Hyperlink,
    InputLabel,
    HyperLinkInputContainer,
    InputPassword,
    PreTabInput,
    StyledTextarea,
    DescriptionInputWrapper,
    CustomPlaceholder,
    InputDisplayLabelInRowWrapper,
    RemoveIcon,
    AddIcon,
    FloatSelectStyle,
    OptionInnerContainer,
    OptionSpace,
    OptionContainer,
    FloatTextAreaStyle,
    FloatInputStyle,
    IconContainer,
    FloatInputLabel,
    AsteriskComponent,
    FloatTextAreaContainer,
    FloatInputContainer,
    FloatInputCommonContainer,
    InputTFAKey,
    InputFieldReadOnly,
    InputErrorDisplay,
    SplitInputCell,
    SplitInputStyle,
    StyledTextInputFiled,
    InputSection,
    Text,
};
