import { flow } from "mobx";
import dataStore from "../common/dataStore";
import { parseDateFromServer } from "../../helpers";
import { ElementsTypes, FiltersElementsType, NewElementType } from "../../types/elementsTypes";
import { getElements } from "../../api/elements/getElements";
import { getSelectedElement } from "../../api/elements/getSelectedElement";
import { deactivateElements } from "../../api/elements/deactivateElements";
import { postElements } from "../../api/elements/postElements";
import patchEditElement from "../../api/elements/patchEditElement";
import { activateElements } from "../../api/elements/activateElements";

class ElementStore extends dataStore<ElementsTypes, FiltersElementsType, NewElementType> {
    constructor() {
        super("ElementsStore");
    }

    protected fetchDataMethod = getElements;

    protected fetchSelectedItemMethod = getSelectedElement;

    protected deactivateSelectedItemsMethod = deactivateElements;

    protected activateSelectedItemsMethod = activateElements;

    protected postDataMethod = postElements;

    protected patchDataMethod = patchEditElement;

    fetchData = flow(function* fetchData(this: ElementStore) {
        const response = yield this.fetchWithoutSet();
        if (response?.data.response.results) {
            this._data = parseDateFromServer<ElementsTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const elementStore = new ElementStore();

export default elementStore;
