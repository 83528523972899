import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
    ItemsWrapper, SecionContentWrapper, SectionHeader, SectionWrapper,
} from "../../PagesSettingsComponents";
import SmallButton from "../../../../ui/button/SmallButton";
import PreInput from "../../../../ui/textInput/PreInput";
import EditSettingsModal from "./EditPersonalInfoModal";
import useTranslation from "../../../../../hooks/useTranslation";
import accountStore from "../../../../../store/user/account/accountStore";

const PersonalInfo = () => {
    const { t } = useTranslation();

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const preInputsList = [
        { label: t.table.header.name, value: accountStore.userProfile?.username, key: "PersonalInfoName" },
        { label: t.table.header.email, value: accountStore.userProfile?.email, key: "PersonalInfoEmail" },
        { label: t.table.header.role, value: accountStore.userProfile?.role_name, key: "PersonalInfoRole" },
        {
            label: t.table.header.createdBy,
            value: accountStore.userProfile?.created_by_name,
            key: "PersonalInfoCreatedBy",
        },
        { label: t.table.header.createdAt, value: accountStore.userProfile?.created_at, key: "PersonalInfoCreatedAt" },
        {
            label: t.table.header.timezone,
            value: accountStore.userProfile?.timezone,
            key: "PersonalInfoCreatedTimezone",
        },
        {
            label: t.table.header.info,
            value: accountStore.userProfile?.info,
            key: "PersonalInfoInfo",
        },
    ];

    return (
        <>
            <SectionWrapper>
                <SectionHeader>
                    {t.settings.personalInfo}
                    <SmallButton variant="secondary" disabled={false} onClick={toggleEditModal}>
                        {t.details.buttons.edit}
                    </SmallButton>
                </SectionHeader>
                <SecionContentWrapper>
                    <ItemsWrapper>
                        {preInputsList.map((input) => <PreInput {...input} />)}
                    </ItemsWrapper>
                </SecionContentWrapper>
            </SectionWrapper>
            <EditSettingsModal
                isOpen={isEditModalVisible}
                setOn={setEditModalVisibility}
                title={t.settings.edit}
                buttonText={t.buttons.save}
            />
        </>
    );
};

export default observer(PersonalInfo);
