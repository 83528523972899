import React from "react";
import { Outlet } from "react-router-dom";
import { PageWrapper, RightPageSide } from "../pages/CommonPagesStyles";
import WelcomeCard from "./WelcomeCard";
import Snackbar from "../snackbar/Snackbar";

const AuthorizationLayout: React.FC = () => (
    <PageWrapper>
        <WelcomeCard />
        <RightPageSide>
            <Outlet />
            <Snackbar />
        </RightPageSide>
    </PageWrapper>
);

export default AuthorizationLayout;
