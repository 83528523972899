import { getProcessingServices } from "../../api/services/filters/getProcessingServices";
import { ServicesTypes } from "../../types/servicesTypes";
import FiltersDataStore from "../common/filtersStore";

class FiltersProcessingServicesStore extends FiltersDataStore<ServicesTypes> {
    protected fetchListMethod = getProcessingServices;

    public get options() {
        return this.data?.map((item) => ({
            value: item.service_id,
            label: `${item.service_id}, ${item.name}`,
        }));
    }
}

const filtersProcessingServicesStore = new FiltersProcessingServicesStore();

export default filtersProcessingServicesStore;
