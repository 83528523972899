import { EditPartnerType, PartnerTypes } from "../../types/partnerTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: PartnerTypes,
}

const patchEditPartner = (data: Partial<EditPartnerType>, partnerId: string) => request<ResponseType>({
    method: "PATCH",
    uri: `entities/partners/${partnerId}/`,
    data,
});

export default patchEditPartner;
