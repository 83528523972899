import { EditUserType, UserType } from "../../types/userType";
import request from "../request";

interface ResponseType {
    message: string,
    response: UserType,
}

const patchEditUserProfile = (data: Partial<EditUserType>) => request<ResponseType>({
    useAuthorization: true,
    method: "PATCH",
    uri: "users/profile/",
    data,
});

export default patchEditUserProfile;
