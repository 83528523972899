import axios, { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import {
    action, computed, flow, makeObservable, observable,
} from "mobx";
import { makePersistable } from "mobx-persist-store";
import { postPasswordReset } from "../../api/account/password/postPasswordResetConfirm";
import { putNewPassword } from "../../api/account/password/putChangeTemporaryPassword";
import { getTfaUri as getFirstLoginTfaUri } from "../../api/account/tfa/getTFAUri";
import { postTFA } from "../../api/account/tfa/postTfa";
import { updateTFAStateInAuthPath } from "../../api/account/tfa/updateTFAStateInAuthPath";
import { postCredentials } from "../../api/auth/postCredentials";
import { postPasswordResetRequest } from "../../api/auth/postPasswordReset";
import { postRefreshToken } from "../../api/auth/postRefreshToken";
import { getTfaUri as getProfileTfaUri } from "../../api/users/getTFAUri";
import { updateTFAState } from "../../api/users/putTFA";
import { AUTH_STORE_LOCAL_STORAGE_KEY } from "../../constants/authStore";
import { getFieldsErrors } from "../../helpers/apiHelper";
import { FetchStatusesType } from "../../types/fetchStatuses";
import { GetTFAUriResponse } from "../../types/userType";
import accountStore from "../user/account/accountStore";
import transactionStore from "../transaction/transactionStore";

interface TokenPayload {
    perms: string[];
    user_id: string;
}

class AuthStore {
    @observable private _accessToken = "";

    @observable private _refreshToken = "";

    @observable private _userId = "";

    @observable private _tfaUri?: string = undefined;

    @observable private _useTFA = false;

    @observable private _isFirstLogin = false;

    @observable public tfaAttemptLimitExceeded = false;

    @observable private _userPermissions: string[] = [];

    @observable private _changeUserPasswordState = FetchStatusesType.unset;

    @observable private _gettingTfaUriState = FetchStatusesType.unset;

    @observable private _authorizeUserState = FetchStatusesType.unset;

    @observable private _secondStepAuthorizeState = FetchStatusesType.unset;

    @observable private _updateTFAState = FetchStatusesType.unset;

    @observable public authErrors: any;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: AUTH_STORE_LOCAL_STORAGE_KEY,
            // @ts-expect-error It's only one way to use private properties with mobx-persist
            properties: ["_accessToken", "_refreshToken", "_userPermissions", "_userId"],
            storage: localStorage,
        });
    }

    @action
    public setUserId = (refreshToken?: typeof this._refreshToken) => {
            const token: typeof this._refreshToken = refreshToken || this._refreshToken;
            if (token) {
                const jwtData = jwtDecode(token) as TokenPayload | null;
                const userId = jwtData?.user_id || "";
                this._userId = userId;
                if (userId) {
                    transactionStore.setDefaultVisibleColumns(userId);
                }
            }
        };

    @action
    public setTokens = (newAccessToken: string, newRefreshToken: string) => {
            this._accessToken = newAccessToken;
            this._refreshToken = newRefreshToken;
            this.setUserId(newRefreshToken);
        };

    @action
    public setUseTFA = (useTFA: boolean) => {
            this._useTFA = useTFA;
        };

    @action
    public logout = () => {
            this.setTokens("", "");
            this.clearUserPermissions();
            this._useTFA = false;
            this._isFirstLogin = false;
            this._updateTFAState = FetchStatusesType.unset;
            accountStore.resetUserProfile();
        };

    @computed
    public get useTFA() {
        return this._useTFA;
    }

    @computed
    public get userId() {
        return this._userId;
    }

    @computed
    public get tfaUri() {
        return this._tfaUri;
    }

    @computed
    public get isFirstLogin() {
        return this._isFirstLogin;
    }

    public authorizeUser = flow(function* authorizeUser(this: AuthStore, data: { email: string, password: string }) {
        try {
            this.tfaAttemptLimitExceeded = false;
            this._authorizeUserState = FetchStatusesType.pending;
            const response = yield postCredentials(data);
            const {
                access, refresh, need_tfa, first_login,
            } = response.data.response;
            this.setTokens(access, refresh);
            this._useTFA = need_tfa || false;
            this._isFirstLogin = first_login ?? false;
            if (!need_tfa && !first_login) {
                this.setUserPermissions(refresh);
                accountStore.getUserProfile(access);
            }
            this._authorizeUserState = FetchStatusesType.success;
        } catch (error) {
            this.authErrors = getFieldsErrors(error, ["password", "email"]);
            this._authorizeUserState = FetchStatusesType.failed;
        }
    });

    public refreshAccessToken = flow(function* refreshAccessToken(this: AuthStore) {
        try {
            const response = yield postRefreshToken({ refresh: this._refreshToken });
            const newAccessToken = response.data.response.access;
            this._accessToken = newAccessToken || "";
            this.setUserPermissions(this._refreshToken);
            if (newAccessToken === undefined) {
                this._refreshToken = "";
            }
        } catch (error) {
            this.logout();
            throw error;
        }
    });

    public secondStepAuthorize = flow(function* secondStepAuthorize(this: AuthStore, data: { key: string }) {
        try {
            this._secondStepAuthorizeState = FetchStatusesType.pending;
            const response = yield postTFA(data, this._accessToken);
            const { access, refresh } = response.data.response;
            this.setTokens(access, refresh);
            this.setUserPermissions(refresh);
            accountStore.getUserProfile(access);
            this._secondStepAuthorizeState = FetchStatusesType.success;
        } catch (error) {
            const errorData = error as AxiosError;
            if (axios.isAxiosError(error)) {
                if (errorData?.response?.status === 401
                    || error.response?.data.response?.key?.tfa_error_counter === undefined) {
                    this.tfaAttemptLimitExceeded = true;
                } else {
                    this.tfaAttemptLimitExceeded = error.response?.data.response?.key?.tfa_error_counter >= 5;
                }
            }
            if (errorData?.response?.status === 400) {
                this.authErrors = getFieldsErrors(error, ["key"]);
            }
            this._secondStepAuthorizeState = FetchStatusesType.failed;
        }
    });

    public sendRecoveryPasswordLink = flow(function* sendRecoveryPasswordLink(this: AuthStore, data: {
        email: string
    }) {
        try {
            yield postPasswordResetRequest(data);
        } catch (error) {
            this.authErrors = getFieldsErrors(error, ["email"]);
        }
    });

    public changePassword = flow(function* changePassword(this: AuthStore, data: {
        new_password: string,
        new_password_conf: string
    }, token: string) {
        try {
            this._changeUserPasswordState = FetchStatusesType.pending;
            const response = yield postPasswordReset(data, token);
            const { access, refresh, need_tfa } = response.data.response;
            this.setTokens(access, refresh);
            this.setUserPermissions(refresh);
            accountStore.getUserProfile(access);
            this._useTFA = need_tfa;
            this._changeUserPasswordState = FetchStatusesType.success;
        } catch (error) {
            const responseError = getFieldsErrors(
                error,
                ["new_password", "new_password_conf"],
            );
            this._changeUserPasswordState = FetchStatusesType.failed;
            this.authErrors = responseError;
        }
    });

    public createNewPassword = flow(function* changeTemporaryPassword(this: AuthStore, data: {
        new_password: string, new_password_conf: string
    }) {
        try {
            const response = yield putNewPassword(this._accessToken, data);
            const { access } = response.data.response;
            this.setTokens(access, "");
        } catch (error) {
            const responseError = getFieldsErrors(
                error,
                ["new_password", "new_password_conf"],
            );
            this.authErrors = responseError;
        }
    });

    public getTfaUri = flow(function* getTfaUri(this: AuthStore) {
        try {
            this._gettingTfaUriState = FetchStatusesType.pending;
            let response: GetTFAUriResponse;
            if (this._isFirstLogin) {
                response = yield getFirstLoginTfaUri(this._accessToken);
            } else {
                response = yield getProfileTfaUri(this._accessToken);
            }
            this._tfaUri = response.data.response.tfa_uri;
            this._gettingTfaUriState = FetchStatusesType.success;
        } catch (error) {
            this._gettingTfaUriState = FetchStatusesType.failed;
        }
    });

    public configureTFA = flow(function* configureTFA(
        this: AuthStore,
        data: { key: string, use_tfa: boolean },
        isAuthApiPath?: boolean,
    ) {
        try {
            this._updateTFAState = FetchStatusesType.pending;
            let response;
            if (isAuthApiPath) {
                response = yield updateTFAStateInAuthPath(data, this._accessToken);
            } else {
                response = yield updateTFAState(data);
            }
            const { access, refresh, use_tfa } = response.data.response;
            this._useTFA = use_tfa;
            this.setTokens(access, refresh);
            if (isAuthApiPath) {
                this.setUserPermissions(refresh);
                accountStore.getUserProfile(access);
            }
            this._updateTFAState = FetchStatusesType.success;
        } catch (error) {
            const errorData = error as AxiosError;
            if (axios.isAxiosError(error)) {
                if (errorData?.response?.status === 401
                    || error.response?.data.response?.key?.tfa_error_counter === undefined) {
                    this.tfaAttemptLimitExceeded = true;
                } else {
                    this.tfaAttemptLimitExceeded = error.response?.data.response?.key?.tfa_error_counter >= 5;
                }
            }
            if (errorData?.response?.status === 400 || errorData?.response?.status === 401) {
                this.authErrors = getFieldsErrors(error, ["key"]);
            }
            this._updateTFAState = FetchStatusesType.failed;
        }
    });

    public changeUserPassword = flow(function* changeUserPassword(this: AuthStore) {
        try {
            this._authorizeUserState = FetchStatusesType.pending;
            yield postPasswordResetRequest({ email: accountStore.userProfile?.email || "" });
            this._authorizeUserState = FetchStatusesType.success;
        } catch (error) {
            this._authorizeUserState = FetchStatusesType.failed;
        }
    });

    @action
    public initializeLoginFromEmail = (accessToken: string) => {
            this.setFirstLogin(true);
            this.setTokens(accessToken, this._refreshToken);
        };

    @action
    public setFirstLogin = (isFirstLogin: boolean) => {
            this._isFirstLogin = isFirstLogin;
        };

    @action
    public unsetState = () => {
            this._authorizeUserState = FetchStatusesType.unset;
            this._secondStepAuthorizeState = FetchStatusesType.unset;
            this._gettingTfaUriState = FetchStatusesType.unset;
            this.authErrors = null;
        };

    @action
    public setUserPermissions(refreshToken: typeof this._refreshToken) {
        if (refreshToken) {
            const permissions = jwtDecode(refreshToken) as TokenPayload | null;
            if (permissions?.perms) {
                this._userPermissions = permissions.perms;
            }
        } else {
            this._userPermissions = [];
        }
    }

    @action
    public clearUserPermissions() {
        this._userPermissions = [];
    }

    @computed
    public get isAuthError() {
        return this._authorizeUserState === FetchStatusesType.failed
            || this._secondStepAuthorizeState === FetchStatusesType.failed;
    }

    @computed
    public get loading() {
        return this._authorizeUserState === FetchStatusesType.pending
            || this._gettingTfaUriState === FetchStatusesType.pending
            || this._secondStepAuthorizeState === FetchStatusesType.pending
            || this._changeUserPasswordState === FetchStatusesType.pending
            || this._updateTFAState === FetchStatusesType.pending;
    }

    @computed
    public get isChangingPasswordSuccess() {
        return this._changeUserPasswordState === FetchStatusesType.success;
    }

    @computed
    public get isChangingPasswordInProgress() {
        return this._changeUserPasswordState === FetchStatusesType.pending;
    }

    @computed
    public get isUpdatingTFA() {
        return this._updateTFAState === FetchStatusesType.pending;
    }

    @computed
    public get isAuthenticated() {
        return !!this._refreshToken;
    }

    @computed
    public get isGettingTfaUriInProgress() {
        return this._gettingTfaUriState === FetchStatusesType.pending;
    }

    @computed
    public get isGettingTfaUriSucceed() {
        return this._gettingTfaUriState === FetchStatusesType.success;
    }

    @computed
    public get userPermissions() {
        return this._userPermissions;
    }
}

const authStore = new AuthStore();

export default authStore;
