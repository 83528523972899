import request from "../request";

interface RequestType {
    email: string,
}

interface ResponseType {
    message: string,
}

export const resendMail = (data: RequestType) => request<ResponseType>({
    method: "POST",
    uri: "users/resend-mail/",
    data,
});
