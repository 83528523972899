import { PartnerTypes } from "../../types/partnerTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: PartnerTypes,
}

export const getSelectedPartner = (partnerId: string) => request<ResponseType>({
    method: "GET",
    uri: `entities/partners/${partnerId}/`,
});
