import { useSortable } from "@dnd-kit/sortable";
import { ReactComponent as MoveIcon } from "../../../../../../resources/icons/table/move.svg";
import { DraggableCellComponent } from "./DraggableCellComponents";

interface DataType {
    id: string | number;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const DraggableCell = <D extends DataType>({ dataItem }: PropsType<D>) => {
    const {
        attributes,
        listeners,
        isDragging,
    } = useSortable({
        id: dataItem.id,
    });
    return (
        <DraggableCellComponent type="button" {...attributes} {...listeners} $isDragging={isDragging}>
            <MoveIcon />
        </DraggableCellComponent>
    );
};

export default DraggableCell;
