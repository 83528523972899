import { ReactNode } from "react";

import Loader from "../loader/Loader";
import { MenuItemComponent } from "./MenuDropdownComponents";

interface MenuDropdownItemProps {
    isLoading?: boolean;
    icon: ReactNode;
    title: string;
}

const MenuDropdownItem = ({ isLoading, icon, title }: MenuDropdownItemProps) => (
    <MenuItemComponent>
        {isLoading ? <Loader variant="secondary" size="16" /> : icon}
        {title}
    </MenuItemComponent>
);

export default MenuDropdownItem;
