import { PermissionsType } from "../../types/permissionsType";
import request from "../request";

interface GetPermissionsResponse {
    message: string,
    response: {
        count: number
        results: PermissionsType[];
    }
}

export const getPermissions = () => request<GetPermissionsResponse>({
    method: "GET",
    uri: "roles/permissions",
});
