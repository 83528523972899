import { EditRuleTypes, RulesPacksTypes } from "../../types/rulesPacksTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: RulesPacksTypes,
}

const patchRulesPack = (data: Partial<EditRuleTypes>, ruleId: string) => request<ResponseType>({
    useAuthorization: true,
    method: "PATCH",
    uri: `rules-packs/${ruleId}/`,
    data,
});

export default patchRulesPack;
