import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import Routes from "../../../../../../constants/routes";
import partnerRulesPacksStore from "../../../../../../store/entities/partner/partnerRulesPacksStore";
import { RulesPacksTypes } from "../../../../../../types/rulesPacksTypes";
import Table from "../../../../../ui/table/Table";
import TableFieldsDetailsLink from "../../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { ColumnType } from "../../../../../ui/table/types";
import withPermission from "../../../../../../hocs/withPermissions";
import { TableMainLink } from "../../../../../ui/tableFields/nameLink/TableMainLink";
import { Tabs } from "../../../../../../constants/tabs";

interface ProtectedPartnerRulesPacksTableProps {
    permission: string;
    partnerId?: string;
}

type WrappedComponentProps = Pick<ProtectedPartnerRulesPacksTableProps, "partnerId">

const columns: ColumnType<RulesPacksTypes>[] = [
    {
        header: "table.header.ruleName",
        field: "name",
        isResizable: true,
        width: "666px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.rulesPacks.href}/${dataItem.id}`}
                state={{ previousTab: Tabs.RULES_PACKS, tab: Tabs.NOT_SPECIFIED }}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.ruleId", field: "id", sortable: true },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                dataItem={dataItem}
                href={Routes.rulesPacks.href}
                state={{ previousTab: Tabs.RULES_PACKS, tab: Tabs.NOT_SPECIFIED }}
            />
        ),
    },
];

const WrappedComponent = observer(({ partnerId }: WrappedComponentProps) => {
    const tablePagination = {
        ...partnerRulesPacksStore.pagination,
        setPaginationPage: partnerRulesPacksStore.setPaginationPage,
        setPaginationSize: partnerRulesPacksStore.setPaginationSize,
    };

    useEffect(() => {
        partnerRulesPacksStore.fetchData(Number(partnerId));
    }, [partnerRulesPacksStore.pagination, partnerRulesPacksStore.ordering]);

    return (
        <Table
            columns={columns}
            data={partnerRulesPacksStore.data}
            pagination={tablePagination}
            sortMode={partnerRulesPacksStore.ordering}
            loading={partnerRulesPacksStore.loading}
            onSort={partnerRulesPacksStore.toggleSort}
        />
    );
});

const ProtectedPartnerRulesPacksTable = ({ partnerId, permission }: ProtectedPartnerRulesPacksTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission partnerId={partnerId} />;
};

export default memo(ProtectedPartnerRulesPacksTable);
