import { useMemo, useState } from "react";

import { permissionsMap } from "../../../../../../constants/permissions";
import routes from "../../../../../../constants/routes";
import { Tabs } from "../../../../../../constants/tabs";
import { toggleActionModalState } from "../../../../../../helpers/modalHelper";
import { useNavigation } from "../../../../../../hooks/useNavigation";
import useTranslation from "../../../../../../hooks/useTranslation";
import tgGroupsAlertsStore from "../../../../../../store/alerts/tgGroupsAlertsStore/tgGroupsAlertsStore";
import snackbarStore from "../../../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../../../types/actionModalTypes";
import { FetchStatusesType } from "../../../../../../types/fetchStatuses";
import ActionModal from "../../../../../common/modal/actionModal/ActionModal";
import { PlusIcon } from "../../../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../../../ui/button/SmallButton";
import Link from "../../../../../ui/link/Link";
import ProtectedMenu from "../components/TgGroupDetailsMenu/ProtectedMenu";

export function useTabsActions(tgGroupId?: string) {
    const { t, concatenate } = useTranslation();
    const { navigateBackward } = useNavigation();

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const handleNavigate = () => navigateBackward({ tab: Tabs.TG_GROUPS });

    const MenuComponent = (
        <ProtectedMenu
            permission={permissionsMap.alerts.changeStatus}
            store={tgGroupsAlertsStore}
            toggleActionModal={toggleActionModal}
        />
    );

    const AddButtonComponent = (
        <Link to={`${routes.alert.href}/new`} state={{ id: tgGroupId }}>
            <ProtectedSmallButton
                permission={permissionsMap.alerts.add}
                variant="tertiary"
                title={t.alerts.text.partOfLink}
                iconLeft={<PlusIcon />}
            />
        </Link>
    );

    const tabActionsList = useMemo(() => [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "menu-dropdown-button-component", content: MenuComponent },
    ], [permissionsMap, tgGroupId, tgGroupsAlertsStore]);

    const deactivateItems = async () => {
        const result = await tgGroupsAlertsStore.deactivateSelectedItems();
        toggleActionModal();

        if (tgGroupId && result === FetchStatusesType.success) {
            await tgGroupsAlertsStore.fetchData(tgGroupId);
            const snackbarSuccessText = concatenate(
                t.snackbar.deactivate.success,
                { itemsIdsList: tgGroupsAlertsStore.deactivatedItems },
            );
            snackbarStore.add({ variant: "success", text: snackbarSuccessText });
        } else {
            snackbarStore.add({ variant: "error", text: t.snackbar.deactivate.error });
        }
    };

    const activateItems = async () => {
        const result = await tgGroupsAlertsStore.activateSelectedItems();
        toggleActionModal();

        if (tgGroupId && result === FetchStatusesType.success) {
            await tgGroupsAlertsStore.fetchData(tgGroupId);
            const snackbarSuccessText = concatenate(
                t.snackbar.activate.success,
                { itemsIdsList: tgGroupsAlertsStore.activatedItems },
            );
            snackbarStore.add({ variant: "success", text: snackbarSuccessText });
        } else {
            snackbarStore.add({ variant: "error", text: t.snackbar.activate.error });
        }
    };

    const ActionModalComponent = (
        <ActionModal
            entityName={t.alerts.text.partOfLink}
            handleActivate={activateItems}
            handleDeactivate={deactivateItems}
            toggleModal={toggleActionModal}
            modalState={actionModalState}
        />
    );

    const actionHeaderContentList = [
        {
            key: "back-button-component",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    return { ActionModalComponent, actionHeaderContentList, tabActionsList };
}
