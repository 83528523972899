import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthRoutes } from "../../../../constants/routes";
import useTranslation from "../../../../hooks/useTranslation";
import { PrimaryButton } from "../../../ui/button/ButtonStyles";
import { HeaderText } from "../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../CommonPagesStyles";
import { HeaderSection } from "../../auth/login/authTFA/AuthTFAStyles";
import { PasswordConfirmationWrapper, PasswordConfirmHeader } from "./PasswordConfirmationStyle";
import { ReactComponent as CheckIcon } from "../../../../resources/icons/checkIcon.svg";

const PagePasswordConfirmation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleButtonClickOn = () => {
        navigate(AuthRoutes.firstLoginSetUpTFA.href);
    };
    return (
        <RightSidePageWrapper>
            <PasswordConfirmationWrapper>
                <PasswordConfirmHeader>
                    <CheckIcon />
                    <HeaderSection>
                        <HeaderText>{t.changeTemporaryPassword.text.passwordConfirmation}</HeaderText>
                        {t.changeTemporaryPassword.text.successfullySetup}
                    </HeaderSection>
                </PasswordConfirmHeader>
                <PrimaryButton onClick={handleButtonClickOn}>{t.buttons.ok}</PrimaryButton>
            </PasswordConfirmationWrapper>
        </RightSidePageWrapper>
    );
};

export default PagePasswordConfirmation;
