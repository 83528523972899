import { Fragment, ReactNode } from "react";
import {
    ActionsAndTabsContainer, ActionsContainer, ActiveTitle, Container,
    ComponentWrapper, SubTitle, SubTitleActive, Title, TitleContainer,
} from "./SecondHeaderComponents";
import { splitHeaderName } from "../../helpers/secondHeaderHelper";
import useTranslation from "../../hooks/useTranslation";

interface SecondHeaderProps {
    title: string
    tabs?: ReactNode
    subTitle?: { from: string, to: string }
    actionsContent?: { content: ReactNode, key: string, isDisplayed?: boolean }[]
    isCapitalized?: boolean
}

const SecondHeader = ({
    title,
    tabs,
    actionsContent,
    subTitle,
    isCapitalized = true,
}: SecondHeaderProps) => {
    const { t } = useTranslation();
    const splittedName = splitHeaderName(title);

    return (
        <ComponentWrapper>
            <Container $tabs={!!tabs}>
                <TitleContainer>
                    {splittedName.title
                        ? (
                            <>
                                <Title $isCapitalized={isCapitalized}>{splittedName.title}</Title>
                                <ActiveTitle>{splittedName.activeTitle}</ActiveTitle>
                            </>
                        )
                        : <ActiveTitle>{splittedName.activeTitle}</ActiveTitle>}
                    {subTitle ? (
                        <>
                            <SubTitleActive>{`${t.status.active} ${subTitle.from}`}</SubTitleActive>
                            <SubTitle>{`/${subTitle.to}`}</SubTitle>
                        </>
                    ) : null}
                </TitleContainer>

                <ActionsAndTabsContainer $tabs={!!tabs}>
                    {tabs}
                    <ActionsContainer>
                        {actionsContent?.map((actionContent) => {
                            const { isDisplayed } = actionContent;

                            return isDisplayed !== false && (
                                <Fragment key={actionContent.key}>
                                    {actionContent.content}
                                </Fragment>
                            );
                        })}
                    </ActionsContainer>
                </ActionsAndTabsContainer>
            </Container>
        </ComponentWrapper>
    );
};
export default SecondHeader;
