import request from "../request";
import { ListsFiltersTypes, ListsTypes } from "../../types/listsTypes";

type GetListsRequest = ListsFiltersTypes & {
    rules_pack?: string
    limit?: number
    offset?: number
    ordering?: string
}

interface GetListsResponse {
    message: string,
    response: {
        count: number
        results: ListsTypes[];
    }
}

export const getLists = (params?: GetListsRequest) => request<GetListsResponse>({
    method: "GET",
    uri: "lists/",
    params,
});
