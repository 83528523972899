import React from "react";
import StatusBadge from "../../textField/StatusBadge";

interface DataType {
    is_active?: boolean;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const TableFieldsStatusBadge = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <StatusBadge
        variant={dataItem.is_active ? "success" : "inactive"}
        multiLanguageText={dataItem.is_active ? "status.active" : "status.inactive"}
    />
);

export default TableFieldsStatusBadge;
