export const ALL_OPTION = "all";

export enum FieldMode {
  Multiple = "multiple",
  Tags = "tags",
}

export enum FieldModeOption {
  MultiSelect = "multi-select",
  Select = "select",
}
