import routes from "../../../../constants/routes";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface DataType {
    id: string | number;
    name?: string;
    username?: string;
    message?: string;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const NameLinkCell = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <TableMainLink to={`${routes.transactions.href}/${dataItem.id}`}>{dataItem.id}</TableMainLink>
);

export default NameLinkCell;
