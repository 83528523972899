import { action } from "mobx";
import dataStore from "../common/dataStore";
import { getPermissions } from "../../api/permissions/getPermissions";
import { PermissionsType } from "../../types/permissionsType";

class PermissionsStore extends dataStore<PermissionsType, null, null> {
    protected fetchDataMethod = getPermissions;

    public get notAddedItems() {
        return this.data.filter((permission) => !this.checkedItems.includes(permission.id));
    }

    public get addedItems() {
        return this.data.filter((permission) => this.checkedItems.includes(permission.id));
    }

    @action
    public clearAddedItems = () => {
            this._checkedItems = [];
        };
}

const permissionsStore = new PermissionsStore();

export default permissionsStore;
