import { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import Table from "../../../../../../ui/table/Table";
import TableFieldsCheckbox from "../../../../../../ui/tableFields/checkbox/TableFieldsCheckbox";
import rolePermissionStore from "../../../../../../../store/user/roles/rolePermissionStore";
import useTranslation from "../../../../../../../hooks/useTranslation";
import { RolePermissionType } from "../../../../../../../types/rolesTypes";
import {
    cellStyle,
    headerStyle,
    permissionsCellStyle,
    permissionsHeaderStyle,
    tableContainerStyle,
} from "../RolesModalComponents";
import filtersRolesStore from "../../../../../../../store/filters/rolesFilterStore";

const RolesTable = () => {
    const { translate } = useTranslation();

    const columns = useMemo(() => (
        [{
            header: "table.header.possibilities",
            field: "codename",
            getField: (permission: RolePermissionType) => translate(`roles.permissions.${permission.codename}`),
            headerStyles: permissionsHeaderStyle,
            cellStyles: permissionsCellStyle,
        },
        ...filtersRolesStore.options.map(({ label }) => (
            {
                header: label || "",
                field: label || "",
                cellCenter: true,
                headerStyles: headerStyle,
                cellStyles: cellStyle,
                Component: TableFieldsCheckbox,
            }
        )),
        ]), [filtersRolesStore.options]);

    useEffect(() => {
        filtersRolesStore.fetch();
        rolePermissionStore.fetch();
    }, []);

    return (
        <Table
            columns={columns}
            data={rolePermissionStore.data}
            loading={rolePermissionStore.loading}
            tableContainerStyles={tableContainerStyle}
            scrollable
        />
    );
};

export default observer(RolesTable);
