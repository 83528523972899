import request from "../request";
import { PostAllAttributesToBlacklistRequestType } from "../../types/transactionTypes";

interface ResponseType {
    message: string,
    response: unknown,
}

const postAllAttributesToBlacklist = (data: PostAllAttributesToBlacklistRequestType) => request<ResponseType>({
    method: "POST",
    uri: "elements/block-all/",
    data,
});

export default postAllAttributesToBlacklist;
