import request from "../request";
import { LimitsTypes } from "../../types/limitsTypes";

interface ResponseType {
    message: string,
    response: LimitsTypes,
}

export const getSelectedLimit = (limitId: string) => request<ResponseType>({
    method: "GET",
    uri: `limits/${limitId}/`,
});
