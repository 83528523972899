import { AlertsTypes, NewAlertTypes } from "../../types/alertsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: AlertsTypes,
}

export const postAlert = (data: NewAlertTypes) => request<ResponseType>({
    method: "POST",
    uri: "alerts/",
    data,
});
