import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import alertsStore from "../../../../store/alerts/alerts/alertsStore";
import { AlertProcessingTypes, AlertsTypes } from "../../../../types/alertsTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import TableFieldsImportanceStatus from "../components/TableFieldImportanceStatus/TableFieldsImportanceStatus";

interface ProtectedAlertsTableProps {
    permission: string;
}

const columns: ColumnType<AlertsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.name",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.alert.href}/${dataItem.id}`}>
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.alertId", field: "id" },
    { header: "table.header.type", field: "alert_type" },
    {
        header: "table.header.importance",
        field: "importance",
        getField: (dataItem) => (<TableFieldsImportanceStatus status={dataItem.importance} />),
    },
    { header: "table.header.createdAt", field: "created" },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.triggerText",
        field: "trigger_text",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.partnerId",
        field: "partners",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.partners.map((partner) => partner.id).join(", "),
    },
    {
        header: "table.header.serviceId",
        field: "services",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => dataItem.services.map((service) => service.id).join(", "),
    },
    {
        header: "table.header.processingId",
        field: "processing",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (dataItem.processing as AlertProcessingTypes)?.id,
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        sortable: true,
        Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.alert.href} />
        ),
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...alertsStore.pagination,
        setPaginationPage: alertsStore.setPaginationPage,
        setPaginationSize: alertsStore.setPaginationSize,
    };

    useEffect(() => {
        alertsStore.fetchData();
    }, [alertsStore.pagination, alertsStore.filters, alertsStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            pagination={tablePagination}
            data={alertsStore.data}
            sortMode={alertsStore.ordering}
            loading={alertsStore.loading}
            checkedItems={alertsStore.checkedItems}
            onSort={alertsStore.toggleSort}
            onCheckboxChange={alertsStore.toggleItem}
            onMainCheckboxChange={alertsStore.toggleMainItem}
        />
    );
});

const ProtectedAlertsTable = ({ permission }: ProtectedAlertsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedAlertsTable);
