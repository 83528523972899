import { ReactNode } from "react";
import { StatusBadgeComponent, StatusTextFieldVariants } from "./StatusBadgeComponents";
import useTranslation from "../../../hooks/useTranslation";

export interface StatusBadgeProps {
    variant: StatusTextFieldVariants
    width?: string
    children?: ReactNode
    multiLanguageText?: string
}

const StatusBadge = ({
    variant, width, children, multiLanguageText,
}: StatusBadgeProps) => {
    const { translate } = useTranslation();

    return (
        <StatusBadgeComponent $variant={variant} width={width}>
            {multiLanguageText ? translate(multiLanguageText) : children}
        </StatusBadgeComponent>
    );
};

export default StatusBadge;
