import {
    Dispatch, FC, FormEvent, Ref, SetStateAction, useState,
} from "react";
import { observer } from "mobx-react-lite";
import SplitInput from "../../../ui/textInput/SplitInput";
import authStore from "../../../../store/auth/authStore";
import ErrorLabel from "../../../ui/errors/ValidationErrorBox/ErrorLabel";
import { PrimaryButton } from "../../../ui/button/ButtonStyles";
import useTranslation from "../../../../hooks/useTranslation";
import { TFAAndErrorMessageSection, TFAFormWrapper } from "./TFAFormComponents";
import { SplitInputSection } from "../../AuthorizationLayoutStyles";

interface TFAFormProps {
    setData: (data: string) => void;
    onSubmit: (event: FormEvent<HTMLFormElement>) => void
    formRef?: Ref<any>
    submitButton?: boolean
    setIsParentFormValid?: Dispatch<SetStateAction<boolean>>,
}

const TFAForm: FC<TFAFormProps> = ({
    setData,
    onSubmit,
    formRef,
    submitButton = false,
    setIsParentFormValid,
}) => {
    const { t } = useTranslation();

    const [isFormValid, setIsFormValid] = useState(true);

    const handleReset = () => {
        authStore.unsetState();
    };

    const setFormInput = (inputValues: Array<string[6]>) => {
        if (authStore.authErrors) handleReset();
        const allNumbers = inputValues.every((value) => /^\d$/.test(value));
        setIsFormValid(allNumbers);
        setIsParentFormValid?.(allNumbers);
        if (allNumbers) {
            setData(inputValues.join(""));
        }
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit?.(event);
    };

    return (
        <TFAFormWrapper onSubmit={handleSubmit} onReset={handleReset} ref={formRef}>
            <TFAAndErrorMessageSection>
                <SplitInputSection>
                    <SplitInput
                        length={6}
                        onChange={setFormInput}
                        isError={authStore.authErrors}
                        disabled={authStore.loading}
                    />
                </SplitInputSection>
                {authStore.authErrors && ErrorLabel(t.login.tfa.error)}
            </TFAAndErrorMessageSection>
            {submitButton
                ? (
                    <PrimaryButton
                        type="submit"
                        disabled={(!isFormValid && !authStore.authErrors) || authStore.loading}
                    >
                        {t.buttons.enter}
                    </PrimaryButton>
                )
                : null}
        </TFAFormWrapper>
    );
};

export default observer(TFAForm);
