import request from "../request";
import { RoleType } from "../../types/rolesTypes";

interface ResponseType {
    message: string;
    response: RoleType;
}

export const getSelectedRole = (roleId: string) => request<ResponseType>({
    method: "GET",
    uri: `roles/${roleId}/`,
});
