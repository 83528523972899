import { css } from "styled-components";
import { colors } from "../../../../../../styles/variables";

export const tableContainerStyle = css`
    border-radius: 12px;
    border: 1px solid ${colors.inputDefaultBorder};
    max-height: 40vh;
    margin-top: 24px;

    @media only screen and (min-height: 500px) {
        max-height: 45vh;
    }

    @media only screen and (min-height: 600px) {
        max-height: 55vh;
    }

    @media only screen and (min-height: 700px) {
        max-height: 60vh;
    }

    @media only screen and (min-height: 800px) {
        max-height: 65vh;
    }

    @media only screen and (min-height: 900px) {
        max-height: 69vh;
    }

    @media only screen and (min-height: 1000px) {
        max-height: 73vh;
    }

    @media only screen and (min-height: 1180px) {
        max-height: 75vh;
    }

    td {
        height: 40px
    }
`;
