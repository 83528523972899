import { NewServicesType } from "../../types/partnerTypes";
import request from "../request";
import { ServicesTypes } from "../../types/servicesTypes";

interface ResponseType {
    message: string,
    response: ServicesTypes,
}

export const postServices = (data: NewServicesType) => request<ResponseType>({
    method: "POST",
    uri: "entities/services/",
    data,
});
