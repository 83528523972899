import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const activateEmployees = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "users/activate/",
    data,
});

export default activateEmployees;
