import { ItemType } from "antd/es/menu/hooks/useItems";

import MenuDropdownItem from "../components/ui/menuDropdown/MenuDropdownItem";
import { ReactComponent as CloseCircleIcon } from "../resources/icons/closeCircleIcon.svg";
import { ReactComponent as TickCircleIcon } from "../resources/icons/tickCircleIcon.svg";
import { colors } from "../styles/variables";
import { ActionModalType } from "../types/actionModalTypes";

export const getDeactivateAndActivateMenuItems = (
    translate: (text: string) => string,
    toggleActionModal: (type: ActionModalType) => void,
    store: any,
): ItemType[] => [
    {
        key: "activate-item",
        label: <MenuDropdownItem
            icon={<TickCircleIcon width="16px" height="16px" />}
            title={translate("buttons.activate")}
        />,
        disabled: !store.hasDisabledCheckedItems,
        onClick: () => toggleActionModal("activate"),
    },
    {
        key: "deactivate-item",
        label: <MenuDropdownItem
            icon={<CloseCircleIcon fill={colors.error} width="16px" height="16px" />}
            title={translate("buttons.deactivate")}
        />,
        disabled: !store.hasActiveCheckedItems,
        onClick: () => toggleActionModal("deactivate"),
    },
];
