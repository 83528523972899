import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const deactivateEmployees = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "users/deactivate/",
    data,
});

export default deactivateEmployees;
