import request from "../request";
import { EditEmployeeType, EmployeesTypes } from "../../types/employeesTypes";

interface ResponseType {
    message: string,
    response: EmployeesTypes,
}

const patchEmployees = (data: EditEmployeeType, employeeId: string | null) => request<ResponseType>({
    useAuthorization: true,
    method: "PATCH",
    uri: `users/${employeeId}/`,
    data,
});

export default patchEmployees;
