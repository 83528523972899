import { ChangeStatusResponseType } from "../../types/commonFiltersType";
import request from "../request";

interface RequestType {
    id: number[],
}

const activateAlerts = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "alerts/activate/",
    data,
});

export default activateAlerts;
