import { getTgGroups } from "../../../api/tgGroups/filters/getTgGroups";
import { TgGroupsTypes } from "../../../types/tgGroupsTypes";
import FiltersDataStore from "../../common/filtersStore";

class FiltersAlertsTgGroupsStore extends FiltersDataStore<TgGroupsTypes> {
    protected fetchListMethod = getTgGroups;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: item.name,
        }));
    }
}

const filtersAlertsTgGroupsStore = new FiltersAlertsTgGroupsStore();

export default filtersAlertsTgGroupsStore;
