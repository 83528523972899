import request from "../../request";
import { RulesPacksTypes } from "../../../types/rulesPacksTypes";

interface GetRulesPacksResponse {
    message: string,
    response: {
        count: number,
        results: RulesPacksTypes[],
    }
}

const getRulesPacks = () => request<GetRulesPacksResponse>({
    method: "GET",
    uri: "entities/filters/rules_packs/",
});

export default getRulesPacks;
