import React from "react";
import { colors } from "../../../styles/variables";
import { RulesStyled } from "../../pages/firstLogin/changePassword/PasswordConfirmationStyle";
import { PasswordValidationProps, RuleTemplateProps } from "./PasswordValidation";
import { ReactComponent as RuleIcon } from "../../../resources/icons/ruleIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../resources/icons/successIcon.svg";
import { ReactComponent as FailureIcon } from "../../../resources/icons/failureIcon.svg";

interface PasswordRulesProps extends RuleTemplateProps, PasswordValidationProps {}

const rulesStyles = {
    success: {
        textColor: colors.successBackground,
        Icon: SuccessIcon,
    },
    failure: {
        textColor: colors.error,
        Icon: FailureIcon,
    },
    active: {
        textColor: colors.activeText,
        Icon: RuleIcon,
    },
};

const PasswordRule = ({
    translation, regex, formik, fieldName,
}: PasswordRulesProps) => {
    const {
        values, errors, touched, isSubmitting, isValid, dirty,
    } = formik;

    const getRuleValidationStatus = () => {
        if (!values[fieldName]) return "active";
        if (regex.test(values[fieldName])) return "success";
        if (errors[fieldName] && touched[fieldName] && !isSubmitting) return "failure";
        return "active";
    };
    const status = getRuleValidationStatus();
    const stylesPack = rulesStyles[status];

    return (
        <RulesStyled $color={stylesPack.textColor}>
            <stylesPack.Icon />
            {translation}
        </RulesStyled>
    );
};

export default PasswordRule;
