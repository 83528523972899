import Loader, { LoaderProps } from "./Loader";
import { GlobalLoaderWrapper } from "./LoaderComponents";

interface GlobalLoaderProps extends LoaderProps{
    isLoading: boolean;
}
const GlobalLoader = ({ isLoading, size, variant } : GlobalLoaderProps) => (
    <GlobalLoaderWrapper>
        {isLoading && <Loader size={size} variant={variant} />}
    </GlobalLoaderWrapper>
);

export default GlobalLoader;
