import React from "react";
import useTranslation from "../../../hooks/useTranslation";
import SecondHeader from "../../secondHeader/SecondHeader";

const PagesHome: React.FC = () => {
    const { t } = useTranslation();

    return (
        <SecondHeader title={t.menu.links.home} />
    );
};

export default PagesHome;
