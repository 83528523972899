import request from "../request";

type DataType = {
    email: string;
}

type ResponseType = {
    message: string;
    response: {
        detail: string;
    };
}

export const postPasswordResetRequest = (data: DataType) => request<ResponseType>({
    useAuthorization: false,
    method: "POST",
    uri: "auth/password/reset/request/",
    data,
});
