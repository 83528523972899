import React from "react";
import { TextError } from "../../TextStyle";
import { ErrorMessage, WarningIconStyle } from "../ErrorsStyle";
import warningIcon from "../../../../resources/icons/warningIcon.svg";

const ErrorLabel = (error?: string) => (
    <ErrorMessage>
        <WarningIconStyle>
            <img src={warningIcon} width="12" height="12" alt="warning-icon" />
        </WarningIconStyle>
        <TextError>
            {error}
        </TextError>
    </ErrorMessage>
);

export default ErrorLabel;
