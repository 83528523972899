import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import tgGroupsStore from "../../../../store/alerts/tgGroups/tgGroupsStore";
import { TgGroupsTypes } from "../../../../types/tgGroupsTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedTgGroupsTableProps {
    permission: string;
}

const columns: ColumnType<TgGroupsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.name",
        field: "name",
        isResizable: true,
        width: "360px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.groups.href}/${dataItem.id}`}>
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.alertId", field: "id" },
    {
        header: "table.header.chatLink",
        field: "chat_link",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => {
            const { chat_link } = dataItem;
            return <TableMainLink to={chat_link} target="_blank">{chat_link}</TableMainLink>;
        },
    },
    { header: "table.header.chatId", field: "chat_id" },
    { header: "table.header.threadId", field: "thread_id" },
    { header: "table.header.alertsCount", field: "alerts_count" },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.isActive",
        field: "is_active",
        getField: (dataItem) => (<TableFieldsStatusBadge dataItem={dataItem} />),
    },
    {
        header: "table.header.details",
        field: "details",
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.groups.href} />
        ),
        cellStyles: { width: "0px" },
    },
];

const WrappedComponent = observer(() => {
    const tablePagination = {
        ...tgGroupsStore.pagination,
        setPaginationPage: tgGroupsStore.setPaginationPage,
        setPaginationSize: tgGroupsStore.setPaginationSize,
    };

    useEffect(() => {
        tgGroupsStore.fetch();
    }, [tgGroupsStore.pagination, tgGroupsStore.filters, tgGroupsStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={tgGroupsStore.data}
            pagination={tablePagination}
            checkedItems={tgGroupsStore.checkedItems}
            sortMode={tgGroupsStore.ordering}
            loading={tgGroupsStore.loading}
            onSort={tgGroupsStore.toggleSort}
            onCheckboxChange={tgGroupsStore.toggleItem}
            onMainCheckboxChange={tgGroupsStore.toggleMainItem}
        />
    );
});

const ProtectedTgGroupsTable = ({ permission }: ProtectedTgGroupsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission />;
};

export default memo(ProtectedTgGroupsTable);
