import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";

import * as Yup from "yup";
import {
    getTranslatedHandbook,
    notificationsStatusOptionsHandbook,
    alertImportanceOptionsHandbook,
} from "../../../../constants/handbooks";
import useTranslation from "../../../../hooks/useTranslation";
import alertsStore from "../../../../store/alerts/alerts/alertsStore";
import notificationsStore from "../../../../store/alerts/notifications/notificationsStore";
import alertsFiltersStore from "../../../../store/filters/alerts/alertsFiltersStore";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import filtersServicesStore from "../../../../store/filters/servicesFilterStore";
import { NotificationsFiltersTypes } from "../../../../types/notificationsTypes";
import {
    FormContainer, HeaderContainer, HeaderTitle, SubTitle,
} from "../../../filters/FiltersModalComponents";
import Button from "../../../ui/button/Button";
import { TextButton } from "../../../ui/button/ButtonStyles";
import DatePicker from "../../../ui/datepicker/DatePicker";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import { validateDateRange } from "../../../../helpers/validationHelper";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import { FieldMode } from "../../../../constants/selectFields";
import { FormFieldType } from "../../../../constants/filterTypes";
import FloatInput from "../../../ui/textInput/FloatInput";

interface NotificationsFiltersProps {
    closeModal: () => void;
}

const NotificationsFilters = ({ closeModal }: NotificationsFiltersProps) => {
    const { t, translate } = useTranslation();

    const notificationsStatusesOptions = getTranslatedHandbook(translate, notificationsStatusOptionsHandbook);
    const notificationsImportanceOptions = getTranslatedHandbook(translate, alertImportanceOptionsHandbook);

    const initialValues: NotificationsFiltersTypes = { ...notificationsStore.filters };

    const formik = useFormik<NotificationsFiltersTypes>({
        initialValues,
        validationSchema: Yup.object({
            created_to: validateDateRange(translate, "created_from"),
        }),
        initialTouched: {
            created_to: !!notificationsStore.filters?.created_to,
        },
        enableReinitialize: true,
        onSubmit: (fields) => {
            notificationsStore.updateFilters(fields);
            closeModal();
        },
    });

    const floatFieldsParamsList = [
        {
            name: "is_checked",
            type: FormFieldType.floatSelect,
            options: notificationsStatusesOptions,
            placeholder: t.filters.fields.isActive,
        },
        {
            name: "importance",
            type: FormFieldType.floatSelect,
            options: notificationsImportanceOptions,
            placeholder: t.filters.fields.importance,
        },
        {
            name: "alert",
            type: FormFieldType.floatSelect,
            options: alertsFiltersStore.options,
            placeholder: t.filters.fields.alert,
        },
        {
            type: FormFieldType.floatInput,
            name: "customer_id",
            placeholder: t.table.header.customerId,
        },
        {
            name: "partner",
            type: FormFieldType.floatSelect,
            options: filtersPartnersStore.options,
            placeholder: t.filters.fields.partner,
        },
        {
            name: "service",
            type: FormFieldType.floatSelect,
            options: filtersServicesStore.options,
            placeholder: t.filters.fields.service,
        },
        {
            name: "processings",
            type: FormFieldType.floatSelect,
            options: filtersProcessingStore.options,
            placeholder: t.filters.fields.processing,
            mode: FieldMode.Multiple,
        },
    ];

    const datePickerParamsList = [
        {
            name: "created_from",
            options: notificationsStatusesOptions,
            placeholder: t.filters.fields.from,
            maxDate: formik.values.created_to,
        },
        {
            name: "created_to",
            options: alertsFiltersStore.options,
            placeholder: t.filters.fields.to,
            minDate: formik.values.created_from,
        },
    ];

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        notificationsStore.resetFilters();
        formik.resetForm();
    };

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.notifications.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>

            <FormContainer onSubmit={handleSubmit}>
                {floatFieldsParamsList.map((input) => {
                    const {
                        type, name, options, placeholder,
                    } = input;

                    switch (type) {
                        case FormFieldType.floatInput:
                            return (
                                <FloatInput
                                    key={name}
                                    formik={formik}
                                    name={name}
                                    placeholder={placeholder}
                                />
                            );
                        case FormFieldType.floatSelect:
                            return (
                                <FloatSelect
                                    key={name}
                                    formik={formik}
                                    name={name}
                                    options={options}
                                    mode={input?.mode}
                                    placeholder={placeholder}
                                />
                            );
                        default:
                            return null;
                    }
                })}

                <SubTitle>{t.filters.fields.creationDate}</SubTitle>
                {datePickerParamsList.map((input) => {
                    const { name, placeholder, ...props } = input;

                    return (
                        <DatePicker
                            {...props}
                            key={name}
                            formik={formik}
                            name={name}
                            placeholder={placeholder}
                        />
                    );
                })}

                <Button variant="primary" type="submit" isLoading={alertsStore.loading}>{t.filters.apply}</Button>
            </FormContainer>
        </>
    );
};

export default observer(NotificationsFilters);
