import { useEffect, useState } from "react";

import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import transactionStore from "../../../../store/transaction/transactionStore";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon } from "../../../ui/button/ButtonComponents";
import SmallButton from "../../../ui/button/SmallButton";
import { ArrowButton } from "../components/ArrowButton";
import ParametersModal from "../components/ParametersModal/ParametersModal";
import TransactionsMenu from "../components/TransactionsMenu/TransactionsMenu";
import TransactionsFilters from "../components/Filters/TransactionsFilters";

export function useTabsActions(filtersCount: number) {
    const { t } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);

    const [isParametersModalVisible, setParametersModalVisibility] = useState(false);
    const toggleParametersModal = () => setParametersModalVisibility((prev) => !prev);

    const updateCount = () => transactionStore.updateCount();

    const downloadFile = async () => {
        await transactionStore.downloadData();

        if (transactionStore.isFetchFileSuccess) {
            snackbarStore.add({ variant: "success", text: t.transactions.snackbar.addFileSuccess });
        } else if (transactionStore.isFetchFileFailed) {
            snackbarStore.add({ variant: "error", text: t.transactions.snackbar.addFileError });
        }
    };

    const isSortIsCreatedAtDesc = !transactionStore.ordering || transactionStore.ordering === "-create_time";
    const isCountButtonDisabled = !isSortIsCreatedAtDesc || transactionStore.isCountLoading;
    const isDownloadButtonDisabled = !transactionStore.data.length || transactionStore.isFileLoading;

    const CountButtonComponent = (
        <ArrowButton
            count={transactionStore.count}
            onClick={updateCount}
            isLoading={transactionStore.isCountLoading}
            disabled={isCountButtonDisabled}
        />
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
            onClick={toggleFilterModal}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuDropdownComponent = (
        <TransactionsMenu
            isDownloadButtonDisabled={isDownloadButtonDisabled}
            downloadFile={downloadFile}
            toggleParametersModal={toggleParametersModal}
        />
    );

    const actionHeaderContentList = [
        {
            key: "transaction-count-button",
            content: CountButtonComponent,
        },
        {
            key: "transaction-filter-button",
            content: FiltersButtonComponent,
        },
        { key: "menu-dropdown-component", content: MenuDropdownComponent },
    ];

    const FilterModal = (
        <FiltersModal
            scrollable
            width="400px"
            isOpen={isFilterModalVisible}
            setOn={toggleFilterModal}
        >
            <TransactionsFilters closeModal={toggleFilterModal} />
        </FiltersModal>
    );

    const ParametersModalComponent = (
        <ParametersModal
            isOpen={isParametersModalVisible}
            setOn={toggleParametersModal}
        />
    );

    useEffect(() => {
        if (isCountButtonDisabled) transactionStore.resetCount();
    }, [isCountButtonDisabled]);

    return { FilterModal, ParametersModalComponent, actionHeaderContentList };
}
