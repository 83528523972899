import request from "../request";
import { EmployeesTypes } from "../../types/employeesTypes";

interface ResponseType {
    message: string,
    response: EmployeesTypes,
}

export const getSelectedEmployee = (employeeId: string) => request<ResponseType>({
    method: "GET",
    uri: `users/${employeeId}/`,
});
