import FiltersDataStore from "../common/filtersStore";
import { LimitsTypes } from "../../types/limitsTypes";
import { getLimits } from "../../api/limits/filters/getLimits";

class FiltersLimitsStore extends FiltersDataStore<LimitsTypes> {
    protected fetchListMethod = getLimits;

    public get options() {
        return this.data?.map((item) => ({
            value: item.id,
            label: `${item.id}, ${item.name}`,
        }));
    }
}

const filtersLimitsStore = new FiltersLimitsStore();

export default filtersLimitsStore;
