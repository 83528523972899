import request from "../request";

type DataType = {
    use_tfa: boolean,
    key: string,
}

type ResponseType = {
    message: string;
    response: {
        use_tfa: boolean;
        access: string;
        refresh: string;
    };
}

export const updateTFAState = (data: DataType) => request<ResponseType>({
    method: "PUT",
    uri: "users/profile/tfa/",
    data,
});
