import { flow } from "mobx";
import dataStore from "../../common/dataStore";
import getRulesPacks from "../../../api/rulesPacks/getRulesPacks";
import { NewRuleTypes, RulesPacksTypes } from "../../../types/rulesPacksTypes";
import { parseDateFromServer } from "../../../helpers";

class ServiceRulesPackStore extends dataStore<RulesPacksTypes, any, NewRuleTypes> {
    protected fetchDataByIdMethod = getRulesPacks;

    fetchData = flow(function* fetchData(this: ServiceRulesPackStore, id: number | string) {
        const response = yield this.fetchDataByIdWithoutSet({ service: id });
        if (response?.data.response.results) {
            this._data = parseDateFromServer<RulesPacksTypes>(
                response.data.response.results,
                "created",
            );
            this._pagination.count = response.data.response.count;
        }
    });
}

const serviceRulesPackStore = new ServiceRulesPackStore();

export default serviceRulesPackStore;
