import withPermission from "../../../../../../hocs/withPermissions";
import {
    TransactionElementInfo, TransactionFieldsTypes, TransactionTypes,
} from "../../../../../../types/transactionTypes";
import { ListsButtonsWrapper } from "../../../../../ui/details/DetailsComponents";
import TransactionListedOperationButtons from "./TransactionListedOperarionButtons";
import TransactionNotListedOperationButtons from "./TransactionNotListedOperationButtons";

interface WrappedComponentProps {
    value?: string,
    label: string,
    fieldType: TransactionFieldsTypes,
    listInfo?: TransactionElementInfo | null,
    transaction: TransactionTypes | null,
}

type ProtectedOperationButtonsProps = WrappedComponentProps & {
    permission: string;
}

const WrappedComponent = ({
    value,
    label,
    listInfo,
    transaction,
    fieldType,
}: WrappedComponentProps) => (
    <ListsButtonsWrapper>
        {
            listInfo ? (
                <TransactionListedOperationButtons
                    label={label}
                    listType={listInfo?.type}
                    transaction={transaction}
                />
            ) : (
                <TransactionNotListedOperationButtons
                    label={label}
                    value={value}
                    fieldType={fieldType}
                    transactionId={transaction?.id}
                />
            )
        }
    </ListsButtonsWrapper>
);

const ProtectedOperationButtons = ({ permission, ...props }: ProtectedOperationButtonsProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission {...props} />;
};

export default ProtectedOperationButtons;
