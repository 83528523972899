import { observer } from "mobx-react-lite";

import { generateHeaderName } from "../../../helpers/secondHeaderHelper";
import useTranslation from "../../../hooks/useTranslation";
import SecondHeader from "../../secondHeader/SecondHeader";
import ProtectedElementsTable from "./ProtectedElementsTable";
import { useTabsActions } from "./hooks/useTabsActions";
import { permissionsMap } from "../../../constants/permissions";

const PagesElements = () => {
    const { t } = useTranslation();

    const headerTitle = generateHeaderName(t.elements.title);

    const {
        FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    } = useTabsActions();

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <ProtectedElementsTable permission={permissionsMap.elements.view} />
            {FilterModalComponent}
            {ActionModalComponent}
        </>
    );
};

export default observer(PagesElements);
