import { ChangeStatusResponseType } from "../../types/commonFiltersType";
import request from "../request";

interface RequestType {
    id: number[],
}

const deactivateTgGroups = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "telegram-groups/deactivate/",
    data,
});

export default deactivateTgGroups;
