import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { ReactComponent as Filter } from "../../../resources/icons/secondHeader/filter.svg";
import { ReactComponent as Plus } from "../../../resources/icons/secondHeader/plus.svg";
import { ReactComponent as Info } from "../../../resources/icons/info-circle.svg";
import { ButtonVariantType } from "../../../types/buttonTypes";
import { ReactComponent as Clone } from "../../../resources/icons/copyIcon.svg";
import { SmallButtonShapeType } from "./SmallButton";

export const ButtonComponent = styled.button<{ $variant: ButtonVariantType, $isLoading?: boolean }>`
    display: inline-flex;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    border-radius: 12px;
    border: none;
    padding: 20px;
    cursor: pointer;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    

    ${({ $variant, $isLoading }) => {
        switch ($variant) {
            case "primary":
                if ($isLoading) {
                    return `
            background-color: ${colors.buttonPrimaryHover};
            color: ${colors.white};
            pointer-events: none;
            `;
                }
                return `
            background-color: ${colors.buttonPrimary};
            color: ${colors.white};
            &:hover {
                background-color: ${colors.buttonPrimaryHover};
            }
        `;
            case "secondary":
                if ($isLoading) {
                    return `
            background-color: ${colors.buttonSecondaryHover};
            color: ${colors.textPrimary};
            pointer-events: none;
            `;
                }
                return `
            background-color: ${colors.buttonDefaultBackground};
            color: ${colors.textPrimary};
            &:hover {
                background: ${colors.buttonSecondaryHover};
            }
        `;
            case "error":
                if ($isLoading) {
                    return `
            background-color: ${colors.buttonErrorHover} !important;
            color: ${colors.white} !important;
            `;
                }
                return `
            background-color: ${colors.error};
            color: ${colors.white};
            &:hover {
                background: ${colors.buttonErrorHover};
            }
        `;
            case "tertiary":
                if ($isLoading) {
                    return `
            background-color: ${colors.buttonSecondaryHover} !important;
            color: ${colors.textPrimaryHover} !important;
            pointer-events: none;
            `;
                }
                return `
            background-color: transparent;
            border: 1px solid ${colors.inputDefaultBorder};
            color: ${colors.textPrimary};
            &:hover {
                background: ${colors.buttonDisabledBackgroundSecondary};
                border-color: ${colors.inputHoverBorder};
            }
        `;
            default:
                return "";
        }
    }}
    ${({ $isLoading }) => {
        if (!$isLoading) {
            return `&:disabled {
        color: ${colors.textDisabled};
        background-color: ${colors.buttonDisabledBackground};
        cursor: not-allowed;

        &:hover {
            background-color: ${colors.buttonDisabledBackground};
            color: ${colors.textDisabled};
        }

        svg {
            fill: ${colors.textDisabled};
        }
    }
    `;
        }
        return "";
    }}
`;

export const SmallButtonComponent = styled(ButtonComponent)<{
    $shape?: SmallButtonShapeType,
    $isCapitalized?: boolean
    $isLoading?: boolean
}>`
    font-size: 14px;
    font-weight: 500;
    width: auto;
    height: 32px;
    padding: ${(props) => (props.$shape === "square" ? "8px" : "8px 16px")};
    line-height: 15px;
    white-space: nowrap;
    text-transform: ${(props) => props.$isCapitalized && "capitalize"};
    border-radius: 8px;
`;

export const FilterIcon = styled(Filter)`
`;
export const PlusIcon = styled(Plus)`
    margin-bottom: 3px;
`;
export const InfoIcon = styled(Info)`
    width: 16px;
    height: 16px;
    margin-bottom: 3px;
`;

export const CloneIcon = styled(Clone)`
    width: 16px;
    height: 16px;
`;
