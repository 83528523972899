import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import Routes from "../../../../../constants/routes";
import { Tabs } from "../../../../../constants/tabs";
import rulesPackPartnersStore from "../../../../../store/rulesPack/rulesPackPartnersStore";
import { RulesPacksPartnersTypes } from "../../../../../types/rulesPacksPartnersTypes";
import Table from "../../../../ui/table/Table";
import { ColumnType } from "../../../../ui/table/types";
import TableFieldsDetailsLink from "../../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../../../ui/tableFields/nameLink/TableMainLink";
import StatusBadge from "../../../../ui/textField/StatusBadge";

interface RulesPacksPartnersTableProps {
    isRadioButtonVisible?: boolean;
    ruleId?: string;
}

const columns: ColumnType<RulesPacksPartnersTypes>[] = [
    {
        field: "radio_button",
        padding: "0px 8px 0px 12px",
        cellStyles: { width: "0px" },
    },
    {
        header: "table.header.partnerName",
        field: "partner_name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.partners.href}/${dataItem.partner}`}
                state={{ previousTab: Tabs.PARTNERS, tab: Tabs.NOT_SPECIFIED }}
            >
                {dataItem.partner_name}
            </TableMainLink>
        ),
    },
    { header: "table.header.partnerId", field: "partner", sortable: true },
    {
        header: "table.header.isActive",
        field: "partner_is_active",
        Component: (field) => {
            const { dataItem } = field;
            return (
                <StatusBadge
                    variant={dataItem.partner_is_active ? "success" : "inactive"}
                    multiLanguageText={dataItem.partner_is_active ? "status.active" : "status.inactive"}
                />
            );
        },
    },
    {
        header: "table.header.services",
        field: "partner_services_count",
        sortable: true,
        cellCenter: true,
    },
    {
        header: "table.header.email",
        field: "partner_email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "partner_phone" },
    {
        header: "table.header.accountManager",
        field: "partner_account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.processingId", field: "partner_processing_id", sortable: true },
    {
        header: "table.header.processingName",
        field: "partner_processing_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink
                href={`${Routes.partners.href}/${dataItem.partner}`}
                state={{ previousTab: Tabs.PARTNERS, tab: Tabs.NOT_SPECIFIED }}
            />
        ),
    },
];

const RulesPacksPartnersTable = ({ ruleId, isRadioButtonVisible }: RulesPacksPartnersTableProps) => {
    const { setPaginationPage, setPaginationSize } = rulesPackPartnersStore;

    const tablePagination = {
        ...rulesPackPartnersStore.pagination,
        setPaginationPage,
        setPaginationSize,
    };

    useEffect(() => {
        if (ruleId) rulesPackPartnersStore.fetchData(ruleId);
    }, [
        rulesPackPartnersStore.pagination,
        rulesPackPartnersStore.filters,
        rulesPackPartnersStore.ordering,
    ]);

    return (
        <Table
            columns={columns}
            data={rulesPackPartnersStore.data}
            pagination={tablePagination}
            checkedItems={rulesPackPartnersStore.checkedItems}
            onCheckboxChange={rulesPackPartnersStore.toggleRadioItem}
            onSort={rulesPackPartnersStore.toggleSort}
            sortMode={rulesPackPartnersStore.ordering}
            loading={rulesPackPartnersStore.loading}
            isItemSelectionAllowed={isRadioButtonVisible}
        />
    );
};

export default observer(RulesPacksPartnersTable);
