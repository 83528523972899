import { flow } from "mobx";
import dataStore from "../../common/dataStore";
import { parseDateFromServer } from "../../../helpers";
import { getRoles } from "../../../api/roles/getRoles";
import { NewRoleType, RoleFiltersType, RoleType } from "../../../types/rolesTypes";
import deactivateRoles from "../../../api/roles/deactivateRoles";
import { postRole } from "../../../api/roles/postRole";
import { getSelectedRole } from "../../../api/roles/getSelectedRole";
import patchRole from "../../../api/roles/patchRole";
import activateRoles from "../../../api/roles/activateRoles";

class RoleStore extends dataStore<RoleType, RoleFiltersType, NewRoleType> {
    protected fetchDataMethod = getRoles;

    protected fetchSelectedItemMethod = getSelectedRole;

    protected patchDataMethod = patchRole;

    protected postDataMethod = postRole;

    protected deactivateSelectedItemsMethod = deactivateRoles;

    protected activateSelectedItemsMethod = activateRoles;

    fetchSelectedItem = flow(function* fetchSelectedItem(this: RoleStore, id: number | string) {
        const response = yield this.fetchSelectedItemWithoutSet(id, true);

        if (response?.data.response) {
            [this._selectedItem] = parseDateFromServer<RoleType>([response.data.response], "created");
        }
    });

    fetchData = flow(function* fetchData(this: RoleStore) {
        const response = yield this.fetchWithoutSet();

        if (response?.data.response.results) {
            this._data = parseDateFromServer<RoleType>(response.data.response.results, "created");

            this._pagination.count = response.data.response.count;
        }
    });
}

const rolesStore = new RoleStore();

export default rolesStore;
