import FiltersDataStore from "../common/filtersStore";
import { getCountries } from "../../api/filters/getCountries";
import { CountriesType } from "../../types/commonFiltersType";

class CountriesFiltersStore extends FiltersDataStore<CountriesType> {
    protected fetchListMethod = getCountries;

    public get options() {
        return this.data?.map((item) => ({
            value: item.code,
            label: item.name,
        }));
    }
}

const filtersCountriesStore = new CountriesFiltersStore();

export default filtersCountriesStore;
