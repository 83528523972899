import { AxiosError } from "axios";

export type ErrorsType = {[fieldName: string]: string} & {common?: string};
type ErrorsRawType = {[fieldName: string]: string[] | string};

const getCommonError = (e: unknown): string => {
    let error = "";
    try {
        if (e instanceof AxiosError && e.response?.data.response) {
            if (e.response.data.response.non_field_errors) {
                const errorsRaw: ErrorsRawType = e.response.data.response;
                // eslint-disable-next-line prefer-destructuring
                error = errorsRaw.non_field_errors[0];
            } else if (e.response.data.response.detail) {
                if (Array.isArray(e.response.data.response.detail)) {
                    const errorsRaw: ErrorsRawType = e.response.data.response;
                    error = errorsRaw.detail[0] || "";
                } else {
                    error = e.response.data.response.detail;
                }
            }
        }
    } catch {
        return error;
    }

    return error;
};

const getFieldsErrors = (e: unknown, fieldsNames: string[]): ErrorsType => {
    const errors: ErrorsType = {};
    const commonError = getCommonError(e);
    if (commonError) {
        errors.common = commonError;
    }
    try {
        if (e instanceof AxiosError && e.response?.data.response) {
            const errorsRaw: ErrorsRawType = e.response.data.response;
            fieldsNames.forEach((fieldName) => {
                if (errorsRaw[fieldName]) {
                    if (Array.isArray(errorsRaw[fieldName])) {
                        // eslint-disable-next-line prefer-destructuring
                        errors[fieldName] = errorsRaw[fieldName][0];
                    } else {
                        errors[fieldName] = errorsRaw[fieldName] as string;
                    }
                }
            });
        }
    } catch {
        return errors;
    }

    return errors;
};

export {
    getCommonError,
    getFieldsErrors,
};
