import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import permissionsStore from "../../../../../store/permissions/permissionsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import rolesStore from "../../../../../store/user/roles/roleStore";
import { EditRoleType } from "../../../../../types/rolesTypes";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import ProtectedSmallButton from "../../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../../ui/button/SmallButton";
import {
    DetailsBodyWrapper, DetailsHeaderWithButton, DetailsHeaderWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import { Icon } from "../../../../ui/new/NewComponents";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import { DetailPageHeader } from "../../../../ui/TextStyle";
import EditPermissionsModal from "./EditPermissionsModal";
import EditRoleModal from "./EditRoleModal";
import ProtectedPermissionTable from "./ProtectedTable";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

type FieldsDataType = EditRoleType & { permissions: number[] };

const RoleDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = rolesStore;

    const permissionsIdsList = selectedItem?.permissions.map((permission) => Number(permission.id));

    const [isPermissionTableEditable, setPermissionTableEditing] = useState(false);
    const [isEditRoleModalVisible, setEditRoleModalVisibility] = useState(false);
    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);

    const togglePermissionTableEditing = () => setPermissionTableEditing((prev) => !prev);
    const toggleEditRoleModal = () => setEditRoleModalVisibility((prev) => !prev);
    const toggleWarningModal = () => setWarningModalVisibility((prev) => !prev);

    const currentTitle = id ? `${t.roles.text.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.roles.title, currentTitle);

    const preInputsParamsList = [
        { label: t.filters.fields.name, value: selectedItem?.name, key: "role-details-name-field" },
        { label: t.table.header.roleId, value: selectedItem?.id.toString(), key: "role-details-id-field" },
        { label: t.table.header.createdAt, value: selectedItem?.created, key: "role-details-created-at-field" },
        { label: t.table.header.createdBy, value: selectedItem?.created_by_name, key: "role-details-created-by-field" },
    ];

    const cancelPermissionsEditing = () => {
        if (permissionsIdsList) permissionsStore.setCheckedItems(permissionsIdsList);
        toggleWarningModal();
        togglePermissionTableEditing();
    };

    const handleSubmit = async () => {
        if (id) {
            const addedPermissionsIdsList = permissionsStore.addedItems.map((permission) => Number(permission.id));
            const fieldsData = { ...selectedItem, permissions: addedPermissionsIdsList };

            await rolesStore.patchData(fieldsData as FieldsDataType, id, []);

            if (rolesStore.isEditItemSuccess) {
                togglePermissionTableEditing();
                snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
            } else {
                snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
            }
        }
    };

    const handleNavigate = () => navigateBackward({ tab: Tabs.ROLES });

    const actionHeaderContentList = [
        {
            key: "role-details-navigate-button",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) rolesStore.fetchSelectedItem(id);
        permissionsStore.fetch();
    }, [id]);

    useEffect(() => {
        if (permissionsIdsList?.length) permissionsStore.setCheckedItems(permissionsIdsList);

        return () => permissionsStore.clearAddedItems();
    }, [permissionsIdsList?.length]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={t.roles.text.title}
                        isStatusActive={selectedItem?.is_active}
                        isEditAllowed={isPermissionTableEditable}
                        permission={permissionsMap.roles.change}
                        onEdit={toggleEditRoleModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map((input) => {
                            const { value, label, key } = input;

                            return <PreInput value={value} label={label} key={key} />;
                        })}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.roles.placeholders.description} value={selectedItem?.description} />
                </DetailsSectionWrapper>

                <DetailsSectionWrapper>
                    <DetailsHeaderWrapper>
                        {/* TODO: Refactor code from line 147 to 174 */}
                        <DetailsHeaderWithButton>
                            <DetailPageHeader>{t.roles.text.permissions}</DetailPageHeader>
                            {
                                !isPermissionTableEditable
                                && (
                                    <ProtectedSmallButton
                                        title={t.buttons.edit}
                                        variant="secondary"
                                        disabled={false}
                                        permission={permissionsMap.roles.change}
                                        onClick={togglePermissionTableEditing}
                                    />
                                )
                            }
                        </DetailsHeaderWithButton>

                        <DetailsHeaderWithButton>
                            {isPermissionTableEditable
                                && (
                                    <DetailsHeaderWithButton>
                                        <SmallButton variant="secondary" onClick={toggleWarningModal}>
                                            {t.buttons.cancel}
                                        </SmallButton>
                                        <SmallButton variant="primary" onClick={handleSubmit}>
                                            {t.buttons.save}
                                        </SmallButton>
                                    </DetailsHeaderWithButton>
                                )}
                        </DetailsHeaderWithButton>
                    </DetailsHeaderWrapper>
                </DetailsSectionWrapper>

                <ProtectedPermissionTable
                    isEditable={isPermissionTableEditable}
                    permission={permissionsMap.roles.change}
                />

                <EditRoleModal
                    isOpen={isEditRoleModalVisible}
                    setOn={toggleEditRoleModal}
                    title={t.roles.edit.title}
                    buttonText={t.buttons.save}
                />

                <EditPermissionsModal
                    isOpen={isWarningModalVisible}
                    title={<Icon />}
                    submitButton={false}
                    setOn={toggleWarningModal}
                    toggleModal={toggleWarningModal}
                    handleNavigate={cancelPermissionsEditing}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(RoleDetails);
