import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import employeeStore from "../../../../store/user/employee/employeeStore";
import rolesStore from "../../../../store/user/roles/roleStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import { PermissionKeys } from "../../../../types/permissionsTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import ProtectedMenu from "../components/EmployeesMenu/ProtectedMenu";
import EmployeesFilters from "../employees/EmployeesFilters";
import { TabsType } from "../../../../types/tabs";
import Link from "../../../ui/link/Link";

type TabTitlesKeys = Extract<PermissionKeys, "employees" | "roles">;

export function useTabsActions(currentTabTitle: PermissionKeys, currentTab?: TabsType) {
    const { t } = useTranslation();

    const [isFilterModalVisible, setFilterModalVisibility] = useState(false);
    const toggleFilterModal = () => setFilterModalVisibility((prev) => !prev);
    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const employeesCustomText = actionModalState.type === "deactivate"
        ? t.employees.text.deactivateModalText : t.employees.text.activateModalText;
    const rolesCustomText = actionModalState.type === "deactivate"
        ? t.roles.text.deactivateModalText : undefined;

    const tabsPropertiesMap = {
        employees: {
            store: employeeStore,
            entityName: t.employees.text.partOfLink,
            actionModalBodyText: employeesCustomText,
            isFilterModalShown: true,
        },
        roles: {
            store: rolesStore,
            entityName: t.roles.text.partOfLink,
            actionModalBodyText: rolesCustomText,
            isFilterModalShown: false,
        },
    };

    const {
        store, entityName, actionModalBodyText, isFilterModalShown,
    } = tabsPropertiesMap[currentTabTitle as TabTitlesKeys];

    const currentAddButtonTitle = currentTabTitle?.slice(0, currentTabTitle.length - 1);
    const filtersCount = objectToValuesList(employeeStore.filters || {}).length;

    const AddButtonComponent = (
        <Link to={`/employees/${currentTabTitle}/new`} state={{ tab: currentTab }}>
            <ProtectedSmallButton
                title={currentAddButtonTitle}
                permission={permissionsMap[currentTabTitle].add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
            />
        </Link>
    );

    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
            onClick={toggleFilterModal}
        >
            {t.buttons.filter}
        </SmallButton>
    );

    const MenuDropdownComponent = (
        <ProtectedMenu
            store={store}
            permission={permissionsMap[currentTabTitle].changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal isOpen={isFilterModalVisible} setOn={toggleFilterModal}>
            <EmployeesFilters closeModal={toggleFilterModal} />
        </FiltersModal>
    );

    const ActionModalComponent = (
        <ActionModal
            store={store}
            entityName={entityName}
            customBodyText={actionModalBodyText}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent, isDisplayed: isFilterModalShown },
        { key: "menu-dropdown-button-component", content: MenuDropdownComponent },
    ];

    return {
        FilterModalComponent,
        ActionModalComponent,
        actionHeaderContentList,
    };
}
