import { ElementsTypes } from "../../types/elementsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: ElementsTypes,
}

export const getSelectedElement = (elementId: string) => request<ResponseType>({
    method: "GET",
    uri: `elements/${elementId}/`,
});
