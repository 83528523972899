import { FC } from "react";
import { MainLoaderCircle, SecondaryLoaderCircle } from "./LoaderComponents";
import { LoaderVariantType } from "../../../types/loaderTypes";

export interface LoaderProps {
    variant: LoaderVariantType;
    size?: string;
}

const Loader: FC<LoaderProps> = ({ variant, size = "24" }) => (
    <svg height={size} width={size} viewBox="0 0 300 300">
        <MainLoaderCircle
            $variant={variant}
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="290 150 150"
                to="640 150 150"
                dur="1s"
                repeatCount="indefinite"
            />
        </MainLoaderCircle>
        <SecondaryLoaderCircle
            $variant={variant}
            cx="50%"
            cy="50%"
            r="40%"
            fill="none"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 150 150"
                to="360 150 150"
                dur="1s"
                repeatCount="indefinite"
            />
        </SecondaryLoaderCircle>
    </svg>
);

export default Loader;
