import FiltersDataStore from "../common/filtersStore";
import { getTimezones } from "../../api/filters/getTimezones";
import { TimezonesType } from "../../types/commonFiltersType";

class FiltersTimezonesStore extends FiltersDataStore<TimezonesType> {
    protected fetchListMethod = getTimezones;

    public get options() {
        return this.data?.map((item) => ({
            value: item.name,
            label: `${item.offset}, ${item.name}`,
        }));
    }
}

const filtersTimezonesStore = new FiltersTimezonesStore();

export default filtersTimezonesStore;
