import { useEffect } from "react";
import FloatInput, { FloatInputProps } from "./FloatInput";
import CustomPopover from "../popover/CustomPopover";
import { FormikTypes } from "../../../types/formikTypes";
import CustomPopoverItem from "../popover/CustomPopoverItem";
import useTranslation from "../../../hooks/useTranslation";
import { ReactComponent as FilterIcon } from "../../../resources/icons/filterIcon.svg";

interface FloatInputWithPropertiesProps extends FloatInputProps {
    partialMatch?: boolean
    isEmpty?: boolean
    name?: string,
    formik: FormikTypes;
}

const FloatInputWithProperties = ({
    partialMatch = true,
    isEmpty = true,
    name,
    formik,
    ...inputProps
}: FloatInputWithPropertiesProps) => {
    const { t } = useTranslation();

    const partialMatchFieldName = `${name}__isicontains`;
    const isEmptyFieldName = `${name}__isempty`;

    const fieldValue = formik.values[name || ""];
    const isEmptyValue = formik.values[isEmptyFieldName];
    const partialMatchValue = formik.values[partialMatchFieldName];

    const partialMatchItem = (
        <CustomPopoverItem
            title={t.transactions.popover.partialMatch}
            key={partialMatchFieldName}
            name={partialMatchFieldName}
            display={partialMatch}
            disabled={isEmptyValue}
            formik={formik}
        />
    );

    const isEmptyItem = (
        <CustomPopoverItem
            title={t.transactions.popover.isEmpty}
            key={isEmptyFieldName}
            name={isEmptyFieldName}
            display={isEmpty}
            formik={formik}
        />
    );

    const items = [partialMatchItem, isEmptyItem];

    useEffect(() => {
        if (isEmptyValue && name) {
            if (fieldValue) {
                formik.setFieldValue(name, "");
            }
            formik.setFieldValue(partialMatchFieldName, false);
        }
    }, [isEmptyValue]);

    useEffect(() => {
        if (fieldValue) {
            formik.setFieldValue(isEmptyFieldName, false);
        }
    }, [fieldValue]);

    return (
        <CustomPopover items={items}>
            <FloatInput
                name={name}
                formik={formik}
                autoComplete="off"
                postIcon={(isEmptyValue || partialMatchValue) && <FilterIcon />}
                {...inputProps}
            />
        </CustomPopover>
    );
};

export default FloatInputWithProperties;
