import { useState } from "react";
import {
    DoorIconContainer, ExitMenuItem, MenuDoorIcon, ModalDescription, ModalDoorIcon,
} from "./MenuComponents";
import { useClearStorage } from "../../hooks/useClearStorage";
import authStore from "../../store/auth/authStore";
import useTranslation from "../../hooks/useTranslation";
import Modal from "../ui/modal/Modal";
import { Row } from "../common/modal/warning/blockModal/BlockModalComponents";
import Button from "../ui/button/Button";
import { colors } from "../../styles/variables";

const LogoutButton = () => {
    const { t } = useTranslation();

    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
    const toggleWarningModal = () => setIsWarningModalVisible((prevState) => !prevState);

    const { clearAllStorages } = useClearStorage();

    const handleLogout = () => {
        clearAllStorages();
        authStore.logout();
    };

    return (
        <>
            <ExitMenuItem onClick={toggleWarningModal}>
                <MenuDoorIcon stroke={colors.error} />
                <span>{t.menu.links.logout}</span>
            </ExitMenuItem>
            <Modal
                isOpen={isWarningModalVisible}
                setOn={setIsWarningModalVisible}
                submitButton={false}
                title={<DoorIconContainer><ModalDoorIcon /></DoorIconContainer>}
                gap="0"
            >
                <ModalDescription>{t.login.text.logout}</ModalDescription>
                <Row>
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={toggleWarningModal}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={handleLogout}
                    >
                        {t.buttons.logout}
                    </Button>
                </Row>
            </Modal>
        </>
    );
};

export default LogoutButton;
