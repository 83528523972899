import styled from "styled-components";
import { ReactComponent as CloseIconResource } from "../../resources/icons/closeIcon.svg";
import { colors, textAndIconColor } from "../../styles/variables";
import { SnackbarVariantType } from "../../types/snackbarTypes";

export const Container = styled.div`
    z-index: 9999999;
    position: fixed;
    margin-top: -6px;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: hidden;
`;

type ItemType = {
    $variant: SnackbarVariantType,
    $active?: boolean,
}

export const Item = styled.div<ItemType>`
    display: flex;
    align-items: center;
    gap: 30px;
    width: fit-content;
    overflow: hidden;
    padding: 0 16px;
    border-radius: 8px;
    transition: all .3s;

    height: ${(props) => (props.$active ? "36px" : "0")};
    margin: ${(props) => (props.$active ? "6px 0" : "0")};

    ${textAndIconColor(colors.white)};

    background-color: ${(props) => (
        (props.$variant === "error" && colors.error)
            || (props.$variant === "success" && colors.successBackground)
            || (props.$variant === "warning" && colors.warning)
            || colors.activeText
    )};
`;

export const Text = styled.span`
    margin-left: auto;
    font-size: 14px;
    line-height: 16px;
`;

export const CloseIcon = styled(CloseIconResource)`
    flex-shrink: 0;
    margin-left: 10px;
    cursor: pointer;
`;
