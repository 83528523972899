import styled from "styled-components";

export const TFAFormWrapper = styled.form`
    display: flex;
    flex-direction: column;
    gap: 60px;
`;

export const TFAAndErrorMessageSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
