import request from "../request";
import { EditListType, ListsTypes } from "../../types/listsTypes";

interface ResponseType {
    message: string,
    response: ListsTypes,
}

const postCloneList = (data: EditListType, listId: string | number) => request<ResponseType>({
    method: "POST",
    uri: `lists/${listId}/clone/`,
    data,
});

export default postCloneList;
