import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { FocusEvent, useState } from "react";

import { DATE_FORMAT } from "../../../constants/dates";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { DatePickerProps } from "./DatePicker";
import { DatePickerContainer, PreDatePickerStyledInput, PreDatePickerWrapper } from "./DatePickerComponents";

dayjs.extend(utc);
const disabledDate = (current: Dayjs): boolean => current && current.isBefore(dayjs().startOf(("day")));

const PreDatePicker = ({
    formik,
    addonBeforeText,
    serverErrors,
    isNowButtonShown = true,
    onChange,
    resetServerError,
    ...props
}: Omit<DatePickerProps, "maxDate">) => {
    let isError = false;
    let error;
    let {
        value,
        minDate,
    } = props;
    const name = props.name || "";
    const [isDateSelected, setDateSelected] = useState(!!formik?.values[name]);

    if (formik) {
        const formikValue = formik.values[name];

        if (formikValue && typeof formikValue === "string") {
            value = dayjs(formik.values[name]);
        }

        error = serverErrors?.[name] || (formik?.touched[name] && formik.errors[name]);
        isError = !!error;
    }

    const handleChange = (_: unknown, date: string | string[]) => {
        if (value) {
            setDateSelected(true);
        }
        if (typeof date === "string") {
            let parsedDate = date;

            if (date) {
                setDateSelected(true);
                parsedDate = dayjs(date, DATE_FORMAT).utc().format();
            } else {
                setDateSelected(false);
            }

            formik?.setFieldValue(name, parsedDate);
            formik?.setFieldTouched(name, false);
            resetServerError?.(name);
            onChange?.(parsedDate);
        }
    };

    const handleBlur = (event: FocusEvent<HTMLElement, Element>) => {
        formik?.handleBlur(event as FocusEvent<HTMLInputElement>);
    };

    if (minDate && typeof minDate === "string") {
        minDate = dayjs(minDate);
    }

    return (
        <DatePickerContainer>
            <PreDatePickerWrapper $isError={isError}>
                {isDateSelected && <PreDatePickerStyledInput readOnly value={addonBeforeText} />}
                <DatePicker
                    {...props}
                    showTime
                    showNow={isNowButtonShown}
                    format={{
                        format: DATE_FORMAT,
                        type: "mask",
                    }}
                    value={value}
                    minDate={minDate as Dayjs}
                    disabledDate={disabledDate}
                    onBlurCapture={handleBlur}
                    onChange={handleChange}
                />
            </PreDatePickerWrapper>
            {isError && ErrorLabel(error || "")}
        </DatePickerContainer>
    );
};

export default PreDatePicker;
