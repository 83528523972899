import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const SwitchContainer = styled.label`
    position: relative;
    width: 26px;
    height: 16px;
    display: inline-block;
`;

export const SwitchCheckbox = styled.input`
    display: none;
`;

export const SwitchSlider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: ${colors.switchDefaultBackground};
    -webkit-transition: .4s;
    transition: .4s;

    ${SwitchCheckbox}:disabled + && {
        cursor: default
    }

    &:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 1px;
        bottom: 2px;
        border-radius: 50%;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    ${SwitchCheckbox}:checked + && {
        background-color: ${colors.successBackground};

        &:before {
            -webkit-transform: translateX(11px);
            -ms-transform: translateX(11px);
            transform: translateX(11px);
        }
    }
`;
