import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as DoorIconRaw } from "../../resources/icons/doorIcon.svg";
import { ReactComponent as LogoIcon } from "../../resources/icons/logo.svg";
import { colors } from "../../styles/variables";

const MenuContainer = styled.menu`
    position: sticky;
    top: 20px;
    z-index: 10;
    align-self: start;

    height: calc(100vh - 70px);

    grid-area: menu;
    display: flex;
    flex-direction: column;
    row-gap: 50px;

    margin: 0;
    padding: 15px 10px;

    background-color: ${colors.white};
    border-radius: 12px;
`;

const Logo = styled(LogoIcon)`
    margin-top: 10px;
    min-width: 102px;
    min-height: 32px;
`;

const MenuItem = styled(({ children, ...props }: NavLinkProps) => (
    <NavLink {...props} style={{ width: "calc(100% - 20px)" }}>
        {children}
    </NavLink>
))`
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: fit-content;

    padding: 6px 10px;
    border-radius: 8px;
    background-color: ${colors.white};

    text-decoration: none;

    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: ${colors.textDefault};
    }

    &:hover, &.active {
        background-color: ${colors.buttonPrimary};
        width: calc(100% - 20px);

        span {
            color: ${colors.white};
        }

        svg {
            fill: ${colors.white};
            stroke: ${colors.white};
        }
    }
`;

const MenuItemSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const MenuItemsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

const MenuItemsBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

const DoorIconContainer = styled.div`
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: ${colors.buttonDefaultBackground};
`;

const ModalDescription = styled.p`
    margin: 24px 0 32px;
    color: ${colors.activeText};
`;

const MenuDoorIcon = styled(DoorIconRaw)`
    stroke: ${colors.error};
`;

const ModalDoorIcon = styled(DoorIconRaw)`
    color: ${colors.buttonPrimary};
    width: 32px;
    height: 32px;
`;

const ExitMenuItem = styled.button`
    display: flex;
    padding: 6px 10px;
    align-items: center;
    column-gap: 6px;
    width: 100%;
    border-radius: 8px;
    background-color: ${colors.white};

    text-decoration: none;

    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: ${colors.error};
    border: none;
    cursor: pointer;

    &:hover {
        background-color: ${colors.error};

        span {
            color: ${colors.white};
        }

        svg {
            fill: ${colors.white};
            stroke: ${colors.white};
        }
    }
`;

const MenuItemTitle = styled.span``;

export {
    Logo,
    MenuDoorIcon,
    ModalDoorIcon,

    MenuItem,
    MenuContainer,
    MenuItemSectionContainer,
    MenuItemsListContainer,
    MenuItemsBottomContainer,
    DoorIconContainer,
    ModalDescription,
    ExitMenuItem,
    MenuItemTitle,
};
