export const MAX_PHONE_LENGTH = 128;
export const MAX_FIELD_USER_NAME_LENGTH = 150;
export const MAX_FIELD_NAME_LENGTH = 250;
export const MAX_CHAT_LINK_LENGTH = 250;
export const MAX_CHAT_ID_LENGTH = 250;
export const MAX_THREAD_ID_LENGTH = 250;
export const MAX_EMAIL_LENGTH = 254;
export const MAX_TRIGGER_FILED_LENGTH = 255;
export const MAX_DESCRIPTION_FILED_LENGTH = 256;
export const MAX_SITE_URL_LENGTH = 1024;
