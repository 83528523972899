import { useFormik } from "formik";
import { FC, FormEvent } from "react";
import { observer } from "mobx-react-lite";
import {
    FilterModalContainer,
    FormContainer,
    HeaderContainer,
    HeaderTitle,
} from "../../../filters/FiltersModalComponents";
import { TextButton } from "../../../ui/button/ButtonStyles";
import useTranslation from "../../../../hooks/useTranslation";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatSelect from "../../../ui/textInput/FloatSelect";
import partnerStore from "../../../../store/entities/partner/partnerStore";
import filtersPartnersStore from "../../../../store/filters/partnersFilterStore";
import filtersProcessingStore from "../../../../store/filters/processingFilterStore";
import filtersTimezonesStore from "../../../../store/filters/timezonesFilterStore";
import filtersPartnersManagersStore from "../../../../store/filters/entities/partnersManagersFilterStore";
import { getTranslatedHandbook, statusOptionsHandbook } from "../../../../constants/handbooks";
import Button from "../../../ui/button/Button";
import { PartnerFiltersTypes } from "../../../../types/partnerTypes";

interface PartnersFiltersProps {
    closeModal: () => void;
}

const PartnersFilters: FC<PartnersFiltersProps> = ({ closeModal }) => {
    const { t, translate } = useTranslation();

    const initialValues: PartnerFiltersTypes = {
        ...partnerStore.filters,
    };

    const formik = useFormik<PartnerFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            partnerStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        partnerStore.resetFilters();
        formik.resetForm();
    };

    const statusOptions = getTranslatedHandbook(translate, statusOptionsHandbook);

    return (
        <FilterModalContainer>
            <HeaderContainer>
                <HeaderTitle>{t.entities.partners.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="id"
                    options={filtersPartnersStore.options}
                    placeholder={t.filters.fields.partner}
                />
                <FloatSelect
                    formik={formik}
                    name="is_active"
                    options={statusOptions}
                    placeholder={t.filters.fields.isActive}
                />
                <FloatInput
                    formik={formik}
                    name="email"
                    placeholder={t.filters.fields.email}
                />
                <FloatInput
                    formik={formik}
                    name="phone"
                    placeholder={t.filters.fields.phone}
                />
                <FloatSelect
                    formik={formik}
                    name="account_manager"
                    options={filtersPartnersManagersStore.options}
                    placeholder={t.filters.fields.accountManager}
                />
                <FloatSelect
                    formik={formik}
                    name="timezone"
                    options={filtersTimezonesStore.options}
                    placeholder={t.filters.fields.timezone}
                />
                <FloatSelect
                    formik={formik}
                    name="processing"
                    options={filtersProcessingStore.options}
                    placeholder={t.filters.fields.processing}
                />
                <Button variant="primary" isLoading={partnerStore.loading} type="submit">{t.filters.apply}</Button>
            </FormContainer>
        </FilterModalContainer>
    );
};

export default observer(PartnersFilters);
