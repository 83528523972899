import request from "../request";
import { NewRoleType, RoleType } from "../../types/rolesTypes";

interface ResponseType {
    message: string;
    response: RoleType;
}

export const postRole = (data: NewRoleType) => request<ResponseType>({
    method: "POST",
    uri: "roles/",
    data,
});
