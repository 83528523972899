import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DataType, RowProps, TableProps } from "../../types";
import DraggableRow from "../TableRow/DraggableRow";
import StaticRow from "../TableRow/StaticRow";

type TableBodyProps<D extends DataType> = {
    dataIds: number[]
    isTableRowsMovable?: boolean
} & Omit<RowProps<D>, "item"> & Pick<TableProps<D>, "data" | "loading" | "isItemSelectionAllowed">

const TableBody = <D extends DataType>({
    data,
    loading,
    checkedItems,
    columns,
    onCheckboxChange,
    columnWidths,
    dataIds,
    isTableRowsMovable = false,
    isItemSelectionAllowed,
}: TableBodyProps<D>) => {
    if (isTableRowsMovable) {
        return (
            <tbody>
                <SortableContext
                    items={dataIds}
                    strategy={verticalListSortingStrategy}
                >
                    {!loading && data?.map((item) => (
                        <DraggableRow
                            key={item.id}
                            item={item}
                            columnWidths={columnWidths}
                            checkedItems={checkedItems}
                            columns={columns}
                            onCheckboxChange={onCheckboxChange}
                            isItemSelectionAllowed={isItemSelectionAllowed}
                        />
                    ))}
                </SortableContext>
            </tbody>
        );
    }

    return (
        <tbody>
            {!loading && data?.map((item) => (
                <StaticRow
                    key={item.id}
                    item={item}
                    columnWidths={columnWidths}
                    checkedItems={checkedItems}
                    columns={columns}
                    onCheckboxChange={onCheckboxChange}
                    isItemSelectionAllowed={isItemSelectionAllowed}
                />
            ))}
        </tbody>
    );
};

export default TableBody;
