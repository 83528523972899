import React from "react";

import { capitalizeFirstLetter } from "../../../../helpers";
import { TransactionDecision } from "../../../../types/transactionTypes";
import { ColoredTextField } from "./ColoredCellComponents";

interface DataType {
    decision: TransactionDecision;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const ColoredDecision = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <ColoredTextField value={dataItem.decision.toLowerCase() as TransactionDecision}>
        {capitalizeFirstLetter(dataItem.decision)}
    </ColoredTextField>
);

export default ColoredDecision;
