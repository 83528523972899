import { observer } from "mobx-react-lite";
import React from "react";
import useTranslation from "../../../hooks/useTranslation";
import SecondHeader from "../../secondHeader/SecondHeader";
import PersonalInfo from "./sections/PersonalInfo/PersonalInfo";
import Security from "./sections/Security/Security";
import { SettingsContainer } from "./PagesSettingsComponents";

const PagesSettings = () => {
    const { t } = useTranslation();

    return (
        <>
            <SecondHeader title={t.menu.links.settings} />
            <SettingsContainer>
                <PersonalInfo />
                <Security />
            </SettingsContainer>
        </>
    );
};

export default observer(PagesSettings);
