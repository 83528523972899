import styled from "styled-components";

import { colors } from "../../../styles/variables";
import { SecondaryButton } from "../button/ButtonStyles";

export const dropdownStyle = {
    display: "flex",
    width: "fit-content",
    padding: "5px",
    flexDirection: "column",
    gap: "5px",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px 0px rgba(153, 153, 153, 0.20)",
};

export const MenuDropdownButton = styled(SecondaryButton)<{ $isVisible: boolean }>`
    display: flex;
    height: 32px;
    width: 32px;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;

    background-color: ${(props) => (
        props.$isVisible ? colors.buttonPrimary : colors.buttonDefaultBackground
    )};

    &:hover {
        background-color: ${(props) => (
        props.$isVisible ? colors.buttonSecondaryHover : colors.buttonSecondaryHover
    )};
    }

`;

export const MenuItemComponent = styled.span`
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 5px;
    color: ${colors.activeText};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 105%;
    
    &:hover {
        background-color: ${colors.tableRowZebraBackground};  
    }
    &:active {
        background-color: ${colors.tableRowSelectedBackground};
    }
    &:disabled {
        background-color: ${colors.white};  
        color: ${colors.textDisabled};  
    }
`;
