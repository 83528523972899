import { capitalizeFirstLetter } from "../../../../../helpers";
import { AlertsImportanceTypes } from "../../../../../types/alertsTypes";
import { Row, Text } from "../TableComponents";
import { TableFieldImportanceStatusComponent } from "./TableFieldImportanceStatusComponents";

type TableFieldsImportanceStatusProps = {
    status: AlertsImportanceTypes;
}

const TableFieldImportanceStatus = ({ status }: TableFieldsImportanceStatusProps) => {
    const modifiedStatusText = capitalizeFirstLetter(status);

    return (
        <Row>
            <TableFieldImportanceStatusComponent $variant={status} />
            <Text>{modifiedStatusText}</Text>
        </Row>
    );
};

export default TableFieldImportanceStatus;
