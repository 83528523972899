import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import routes from "../../../constants/routes";
import withPermission from "../../../hocs/withPermissions";
import limitStore from "../../../store/limit/limitStore";
import { LimitsTypes } from "../../../types/limitsTypes";
import Table from "../../ui/table/Table";
import { tableContainerStyle } from "../../ui/table/TableComponents";
import { ColumnType } from "../../ui/table/types";
import TableFieldsDetailsLink from "../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import { TableMainLink } from "../../ui/tableFields/nameLink/TableMainLink";
import TableFieldsStatusBadge from "../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import TableFieldsTypeBadge from "../../ui/tableFields/typeBadge/TableFieldTypeBadge";

interface ProtectedLimitsTableProps {
    permission: string;
    isCheckboxVisible: boolean;
}

type WrappedComponentProps = Omit<ProtectedLimitsTableProps, "permission">

const columns: ColumnType<LimitsTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.limitName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink to={`${routes.limits.href}/${dataItem.id}`}>{dataItem.name}</TableMainLink>
        ),
    },
    { header: "table.header.limitId", field: "id", sortable: true },
    { header: "table.header.rulesPackId", field: "rules_pack", sortable: true },
    { header: "table.header.createdAt", field: "created" },
    { header: "table.header.lastUpdate", field: "last_update", sortable: true },
    {
        header: "table.header.createdBy",
        field: "created_by_name",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.type", field: "channel_check", Component: TableFieldsTypeBadge },
    {
        header: "table.header.isActive", field: "is_active", sortable: true, Component: TableFieldsStatusBadge,
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={routes.limits.href} />
        ),
    },
];

const WrappedComponent = observer(({ isCheckboxVisible }: WrappedComponentProps) => {
    const tablePagination = {
        ...limitStore.pagination,
        setPaginationPage: limitStore.setPaginationPage,
        setPaginationSize: limitStore.setPaginationSize,
    };

    useEffect(() => {
        limitStore.fetchData();
    }, [limitStore.pagination, limitStore.filters, limitStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={tableContainerStyle}
            columns={columns}
            data={limitStore.data}
            pagination={tablePagination}
            isItemSelectionAllowed={isCheckboxVisible}
            checkedItems={limitStore.checkedItems}
            sortMode={limitStore.ordering}
            loading={limitStore.loading}
            onSort={limitStore.toggleSort}
            onCheckboxChange={limitStore.toggleItem}
            onMainCheckboxChange={limitStore.toggleMainItem}
        />
    );
});

const ProtectedLimitsTable = ({ isCheckboxVisible, permission }: ProtectedLimitsTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isCheckboxVisible={isCheckboxVisible} />;
};

export default memo(ProtectedLimitsTable);
