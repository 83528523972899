import { type SelectProps, type RefSelectProps } from "antd";
import { useRef, useState } from "react";
import { ALL_OPTION, FieldMode, FieldModeOption } from "../../../constants/selectFields";
import { isEmptyArray } from "../../../helpers";
import useFloatInput from "../../../hooks/useFloatInputs";
import useTranslation from "../../../hooks/useTranslation";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import {
    AsteriskComponent,
    FloatInputCommonContainer,
    FloatInputContainer,
    FloatInputLabel,
    FloatSelectStyle,
} from "./InputStyles";
import { TextInputProps } from "./TextInput";
import { Option } from "../select/option/Option";

export type OptionType = { label?: string, value?: number | string }

export type FloatInputProps = SelectProps<unknown, OptionType> & Omit<TextInputProps, "onChange"> & {
    value?: string | number | boolean;
    saveFormat?: "value" | "label";
    isRequired?: boolean;
    isSelectAllOption?: boolean;
    onChange?: (value: unknown) => void;
};

const FloatSelect = ({
    formik,
    serverErrors,
    placeholder,
    name = "",
    options,
    saveFormat,
    isCommonError,
    isRequired,
    isSelectAllOption = false,
    onChange,
    resetServerError,
    ...props
}: FloatInputProps) => {
    const { t } = useTranslation();
    const selectRef = useRef<RefSelectProps>(null);

    const [inputValue, setInputValue] = useState<string>("");

    const inputArguments = {
        fieldProps: props, name, formik, serverErrors, resetServerError, isCommonError,
    };

    const {
        isFieldError, fieldError, isOccupied, fieldValue, setFocus, handleBlur,
    } = useFloatInput(inputArguments);

    const isAllFieldsSelected = options?.length === formik?.values[name]?.length;
    const optionLabelText = isAllFieldsSelected ? t.buttons.clearAll : t.buttons.selectAll;
    const optionsList = isSelectAllOption ? [{ value: ALL_OPTION, label: optionLabelText }, ...options || []] : options;

    const selectPopupClassName = props.mode === FieldMode.Multiple
        ? FieldModeOption.MultiSelect
        : FieldModeOption.Select;

    const handleBlurCapture = () => formik?.setFieldTouched(name, true);

    const handleChange = (newValue: unknown, option: OptionType) => {
        let newFormikValue = newValue;
        const isNewValueEmptyArray = isEmptyArray(newValue);

        if (newValue === undefined || isNewValueEmptyArray) {
            newFormikValue = null;
        } else if (saveFormat === "label" && option?.label) {
            newFormikValue = option.label;
        }
        formik?.setFieldValue(name, newFormikValue);
        formik?.setFieldTouched(name, false);
        resetServerError?.(name);
        onChange?.(newFormikValue);
    };

    const handleSelect = (value: unknown) => {
        if (value === ALL_OPTION) {
            const optionsValuesList = options?.map((field) => field.value);
            const formikValue = isAllFieldsSelected ? null : optionsValuesList;

            formik?.setFieldValue(name, formikValue);
        }
    };

    // AntDesign has an issue, Select component isn't blurring immediately when cleared, it's likely due the way
    // the component handles state updates and DOM manipulation internally. When the clear button is clicked, AntDesign
    // updates the internal state of the Select component, which might cause a slight delay before the blur method
    // can be called effectively.
    const handleClear = () => {
        setTimeout(() => {
            selectRef.current?.blur();
            formik?.setFieldTouched(name, false);
        }, 0);
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    return (
        <FloatInputCommonContainer>
            <FloatInputContainer onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
                <FloatSelectStyle
                    {...props}
                    ref={selectRef}
                    value={fieldValue}
                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    onChange={handleChange}
                    {...(isSelectAllOption && { onSelect: handleSelect })}
                    onBlur={handleBlur}
                    onBlurCapture={handleBlurCapture}
                    onClear={handleClear}
                    searchValue={inputValue}
                    onSearch={handleInputChange}
                    options={optionsList}
                    filterOption={(input, option) => {
                        const optionLabel = option?.label?.toString().toLowerCase();
                        const optionKey = option?.key?.toString().toLowerCase();
                        return optionKey?.indexOf(input.toLowerCase()) >= 0
                            || optionLabel.indexOf(input.toLowerCase()) >= 0;
                    }}
                    $isError={isFieldError}
                    name={name}
                    disabled={props.disabled}
                    $disabled={props.disabled}
                    showSearch
                    allowClear
                    maxTagCount="responsive"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    dropdownRender={(originNode) => <div className={selectPopupClassName}>{originNode}</div>}
                    menuItemSelectedIcon={null}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    optionRender={(option, { index }) => (
                        <Option
                            value={option.value}
                            label={option.data.label}
                            mode={props.mode}
                            isLastOption={options && index === options.length - 1}
                        />
                    )}
                />

                <FloatInputLabel $isError={isFieldError} $label={isOccupied} htmlFor={name}>
                    {placeholder}
                    {isRequired && <AsteriskComponent>*</AsteriskComponent>}
                </FloatInputLabel>
            </FloatInputContainer>

            {isFieldError && fieldError && ErrorLabel(fieldError || "")}
        </FloatInputCommonContainer>
    );
};

export default FloatSelect;
