import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../constants/permissions";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import useTranslation from "../../../../hooks/useTranslation";
import rulesPackStore from "../../../../store/rulesPack/rulesPackStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { EditRuleTypes } from "../../../../types/rulesPacksTypes";
import { SwitchButtonContainer } from "../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../ui/modal/modalStyle";
import ProtectedSwitch from "../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";
import { Title } from "../new/NewRulesPackComponents";

const fieldsList: Array<keyof EditRuleTypes> = ["name", "is_active", "description"];

const EditRuleModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: ruleId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = rulesPackStore;

    const formik = useFormik<EditRuleTypes>({
        initialValues: {},
        validationSchema: Yup.object({
            name: Yup.string().required(t.validation.required)
                .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        }),
        onSubmit: async (values) => {
            if (ruleId) {
                await rulesPackStore.patchData(values, ruleId, fieldsList);

                if (rulesPackStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.rulesPacks.snackbar.editSuccess });
                    setOn(false);
                } else {
                    snackbarStore.add({ variant: "error", text: t.rulesPacks.snackbar.addError });
                }
            }
        },
    });

    const toggleRulesPackStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    const handleCloseModal = () => {
        rulesPackStore.unsetEditState();
        formik.resetForm();
    };

    useEffect(() => () => {
        handleCloseModal();
    }, []);

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isForm
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={rulesPackStore.loading}
            setOn={setOn}
            handleClose={handleCloseModal}
            handleSubmit={formik.handleSubmit}
        >
            <EditSectionWrapper>
                <FloatInput
                    formik={formik}
                    disabled={rulesPackStore.loading}
                    name="name"
                    defaultValue={selectedItem?.name}
                    placeholder={t.table.header.ruleName}
                    isRequired
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={rulesPackStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    defaultValue={selectedItem?.description}
                    placeholder={t.table.header.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.status.title}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />

                    <ProtectedSwitch
                        disabled={rulesPackStore.loading}
                        permission={permissionsMap.rules_packs.changeStatus}
                        checked={formik.values.is_active}
                        onChange={toggleRulesPackStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditRuleModal);
