import React from "react";
import ErrorLabel from "../errors/ValidationErrorBox/ErrorLabel";
import { InputPassword } from "../textInput/InputStyles";
import { ReactComponent as ClosedEye } from "../../../resources/icons/closedEyeIcon.svg";
import { ReactComponent as OpenedEye } from "../../../resources/icons/openedEyeIcon.svg";
import { TextInputProps } from "../textInput/TextInput";

const renderEyeIcon = (isVisible :boolean) => (
    isVisible ? <OpenedEye /> : <ClosedEye />
);
const PasswordInput: React.FC<TextInputProps> = ({
    formik,
    serverErrors,
    resetServerError,
    isCommonError,
    regex,
    showFiledError = true,
    ...props
}) => {
    let isError;
    let error;
    let {
        value, onChange, onBlur,
    } = props;
    const field = props.name || "";
    if (formik) {
        value = formik.values[field];
        onChange = onChange ?? formik.handleChange;
        onBlur = onBlur ?? formik.handleBlur;
        error = !isCommonError && (serverErrors?.[field] || (formik?.touched[field] && formik.errors[field]));
        isError = !!error || !!isCommonError;
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (regex) {
            if (!regex.test(event.target.value)) {
                return;
            }
        }
        formik?.setFieldTouched(props.name || "", false);
        resetServerError?.(props.name || "");
        onChange?.(event);
    };
    return (
        <>
            <InputPassword
                {...props}
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                visibilityToggle
                iconRender={renderEyeIcon}
                $isError={isError}
            />
            {(error && showFiledError) && ErrorLabel(error)}
        </>
    );
};

export default PasswordInput;
