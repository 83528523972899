import { RequestParamsType } from "../../../store/common/filtersStore";
import { PartnerTypes } from "../../../types/partnerTypes";
import request from "../../request";

interface GetPartnersResponse {
    message: string,
    response: {
        count: number,
        results: PartnerTypes[],
    }
}

export const getPartners = (params?: RequestParamsType) => request<GetPartnersResponse>({
    method: "GET",
    uri: "entities/filters/partners/",
    params,
});
