import styled from "styled-components";

import { colors } from "../../../../../styles/variables";
import { AlertsImportanceTypes } from "../../../../../types/alertsTypes";

const statusesColorsMap: Record<AlertsImportanceTypes, string> = {
    high: colors.error,
    medium: colors.buttonPrimary,
    low: colors.inputDefaultBorder,
};

export const TableFieldImportanceStatusComponent = styled.span<{ $variant: AlertsImportanceTypes }>`
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;

    background-color: ${(props) => {
        const { $variant } = props;

        return statusesColorsMap[$variant];
    }};

`;
