import { Body, Container, Title } from "./CommandsModalComponents";
import useTranslation from "../../../../../hooks/useTranslation";
import CodeEditor from "../../../../ui/codeEditor/CodeEditor";

const CommandsModal = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <Title>
                {t.limits.text.commands}
            </Title>
            <Body>
                <CodeEditor
                    value={t.code}
                    showDisabledSnackbar={false}
                    disabled
                />
            </Body>
        </Container>
    );
};

export default CommandsModal;
