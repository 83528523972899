export const RegExpressions = {
    domain: /^[A-ZА-ЯЁa-zа-яё0-9._%+-]+.[A-ZА-ЯЁa-zа-яё0-9.-]{2,63}$/,
    email: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
    emailWithSpaces: /^\s*([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\s*$/,
    phone: /^\+?\d{3,18}$/,
    phoneWithSpaces: /^\s*\+?\d{3,18}\s*$/,
    password: /^[a-zA-Z0-9#\-_~!$&'()*+,;=:. %]*$/,
    numbers: /^[0-9]*$/,
    lowercaseAndUppercase: /^(?=.*[a-z])(?=.*[A-Z])/,
    specialSymbolAndNumber: /(?=.*\d)(?=.*[#\-_~!$&'()*+,;=:.])/,
    passwordLength: /^.{8,31}$/,
    card: /^\s*\d{13,19}\s*$/,
    sha256hash: /^\s*[a-f0-9]{64}\s*$/,
    ipv4Address: /^\s*(\b25[0-5]|\b2[0-4][0-9]|\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\s*$/,
    // eslint-disable-next-line max-len
    ipv6Address: /^\s*(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\s*$/,
};
