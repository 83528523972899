import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Modal, { ModalProps } from "../../../../../ui/modal/Modal";
import useTranslation from "../../../../../../hooks/useTranslation";
import Button from "../../../../../ui/button/Button";
import { ModalButtonRow } from "../../../../transactions/details/components/sections/SectionsComponents";
import rulesPackLimitsStore from "../../../../../../store/rulesPack/rulesPackLimitsStore";
import RulesPacksDetailsLimitsMoveTable from "../RulesPacksLimitsMoveTable/RulesPacksLimitsMoveTable";
import { RulesPacksLimitsType } from "../../../../../../types/limitsTypes";
import snackbarStore from "../../../../../../store/snackbar/snackbarStore";
import BlockModal from "../../../../../common/modal/warning/blockModal/BlockModal";
import { Icon } from "../../../../../ui/new/NewComponents";

interface RulesPacksLimitsMoveModalProps extends ModalProps {
    ruleId?: string
}

const RulesPacksLimitsMoveModal = ({ setOn, isOpen, ruleId }: RulesPacksLimitsMoveModalProps) => {
    const { t } = useTranslation();

    const [limits, setLimits] = useState<RulesPacksLimitsType[]>([]);
    const [isWarningModalVisible, setWarningModalVisibility] = useState(false);

    const toggleWarningModal = () => {
        setWarningModalVisibility(!isWarningModalVisible);
    };

    const sortedStoreLimits = [...rulesPackLimitsStore.orderingLimits].sort((a, b) => a.order - b.order);

    const isTableChanged = limits.some((limit, index) => (
        limit.id !== sortedStoreLimits[index].id));

    const changeLimitsOrdering = () => {
        if (ruleId) {
            rulesPackLimitsStore.changeOrdering(limits, ruleId).then(() => {
                if (!rulesPackLimitsStore.postErrors?.common) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
                setOn(false);
            });
        }
    };

    const closeModal = () => {
        if (isTableChanged) {
            toggleWarningModal();
        } else {
            setOn(false);
        }
    };

    const confirmExit = () => {
        setWarningModalVisibility(false);
        setOn(false);
    };

    const changeLimitsOrder = (currentLimits: RulesPacksLimitsType[]) => {
        const newLimits = currentLimits.map((limit, index) => ({ ...limit, order: index + 1 }));
        setLimits(newLimits);
    };

    useEffect(() => {
        if (ruleId && isOpen) rulesPackLimitsStore.fetchAllData(Number(ruleId));
    }, [ruleId, isOpen]);

    useEffect(() => {
        setLimits(sortedStoreLimits);
    }, [rulesPackLimitsStore.orderingLimits]);

    return (
        <>
            <Modal
                gap="0"
                width="984px"
                title={t.rulesPacks.details.moveLimit}
                isOpen={isOpen}
                submitButton={false}
                setOn={setOn}
                handleClose={closeModal}
                hasChildModal
                scrollable
            >
                <RulesPacksDetailsLimitsMoveTable data={limits} setData={changeLimitsOrder} />
                <ModalButtonRow $marginTop="auto">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setOn(false)}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        isLoading={rulesPackLimitsStore.loading}
                        disabled={!isTableChanged || rulesPackLimitsStore.loading}
                        onClick={changeLimitsOrdering}
                    >
                        {t.buttons.save}
                    </Button>
                </ModalButtonRow>
            </Modal>
            <BlockModal
                isOpen={isWarningModalVisible}
                title={<Icon />}
                submitButton={false}
                setOn={toggleWarningModal}
                toggleModal={toggleWarningModal}
                handleNavigate={confirmExit}
                blockOverflow={false}
            />
        </>
    );
};

export default observer(RulesPacksLimitsMoveModal);
