import { observer } from "mobx-react-lite";

import useTranslation from "../../../../../../hooks/useTranslation";
import permissionsStore from "../../../../../../store/permissions/permissionsStore";
import TableLoader from "../../../../../ui/table/components/TableLoader/TableLoader";
import {
    EmptyTable, Table as StyledTable, TableContainer, TD, TH, THContainer, TR,
} from "./TableComponents";

const PermissionsTableReadonly = () => {
    const { t, translate } = useTranslation();

    const { addedItems, loading } = permissionsStore;

    if (!loading && !addedItems.length) {
        return (
            <EmptyTable>
                <h3>{t.roles.table.empty.readonly.header}</h3>
                <p>{t.roles.table.empty.delete.text}</p>
            </EmptyTable>
        );
    }
    return (
        <TableContainer $shrinkHeight={!addedItems.length || !!loading}>
            <StyledTable>
                <thead>
                    <TR>
                        <TH>
                            <THContainer>{t.roles.table.header.added}</THContainer>
                        </TH>
                    </TR>
                </thead>
                <tbody>
                    {!loading && addedItems?.map((item) => {
                        const { id, codename } = item;

                        return (
                            <TR key={id}>
                                <TD>{translate(`roles.permissions.${codename}`)}</TD>
                            </TR>
                        );
                    })}
                </tbody>
            </StyledTable>
            {loading && <TableLoader />}

        </TableContainer>
    );
};

export default observer(PermissionsTableReadonly);
