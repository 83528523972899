import { ALL_OPTION, FieldMode } from "../../../../constants/selectFields";
import { OptionContainer, OptionInnerContainer, OptionSpace } from "../../textInput/InputStyles";
import Icon from "../Icon";
import { Label } from "./OptionComponents";

type OptionProps = {
    label: string;
    value?: string | number;
    mode?: "multiple" | "tags";
    isLastOption?: boolean;
}

export const Option = ({
    label, value, mode, isLastOption,
}: OptionProps) => {
    const isIconShown = mode === FieldMode.Multiple && value !== ALL_OPTION;

    return (
        <OptionContainer>
            <OptionInnerContainer className="option-container">
                <Label aria-label={label}>{label}</Label>
                {isIconShown && <Icon />}
            </OptionInnerContainer>
            {!isLastOption && <OptionSpace />}
        </OptionContainer>
    );
};
