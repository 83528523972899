import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../../hooks/useTranslation";
import authStore from "../../../../../store/auth/authStore";
import { HeaderText } from "../../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../../CommonPagesStyles";
import { HeaderSection, TFABody } from "./AuthTFAStyles";
import TFAForm from "../../../../authorizationLayout/TFA/TFAForm/TFAForm";
import attentionIcon from "../../../../../resources/icons/attentionIcon.svg";
import TFARecoveryModal from "../../../../ui/modal/TFARecoveryModal";
import { AuthRoutes } from "../../../../../constants/routes";

const PagesAuthTFA: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const performAuthorize = () => {
        authStore.secondStepAuthorize({ key: data })
            .then(() => {
                if (authStore.tfaAttemptLimitExceeded) {
                    setIsModalVisible(true);
                    authStore.unsetState();
                }
            });
    };

    const handleCloseBlockedModal = () => {
        authStore.setFirstLogin(false);
        navigate(AuthRoutes.login.href);
    };

    return (
        <RightSidePageWrapper>
            <TFABody>
                <HeaderSection>
                    <HeaderText>{t.login.text.mustLogin}</HeaderText>
                    <div>{t.login.tfa.enterTFA}</div>
                </HeaderSection>
                <TFAForm
                    onSubmit={performAuthorize}
                    setData={setData}
                    submitButton
                />
                <TFARecoveryModal
                    isOpen={isModalVisible}
                    setOn={setIsModalVisible}
                    title={t.modal.modalTitle.tfaRecovery}
                    iconURL={attentionIcon}
                    buttonText={t.login.tfaButtons.ok}
                    handleClose={handleCloseBlockedModal}
                />
            </TFABody>
        </RightSidePageWrapper>
    );
};

export default observer(PagesAuthTFA);
