import { TgGroupsTypes } from "../../types/tgGroupsTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: TgGroupsTypes,
}

export const getSelectedTgGroup = (tgGroupId: string) => request<ResponseType>({
    method: "GET",
    uri: `telegram-groups/${tgGroupId}/`,
});
