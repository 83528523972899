import styled from "styled-components";
import SmallButton from "../../../ui/button/SmallButton";
import { colors } from "../../../../styles/variables";

export const ArrowButtonComponent = styled(SmallButton)`
 
    &:disabled svg {
        fill: none;
        stroke: ${colors.textDisabled};
    }
`;
