import { ReactElement } from "react";
import { defaultPageSizeOptions } from "../../../constants/pagination";
import Pagination from "../pagination/Pagination";
import { EmptyTable, TableContainer, TableRootContainer } from "./TableComponents";
import TableLoader from "./components/TableLoader/TableLoader";
import { DataType, TableProps } from "./types";
import useTranslation from "../../../hooks/useTranslation";
import TableContent from "./components/TableContent/TableContent";

const Table = <D extends DataType>({
    data,
    setData,
    sensors,
    sortMode,
    columns,
    loading,
    pagination,
    tableContainerStyles,
    checkedItems,
    scrollable,
    onSort,
    onCheckboxChange,
    onMainCheckboxChange,
    isItemSelectionAllowed = true,
}: TableProps<D>): ReactElement => {
    const { t } = useTranslation();

    if (!loading && !data.length) {
        return (
            <EmptyTable>
                <h3>{t.table.empty.header}</h3>
                <p>{t.table.empty.text}</p>
            </EmptyTable>
        );
    }

    const atLeastOnePageAvailable = !!(pagination && (pagination.count / defaultPageSizeOptions[0].value) > 1);
    const isPaginationVisible = atLeastOnePageAvailable && !!data.length && !loading;

    return (
        <TableRootContainer>
            <TableContainer
                $shrinkHeight={!data.length || !!loading}
                $styles={tableContainerStyles}
                $scrollable={scrollable}
            >
                <TableContent
                    data={data}
                    setData={setData}
                    sensors={sensors}
                    sortMode={sortMode}
                    columns={columns}
                    loading={loading}
                    checkedItems={checkedItems}
                    onSort={onSort}
                    onCheckboxChange={onCheckboxChange}
                    onMainCheckboxChange={onMainCheckboxChange}
                    isItemSelectionAllowed={isItemSelectionAllowed}
                />
            </TableContainer>

            {isPaginationVisible && <Pagination {...pagination} />}

            {loading && <TableLoader />}
        </TableRootContainer>
    );
};

export default Table;
