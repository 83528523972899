import { flow } from "mobx";

import getRulesPacksServices from "../../api/rulesPacks/getRulesPacksServices";
import postRulePackService from "../../api/rulesPacks/postRulePackService";
import unlinkRulesPacksServices from "../../api/rulesPacks/unlinkRulesPacksServices";
import { NewRulePackServiceType, RulesPacksServicesTypes } from "../../types/rulesPacksServicesTypes";
import dataStore from "../common/dataStore";

class RulesPackServicesStore extends dataStore<RulesPacksServicesTypes, any, NewRulePackServiceType> {
    constructor() {
        super("RulesPackServicesStore");
    }

    protected postDataMethod = postRulePackService;

    protected unlinkSelectedItemMethod = unlinkRulesPacksServices;

    protected fetchDataByIdMethod = getRulesPacksServices;

    fetchData = flow(function* fetchData(this: RulesPackServicesStore, id: number | string) {
        yield this.fetchDataById({ rules_pack: id });
    });
}

const rulesPackServicesStore = new RulesPackServicesStore();

export default rulesPackServicesStore;
