import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent, useEffect } from "react";

import useTranslation from "../../../../../hooks/useTranslation";
import filtersPartnersStore from "../../../../../store/filters/partnersFilterStore";
import rulesPackPartnersStore from "../../../../../store/rulesPack/rulesPackPartnersStore";
import { PartnerFiltersTypes } from "../../../../../types/partnerTypes";
import { FormContainer, HeaderContainer, HeaderTitle } from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import { TextButton } from "../../../../ui/button/ButtonStyles";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { FieldMode } from "../../../../../constants/selectFields";

interface RulesPacksPartnersFiltersProps {
    ruleId?: string;
    closeModal: () => void;
}

const RulesPacksPartnersFilters = ({ ruleId, closeModal }: RulesPacksPartnersFiltersProps) => {
    const { t } = useTranslation();

    const initialValues: PartnerFiltersTypes = {
        ...rulesPackPartnersStore.filters,
    };

    const formik = useFormik<PartnerFiltersTypes>({
        initialValues,
        enableReinitialize: true,
        onSubmit: (fields) => {
            rulesPackPartnersStore.updateFilters(fields);
            closeModal();
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const resetFilters = () => {
        rulesPackPartnersStore.resetFilters();
        formik.resetForm();
    };

    useEffect(() => {
        filtersPartnersStore.fetchById(Number(ruleId), true);
    }, []);

    return (
        <>
            <HeaderContainer>
                <HeaderTitle>{t.entities.partners.filters}</HeaderTitle>
                <TextButton onClick={resetFilters}>{t.filters.reset}</TextButton>
            </HeaderContainer>
            <FormContainer onSubmit={handleSubmit}>
                <FloatSelect
                    formik={formik}
                    name="partner"
                    options={filtersPartnersStore.options}
                    mode={FieldMode.Multiple}
                    placeholder={t.filters.fields.partner}
                />
                <Button
                    variant="primary"
                    isLoading={rulesPackPartnersStore.loading}
                    type="submit"
                >
                    {t.filters.apply}
                </Button>
            </FormContainer>
        </>
    );
};

export default observer(RulesPacksPartnersFilters);
