import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const activateLists = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "lists/activate/",
    data,
});

export default activateLists;
