import styled from "styled-components";
import { colors } from "../../../../styles/variables";

export const HeaderAndQRSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TFAPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const QR = styled.canvas`
  display: block;
  margin: 0 auto 35px;
  width: 200px !important;
  height: 200px !important;
`;

export const KeySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;

  color: ${colors.activeText};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
`;

export const CopyKeyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${colors.inputDefaultBorder};
  padding: 20px 20px 19px;
  
  color: ${colors.activeText};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
`;

export const CopyButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
`;

export const SplitInputWithText = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;

  color: ${colors.activeText};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
`;

export const LinkAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  margin: 20px auto 0;
  
  img {
    width: 12px;
    height: 12px;
  }
`;
