import { useState } from "react";

import { permissionsMap } from "../../../../constants/permissions";
import { objectToValuesList } from "../../../../helpers";
import { toggleActionModalState } from "../../../../helpers/modalHelper";
import useTranslation from "../../../../hooks/useTranslation";
import CircleIcon from "../../../../resources/icons/circleIcon/CustomCircleIcon";
import limitStore from "../../../../store/limit/limitStore";
import { ActionModalState, ActionModalType } from "../../../../types/actionModalTypes";
import ActionModal from "../../../common/modal/actionModal/ActionModal";
import FiltersModal from "../../../filters/FiltersModal";
import { FilterIcon, PlusIcon } from "../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../ui/button/SmallButton";
import ProtectedLimitsMenu from "../components/LimitsMenu/ProtectedLimitsMenu";
import LimitsFilters from "../LimitsFilters";
import Link from "../../../ui/link/Link";

export function useTabsActions() {
    const { t } = useTranslation();

    const [isFiltersModalVisible, setFiltersModalVisibility] = useState(false);
    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });

    const toggleFiltersModal = () => setFiltersModalVisibility((prev) => !prev);
    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);

    const filtersCount = objectToValuesList(limitStore.filters || {}).length;
    const singleTitle = t.limits.title.slice(0, t.limits.title.length - 1);

    const AddButtonComponent = (
        <Link to={`/${t.limits.title.toLowerCase()}/new`}>
            <ProtectedSmallButton
                title={singleTitle}
                permission={permissionsMap.limits.add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
            />
        </Link>
    );
    const FiltersButtonComponent = (
        <SmallButton
            variant="primary"
            onClick={toggleFiltersModal}
            iconLeft={<FilterIcon />}
            iconRight={!!filtersCount && <CircleIcon isActive count={filtersCount} />}
        >
            {t.buttons.filter}
        </SmallButton>
    );
    const MenuDropdownComponent = (
        <ProtectedLimitsMenu
            store={limitStore}
            permission={permissionsMap.limits.changeStatus}
            toggleActionModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "filters-button-component", content: FiltersButtonComponent },
        { key: "menu-dropdown-component", content: MenuDropdownComponent },
    ];

    const ActionModalComponent = (
        <ActionModal
            store={limitStore}
            entityName={t.limits.text.partOfLink}
            modalState={actionModalState}
            toggleModal={toggleActionModal}
        />
    );

    const FilterModalComponent = (
        <FiltersModal isOpen={isFiltersModalVisible} setOn={toggleFiltersModal}>
            <LimitsFilters closeModal={toggleFiltersModal} />
        </FiltersModal>
    );

    return {
        FilterModalComponent, ActionModalComponent, actionHeaderContentList,
    };
}
