import { NotificationAction } from "../../components/pages/alerts/data";
import request from "../request";

interface NotificationStatusType {
    is_checked: boolean;
}

interface RequestType {
    id: number[],
}

export interface ToggleNotificationResponseType {
    message: string;
    response: {
        message: string;
        details: Record<number, NotificationStatusType>[];
    }
}

const toggleNotificationsStatus = (
    data: RequestType,
    action: NotificationAction,
) => request<ToggleNotificationResponseType>({
    method: "PATCH",
    uri: `notifications/${action}/`,
    data,
});

export default toggleNotificationsStatus;
