import React from "react";
import { ColoredTextField } from "./ColoredCellComponents";
import { TransactionStatus } from "../../../../types/transactionTypes";
import { capitalizeFirstLetter } from "../../../../helpers";

interface DataType {
    status: TransactionStatus;
}

type PropsType<D extends DataType> = {
    dataItem: D;
}

const ColoredStatus = <D extends DataType>({ dataItem }: PropsType<D>) => (
    <ColoredTextField value={dataItem.status.toLowerCase() as TransactionStatus}>
        {capitalizeFirstLetter(dataItem.status)}
    </ColoredTextField>
);

export default ColoredStatus;
