import styled from "styled-components";
import { ModalBodyText } from "../../../ui/TextStyle";
import { Row } from "../../../ui/new/NewComponents";

export const FormWrapper = styled.form`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
`;

export const TextareaRow = styled.div`
    grid-column-start: 1;
    grid-column-end: 3;
`;

export const RulesPacksModalBodyText = styled(ModalBodyText)`
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const RulesPacksModalRow = styled(Row)`
    width: 420px;
`;
