import { useMemo, useState } from "react";

import { permissionsMap } from "../../../../../constants/permissions";
import routes from "../../../../../constants/routes";
import { toggleActionModalState } from "../../../../../helpers/modalHelper";
import { useNavigation } from "../../../../../hooks/useNavigation";
import useTranslation from "../../../../../hooks/useTranslation";
import listElementsStore from "../../../../../store/list/listElementsStore";
import listStore from "../../../../../store/list/listStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { ActionModalState, ActionModalType } from "../../../../../types/actionModalTypes";
import { ElementListTypes } from "../../../../../types/listsTypes";
import ActionModal from "../../../../common/modal/actionModal/ActionModal";
import { CloneIcon, PlusIcon } from "../../../../ui/button/ButtonComponents";
import ProtectedSmallButton from "../../../../ui/button/ProtectedSmallButton";
import SmallButton from "../../../../ui/button/SmallButton";
import Link from "../../../../ui/link/Link";
import CloneListModal from "../../components/CloneListModal";
import ListsDetailsMenu from "../components/ListsDetailsMenu/ListsDetailsMenu";
import UploadListsElementsModal from "../components/UploadListsElementsModal.tsx/UploadListsElementsModal";

export function useTabsActions(listId?: string, selectedListType?: ElementListTypes | null) {
    const { t } = useTranslation();
    const { navigateBackward, navigateTo } = useNavigation();

    const isDownloadButtonDisabled = !listElementsStore.data.length || listElementsStore.isFileDownloading;
    const isUploadButtonDisabled = listElementsStore.isFileUploading;

    const [actionModalState, setActionModalState] = useState<ActionModalState>({ isVisible: false, type: "activate" });
    const [isCloneModalVisible, setCloneModalVisibility] = useState(false);
    const [isUploadModalVisible, setUploadModalVisibility] = useState(false);

    const toggleActionModal = (type?: ActionModalType) => toggleActionModalState(setActionModalState, type);
    const toggleCloneModal = () => setCloneModalVisibility((prev) => !prev);
    const toggleUploadModal = () => setUploadModalVisibility((prev) => !prev);

    const handleNavigate = () => navigateBackward();

    const downloadFile = async () => {
        if (listId) {
            await listElementsStore.downloadData(listId);

            if (listElementsStore.isDownloadFileSuccess) {
                snackbarStore.add({ variant: "success", text: t.lists.snackbar.addFileSuccess });
            } else if (listElementsStore.isDownloadFileFailed) {
                snackbarStore.add({ variant: "error", text: t.lists.snackbar.addFileError });
            }
        }
    };

    const customFetchMethod = () => listElementsStore.fetchData(listId || "");

    const handleCloseCloneModal = () => {
        if (listStore.pagination.page !== 1) listStore.setPaginationPage(1);

        navigateTo({ to: routes.lists.href });
        snackbarStore.add({ variant: "success", text: t.lists.snackbar.addSuccess });
    };

    const CloneButtonComponent = (
        <ProtectedSmallButton
            permission={permissionsMap.lists.add}
            variant="tertiary"
            iconLeft={<CloneIcon />}
            onClick={toggleCloneModal}
            title={t.buttons.clone}
        />
    );

    const BackButtonComponent = (
        <SmallButton variant="secondary" onClick={handleNavigate}>
            {t.buttons.back}
        </SmallButton>
    );

    const AddButtonComponent = (
        <Link
            to={`${routes.elements.href}/new`}
            state={{ id: listId, selectedType: selectedListType }}
        >
            <ProtectedSmallButton
                permission={permissionsMap.elements.add}
                variant="tertiary"
                iconLeft={<PlusIcon />}
                title={t.elements.partOfLink}
            />
        </Link>
    );

    const MenuDropdownComponent = (
        <ListsDetailsMenu
            store={listElementsStore}
            isDownloadButtonDisabled={isDownloadButtonDisabled}
            isUploadButtonDisabled={isUploadButtonDisabled}
            toggleUploadModal={toggleUploadModal}
            downloadFile={downloadFile}
            toggleActionModal={toggleActionModal}
        />
    );

    const actionHeaderContentList = [
        { key: "clone-button-component", content: CloneButtonComponent },
        { key: "back-button-component", content: BackButtonComponent },
    ];

    const tabActionsList = useMemo(() => [
        { key: "add-button-component", content: AddButtonComponent },
        { key: "menu-dropdown-button-component", content: MenuDropdownComponent },
    ], [permissionsMap, listElementsStore]);

    const ActionModalComponent = (
        <ActionModal
            entityName={t.elements.title}
            customFetchMethod={customFetchMethod}
            store={listElementsStore}
            toggleModal={toggleActionModal}
            modalState={actionModalState}
        />
    );

    const CloneListModalComponent = (
        <CloneListModal
            isOpen={isCloneModalVisible}
            title={t.lists.text.clone}
            buttonText={t.buttons.clone}
            handleClose={handleCloseCloneModal}
            setOn={toggleCloneModal}
        />
    );

    const UploadModalComponent = (
        <UploadListsElementsModal
            listId={listId}
            title={t.lists.text.upload}
            buttonText={t.lists.buttons.upload}
            isOpen={isUploadModalVisible}
            setOn={toggleUploadModal}
            handleClose={toggleUploadModal}
        />
    );

    return {
        CloneListModalComponent,
        tabActionsList,
        ActionModalComponent,
        UploadModalComponent,
        actionHeaderContentList,
    };
}
