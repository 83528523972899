import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { generateHeaderName } from "../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../hooks/useTranslation";
import transactionsStore from "../../../../store/transaction/transactionStore";
import SecondHeader from "../../../secondHeader/SecondHeader";
import { DetailsPageWrapper } from "../../../ui/details/DetailsComponents";
import { useTabsActions } from "./hooks/useTabsActions";
import CustomerInfo from "./components/sections/CustomerInfo";
import DeviceInfo from "./components/sections/DeviceInfo";
import MarkersInfo from "./components/sections/MarkersInfo";
import OrderInfo from "./components/sections/OrderInfo";
import PaymentInfo from "./components/sections/PaymentInfo";
import RulesPackInfo from "./components/sections/RulesPackInfo";
import TechnicalInfo from "./components/sections/TechnicalInfo";

const TransactionDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { selectedItem } = transactionsStore;

    const currentTitle = id ? `${t.transactions.text.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.transactions.title, currentTitle);

    const { BlockAllModalComponent, actionHeaderContentList } = useTabsActions();

    useEffect(() => {
        if (id) transactionsStore.fetchSelectedItem(id);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />
            <DetailsPageWrapper>
                <OrderInfo transaction={selectedItem} />
                <RulesPackInfo transaction={selectedItem} />
                <MarkersInfo transaction={selectedItem} />
                <CustomerInfo transaction={selectedItem} />
                <DeviceInfo transaction={selectedItem} />
                <PaymentInfo transaction={selectedItem} />
                <TechnicalInfo transaction={selectedItem} />
            </DetailsPageWrapper>
            {BlockAllModalComponent}
        </>
    );
};

export default observer(TransactionDetails);
