import request from "../request";
import { ProcessingTypes } from "../../types/processingTypes";

interface ResponseType {
    message: string,
    response: ProcessingTypes,
}

export const getSelectedProcessing = (processingId: string) => request<ResponseType>({
    method: "GET",
    uri: `entities/processings/${processingId}/`,
});
