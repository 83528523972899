import styled from "styled-components";
import { colors } from "../../../../../../styles/variables";

export const CheckInboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const IconAndHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const CheckInboxFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  color: ${colors.textDefault};

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
`;

export const MalIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 56px;
  height: 56px;
  border: none;
  background: none;
`;
