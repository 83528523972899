import { ItemType } from "antd/es/menu/hooks/useItems";
import { observer } from "mobx-react-lite";
import { memo } from "react";

import withPermission from "../../../../../../hocs/withPermissions";
import MenuDropdown from "../../../../../ui/menuDropdown/MenuDropdown";

interface MenuProps {
    menuItemsList: ItemType[],
}

type ProtectedRulesPackDetailsMenuProps = MenuProps & {
    permission: string;
}

const WrappedComponent = observer(({ menuItemsList }: MenuProps) => <MenuDropdown items={menuItemsList} />);

const ProtectedRulesPackDetailsMenu = ({ permission, menuItemsList }: ProtectedRulesPackDetailsMenuProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission menuItemsList={menuItemsList} />;
};

export default memo(ProtectedRulesPackDetailsMenu);
