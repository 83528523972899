import { AxiosError } from "axios";
import { flow, makeAutoObservable } from "mobx";
import patchEditUserProfile from "../../../api/users/patchEditUser";
import { getUserProfile } from "../../../api/users/getUserProfile";
import { parseDate } from "../../../helpers";
import { getFieldsErrors } from "../../../helpers/apiHelper";
import { FetchStatusesType } from "../../../types/fetchStatuses";
import { EditUserType, UserType } from "../../../types/userType";
import authStore from "../../auth/authStore";

const fieldsList: Array<keyof EditUserType> = ["username", "timezone", "info"];

class AccountStore {
    private _userProfile: UserType | null = null;

    private getUserProfileState: FetchStatusesType;

    private editUserProfileState: FetchStatusesType;

    public editUserProfileError?: any;

    public constructor() {
        makeAutoObservable(this);
        this.getUserProfileState = FetchStatusesType.unset;
        this.editUserProfileState = FetchStatusesType.unset;
    }

    public getUserProfile = flow(function* (this: AccountStore, token?: string) {
        try {
            if (token || authStore.isAuthenticated) {
                this.getUserProfileState = FetchStatusesType.pending;
                const response = yield getUserProfile(token);
                this._userProfile = response.data.response;
                authStore.setUseTFA(response.data.response.use_tfa);
                if (this._userProfile && response.data.response.created_at) {
                    this._userProfile.created_at = parseDate(response.data.response.created_at).toString();
                }
                this.getUserProfileState = FetchStatusesType.success;
            }
        } catch (error) {
            this.getUserProfileState = FetchStatusesType.failed;
        }
    });

    public editUserProfile = flow(function* (this: AccountStore, data: EditUserType) {
        try {
            this.editUserProfileState = FetchStatusesType.pending;
            const response = yield patchEditUserProfile(data);
            this._userProfile = { ...this._userProfile, ...response.data.response };
            this.editUserProfileState = FetchStatusesType.success;
        } catch (error) {
            const errorData = error as AxiosError;
            this.editUserProfileError = getFieldsErrors(errorData, fieldsList);
            this.editUserProfileState = FetchStatusesType.failed;
        }
    });

    public resetUserProfile = () => {
        this._userProfile = null;
    };

    resetEditError = (field: string) => {
        const newErrorsList = { ...this.editUserProfileError };
        delete newErrorsList[field];
        delete newErrorsList.common;
        this.editUserProfileError = newErrorsList;
    };

    public get userProfile() {
        return this._userProfile;
    }

    public get loading() {
        return this.getUserProfileState === FetchStatusesType.pending
            || this.editUserProfileState === FetchStatusesType.pending;
    }

    public get isEditProfileSuccess() {
        return this.editUserProfileState === FetchStatusesType.success;
    }

    public unsetEditState = () => {
        this.editUserProfileState = FetchStatusesType.unset;
        this.editUserProfileError = null;
    };

    public get editUserProfileErrors() {
        return this.editUserProfileError;
    }
}

const accountStore = new AccountStore();

export default accountStore;
