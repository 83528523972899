import { NewPartnerType, PartnerTypes } from "../../types/partnerTypes";
import request from "../request";

interface ResponseType {
    message: string,
    response: PartnerTypes,
}

export const postPartner = (data: NewPartnerType) => request<ResponseType>({
    method: "POST",
    uri: "entities/partners/",
    data,
});
