import request from "../request";
import { ServicesTypes } from "../../types/servicesTypes";

interface ResponseType {
    message: string,
    response: ServicesTypes,
}

export const getSelectedService = (serviceId: string) => request<ResponseType>({
    method: "GET",
    uri: `entities/services/${serviceId}/`,
});
