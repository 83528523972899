import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../../constants/permissions";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_USER_NAME_LENGTH } from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import permissionsStore from "../../../../../store/permissions/permissionsStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import rolesStore from "../../../../../store/user/roles/roleStore";
import { EditRoleType } from "../../../../../types/rolesTypes";
import { SwitchButtonContainer } from "../../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../../ui/modal/modalStyle";
import { Title } from "../../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../../ui/textField/StatusBadge";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof EditRoleType> = ["name", "description", "is_active"];

const EditRoleModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = rolesStore;

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(t.validation.required)
            .max(
                MAX_FIELD_USER_NAME_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_USER_NAME_LENGTH}`),
            ),
        description: Yup.string()
            .max(
                MAX_DESCRIPTION_FILED_LENGTH,
                translate(`validation.inputFieldMaxLengthError.${MAX_DESCRIPTION_FILED_LENGTH}`),
            ),
    });

    const formik = useFormik<EditRoleType>({
        initialValues: {},
        validationSchema,

        onSubmit: (values) => {
            if (id) {
                const permissionsIdsList = permissionsStore.addedItems.map((permission) => permission.id);
                const fieldsData = permissionsIdsList.length ? { ...values, permissions: permissionsIdsList } : values;

                rolesStore.patchData(fieldsData as EditRoleType, id, fieldsList).then(() => {
                    if (rolesStore.isEditItemSuccess) {
                        setOn(!isOpen);
                        snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    } else {
                        snackbarStore.add({ variant: "error", text: t.snackbar.add.error });
                    }
                });
            }
        },
    });

    const handleCloseModal = () => {
        rolesStore.unsetEditState();
        formik.resetForm();
    };

    const toggleRoleStatus = (event: ChangeEvent<HTMLInputElement>) => {
        if (formik.values.is_active) {
            snackbarStore.add({ variant: "warning", text: t.roles.snackbar.deactivateRole });
        }
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem, isOpen]);

    return (
        <Modal
            isForm
            gap="0"
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={rolesStore.loading}
            setOn={setOn}
            handleClose={handleCloseModal}
            handleSubmit={formik.handleSubmit}
        >
            <EditSectionWrapper $margin="32px 0">
                <FloatInput
                    formik={formik}
                    disabled={rolesStore.loading}
                    name="name"
                    defaultValue={selectedItem?.name}
                    isRequired
                    placeholder={t.table.header.name}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={rolesStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.roles.placeholders.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        disabled={rolesStore.loading}
                        permission={permissionsMap.roles.changeStatus}
                        checked={formik.values.is_active}
                        onChange={toggleRoleStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditRoleModal);
