import request from "../request";
import { EditListType, ListsTypes } from "../../types/listsTypes";

interface ResponseType {
    message: string,
    response: ListsTypes,
}

const patchList = (data: EditListType, listId: string | null) => request<ResponseType>({
    method: "PATCH",
    uri: `lists/${listId}/`,
    data,
});

export default patchList;
