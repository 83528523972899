import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import * as Yup from "yup";
import useTranslation from "../../../../../hooks/useTranslation";
import filtersTimezonesStore from "../../../../../store/filters/timezonesFilterStore";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { SnackbarVariantType } from "../../../../../types/snackbarTypes";
import { EditUserType } from "../../../../../types/userType";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import FloatInput from "../../../../ui/textInput/FloatInput";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import { Box } from "../../PagesSettingsComponents";
import accountStore from "../../../../../store/user/account/accountStore";

const EditPersonalInfoModal = ({ ...props }: ModalProps) => {
    const { t } = useTranslation();
    const { userProfile, editUserProfileErrors, resetEditError } = accountStore;
    const timezones = filtersTimezonesStore.options;

    const showSnackBar = (variant: SnackbarVariantType) => {
        snackbarStore.add({
            variant,
            text: variant === "success" ? t.snackbar.add.success : t.snackbar.add.error,
        });
    };

    const formik = useFormik<EditUserType>({
        initialValues: {},
        validationSchema: Yup.object({
            username: Yup.string().required(t.validation.required),
            timezone: Yup.string().required(t.validation.required),
            info: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            if (userProfile) {
                accountStore.editUserProfile(values).then(() => {
                    if (accountStore.isEditProfileSuccess) {
                        showSnackBar("success");
                        props.setOn(false);
                    } else {
                        showSnackBar("error");
                    }
                });
            }
        },
    });
    const handleCloseModal = () => {
        accountStore.unsetEditState();
        formik.resetForm();
    };

    useEffect(() => {
        if (props.isOpen) {
            if (!timezones?.length) {
                filtersTimezonesStore.fetch();
            }
        }

        return () => {
            handleCloseModal();
        };
    }, [props.isOpen]);

    useEffect(() => {
        formik.setValues({ ...userProfile });
    }, [userProfile, props.isOpen]);

    return (
        <Modal
            isOpen={props.isOpen}
            setOn={props.setOn}
            title={props.title}
            buttonText={props.buttonText}
            isLoading={accountStore.loading}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
            isForm
        >
            <FloatInput
                formik={formik}
                disabled={accountStore.loading}
                name="username"
                defaultValue={userProfile?.username}
                isRequired
                placeholder={t.table.header.name}
                serverErrors={editUserProfileErrors}
                resetServerError={resetEditError}
            />
            <Box>
                <FloatSelect
                    formik={formik}
                    disabled={accountStore.loading}
                    name="timezone"
                    options={timezones}
                    isRequired
                    placeholder={t.table.header.timezone}
                    serverErrors={editUserProfileErrors}
                    resetServerError={resetEditError}
                />
            </Box>
            <Box>
                <FloatInput
                    formik={formik}
                    disabled={accountStore.loading}
                    name="info"
                    defaultValue={userProfile?.info}
                    placeholder={t.table.header.info}
                    serverErrors={editUserProfileErrors}
                    resetServerError={resetEditError}
                />
            </Box>
        </Modal>
    );
};

export default observer(EditPersonalInfoModal);
