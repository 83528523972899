import request from "../request";
import { ChangeStatusResponseType } from "../../types/commonFiltersType";

interface RequestType {
    id: number[],
}

const deactivateServices = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "entities/services/deactivate/",
    data,
});

export default deactivateServices;
