import styled from "styled-components";
import { colors } from "../../../styles/variables";

export const PopoverMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const PopoverItem = styled.div<{ $disabled?: boolean }>`
    display: flex;
    height: 20px;
    padding: 8px 10px;
    justify-content: space-between;
    align-items: center;
    color: ${({ $disabled }) => ($disabled ? colors.textDisabled : colors.activeText)};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 105%;
`;

export const overlayStyle = {
    width: "310px",
    borderRadius: "12px",
    border: `1px solid ${colors.inputHoverBorder}`,
    padding: "10px",
    background: "#FFFFFF",
};

export const overlayInnerStyle = {
    boxShadow: "none",
    padding: 0,
};
