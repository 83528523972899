import { useState } from "react";

import useTranslation from "../../../../../../hooks/useTranslation";
import { ListType, TransactionFieldsTypes } from "../../../../../../types/transactionTypes";
import Button from "../../../../../ui/button/Button";
import Modal from "../../../../../ui/modal/Modal";
import NewTransactionListElement from "../NewTransactionListElement";
import AddToListModalBody from "./AddToListModalBody";
import { ButtonType, ModalParamsTypes } from "./types";
import elementStore from "../../../../../../store/element/elementStore";

enum ListTypeText {
    White = "White",
    Black = "Black",
}

enum ListCategory {
    Whitelisted = "whitelisted",
    Blacklisted = "blacklisted",
}

interface TransactionNotListedOperationButtonsProps {
    value?: string,
    label: string,
    fieldType: TransactionFieldsTypes,
    transactionId?: number,
}

type Translate = (text: string) => string

const getListModalOptions = (
    label: string,
    translate: Translate,
    listCategory: ListType,
    buttonType: ButtonType = "primary",
): ModalParamsTypes => ({
    title: translate(`transactions.buttons.lists.add.${listCategory}`),
    addButtonType: buttonType,
    listCategory,
    text: <AddToListModalBody
        action="add"
        type={listCategory}
        label={label}
        translate={translate}
    />,
});

const TransactionNotListedOperationButtons = ({
    value,
    label,
    transactionId,
    fieldType,
}: TransactionNotListedOperationButtonsProps) => {
    const { t, translate } = useTranslation();

    const [isAddModalVisible, setAddModalVisibility] = useState(false);
    const toggleAddModal = () => setAddModalVisibility(!isAddModalVisible);

    const [modalParams, setModalParams] = useState<ModalParamsTypes>(
        getListModalOptions(label, translate, ListCategory.Blacklisted),
    );

    const listType = modalParams.listCategory === ListCategory.Whitelisted ? ListTypeText.White : ListTypeText.Black;

    const openBlackListModal = () => {
        setModalParams(getListModalOptions(label, translate, ListCategory.Blacklisted, "error"));
        toggleAddModal();
    };

    const openWhiteListModal = () => {
        setModalParams(getListModalOptions(label, translate, ListCategory.Whitelisted));
        toggleAddModal();
    };

    return (
        <>
            <Button variant="primary" onClick={openBlackListModal}>
                {t.transactions.buttons.lists.add.blacklisted}
            </Button>
            <Button variant="primary" onClick={openWhiteListModal}>
                {t.transactions.buttons.lists.add.whitelisted}
            </Button>

            <Modal
                gap="15px"
                submitButton={false}
                title={modalParams.title}
                isLoading={elementStore.loading}
                modalBodyText={modalParams.text}
                isOpen={isAddModalVisible}
                setOn={toggleAddModal}
            >
                <NewTransactionListElement
                    entitiesValue={value}
                    fieldType={fieldType}
                    transactionId={transactionId}
                    addButtonType={modalParams.addButtonType}
                    listType={listType}
                    closeModal={toggleAddModal}
                />
            </Modal>
        </>

    );
};

export default TransactionNotListedOperationButtons;
