import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { RegExpressions } from "../../../../../constants/regex";
import { AuthRoutes } from "../../../../../constants/routes";
import useTranslation from "../../../../../hooks/useTranslation";
import authStore from "../../../../../store/auth/authStore";
import { InputSection } from "../../../../ui/textInput/InputStyles";
import TextField from "../../../../ui/textInput/TextInput";
import { HeaderText, TextLink } from "../../../../ui/TextStyle";
import { RightSidePageWrapper } from "../../../CommonPagesStyles";
import { FormWrapper, LogInFooter } from "../../AuthStyles";
import { HeaderSection } from "../../login/authTFA/AuthTFAStyles";
import { PasswordRecoveryContent } from "./PasswordRecoveryStyle";
import Button from "../../../../ui/button/Button";

type FieldsTypes = {
    email: string,
};

const PagesPasswordRecovery = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const errors = authStore.authErrors;
    const commonErrors = errors?.common;

    const handleNavigate = () => {
        authStore.unsetState();
        navigate(AuthRoutes.login.href);
    };

    const formik = useFormik<FieldsTypes>({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required(t.validation.emailIsRequired)
                .email(t.validation.email)
                .max(320, t.validation.email)
                .test(
                    "domain",
                    t.validation.email,
                    (email) => {
                        const domain = email?.split("@").pop() || "";
                        return RegExpressions.domain.test(domain);
                    },
                ),
        }),
        onSubmit: (fields) => {
            authStore.unsetState();
            authStore.sendRecoveryPasswordLink({ email: fields.email }).then(() => {
                const allErrors = authStore.authErrors?.common || authStore.authErrors?.email || "";
                if (allErrors) {
                    formik.setErrors({ email: allErrors });
                } else {
                    navigate(AuthRoutes.passwordResetCheckInbox.href);
                }
            });
        },
        onReset: () => authStore.unsetState(),
    });

    return (
        <RightSidePageWrapper>
            <PasswordRecoveryContent>
                <HeaderSection>
                    <HeaderText>
                        {t.passwordRecovery.text.recoveryPassword}
                    </HeaderText>
                    <div>
                        {t.passwordRecovery.text.enterEmail}
                    </div>
                </HeaderSection>

                <FormWrapper onSubmit={formik.handleSubmit}>
                    <InputSection>
                        <TextField
                            formik={formik}
                            name="email"
                            placeholder={t.passwordRecovery.input.email}
                            resetServerError={authStore.unsetState}
                            serverErrors={errors}
                            isCommonError={commonErrors}
                        />
                    </InputSection>

                    <Button variant="primary" type="submit" disabled={!!formik.errors.email}>
                        {t.password.reset}
                    </Button>

                    <LogInFooter>
                        <div>
                            <TextLink onClick={handleNavigate}>
                                {t.login.text.loginPage}
                            </TextLink>
                        </div>
                    </LogInFooter>
                </FormWrapper>
            </PasswordRecoveryContent>
        </RightSidePageWrapper>
    );
};

export default observer(PagesPasswordRecovery);
