import { ButtonHTMLAttributes } from "react";
import { ButtonComponent } from "./ButtonComponents";
import Loader from "../loader/Loader";
import { ButtonVariantType } from "../../../types/buttonTypes";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant: ButtonVariantType;
    isLoading?: boolean;
};

const Button = ({ isLoading, ...props }: ButtonProps) => (
    <ButtonComponent {...props} $variant={props.variant} $isLoading={isLoading}>
        <>
            {isLoading && <Loader variant={props.variant} />}
            {props.children}
        </>
    </ButtonComponent>
);

export default Button;
