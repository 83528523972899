import { Fragment } from "react";

import { permissionsMap } from "../../../../../../constants/permissions";
import Routes from "../../../../../../constants/routes";
import { capitalizeFirstLetter } from "../../../../../../helpers";
import useTranslation from "../../../../../../hooks/useTranslation";
import { TransactionInfoProps } from "../../../../../../types/transactionTypes";
import { DetailsBodyWrapper, DetailsSectionWrapper } from "../../../../../ui/details/DetailsComponents";
import HyperlinkTextInput from "../../../../../ui/textInput/HyperlinkTextInput";
import PreInput from "../../../../../ui/textInput/PreInput";
import { DetailPageHeader } from "../../../../../ui/TextStyle";
import { ColoredTextField, EmptyTitle } from "./SectionsComponents";

const splitStringByColon = (str: string): string[] => {
    if (!str) return [];

    const parts = str.split(":");
    const leftSide = parts[0].concat(":");
    const rightSide = parts[1]?.trim();

    return [leftSide, rightSide];
};

const getRouteByDecisionType = (str: string) => {
    if (!str) return "";

    const isLimitDecision = str.includes("limit");

    return isLimitDecision ? Routes.limits.href : Routes.lists.href;
};

const OrderInfo = ({ transaction }: TransactionInfoProps) => {
    const { t } = useTranslation();

    const hasRulesPacks = transaction?.rules_packs.length;

    return (
        <DetailsSectionWrapper>
            <DetailPageHeader>
                {t.transactions.text.rulesPackTitle}
            </DetailPageHeader>

            {hasRulesPacks ? (
                <DetailsBodyWrapper>
                    {transaction?.rules_packs.map((rulesPack) => {
                        const [value, id] = splitStringByColon(rulesPack?.details);
                        const route = getRouteByDecisionType(rulesPack?.details);
                        const detailsFieldPermission = value?.includes("list")
                            ? permissionsMap.lists.view : permissionsMap.limits.view;

                        const hasRulesPackInnerMessages = !!rulesPack.inner_messages?.length;

                        return (
                            <Fragment key={rulesPack?.id}>
                                <HyperlinkTextInput
                                    permission={permissionsMap.rules_packs.view}
                                    label={t.table.header.ruleName}
                                    hyperlinkName={rulesPack?.name}
                                    elementId={rulesPack?.id}
                                    navigateTo={Routes.rulesPacks.href}
                                />

                                <ColoredTextField
                                    label={t.table.header.decision}
                                    value={capitalizeFirstLetter(rulesPack?.decision || "")}
                                />

                                {
                                    hasRulesPackInnerMessages && rulesPack.inner_messages.map((message) => (
                                        <PreInput
                                            key={message}
                                            label={t.table.header.innerMessage}
                                            value={message}
                                        />
                                    ))
                                }

                                <HyperlinkTextInput
                                    permission={detailsFieldPermission}
                                    label={t.table.header.details}
                                    value={value}
                                    hyperlinkName={id}
                                    elementId={Number(id)}
                                    navigateTo={route}
                                    isGrid
                                />
                            </Fragment>
                        );
                    })}
                </DetailsBodyWrapper>
            ) : (
                <EmptyTitle>{t.transactions.text.rulesPacksEmpty}</EmptyTitle>
            )}
        </DetailsSectionWrapper>
    );
};

export default OrderInfo;
