import { flow } from "mobx";
import dataStore from "../../common/dataStore";
import { NewPartnerType, PartnerTypes } from "../../../types/partnerTypes";
import { getPartners } from "../../../api/partners/getPartners";

class ProcessingPartnersStore extends dataStore<PartnerTypes, any, NewPartnerType> {
    protected fetchDataByIdMethod = getPartners;

    fetchData = flow(function* fetchData(this: ProcessingPartnersStore, id: number | string) {
        yield this.fetchDataById({ processing: id });
    });
}

const processingPartnersStore = new ProcessingPartnersStore();

export default processingPartnersStore;
