import styled from "styled-components";

export const Row = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 6px;
`;

export const Text = styled.p`
    margin: 0;
    padding: 0;
`;
