import styled from "styled-components";
import { colors } from "../../../styles/variables";
import Button from "../../ui/button/Button";

export const SectionWrapper = styled.section`
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: auto;
    border-radius: 12px;
    background: ${colors.white};
`;

export const SecionContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const SectionHeader = styled.div`
    color: ${colors.activeText};
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
`;

export const ItemsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 20px 20px;
    align-self: stretch;
`;

export const Box = styled.div`
    display: flex;
    flex: 1;
    gap: 4px;
    flex-direction: column;
`;

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const SecurityContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const SecurityButton = styled(Button)`
    height: 56px;
    width: 360px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
`;

export const ChangePasswordModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ChangePasswordModalTitle = styled.h3`
    color: ${colors.activeText};
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 105%;
    margin: 0;
`;

export const ChangePasswordModalText = styled(ChangePasswordModalTitle).attrs({
    as: "p",
})`
    color: ${colors.textDefault};
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
`;

export const Description = styled.p`
    color: ${colors.activeText};
`;

export const DeactivateModalDescription = styled.p`
    color: ${colors.activeText};
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    margin: 30px 0 15px;
`;

export const DeactivateModalBody = styled.div`
    margin-bottom: 30px;
`;
