import { observer } from "mobx-react-lite";
import { memo, useEffect } from "react";

import Routes from "../../../../constants/routes";
import withPermission from "../../../../hocs/withPermissions";
import serviceStore from "../../../../store/entities/service/serviceStore";
import { ServicesTypes } from "../../../../types/servicesTypes";
import Table from "../../../ui/table/Table";
import { multiplyTableContainerStyle } from "../../../ui/table/TableComponents";
import { ColumnType } from "../../../ui/table/types";
import TableFieldsDetailsLink from "../../../ui/tableFields/detailsLink/TableFieldsDetailsLink";
import TableFieldsStatusBadge from "../../../ui/tableFields/statusBadge/TableFieldsStatusBadge";
import { TableMainLink } from "../../../ui/tableFields/nameLink/TableMainLink";

interface ProtectedServicesTableProps {
    isCheckboxVisible: boolean;
    permission: string;
}

type WrappedComponentProps = Pick<ProtectedServicesTableProps, "isCheckboxVisible">

const columns: ColumnType<ServicesTypes>[] = [
    { field: "checkbox", cellStyles: { width: "0px" } },
    {
        header: "table.header.serviceName",
        field: "name",
        isResizable: true,
        width: "180px",
        getField: (dataItem) => (
            <TableMainLink
                to={`${Routes.services.href}/${dataItem.id}`}
            >
                {dataItem.name}
            </TableMainLink>
        ),
    },
    { header: "table.header.serviceId", field: "id", sortable: true },
    { header: "table.header.isActive", field: "is_active", Component: TableFieldsStatusBadge },
    {
        header: "table.header.partnerName",
        field: "partner_name",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.partnerId", field: "partner", sortable: true },
    {
        header: "table.header.email",
        field: "email",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.phone", field: "phone" },
    {
        header: "table.header.accountManager",
        field: "account_manager",
        isResizable: true,
        width: "180px",
    },
    { header: "table.header.timezone", field: "timezone" },
    {
        header: "table.header.description",
        field: "description",
        isResizable: true,
        width: "180px",
    },
    {
        header: "table.header.details",
        field: "details",
        cellStyles: { width: "0px" },
        getField: (dataItem) => (
            <TableFieldsDetailsLink dataItem={dataItem} href={Routes.services.href} />
        ),
    },
];

const WrappedComponent = observer(({ isCheckboxVisible }: WrappedComponentProps) => {
    const tablePagination = {
        ...serviceStore.pagination,
        setPaginationPage: serviceStore.setPaginationPage,
        setPaginationSize: serviceStore.setPaginationSize,
    };

    useEffect(() => {
        serviceStore.fetch();
    }, [serviceStore.pagination, serviceStore.filters, serviceStore.ordering]);

    return (
        <Table
            scrollable
            tableContainerStyles={multiplyTableContainerStyle}
            columns={columns}
            data={serviceStore.data}
            pagination={tablePagination}
            checkedItems={serviceStore.checkedItems}
            sortMode={serviceStore.ordering}
            loading={serviceStore.loading}
            onSort={serviceStore.toggleSort}
            onCheckboxChange={serviceStore.toggleItem}
            onMainCheckboxChange={serviceStore.toggleMainItem}
            isItemSelectionAllowed={isCheckboxVisible}
        />
    );
});

const ProtectedServicesTable = ({ isCheckboxVisible, permission }: ProtectedServicesTableProps) => {
    const ComponentWithPermission = withPermission(WrappedComponent, permission);
    return <ComponentWithPermission isCheckboxVisible={isCheckboxVisible} />;
};

export default memo(ProtectedServicesTable);
