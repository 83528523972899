import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from "react-router-dom";
import { useNavigation } from "../../../hooks/useNavigation";
import { LocationState } from "../../../types/locationTypes";

type LinkProps = Omit<ReactRouterLinkProps, "state"> & {
    state?: LocationState;
}

const Link = ({ children, state: newState, ...props }: LinkProps) => {
    const { getUpdatedState } = useNavigation();
    const state = getUpdatedState(newState);

    return (
        <ReactRouterLink state={state} {...props}>
            {children}
        </ReactRouterLink>
    );
};

export default Link;
