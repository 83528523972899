import { Dispatch, ReactElement, SetStateAction } from "react";
import {
    SortedHeader, SortIconComponent, TableCheckBoxComponent, TH, THContainer, TR,
} from "../../TableComponents";
import THHeader from "../ResizableTableHeader/ResizableTableHeader";
import { ColumnType, DataType, TableProps } from "../../types";
import useTranslation from "../../../../../hooks/useTranslation";

interface TableHeadProps<D extends DataType> extends TableProps<D> {
    columnWidths: { [key: string]: number; }
    setColumnWidths: Dispatch<SetStateAction<({ [key: string]: number; })>>
}

const TableHead = <D extends DataType>({
    data,
    sortMode,
    columns,
    checkedItems,
    onSort,
    onMainCheckboxChange,
    columnWidths,
    setColumnWidths,
    isItemSelectionAllowed,
}: TableHeadProps<D>): ReactElement => {
    const { translate } = useTranslation();

    const allItemsChecked = !!data.length && checkedItems?.length === data.length;

    const getTextAndSortIcon = (column: ColumnType<D>) => {
        const DESCSortMode = (sortMode?.[0] === "-");
        const sortModeWithoutMinus = DESCSortMode ? sortMode.slice(1, sortMode.length) : sortMode;
        const currentColumn = sortModeWithoutMinus === column.field;

        return (
            <>
                <SortedHeader>
                    {translate(column.header)}
                </SortedHeader>
                <SortIconComponent
                    $colorizeASC={currentColumn && !DESCSortMode}
                    $colorizeDESC={currentColumn && DESCSortMode}
                />
            </>
        );
    };

    const getHeader = (column: ColumnType<D>) => {
        if (typeof column.header === "string") {
            if (column.sortable) {
                return getTextAndSortIcon(column);
            }
            return translate(column.header);
        }
        return "";
    };

    const onColumnClick = (column: ColumnType<D>) => {
        if (!column.sortable || !onSort) return undefined;

        if (sortMode === column.field) {
            return onSort(`-${column.field}`);
        }

        if (sortMode === `-${column.field}`) {
            return onSort(null);
        }
        return onSort(column.field);
    };

    const handleColumnResize = (field: string, newWidth: number) => {
        const updatedWidths = { ...columnWidths };
        updatedWidths[field] = newWidth;
        setColumnWidths(updatedWidths);
    };

    const getHeaderContent = (column: ColumnType<D>) => {
        if (isItemSelectionAllowed && column.field === "checkbox" && onMainCheckboxChange) {
            return <TableCheckBoxComponent checked={allItemsChecked} onChange={onMainCheckboxChange} />;
        }
        return getHeader(column);
    };

    return (
        <thead>
            <TR $isHighlighted={false}>
                {columns?.map((column) => {
                    const {
                        field, sortable, isResizable, padding, headerStyles,
                    } = column;

                    const headerContent = getHeaderContent(column);

                    return (
                        <TH key={field} $sortable={sortable || false} $width={columnWidths[field]}>
                            <THHeader
                                {...(isResizable && { width: columnWidths[field] })}
                                onResize={(newWidth: number) => handleColumnResize(field, newWidth)}
                            >
                                <THContainer
                                    $padding={padding}
                                    $styles={headerStyles}
                                    onClick={() => onColumnClick(column)}
                                    $isHoverActive={headerContent !== ""}
                                >
                                    {headerContent}
                                </THContainer>
                            </THHeader>
                        </TH>
                    );
                })}
            </TR>
        </thead>
    );
};

export default TableHead;
