import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { permissionsMap } from "../../../../constants/permissions";
import { MAX_DESCRIPTION_FILED_LENGTH, MAX_FIELD_NAME_LENGTH } from "../../../../constants/validationRules";
import useTranslation from "../../../../hooks/useTranslation";
import rulesPacksFiltersStore from "../../../../store/filters/rulesPacksFiltersStore";
import listStore from "../../../../store/list/listStore";
import snackbarStore from "../../../../store/snackbar/snackbarStore";
import { EditListType } from "../../../../types/listsTypes";
import { SwitchButtonContainer } from "../../../ui/details/DetailsComponents";
import Modal, { ModalProps } from "../../../ui/modal/Modal";
import { EditSectionWrapper } from "../../../ui/modal/modalStyle";
import { Title } from "../../../ui/new/NewComponents";
import ProtectedSwitch from "../../../ui/switch/ProtectedSwitch";
import StatusBadge from "../../../ui/textField/StatusBadge";
import FloatInput from "../../../ui/textInput/FloatInput";
import FloatTextArea from "../../../ui/textInput/FloatTextArea";

const fieldsList: Array<keyof EditListType> = ["name", "is_active", "is_global", "rules_pack"];

const EditListModal = ({
    isOpen,
    title,
    buttonText,
    setOn,
}: ModalProps) => {
    const { t, translate } = useTranslation();
    const { id: listId } = useParams();
    const { selectedItem, editItemErrors, resetEditError } = listStore;

    const formik = useFormik<EditListType>({
        initialValues: {},
        validationSchema: Yup.object({
            name: Yup.string().required(t.validation.required)
                .max(MAX_FIELD_NAME_LENGTH, translate(`validation.inputFieldMaxLengthError.${MAX_FIELD_NAME_LENGTH}`)),
        }),
        onSubmit: async (values) => {
            if (listId) {
                await listStore.patchSelectedItem(values, listId, fieldsList);
                if (listStore.isEditItemSuccess) {
                    snackbarStore.add({ variant: "success", text: t.snackbar.editSuccess });
                    setOn(false);
                } else {
                    snackbarStore.add({ variant: "error", text: t.snackbar.editError });
                }
            }
        },
    });

    const handleCloseModal = () => {
        listStore.unsetEditState();
        formik.setValues({ ...selectedItem });
        formik.setErrors({});
    };

    const toggleListStatus = (event: ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue("is_active", event.target.checked);
    };

    useEffect(() => {
        formik.setValues({ ...selectedItem });
    }, [selectedItem, isOpen]);

    useEffect(() => {
        if (isOpen) rulesPacksFiltersStore.fetch();

        return () => handleCloseModal();
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            buttonText={buttonText}
            isLoading={listStore.loading}
            setOn={setOn}
            handleSubmit={formik.handleSubmit}
            handleClose={handleCloseModal}
            isForm
        >
            <EditSectionWrapper>
                <FloatInput
                    formik={formik}
                    disabled={listStore.loading}
                    name="name"
                    defaultValue={selectedItem?.name}
                    isRequired
                    placeholder={t.table.header.listName}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />
                <FloatTextArea
                    formik={formik}
                    disabled={listStore.loading}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    placeholder={t.table.header.description}
                    serverErrors={editItemErrors}
                    resetServerError={resetEditError}
                />

                <SwitchButtonContainer>
                    <Title>{t.partners.text.status}</Title>
                    <StatusBadge
                        variant={formik.values.is_active ? "success" : "inactive"}
                        multiLanguageText={formik.values.is_active ? "status.active" : "status.inactive"}
                    />
                    <ProtectedSwitch
                        disabled={listStore.loading}
                        checked={formik.values.is_active}
                        permission={permissionsMap.lists.changeStatus}
                        onChange={toggleListStatus}
                    />
                </SwitchButtonContainer>
            </EditSectionWrapper>
        </Modal>
    );
};

export default observer(EditListModal);
