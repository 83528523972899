import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MAX_DATE } from "../../../../constants/dates";
import { permissionsMap } from "../../../../constants/permissions";
import RoutesLinks from "../../../../constants/routes";
import { parseDate } from "../../../../helpers";
import { generateHeaderName } from "../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../hooks/useTranslation";
import elementStore from "../../../../store/element/elementStore";
import DetailsHeader from "../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../secondHeader/SecondHeader";
import SmallButton from "../../../ui/button/SmallButton";
import { DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper } from "../../../ui/details/DetailsComponents";
import PreInput from "../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../ui/textInput/TextareaLabelInRow";
import EditElementModal from "./EditElementModal";
import { LocationState } from "../../../../types/locationTypes";
import { useNavigation } from "../../../../hooks/useNavigation";

const ElementDetails = () => {
    const navigate = useNavigate();

    const { selectedItem } = elementStore;
    const { t } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();

    const currentTabTitle = id ? `${t.elements.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.elements.title, currentTabTitle);

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const getListDate = () => {
        const listDate = selectedItem?.time_on_the_list;

        if (!listDate) return "";

        if (listDate === MAX_DATE) return t.placeholder.forever;

        return `${t.placeholder.until} ${parseDate(listDate)}`;
    };

    const preInputParamsList = [
        {
            key: "elements-details-name",
            label: t.placeholder.elementName,
            value: selectedItem?.name || "",
        },
        {
            key: "elements-details-id",
            label: t.placeholder.elementId,
            value: selectedItem?.id?.toString() || "",
        },
        {
            key: "elements-details-list-name",
            label: t.placeholder.listName,
            value: selectedItem?.list_name || "",
        },
        {
            key: "elements-details-list-id",
            label: t.placeholder.listId,
            value: selectedItem?.list?.toString() || "",
        },
        {
            key: "elements-details-list-time",
            label: t.placeholder.timeOnTheList,
            value: getListDate(),
        },
    ];

    const handleNavigate = () => navigateBackward();

    const actionHeaderContentList = [
        {
            key: "element-details-back-button",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    useEffect(() => {
        if (id) elementStore.fetchSelectedItem(id);
    }, []);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContentList} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.elements.partOfLink} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.elements.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputParamsList.map((input) => <PreInput {...input} />)}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow
                        label={t.placeholder.description}
                        value={selectedItem?.description || ""}
                    />
                </DetailsSectionWrapper>

                <EditElementModal
                    isOpen={isEditModalVisible}
                    title={t.elements.edit.title}
                    buttonText={t.buttons.save}
                    setOn={toggleEditModal}
                />
            </DetailsPageWrapper>
        </>
    );
};

export default observer(ElementDetails);
