import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { permissionsMap } from "../../../../../constants/permissions";
import { generateHeaderName } from "../../../../../helpers/secondHeaderHelper";
import useTranslation from "../../../../../hooks/useTranslation";
import employeeStore from "../../../../../store/user/employee/employeeStore";
import DetailsHeader from "../../../../detailsHeader/DetailsHeader";
import SecondHeader from "../../../../secondHeader/SecondHeader";
import SmallButton from "../../../../ui/button/SmallButton";

import {
    DetailsBodyWrapper, DetailsPageWrapper, DetailsSectionWrapper,
} from "../../../../ui/details/DetailsComponents";
import { ReactComponent as ResendIcon } from "../../../../../resources/icons/secondHeader/resendEmail.svg";
import PreInput from "../../../../ui/textInput/PreInput";
import TextareaLabelInRow from "../../../../ui/textInput/TextareaLabelInRow";
import EditEmployeesModal from "./EditEmployeesModal";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { useNavigation } from "../../../../../hooks/useNavigation";
import { Tabs } from "../../../../../constants/tabs";

const EmployeeDetails = () => {
    const { t, concatenate } = useTranslation();
    const { id } = useParams();
    const { navigateBackward } = useNavigation();
    const { selectedItem } = employeeStore;

    const [isEditModalVisible, setEditModalVisibility] = useState(false);
    const toggleEditModal = () => setEditModalVisibility((prev) => !prev);

    const currentTitle = id ? `${t.employees.text.partOfLink} №${id}` : "";
    const headerTitle = generateHeaderName(t.employees.title, currentTitle);

    const resendEmail = async () => {
        if (selectedItem?.email) {
            await employeeStore.resendEmail(selectedItem.email);
            if (employeeStore.isPostRequestSuccess) {
                const snackbarSuccessEmailText = concatenate(
                    t.employees.snackbar.addSuccessEmail,
                    { email: selectedItem.email },
                );
                snackbarStore.add({ variant: "success", text: snackbarSuccessEmailText });
            } else {
                const snackbarFailedEmailText = concatenate(
                    t.employees.snackbar.addErrorEmail,
                    { email: selectedItem.email },
                );
                snackbarStore.add({ variant: "error", text: snackbarFailedEmailText });
            }
        }
    };

    const handleNavigate = () => navigateBackward({ tab: Tabs.EMPLOYEES });

    const resendEmailButton = {
        key: "ResendEmail",
        content: (
            <SmallButton
                variant="tertiary"
                onClick={resendEmail}
                iconLeft={<ResendIcon width={16} height={16} />}
                isLoading={employeeStore.loading}
                isCapitalized={false}
            >
                {t.buttons.resendEmail}
            </SmallButton>
        ),
        isDisplayed: (selectedItem && selectedItem.first_login === true) ?? false,
    };

    const actionHeaderContent = [
        resendEmailButton,
        {
            key: "BackToEmployees",
            content: (
                <SmallButton variant="secondary" onClick={handleNavigate}>
                    {t.buttons.back}
                </SmallButton>
            ),
        },
    ];

    const preInputsParamsList = [
        { label: t.filters.fields.name, value: selectedItem?.username, key: "employee-details-username" },
        { label: t.table.header.email, value: selectedItem?.email, key: "employee-details-email" },
        { label: t.table.header.role, value: selectedItem?.role_name, key: "employee-details-role-name" },
        { label: t.table.header.createdBy, value: selectedItem?.created_by_name, key: "employee-details-created-by" },
        { label: t.table.header.createdAt, value: selectedItem?.created_at, key: "employee-details-created-at" },
        { label: t.table.header.timezone, value: selectedItem?.timezone, key: "employee-details-timezone" },
        {
            label: t.table.header.processingName,
            value: selectedItem?.processing_name || t.placeholder.allProcessings,
            key: "employee-details-processing-name",
        },
    ];

    useEffect(() => {
        if (id) employeeStore.fetchSelectedItem(id);
    }, [id]);

    return (
        <>
            <SecondHeader title={headerTitle} actionsContent={actionHeaderContent} />

            <DetailsPageWrapper>
                <DetailsSectionWrapper>
                    <DetailsHeader
                        title={`${t.employees.text.partOfLink} ${t.details.title}`}
                        isStatusActive={selectedItem?.is_active}
                        permission={permissionsMap.employees.change}
                        onEdit={toggleEditModal}
                    />

                    <DetailsBodyWrapper>
                        {preInputsParamsList.map((input) => {
                            const { value, label, key } = input;

                            return <PreInput value={value} label={label} key={key} />;
                        })}
                    </DetailsBodyWrapper>

                    <TextareaLabelInRow label={t.employees.placeholders.info} value={selectedItem?.info} />
                </DetailsSectionWrapper>

                <EditEmployeesModal
                    isOpen={isEditModalVisible}
                    setOn={toggleEditModal}
                    title={t.employees.edit.title}
                    buttonText={t.buttons.save}
                />
            </DetailsPageWrapper>
        </>

    );
};

export default observer(EmployeeDetails);
