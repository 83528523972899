import { useFormik } from "formik";
import {
    FC, FormEvent, useEffect, useMemo, useState,
} from "react";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../../constants/validationRules";
import { FormContainer } from "../../../../filters/FiltersModalComponents";
import useTranslation from "../../../../../hooks/useTranslation";
import FloatSelect from "../../../../ui/textInput/FloatSelect";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import { Row } from "../../../../ui/details/DetailsComponents";
import transactionsStore from "../../../../../store/transaction/transactionStore";
import Button from "../../../../ui/button/Button";
import RadioButton from "../../../../ui/radioButton/RadioButton";
import elementStore from "../../../../../store/element/elementStore";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import { ModalButtonRow, RadioButtonLabel, RadioButtonRow } from "./sections/SectionsComponents";
import filtersTransactionListsStore from "../../../../../store/filters/transactionListsFiltersStore";
import { TransactionFieldsTypes } from "../../../../../types/transactionTypes";

interface NewTransactionListElementProps {
    entitiesValue?: string;
    addButtonType: "primary" | "error";
    listType: "Black" | "White";
    transactionId?: number;
    fieldType: TransactionFieldsTypes;
    closeModal: () => void;
}

interface FormTypes {
    name?: string,
    list: number | null,
    description: string,
}

const fieldsList = ["list", "name"];

const NewTransactionListElement: FC<NewTransactionListElementProps> = ({
    entitiesValue,
    addButtonType,
    listType,
    transactionId,
    fieldType,
    closeModal,
}) => {
    const { t } = useTranslation();
    const [selectedGlobal, setSelectedGlobal] = useState(true);

    const [globalLists, localList] = useMemo(() => {
        const { options } = filtersTransactionListsStore || {};
        const global: typeof options = [];
        const local: typeof options = [];
        if (!options) return [global, local];

        options.forEach((option) => {
            if (option.type === fieldType) {
                if (option.is_global) {
                    global.push(option);
                } else {
                    local.push(option);
                }
            }
        });

        return [global, local];
    }, [filtersTransactionListsStore.options]);

    const validationSchema = Yup.object({
        list: Yup.string().required(t.validation.required),
        description: Yup.string().nullable(),
    });

    const formik = useFormik<FormTypes>({
        initialValues: {
            name: entitiesValue,
            list: null,
            description: "",
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (fields) => {
            elementStore.postData(fields, fieldsList).then(() => {
                if (elementStore.isPostRequestSuccess) {
                    closeModal();
                    snackbarStore.add({ variant: "success", text: t.snackbar.add.success });
                    if (transactionId) transactionsStore.fetchSelectedItem(transactionId);
                } else {
                    const errors = elementStore.postErrors?.common || elementStore.postErrors.name;
                    snackbarStore.add({ variant: "error", text: errors });
                    elementStore.resetPostItemState();
                }
            });
        },
    });

    const handleCategoryChange = () => {
        if (formik.values.list) formik.setFieldValue("list", null);
        setSelectedGlobal(!selectedGlobal);
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    useEffect(() => {
        if (transactionId) filtersTransactionListsStore.fetch(transactionId, listType);

        return () => filtersTransactionListsStore.clear();
    }, []);

    return (
        <FormContainer onSubmit={handleSubmit}>
            <Row>
                <RadioButtonRow>
                    <RadioButton
                        value
                        checked={selectedGlobal}
                        onChange={handleCategoryChange}
                        disabled={elementStore.loading}
                    />
                    <RadioButtonLabel $checked={selectedGlobal}>
                        {t.transactions.buttons.global}
                    </RadioButtonLabel>
                </RadioButtonRow>

                <RadioButtonRow>
                    <RadioButton
                        value={false}
                        checked={!selectedGlobal}
                        onChange={handleCategoryChange}
                        disabled={elementStore.loading}
                    />
                    <RadioButtonLabel $checked={!selectedGlobal}>
                        {t.transactions.buttons.local}
                    </RadioButtonLabel>
                </RadioButtonRow>
            </Row>

            <FloatSelect
                formik={formik}
                name="list"
                options={selectedGlobal ? globalLists : localList}
                isRequired
                placeholder={t.filters.fields.list}
                disabled={elementStore.loading}
            />

            <FloatTextArea
                formik={formik}
                placeholder={t.placeholder.description}
                name="description"
                autoSize
                maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                disabled={elementStore.loading}
            />

            <ModalButtonRow $marginTop="24px">
                <Button
                    variant="secondary"
                    type="button"
                    onClick={closeModal}
                >
                    {t.buttons.cancel}
                </Button>
                <Button
                    variant={addButtonType}
                    type="submit"
                    isLoading={elementStore.loading}
                >
                    {t.buttons.add}
                </Button>
            </ModalButtonRow>
        </FormContainer>
    );
};

export default observer(NewTransactionListElement);
