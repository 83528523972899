import { ChangeStatusResponseType } from "../../types/commonFiltersType";
import request from "../request";

interface RequestType {
    id: number[],
}

const deactivateAlerts = (data: RequestType) => request<ChangeStatusResponseType>({
    method: "PATCH",
    uri: "alerts/deactivate/",
    data,
});

export default deactivateAlerts;
