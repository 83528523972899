import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { ListType } from "../../../types/transactionTypes";

export type StatusTextFieldVariants = "success" | "primary" | "inactive" | ListType

export const StatusBadgeComponent = styled.p<{ $variant: StatusTextFieldVariants, width?: string }>`
    display: flex;
    width: ${(props) => props.width || "65px"};
    padding: 4px 8px;
    margin: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    background-color: ${(props) => {
        switch (props.$variant) {
            case "success":
                return colors.successBackground;
            case "primary":
                return colors.buttonPrimary;
            case "blacklisted":
                return colors.error;
            case "whitelisted":
                return colors.successBackground;
            case "inactive":
                return colors.inputDefaultBorder;
            default:
                return colors.white;
        }
    }};

    color: ${(props) => {
        switch (props.$variant) {
            case "inactive":
                return colors.activeText;
            default:
                return colors.white;
        }
    }};
`;
