import { ChangeEvent, FocusEventHandler, useState } from "react";
import { FormikTypes } from "../types/formikTypes";

interface FieldPropsTypes {
    value?: (string | number | boolean) & (string | number | readonly string[]);
    regex?: RegExp;
    onChange?: (event: ChangeEvent<HTMLInputElement> & ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: FocusEventHandler<HTMLInputElement> & FocusEventHandler<HTMLTextAreaElement>;
}

interface InputPropsTypes {
    fieldProps: FieldPropsTypes;
    name: string;
    formik?: FormikTypes;
    serverErrors?: Record<string, string>;
    isCommonError?: string;
    resetServerError?: (field: string) => void;
}

const useFloatInput = ({
    fieldProps, name, formik, serverErrors, isCommonError, resetServerError,
}: InputPropsTypes) => {
    const [focus, setFocus] = useState(false);

    let {
        value,
        // eslint-disable-next-line prefer-const
        regex,
        onChange,
        onBlur,
    } = fieldProps;
    let isError = false;
    let error;

    if (formik) {
        value = formik.values[name];
        onChange = onChange ?? formik.handleChange;
        onBlur = onBlur ?? formik.handleBlur;
        error = serverErrors?.[name] || (formik?.touched[name] && (formik.errors[name]));
        isError = !!error || !!isCommonError;
    }

    const isOccupied = !!(focus || (value !== undefined && value !== null && value.toString().length !== 0));

    function handleChange<T extends HTMLTextAreaElement & HTMLInputElement>(event: ChangeEvent<T>): void {
        if (regex && !regex.test(event.target.value)) {
            return;
        }

        formik?.setFieldTouched(name || "", false);
        resetServerError?.(name || "");
        onChange?.(event);
    }

    return {
        isFieldError: isError,
        fieldError: error,
        fieldValue: value,
        isOccupied,
        setFocus,
        handleChange,
        handleBlur: onBlur,
    };
};

export default useFloatInput;
