import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { FormEvent } from "react";
import * as Yup from "yup";

import { MAX_DESCRIPTION_FILED_LENGTH } from "../../../../../constants/validationRules";
import useTranslation from "../../../../../hooks/useTranslation";
import snackbarStore from "../../../../../store/snackbar/snackbarStore";
import transactionsStore from "../../../../../store/transaction/transactionStore";
import { PostAllAttributesToBlacklistRequestType } from "../../../../../types/transactionTypes";
import { FormContainer } from "../../../../filters/FiltersModalComponents";
import Button from "../../../../ui/button/Button";
import Modal, { ModalProps } from "../../../../ui/modal/Modal";
import FloatTextArea from "../../../../ui/textInput/FloatTextArea";
import AddToListModalBody from "./operationButtons/AddToListModalBody";
import { ModalButtonRow } from "./sections/SectionsComponents";

const BlockAllModal = ({ isOpen, setOn }: ModalProps) => {
    const { t, translate } = useTranslation();
    const { selectedItem } = transactionsStore;

    const formik = useFormik<PostAllAttributesToBlacklistRequestType>({
        initialValues: {
            card_hash: selectedItem?.card_hash ?? "",
            customer_email: selectedItem?.email ?? "",
            customer_phone: selectedItem?.phone ?? "",
            customer_id: selectedItem?.customer_id ?? "",
            device_id: selectedItem?.device_id ?? "",
        },

        validationSchema: Yup.object({ description: Yup.string().nullable() }),

        enableReinitialize: true,

        onSubmit: async (fields) => {
            if (selectedItem?.id) {
                await transactionsStore.addAllAttributesToBlacklist(fields);

                if (transactionsStore.isPostRequestSuccess) {
                    setOn(false);
                    snackbarStore.add({ variant: "success", text: t.transactions.modal.add.success });
                    transactionsStore.fetchSelectedItem(selectedItem.id);
                    formik.resetForm();
                } else {
                    const errorMessage = transactionsStore.postErrors ?? t.snackbar.addError;
                    snackbarStore.add({ variant: "error", text: errorMessage });
                    transactionsStore.resetPostItemState();
                }
            }
        },
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formik.handleSubmit(event);
    };

    const closeModal = () => {
        formik.resetForm();
        setOn(false);
    };

    return (
        <Modal
            submitButton={false}
            title={t.transactions.buttons.lists.add.plural.blacklisted}
            modalBodyText={(
                <AddToListModalBody
                    action="add"
                    actionType="plural"
                    type="globalBlacklisted"
                    label={t.transactions.modal.entities}
                    translate={translate}
                />
            )}
            isOpen={isOpen}
            isLoading={transactionsStore.loading}
            handleClose={closeModal}
            setOn={setOn}
        >
            <FormContainer onSubmit={handleSubmit} gap="0">
                <FloatTextArea
                    formik={formik}
                    placeholder={t.placeholder.description}
                    name="description"
                    autoSize
                    maxLength={MAX_DESCRIPTION_FILED_LENGTH}
                    disabled={transactionsStore.loading}
                />
                <ModalButtonRow $marginTop="24px">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={closeModal}
                    >
                        {t.buttons.cancel}
                    </Button>
                    <Button
                        variant="error"
                        type="submit"
                        isLoading={transactionsStore.loading}
                        disabled={transactionsStore.loading}
                    >
                        {t.buttons.add}
                    </Button>
                </ModalButtonRow>
            </FormContainer>
        </Modal>
    );
};

export default observer(BlockAllModal);
