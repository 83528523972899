import styled from "styled-components";
import { Radio as AntDRadioButton } from "antd";
import { colors } from "../../../styles/variables";

export const RadioButtonComponent = styled(AntDRadioButton)`
        /* .ant-checkbox-inner {
        background-color: ${colors.white};
        border: 1px solid ${colors.inputDefaultBorder};
    }

    &&:not(.ant-checkbox-wrapper-disabled) {
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.buttonPrimary};
            border-color: ${colors.buttonPrimary};
        }

        &&:hover .ant-checkbox-inner {
            background-color: ${colors.buttonDefaultBackground};
            border-color: ${colors.buttonPrimary};
        }

        &&:hover .ant-checkbox-checked .ant-checkbox-inner {
            border-color: ${colors.buttonPrimaryHover};
            background-color: ${colors.buttonPrimaryHover};
        }
    }

    &&.ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner {
            background-color: ${colors.buttonDisabledBackgroundSecondary};
            border-color: ${colors.buttonDisabledBackground};
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${colors.buttonDisabledBackground};

        }
    }*/

`;
