import { createPermissions } from "../helpers/permissionsHelper";
import { PermissionsTypes } from "../types/permissionsTypes";

export const permissionsMap: PermissionsTypes = {
    lists: createPermissions("list"),
    limits: createPermissions("limit"),
    elements: createPermissions("element"),
    partners: createPermissions("partner"),
    services: createPermissions("service"),
    processings: createPermissions("processing"),
    rules_packs: createPermissions("rulespack"),
    rules_pack_services: createPermissions("servicerulespack"),
    rules_pack_partner: createPermissions("partnerrulespack"),
    alerts: createPermissions("alert"),
    notifications: createPermissions("notification"),
    tg_groups: createPermissions("telegramgroup"),
    employees: createPermissions("user"),
    roles: createPermissions("role"),
    transactions: createPermissions("transaction"),
};
